<template>
    <div v-if="showMe">
        <section v-if="!showForm">
            <section>
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12 padding-none text-center">
                        <div class="nav-cleaning">
                            <div class="title">
                                <label class="text-white">PAYMENT METHOD</label>
                            </div>
                            <div v-if="showBackBtn" class="back">
                            <a @click.prevent="back()" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="gray-bg ">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-wrp text-center">
                            <img src="~@/assets/images/card.png" class="py-5">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="location-details bg-white">
                <div class="container-fluid">
                    <div class="row">
                        <div v-for="(item, l) in items" :key="l" v-touch:swipe.left="(event) => paymentSwipeHandler(event, item, l)" v-touch:swipe.right="(event) => hidePaymentHandler(event, item, l)" class="col-lg-12 px-0 mb-2 swipeable">
                            <div v-touch:tap="(event) => setDefault(event, l)"  class="inner" :class="item.show_swipe ? 'show' : ''">
                                <div class="pmt-wrp wrp mb-2 px-3 py-2">
                                    <div class="d-flex" style="justify-content: center;align-items: center;">
                                        <img v-if="item.card.brand == 'visa'" src="~@/assets/images/visa.png" style="width: 50px;"/>
                                        <img v-else-if="item.card.brand == 'mastercard'" src="~@/assets/images/mastercard.png" style="width: 50px;" />
                                        <i v-else class="fa fa-credit-card-alt" aria-hidden="true" style="font-size: 34px;"></i>
                                        <h6 class="mb-0" style="margin-left: 20px;">{{item.card.last4}}</h6>
                                    </div>
                                    <div v-if="item.id == default_payment_id">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <!-- <div class="gary-divider-slim"></div> -->
                            </div>
                            <div @click="removePaymentMethod(item)" class="action-button delete" data-bs-toggle="modal" data-bs-target="#delModal">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>  
            </section>
            <div class="add-card">
                <button @click.prevent="addNew" type="button" class="btn"> <i class="fa fa-plus"></i> Add New Card</button>
            </div>
        </section>
        <section v-else>
            <section class="">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 padding-none text-center">
                            <div class="nav-cleaning">
                                <div class="title">
                                    <label class="text-white">ADD A CARD</label>
                                </div>
                                <div class="back">
                                    <a @click.prevent="showForm=false" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                                </div>
                                <a @click.prevent="save" href="#" class="btn" style="position: absolute;right: 0;"><i class="fa fa-check text-white"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="gray-bg ">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-wrp text-center">
                            <img src="~@/assets/images/card.png" class="py-5">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="address-form">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 mt-1">
                            <input v-model="form.number" v-maska="'#### #### #### ####'" 
                                :class="'form-control ' + (errors.number? 'is-invalid' : '') "
                                type="text" placeholder="Card Number" />
                            <small class="text-danger" v-if=" errors.number">{{ errors.number }}</small>
                        </div>
                        <div class="col-sm-6 mt-1">
                            <input 
                                v-model="form.expiration_date" @keyup="checkExpDate" v-maska="'##/##'" 
                                :class="'form-control ' + (errors.expiration_date? 'is-invalid' : '') "
                                type="text" placeholder="Expiration Date"/>
                            <small class="text-danger" v-if=" errors.expiration_date">{{ errors.expiration_date }}</small>
                        </div>
                        <div class="col-sm-6 mt-1">
                            <input 
                                v-model="form.cvc" v-maska="'###'" 
                                :class="'form-control ' + (errors.cvc ? 'is-invalid' : '') "
                                type="text" class="form-control" placeholder="CVC"/>
                            <small class="text-danger" v-if=" errors.cvc">{{ errors.cvc }}</small>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
    <div class="modal" id="delModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Confirm Delete</h4>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
            <h6>Are you sure you want to delete this card?</h6>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button @click="to_be_deleted={}" type="button" class="btn text-danger" data-bs-dismiss="modal">CANCEL</button>
                <button @click="confirmDelete" type="button" class="btn text-danger" data-bs-dismiss="modal">OK</button>
            </div>

            </div>
        </div>
    </div>
</template>

<script>
import { get as getPaymentMethods, deletePaymentMethod, add as addPaymentMethod, setDefault as setDefaultPaymentMethod } from "@/api/payment-methods"
import $ from 'jquery'
import moment from 'moment'
export default {
  name: 'PaymentMethods',
  components: {
    
  },
  data() {
    return {
        showBackBtn: true,
        showHeader: true,
        showMe: this.visible,
        showForm: false,
        hasError: false,
        errors: {},
        errMsg: '',
        processing: false,
        items: [],
        default_payment_id: '',
        form:{
            id: '',
            number: '',
            month: '',
            year: '',
            cvc: '',
            expiration_date: '',
        },
        to_be_deleted: {}
    }
  },
  props: {
    visible: Boolean,
    view: String,
    hide_back_btn: Boolean,
  },
  emits: ['data', 'close'],
  watch: {
    visible: function (newVal) {
        this.showMe = newVal
    },
    view: function(newVal) {
        if(newVal == 'form') {
            this.showForm = true
        }else {
            this.showForm = false
        }
    },
    items: function (newVal) {
        this.$emit('data', newVal)
    },
  },
  methods: {
    back() {
        // console.log(this.$route)
        if(this.$route.name == 'Payments') {
            window.location.href = window.location.origin + '/#/' ;
        }else {
            this.$emit('close')
        }
    },
    validateForm() {
        // const $this = this
        this.hasError = false;
        this.errMsg = '';
        this.errors = {}

        if (!this.form.cvc) {
            this.hasError = true;
            this.errors.cvc = 'CVC is required'
        }

        if (!this.form.expiration_date) {
            this.hasError = true;
            this.errors.expiration_date = 'Expiration Date is required';
        }

        if (!this.form.number) {
            this.hasError = true;
            this.errors.number = 'Card Number is required';
        }

        if(this.form.expiration_date.length == 5) {
            let day = moment(this.form.year + '-' + this.form.month, "YYYY-MM").daysInMonth(),
                date = moment(this.form.year + '-' + this.form.month+'-'+day);
            if(!date.isValid()) {
                this.hasError = true;
                this.errors.expiration_date = 'Expiration Date is invalid';
                this.form.expiration_date = ''
                this.form.year = ''
                this.form.month = ''
            }else {
                // let now = moment();
                // if(!date.isSameOrAfter(now)) {
                //     this.hasError = true;
                //     this.errors.expiration_date = 'Your card\'s expiration year is invalid';
                // }
                
                // console.log(new Date(date))
                // console.log(new Date())
                // if(new Date(date).getTime() < new Date().setHours(0,0,0,0)) {
                //     this.hasError = true;
                //     this.errors.expiration_date = 'Your card\'s expiration year is invalid';
                // }
            }
        }
        
    },
    async getModels() {
        const $this = this
        $this.$root.isLoading = true;  
        await getPaymentMethods().then(response => {
            // console.log(response)
            $this.$root.isLoading = false;  
            let items = [];
            $(response.data.data).each(function(i, item) {
                item.show_swipe = false;
                items.push(item)
            })
            $this.items = items
            if(items.length > 0) {
                $this.default_payment_id = $this.items[0].id
            }
            
        }).catch(error => {
            $this.processing = false;
            this.$swal.fire(
                '',
                error.response.data.message,
                'error'
            )
            // console.log(error)
        })
    },
    paymentSwipeHandler(event, item, key) {
        console.log(event)
        console.log(item)
        console.log(key)
        this.items[key].show_swipe = true
        // item.show_swipe = true
        // console.log('swiper')
    },
    hidePaymentHandler(event, item, key) {
        console.log(event)
        console.log(item)
        console.log(key)
        // item.show_swipe = false
        this.items[key].show_swipe = false
        // console.log('hide')
    },
    removePaymentMethod(item) {
        console.log(item)
        this.to_be_deleted = item
    },
    async confirmDelete() {
        const $this = this
        $this.$root.isLoading = true;  
        await deletePaymentMethod(this.to_be_deleted.id).then(() => {
            $this.$root.isLoading = false;  
            $this.getModels()
        }).catch(error => {
            $this.$root.isLoading = false;  
            console.log(error);
            $this.$swal.fire(
                '',
                error.response.data.message,
                'error'
            )
        })
    },
    checkExpDate() {
        // console.log(this.form.expiration_date)
        let date_arr = this.form.expiration_date.split("/")
        // console.log(date_arr)
        let month = date_arr[0], year = date_arr.length > 1 ? date_arr[1] : ''
        // console.log('month: ' + month)
        // console.log('year: ' + year)

        if(Number(month) > 1) {
            this.form.expiration_date = ('0'+month).slice(-2) + '/' + year
        }

        this.form.month = ('0'+month).slice(-2);
        this.form.year = '20' + year
    },
    addNew() {
        this.form = {
            id: '',
            number: '',
            month: '',
            year: '',
            cvc: '',
            expiration_date: ''
        }

        this.showForm=true
    },
    async save() {
        const $this = this

        this.validateForm();

        if (this.hasError) {
           return;
        }

        if (this.processing === true) {
          return;
        }

        this.processing = true;
        $this.$root.isLoading = true;  

        let formData = new FormData();
        Object.keys(this.form).forEach(key => {
          if($this.form[key] !== null && $this.form[key] !== 'null') {
            if(Array.isArray($this.form[key])) {
              for (var i = 0; i < $this.form[key].length; i++) {
                if($this.form[key][i] !== undefined)
                  formData.append(key+'[]', $this.form[key][i]);
              }
            }else {
                if(key == 'is_default') {
                    formData.append(key, $this.form[key] ? 1 : 0)
                }
                else {
                    formData.append(key, $this.form[key])
                }
                
            }
          }
        });

        await addPaymentMethod(formData).then(() => {
            $this.$root.isLoading = false;  
            $this.processing = false;
            $this.showForm = false;
            $this.getModels();
        }).catch(error => {
            $this.$root.isLoading = false;  
            $this.processing = false;
            console.log(error);
            // $this.$swal.fire(
            //     '',
            //     error.response.data.message,
            //     'error'
            // )
            $this.$swal.fire({
              title: error.response.data.errors ? error.response.data.errors : error.response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#f44336'
            })
        })
    },
    async setDefault(event, key) {
        const $this = this
        $this.$root.isLoading = true;  
        // console.log('set default')
        console.log(event)
        // console.log(key)
        // console.log(this.items[key].card)
        await setDefaultPaymentMethod(this.items[key].id).then(() => {
            $this.$root.isLoading = false; 
            $this.getModels();
        }).catch(error => {
            $this.$root.isLoading = false;  
            // $this.$swal.fire(
            //     '',
            //     error.response.data.message,
            //     'error'
            // )
            $this.$swal.fire({
              title: error.response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#f44336'
            })
            console.log(error.errors);
        })
    },
    
  },
  created() {
    
    if(this.hide_back_btn) this.showBackBtn = !this.hide_back_btn

    $('.modal-backdrop.fade.show').remove()
    if(this.$root.isLoggedIn) this.getModels()
  }
}
</script>