<template>
  <TheHeader :hide_back="true"/>
  <div class="div-resposnsive">
    <section class="header-inner">
      <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h6>Favourites</h6>
        </div>
      </div>
      </div>
    </section>   
    <div class="main-container">
      <section class="post-tabs tabs-bg-white bg-white">
        <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
              <div id="tab-container" class="tab-content">
                <div class="tab-pane container active" id="posts-tab">
                  <a v-for="(item, l) in items" :key="l" @click.prevent="setMerchant(item)" data-bs-toggle="modal" data-bs-target="#merchant-favourite-modal" class="fav-wrp">
                      <div class="user-info-wrp fave-info-wrp">
                        <div class="img-wrp">
                            <div v-if="item.favourite.profile.avatar" class="circle-avatar" 
                              :style="'background-image: url('+item.favourite.profile.avatar.medium_thumb_url+')'"></div>
                            <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user" style="max-width: 50px;">
                            <img v-if="item.favourite.profile.ovvy_verified == 1" src="~@/assets/img/verified_user.png" class="varification-img">
                        </div>
                        
                      </div>
                    <div class="inner" style="margin-left: 10px;padding-bottom: 5px;">
                      <div class="text">
                        <h5 class="text-dark mb-0">{{item.favourite.full_name}}</h5>
                        <small>Last online: {{$root.formatDate(item.favourite.last_login, 'ddd Do MMMM, hh:mm A')}}</small>
                        <br/>
                        <div style="display: flex;flex-wrap: wrap;gap: 5px;">
                          <span v-for="(category, key) in item.favourite.categories" :key="key">
                            <img :src="category.category.link_icon" style="width: 25px"/>
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>

                  <p v-if="items.length == 0" class="text-center mt-5"><em>No favourite merchants yet</em></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- <div class="space-maker"></div>    -->
  <TheFooter/>

  <div class="modal" id="merchant-favourite-modal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div v-if="hasMerchant" class="modal-body">
          <div class="row">
            <div class="col-md-12 fav-wrp">
              <div class="user-info-wrp" style="height: 70px !important;">
                <div class="img-wrp">
                    <div v-if="merchant.favourite.profile.avatar" class="circle-avatar" 
                      :style="'background-image: url('+merchant.favourite.profile.avatar.medium_thumb_url+')'"></div>
                    <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user" style="max-width: 50px;">
                    <img v-if="merchant.favourite.profile.ovvy_verified == 1" src="~@/assets/img/verified_user.png" class="varification-img">
                </div>
              </div>
              <div class="inner" style="margin-left: 10px;">
                <div class="text">
                  <h5 class="text-dark mb-0">{{merchant.favourite.full_name}}</h5>
                </div>
              </div>
            </div>
            <div class="col-md-12 px-0">
              <ul id="fave-cat-list">
                <li v-for="(item, l) in merchant.favourite.categories" :key="l">
                  <input type="radio" v-model="category" :value="item.category"/>
                  <label>
                    <span style="margin-right: 10px;margin-left: 10px;position: relative;top: 5px;">
                      <img :src="item.category.link_icon" style="width: 25px"/>
                    </span>
                    <span style="position: relative;top: 5px;">
                      {{item.category.name}}
                    </span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="col-md-12 px-0">
              <div class="buttons">
                <a href="#" class="close btn"  data-bs-dismiss="modal">CLOSE</a>
                <button :disabled="!category" @click="goCreateJob()" class="repost btn">REVIEW DETAILS</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/common/TheHeader'
import TheFooter from '@/components/common/TheFooter'
import { getFaves} from "@/api"

export default {
  name: 'FavouritesView',
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      items: [],
      merchant: {},
      hasMerchant: false,
      category: ''
    }
  },
  methods: {
    setMerchant(merchant) {
      this.hasMerchant = true
      this.merchant = merchant
    },
    goCreateJob() {
      window.location.href = '/#/jobs/new/' + this.$root.encrypt((this.category.id).toString()) + '?_vid=' + this.$root.encrypt((this.merchant.favourite_id).toString());
    },
    async getModels() {
      this.$root.isLoading = true;
      await getFaves().then(response => {
        // console.log(response.data)
        this.items = response.data
        this.$root.isLoading = false;
      })
    },
  },
  watch: {
    // activeTab: function(newVal) {
    //   console.log(newVal)
    //   $('#myTab .nav-link').removeClass('active')
    //   this.getJobs()
    // },
  },
  mounted() {
    this.getModels()
  }
}

</script>
<style scoped>
#fave-cat-list {
  list-style-type: none;
  padding-left: 0px !important;
}

#fave-cat-list input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
  width: 100%;
  height: 100%;
}

#fave-cat-list li {
  border-bottom: 1px solid #e8ebf3;
  padding: 20px;
}

#fave-cat-list input[type="radio"]:checked + label {
    background:#dfdfdf;
}

#fave-cat-list li {
  position:relative;
}

#fave-cat-list label, #fave-cat-list input {
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

#merchant-favourite-modal .buttons {
  display: flex;
  margin-top: 25px;
}

#merchant-favourite-modal .buttons a, #merchant-favourite-modal .buttons button{
    width: 50%;
    border-radius: 0;
    padding: 8px;
}

#merchant-favourite-modal .buttons .close {
    background: gray;
    color: #fff;
}

#merchant-favourite-modal .buttons .repost {
    background: #FE4D45;
    color: #fff;
}

.circle-avatar {
  width: 50px;
  height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50px;
  -moz-background-clip: padding;
  border-radius: 50px;
  background-clip: padding-box;
  background-size: cover;
  background-position: center center;
}
.fav-wrp {
  display: flex;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e7e3e370;
}
.fave-info-wrp {
    height: 100px !important;
}
</style>