<template>
    <!--BREADCRUMBS -->
    <section v-if="job.client_activity_type == 'accepted' || 
        (job.client_activity_type == 'post' && job.bid_count > 0) ||
        (job.client_activity_type == 'complete' && (job.client_status == 'Paid' || job.client_status == 'Complete'))" 
        class="job-status">
        <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12">
                <ul>
                <li>
                    <img src="~@/assets/images/postjob.png">
                    <span>1. Post job</span>
                    </li>
                    <li :class="job.client_activity_type == 'post' ? 'active' : ''">
                    <img src="~@/assets/images/compare.png" style="width:38px;">
                    <span>2. Compare</span>
                    </li>
                    <li :class="job.client_activity_type == 'accepted' ? 'active' : ''">
                    <img src="~@/assets/images/acceptandpay.png">
                    <span>3. Accept & Pay</span>
                    </li>
                    <li :class="job.client_activity_type == 'complete' ? 'active' : ''">
                    <img src="~@/assets/images/complete.png">
                    <span>4. Complete</span>
                    </li>
                </ul>
            </div>
            </div>
        </div>
      </section>

      <section class="user-info">
        <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="user-info-wrp height-more">
                  <div class="img-wrp">
                    <div v-if="bid.vendor.profile.avatar" class="circle-avatar" :style="'background-image: url('+bid.vendor.profile.avatar.medium_thumb_url+')'"></div>
                    <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user">
                    <img v-if="loaded && bid.vendor.profile.ovvy_verified == 1" src="~@/assets/img/verified_user.png" class="varification-img">
                  </div>
                    <h5>{{bid.vendor.full_name}}</h5>
                    <ul class="rating">
                        <li v-for="n in 5" :key="n"><i :class="n <= Math.round(Number(bid.vendor.merchant_reviews_avg_rating)) ? 'fa fa-star' : 'fa fa-star-o'"></i> </li>
                        <li> <label class="text-white">{{Number(bid.vendor.merchant_reviews_avg_rating).toFixed(2)}} Rating</label> </li>
                    </ul>
                    <h6>Last Online: {{$root.formatDate(bid.vendor.last_login, 'ddd Do MMMM, hh:mm A')}}</h6>
                    <div class="buttons">
                      <a :href="'/#/job/' + $root.encrypt((bid.job_id).toString()) + '/vendor/'+ $root.encrypt((bid.vendor_id).toString()) +'?_b=job'"  class="btn view-btn">View Profile</a>
                      <a :href="'/#/job/' + $root.encrypt((bid.job_id).toString()) + '/chat/'+ $root.encrypt((bid.vendor_id).toString()) +'?_b=job'" class="btn chat-btn">Chat</a>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      
      <section>
        <section class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Job ID</h6>
                </div>
            </div>
          </div>
        </section>
        <section class="bg-white view-white">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>{{job.id}}</h6>
                </div>
            </div>
          </div>
        </section>
        <section v-if="bid.comment != '' && bid.comment != null" class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Merchant Comments</h6>
                </div>
            </div>
          </div>
        </section>
        <section v-if="bid.comment != '' && bid.comment != null" class="bg-white view-white">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>{{bid.comment}}</h6>
                </div>
            </div>
          </div>
        </section>
        <section class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Schedule</h6>
                </div>
            </div>
          </div>
        </section>
        <section class="bg-white view-white">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6 class="mt-3 p-0"> <i class="fa fa-calendar"></i> &nbsp; {{$root.formatDate(bid.date_start, 'ddd Do MMM')}}, {{$root.convertTimeTo12(bid.time_start)}}</h6>
                    <h6 class="p-0"> <i class="fa fa-map-marker" style="font-size:36px;"></i>&nbsp;&nbsp; {{$root.getFullAddress(job.locations[0])}}</h6>
                </div>
            </div>
          </div>
        </section>
        <section class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Job Details</h6>
                </div>
            </div>
          </div>
        </section>
        <section class="bg-white view-white">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div @click="$parent.showBidDetailsPage = false; $parent.showBidJobDetailsPage = true" class="flex-wrp-center" style="padding: 10px 0px;cursor: pointer;">
                  <div class="wrp d-flex" style="align-items: center;">
                    <div class="icon">
                      <img :src="job.category.link_icon" style="width:35px;margin-right: 10px;"/>
                    </div>
                    <div class="text">
                      <h5>{{job.title}}</h5>
                    </div>
                  </div>
                  <i class="fa fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Payment Amount</h6>
                </div>
            </div>
          </div>
        </section>
        <section class="amonut">
          <!-- EXTRA FEE DISPLAY -->
          <div v-if="jobActivityType == 'accepted' && bid.paid_status == 'paid_callout' && Number(bid.price) > 0" class="container-fluid">
            <div class="row">
                <div class="col-lg-12">

                  <div class="d-flex justify-content-between">
                    <h6>Service Fee Charge</h6>
                    <h5>${{this.$root.commaDecimal(bid.callout_fee)}} 
                      {{bid.paid_status == 'paid_callout' && Number(bid.price) > 0 ? ' (PAID)' : ''}}</h5>
                  </div>

                  <div v-if="bid.paid_status == 'paid_callout' && Number(bid.price) > 0 && (Number(bid.callout_credit) + Number(job.fixed_price_credit)) > 0" class="d-flex justify-content-between">
                      <h6>Service Fee Transaction Credit Used</h6>
                      <h5>- ${{this.$root.commaDecimal(Number(bid.callout_credit) + Number(job.fixed_price_credit))}}</h5>
                  </div>

                  <div v-if="job.promo" class="d-flex justify-content-between txt-green">
                      <h6>Promo({{ job.promo.promo.promo_code }})</h6>
                      <h5>- ${{this.$root.commaDecimal(Number(job.promo.discount))}}</h5>
                  </div>

                  <div v-if="bid.promo" class="d-flex justify-content-between txt-green">
                      <h6>Promo({{ bid.promo.promo.promo_code }})</h6>
                      <h5>- ${{this.$root.commaDecimal(Number(bid.promo.discount))}}</h5>
                  </div>

                  <hr />

                  <div v-if="bid.price != '' && Number(bid.price) > 0" class="d-flex justify-content-between">
                    <h6>Extra Fee</h6>
                    <h5>${{this.$root.commaDecimal(bid.price)}} </h5>
                      <!-- {{bid.paid_status == 'paid_service_fee' ? ' (PAID)' : ''}} -->
                  </div>

                  <div v-if="form.credits_used > 0" class="d-flex justify-content-between">
                      <h6>Credits Used</h6>
                      <h5>- ${{this.$root.commaDecimal(form.credits_used)}}</h5>
                  </div>

                </div>
            </div>
          </div>
          <div v-else class="container-fluid">
            <div class="row">
                <div class="col-lg-12">

                  <div class="d-flex justify-content-between">
                    <h6>Service Fee Charge</h6>
                    <h5>${{this.$root.commaDecimal(bid.callout_fee)}} 
                      {{bid.paid_status == 'paid_callout' && Number(bid.price) > 0 ? ' (PAID)' : ''}}</h5>
                  </div>

                  <div v-if="bid.price != '' && Number(bid.price) > 0" class="d-flex justify-content-between">
                    <h6>Extra Fee</h6>
                    <h5>${{this.$root.commaDecimal(bid.price)}} </h5>
                      <!-- {{bid.paid_status == 'paid_service_fee' ? ' (PAID)' : ''}} -->
                  </div>

                  <div v-if="bid.price != '' && Number(bid.price) > 0" class="d-flex justify-content-between">
                    <h6>Subtotal</h6>
                    <h5>${{this.$root.commaDecimal(Number(bid.callout_fee) + Number(bid.price))}}</h5>
                  </div>

                  <hr v-if="promo_discount > 0 || form.credits_used > 0 || total_credit_used > 0 || bid.promo" />

                  <div v-if="bid.paid_status == 'paid_callout' && Number(bid.price) > 0" class="d-flex justify-content-between">
                      <h6>Transaction Credit Used</h6>
                      <h5>- ${{this.$root.commaDecimal(Number(bid.callout_credit) + Number(job.fixed_price_credit))}}</h5>
                  </div>

                  <div v-if="form.credits_used > 0" class="d-flex justify-content-between">
                      <h6>Credits Used</h6>
                      <h5>- ${{this.$root.commaDecimal(form.credits_used)}}</h5>
                  </div>

                  <div v-if="total_credit_used > 0" class="d-flex justify-content-between">
                      <h6>Credits Used</h6>
                      <h5>- ${{this.$root.commaDecimal(total_credit_used)}}</h5>
                  </div>

                  <div v-if="promo_discount > 0" class="d-flex justify-content-between txt-green">
                      <h6>Promo({{ form.promo.promo_code }})</h6>
                      <h5>- ${{this.$root.commaDecimal(promo_discount)}}</h5>
                  </div>

                  <div v-if="bid.promo" class="d-flex justify-content-between txt-green">
                      <h6>Promo({{ bid.promo.promo.promo_code }})</h6>
                      <h5>- ${{this.$root.commaDecimal(Number(bid.promo.discount))}}</h5>
                  </div>

                  <div v-if="job.promo" class="d-flex justify-content-between txt-green">
                      <h6>Promo({{ job.promo.promo.promo_code }})</h6>
                      <h5>- ${{this.$root.commaDecimal(Number(job.promo.discount))}}</h5>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <section v-if="ifShowCredits()" class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Use Credits</h6>
                </div>
            </div>
          </div>
        </section>
        <section v-if="ifShowCredits()" class="credits-details">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="credits text-center">
                        <button @click="openCreditsModal()" class="btn" data-bs-toggle="modal" >{{form.credits_used > 0 ? 'Credits used $' + $root.commaDecimal(form.credits_used) : 'Use Credits'}}</button>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Payment Details</h6>
                </div>
            </div>
          </div>
        </section>
        <section class="payment-details">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                        <div>
                            <button v-if="jobActivityType == 'post'" @click="ifShowCredits() ? paymentMethodModalShow=true : ''" class="btn dropdown-toggle" type="button">
                                <img v-if="form.payment.card && form.payment.card.brand == 'visa'" src="~@/assets/images/visa.png" style="max-width: 50px;"/> 
                                <img v-else-if="form.payment.card && form.payment.card.brand == 'mastercard'" src="~@/assets/images/mastercard.png" style="max-width: 50px;"/>
                                <i v-else-if="form.payment.card" class="fa fa-credit-card-alt" aria-hidden="true" style="font-size: 20px;"></i>
                                <i v-else class="fa fa-credit-card" aria-hidden="true" style="font-size: 20px;"></i>
                                {{form.payment.card ? form.payment.card.last4 : 'Payment'}}
                            </button>
                            <button v-else-if="job.payment_method" class="btn dropdown-toggle" type="button">
                                <img v-if="job.payment_method.card && job.payment_method.card.brand == 'visa'" src="~@/assets/images/visa.png" style="max-width: 50px;"/> 
                                <img v-else-if="job.payment_method.card && job.payment_method.card.brand == 'mastercard'" src="~@/assets/images/mastercard.png" style="max-width: 50px;"/>
                                <i v-else-if="job.payment_method.card" class="fa fa-credit-card-alt" aria-hidden="true" style="font-size: 20px;"></i>
                                <i v-else class="fa fa-credit-card" aria-hidden="true" style="font-size: 20px;"></i>
                                {{job.payment_method.card ? job.payment_method.card.last4 : 'Payment'}}
                            </button>
                        </div>
                        <div class="divider-small"></div>
                        <a v-if="bid.promo" @click.prevent="" class="promo_code-cont" disabled>{{ bid.promo.promo.promo_code }}</a>
                        <a v-else-if="job.promo" @click.prevent="" class="promo_code-cont" disabled>{{ job.promo.promo.promo_code }}</a>
                        <a v-else-if="bid.paid_status != 'not_paid'" @click.prevent="" class="btn apply-offers-btn" disabled>Offers</a>
                        <a v-else-if="form.promo.id" @click.prevent="clickApplyOffer" class="promo_code-cont">{{form.promo.promo_code}}</a>
                        <a v-else-if="!form.promo.id" @click.prevent="clickApplyOffer" type="button" class="btn apply-offers-btn">Apply Offers</a>
                    </div>
                  </div>
            </div>
          </div>
        </section>
        <section class="gary-divider mt-0">
        </section>
        <section class="payment-details">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <p>Disclaimer: All Payments will be held by Ovvy and only released to merchant when job is confirmed complete by both merchant and client.<br>Only accept the bid when all details of the job are finalised. By proceeding, you accept the latest version of <a href="#" data-bs-toggle="modal" data-bs-target="#clients-cancellation-policy">Cancellation Policy.</a> </p>
                </div>
            </div>
          </div>
        </section>
        <section class="">
          <div class="container-fluid">
                <div class="">
                  <div class="row">
                    <div class="col-lg-12 text-center">
                      <div class="total-amonut-2">
                        <h6>Total</h6>
                        <h5>
                          $ {{this.$root.commaDecimal(current_total)}}
                          <br v-if="initial_total != current_total"/>
                          <span v-if="initial_total != current_total" style="text-decoration: line-through;font-size: 90%;">$ {{this.$root.commaDecimal(Number(bid.callout_fee) + Number(bid.price))}}</span>

                          <!-- <br v-if="job.client_activity_type == 'post' && (form.credits_used + promo_discount) > 0"/>
                          <span v-if="job.client_activity_type == 'post' && form.credits_used + promo_discount > 0" style="text-decoration: line-through;font-size: 90%;">$ {{this.$root.insertDecimal(bid.callout_fee)}}</span> -->
                        </h5>
                      </div>
                      <button v-if="$root.user_id == job.client_id && job.client_activity_type == 'post'" @click="acceptBid()" type="button" class="btn paid-btn btn-green w100">
                        Confirm
                      </button>
                      <button v-else-if="$root.user_id == job.client_id && job.client_activity_type == 'accepted' && Number(bid.price) > 0 && bid.paid_status == 'paid_callout'" 
                        @click="acceptExtraFeeRequest()" type="button" class="btn paid-btn btn-green w100">
                        Accept extra fee ${{$root.commaDecimal(total_extra_fee)}}
                      </button>
                      <button v-else-if="$root.user_id == job.client_id && job.client_activity_type == 'complete' && job.category.status_for_client == 'active'" type="button" class="btn btn-green w100 paid-btn" data-bs-toggle="modal" data-bs-target="#job-rebook">
                        Rebook
                      </button>
                      <button v-else type="button" class="btn btn-light w100 paid-btn">
                        PAID
                      </button>
                      <!-- <div v-if="useCredits" class="total-amonut-2">
                        <h6></h6>
                        <h5 style="text-decoration-line: line-through;">$ {{this.$root.insertDecimal(total)}}</h5>
                      </div>
                      <button v-if="useCredits" @click="pay" type="button" class="btn paid-btn" :class="status == 'accept' ? 'accept-btn' : ''">
                        ACCEPT EXTRA FEE $0.00
                      </button>
                      <button v-else-if="status == 'accept'" @click="pay" type="button" class="btn paid-btn accept-btn">
                        ACCEPT EXTRA FEE {{this.$root.insertDecimal(total)}}
                      </button>
                      <button v-else type="button" class="btn btn-light w100 paid-btn">
                        PAID
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
        </section>
      </section>

<div v-if="jobActivityType == 'post' || (jobActivityType == 'accepted' && Number(bid.price) > 0 && bid.paid_status == 'paid_callout')" class="modal" :class="paymentMethodModalShow ? 'show' : ''" :style="paymentMethodModalShow ? 'display: block;' : ''">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <PaymentMethods :visible="true" @data="setDefaultPayment" @close="paymentMethodModalShow=false"/>
        </div>
      </div>
    </div>
</div>

<div class="modal" :class="creditsModalShow ? 'show' : ''" :style="creditsModalShow ? 'display: block;' : ''">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <div class="title">
            <h6 class="modal-title"><strong>Use Credits</strong></h6>
          </div>
          <div class="close">
            <button @click="creditsModalShow=false" type="button" class="btn-close"></button>
          </div>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <h6>
            Your credits 
            <br/>
            <strong>${{$root.commaDecimal(credit.credits)}}</strong>
          </h6>
          <p class="amonut-title"><strong>Enter the amount to use</strong></p>
          <input v-model="credit.field" type="number" class="amount-field hide_number_arrow" />
          <em v-if="credit.error != ''" class="text-danger text-center" style="justify-content: center;display: flex;">{{credit.error}}</em>
          <!-- <p class="mt-4">Service fee charge: <span>${{$root.commaDecimal(bid.callout_fee)}} {{bid.paid_status == 'paid_callout' && Number(bid.price) > 0 ? ' (PAID)' : ''}}</span></p>
          <p v-if="bid.price != '' && Number(bid.price) > 0">Extra fee: <span>${{$root.commaDecimal(bid.price)}}</span></p>
          
          <p v-if="bid.promo">Promo: <span>${{$root.commaDecimal(bid.promo.discount)}}</span> </p>
          <p v-else>Promo: {{ promo_discount > 0 ? '-' : '' }}<span>${{$root.commaDecimal(promo_discount)}}</span> </p>          
      
          <p>Credits Used: {{ form.credits_used > 0 ? '-' : '' }}<span>${{$root.commaDecimal(form.credits_used)}}</span> </p> -->
          <br/>
          <p class="amonut-title"><strong>Total Due</strong></p>
          <h2 class="text-danger">$ {{$root.commaDecimal(current_total)}}</h2>
          <button @click="verifyCredits" type="button" class="btn save apply-promo-btn">Save</button>
        </div>
      </div>
    </div>
</div>

<div v-if="jobActivityType == 'post' || (jobActivityType == 'accepted' && Number(bid.price) > 0 && bid.paid_status == 'paid_callout')" class="modal" :class="showApplyOffer ? 'show' : ''" :style="showApplyOffer ? 'display: block;' : ''">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
        <div class="modal-body">
            <PromoVerifier :visible="showApplyOffer" :data="promoData" @close="showApplyOffer=false" @apply="(data) => verifyPromo(data)"/>
        </div>
        </div>
    </div>
</div>

</template>
  
<script>
  import PaymentMethods from '@/components/PaymentMethods'
  import PromoVerifier from '@/components/PromoVerifier'  
  import { getUserCredits } from "@/api/credits"
  import {acceptBid} from "@/api" 
  export default {
    name: 'BidDetail',
    components: {
        PaymentMethods,
        PromoVerifier
    },
    props: {
        bid: Object,
        job: Object,
    },
    data() {
      return {
        loaded: false,
        paymentMethodModalShow: false,
        payments_methods: [],
        creditsModalShow: false,
        credit: {
            credits: 0,
            points: 0,
            field: 0,
            error: ''
        },
        showApplyOffer: false,
        form: {
            payment: {},
            promo: {},
            credits_used: 0
        }
      }
    },
    watch: {
        bid: function(newVal) {
            console.log(newVal)
        },
        job: function(newVal) {
            console.log(newVal)
        },
        paymentMethodModalShow: function(newVal) {
            if(newVal) this.$root.modalShow();
            else this.$root.modalClose()
        },
        creditsModalShow: function(newVal) {
            if(newVal) this.$root.modalShow();
            else this.$root.modalClose()
        },
        showApplyOffer: function(newVal) {
            if(newVal) this.$root.modalShow()
            else this.$root.modalClose();
        },
    },
    computed: {
        jobActivityType() {
            return this.job.client_activity_type
        },
        final_total() {
          let total = this.initial_total

          // if(Number(this.bid.price) > 0 && this.bid.paid_status == 'paid_callout' && this.job.type == 'bidding') {
          //   total -= this.bid.callout_fee;
          // }

          if(this.form.promo.id) {
              if(Number(this.form.promo.discount_percent) > 0) {
              let perc = Number(this.form.promo.discount_percent) / 100,
                  discount = total * perc;
                  if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
              total -= discount;
              }else {
              let discount = Number(this.form.promo.discount_fixed_value);
              if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
              total -= discount;
              }
          }

          if(this.form.credits_used > 0) {
            total -= this.form.credits_used
          }

          if(this.bid.promo) {
            total -= Number(this.bid.promo.discount)
          }

          if(this.job.promo) {
            total -= Number(this.job.promo.discount)
          }

          if(Number(this.bid.callout_credit) > 0) {
            total -= Number(this.bid.callout_credit)
          }

          if(Number(this.bid.service_fee_credit) > 0) {
            total -= Number(this.bid.service_fee_credit)
          }

          if(Number(this.job.fixed_price_credit) > 0) {
            total -= Number(this.job.fixed_price_credit)
          }

          return total
        },
        initial_total() {
          return Number(this.bid.callout_fee) + Number(this.bid.price);
          // let total = this.bid.callout_fee 

          // if(this.bid.paid_status == 'paid_callout') {
          //   total = this.bid.callout_fee
          // }else if(this.bid.paid_status == 'paid_service_fee') {
          //   total = Number(this.bid.callout_fee) + Number(this.bid.price)
          // }
          // return total;
        },
        current_total() {
          if(this.bid && Number(this.bid.price) > 0 && this.bid.paid_status == 'paid_callout') {
            let total = Number(this.bid.price)

            if(this.form.credits_used > 0) {
              total -= this.form.credits_used
            }

            return total
          }else {
            return this.final_total
          }
        },
        total_credit_used() {
          let total = 0;

          if(this.bid.paid_status == 'paid_callout') {
            if(Number(this.bid.price) > 0) total += 0
            else total += Number(this.bid.callout_credit) + Number(this.job.fixed_price_credit);
          }else if(this.bid.paid_status == 'paid_service_fee') {
            total += Number(this.bid.callout_credit) + Number(this.bid.service_fee_credit) + Number(this.job.fixed_price_credit);
          }

          return total;
          // return Number(this.bid.callout_credit) + Number(this.bid.service_fee_credit)
        },
        promo_discount() {
            let discount = 0;
            if(this.form.promo.id) {
                if(Number(this.form.promo.discount_percent) > 0) {
                    let perc = Number(this.form.promo.discount_percent) / 100;
                    discount = this.initial_total * perc;
                    if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
                }else {
                    discount = Number(this.form.promo.discount_fixed_value);
                    if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
                }
            }
            return discount
        },
        promoData() {
            return {
                category_id: this.job.category.id,
                total: this.initial_total,
                category: this.job.category,
                job: this.job,
                bid: this.bid
            }
        },
        total_extra_fee() {
          let total = Number(this.bid.price)
          if(this.form.promo.id) {
              if(Number(this.form.promo.discount_percent) > 0) {
              let perc = Number(this.form.promo.discount_percent) / 100,
                  discount = total * perc;
                  if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
              total -= discount;
              }else {
              let discount = Number(this.form.promo.discount_fixed_value);
              if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
              total -= discount;
              }
          }

          if(this.form.credits_used > 0) {
            total -= this.form.credits_used
          }
          return total
        }
    },
    methods: {
        setDefaultPayment(data) {
            this.payments_methods = data
            if(data.length > 0) {
                this.form.payment = data[0]
            }else this.form.payment = {}
        },
        async openCreditsModal() {
            this.$root.isLoading = true
            this.creditsLoading = true
            const $this = this
            $this.credit.field = 0
            $this.credit.error = ''

            await getUserCredits('client').then(response => {
                // console.log(response.data.data)
                $this.$root.isLoading = false
                $this.creditsLoading = false
                $this.creditsModalShow = true
                $this.credit = {
                  credits: response.data.data.credits,
                  points: response.data.data.points,
                  field: $this.form.credits_used > 0 ? $this.form.credits_used : ''
                }
            }).catch(error => {
                console.log(error)
                $this.$root.isLoading = false
                $this.creditsLoading = false
            })
        },
        verifyCredits() {
            this.credit.error = ''
            if(this.credit.field == '') {
                this.form.credits_used = 0
                this.creditsModalShow = false
            }else {
              let field = Number(this.credit.field)
              if(isNaN(this.credit.field)) {
                //error
                this.credit.error = 'Error'
              }else {
                // let total = this.initial_total - this.promo_discount
                let total = this.final_total;
                if(this.bid && Number(this.bid.price) > 0 && this.bid.paid_status == 'paid_callout') {
                  total = Number(this.bid.price)
                }
                
                // console.log(total)
                //proceed
                if(field < 0) {
                    this.credit.error = 'Amount should be greater than 0'
                }else {
                    if(field > this.credit.credits) {
                    this.credit.error = 'Insufficient Credits'
                    }else if(field > total) {
                    this.credit.error = 'Credit is greater than service fee'
                    }
                }
              }

              if(this.credit.error == '') {
                this.form.credits_used = field
                this.creditsModalShow = false
              }
            }
        },
        clickApplyOffer() {
            const $this = this
            if(Object.keys(this.form.promo).length > 0) {
                $this.$swal.fire({
                    title: 'Offers',
                    text: "Are you sure you want to remove existing voucher or promo code?",
                    icon: 'warning',
                    confirmButtonText: 'Continue',
                    confirmButtonColor: '#f44336',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.form.promo = {}
                    }
                })
            }else {
                this.showApplyOffer = true
            }
        },
        async acceptBid() {
          const $this = this

          if(this.final_total > 0 && Object.keys(this.form.payment).length === 0) {
            $this.$notify({
              text: 'Please add your payment details in order to confirm this job',
              type: 'error'
            });
            return;
          }

          $this.$root.isLoading = true

          let formData = new FormData();
          formData.append('source', this.form.payment.id)
          formData.append('credit', this.form.credits_used)
          formData.append('offer_id', this.form.promo.id ? this.form.promo.id : 0)
          formData.append('callout_fee', this.bid.callout_fee)
          
          await acceptBid(this.bid.id, formData).then(() => {
              // console.log(response)
              // console.log('done')
              $this.$root.isLoading = false

              $this.$notify({
                  title: "Congratulations!",
                  text: "Bid accepted",
                  type: 'success'
              });
              $this.$root.goTo('activity#accepted');
          }).catch(error => {
              console.log(error.response.data)
              $this.$root.isLoading = false

              $this.$notify({
                  title: "An error occured.",
                  text: error.response.data.message,
                  type: 'error'
              });
              if(error.response.data.refresh) {
                $this.$root.hardRefresh();
              }
          })
        },
        async acceptExtraFeeRequest() {
          const $this = this

          $this.$root.isLoading = true

          let formData = new FormData();
          formData.append('source', this.form.payment.id)
          formData.append('credit', this.form.credits_used)
          formData.append('offer_id', this.form.promo.id ? this.form.promo.id : 0)
          formData.append('price', this.bid.price)
          await acceptBid(this.bid.id, formData).then(() => {
              // console.log(response)
              // console.log('done')
              $this.$root.isLoading = false

              $this.$notify({
                  title: "Congratulations!",
                  text: "Extra fee request accepted",
                  type: 'success'
              });
              $this.$root.goTo('activity#accepted');
          }).catch(error => {
              console.log(error.response.data)
              $this.$root.isLoading = false

              $this.$notify({
                  title: "An error occured.",
                  text: error.response.data.message,
                  type: 'error'
              });

              if(error.response.data.refresh) {
                $this.$root.hardRefresh();
              }
          })
        },
        ifShowCredits() {
          let show = true;
          if(this.job.bid) {
            if(this.job.bid.type == 'callout') {
              if(Number(this.job.bid.price) > 0) {
                if(this.job.bid.paid_status == 'paid_callout') {
                  return true;
                }else if(this.job.bid.paid_status == 'paid_service_fee'){
                  return false;
                }
              }else {
                return false;
              }
            }else {
              return false;
            }
          }
          
          return show;
        },
        verifyPromo(promo) {
          let total = this.initial_total

          // console.log(total)

          if(Number(promo.discount_percent) > 0) {
            let perc = Number(promo.discount_percent) / 100,
              discount = total * perc;
            if(Number(promo.max_discount_limit) < discount) discount = Number(promo.max_discount_limit)
            total -= discount;
            console.log(discount)
          }else {
            let discount = Number(promo.discount_fixed_value);
            if(Number(promo.max_discount_limit) < discount) discount = Number(promo.max_discount_limit)
            total -= discount;
            console.log(discount)
          }
          
          // console.log(total)

          if(this.form.credits_used > 0) {
            total -= this.form.credits_used
          }
          // console.log(total)

          if(total <= 0) {
            this.$swal.fire({
              text: "Promo value is greater than service fee",
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: '#f44336',
              showCancelButton: true,
            })
            
          }else {
            this.form.promo = promo
          }
        }
    },
    created() {
      
    }
  }
</script>

<style scoped>
.circle-avatar {
    width: 85px;
    height: 85px;
    -webkit-border-radius: 85px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 85px;
    -moz-background-clip: padding;
    border-radius: 85px;
    background-clip: padding-box;
    margin: 7px 0 0 5px;
    float: left;
    background-size: cover;
    background-position: center center;
}
</style>