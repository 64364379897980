<template>
    <div v-if="view == 'welcome'" class="welcome-container">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <a @click="skipBtn()" href="#" class="skip" data-bs-dismiss="modal">Skip</a>
                </div>
            </div>
        </div>
        <section class="main-welcome margin-to-50">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <img src="~@/assets/img/logo-white.png" class="img-fluid logo-white">
                        <h4>The Service Marketplace</h4>
                        <div class="buttons">
                            <a @click.prevent="view = 'sign_up'" href="sign-up.html" class="btn">SIGN UP</a>
                            <a @click.prevent="view = 'login'" href="login.html" class="btn">LOGIN</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <section v-if="view != 'welcome' && showHeader">
        <div class="container-fluid">
        <div class="row">
        <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
            <div class="title">
                <label class="text-white">{{title}}</label>
            </div>
                <div v-if="preference != '' && !showOtp && !otpSuccess" class="back">
                    <a @click.prevent="preference = ''" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                </div>
                <div v-else-if="preference != '' && showOtp && !otpSuccess" class="back">
                    <a @click.prevent="backToSignUpForm()" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                </div>
                <div v-else-if="$route.name == 'Sign Up' && !otpSuccess" class="back">
                    <router-link to="/welcome" class="btn"><i class="fa fa-long-arrow-left text-white"></i></router-link>
                </div>
                <div v-else-if="!otpSuccess" class="back">
                    <a @click.prevent="{preference = ''; view = 'welcome'; showForgotPass =false}" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>

    <div v-show="(view == 'sign_up' && preference != '' && !showOtp && !appleSignUp) || (view == 'login' && !showForgotPass)" class="registration-wrapper container py-2">
        <div class="row mt-1">
            <div class="col-lg-12 text-center">
                <!-- <a v-if="$root.allow_fb" @click.prevent="signUpViaFB()" class="btn btn-facebook btn-100 mt-2 mb-2" href="#">
                    Sign up with Facebook
                </a> -->
                <a v-if="$root.app_display_view == 'ios'" :href="'https://www.facebook.com/dialog/oauth/?client_id='+ $root.facebook_app_id +
                    '&redirect_uri='+ $root.fb_redirect_url +'&state=live&scope=public_profile,email'" 
                    class="btn btn-facebook btn-100 mt-2 mb-2">
                    Sign up with Facebook
                </a>
                <v-facebook-login v-else :app-id="$root.facebook_app_id" version="v8.0" 
                    :option="{
                    cookie: true, xfbml: true, display: 'touch'
                    }"
                    :login-options="{ 
                        scope: 'public_profile,email', 
                        display: 'touch'
                    }"
                    @login="successFacebook($event)"
                    @sdk-init="handleFBInit"
                    @connected="isFBConnected($event)"
                    text-class="Sign Up with Facebook"
                    class="btn btn-facebook btn-100 mt-2 mb-2" >
                    <template #login>Sign Up with Facebook</template>
                </v-facebook-login>
                <vue-apple-login
                    mode="center-align"
                    type="sign in"
                    :border="true"
                    :radius="15"
                    width="100%"
                    height="100%"
                    logoSize="medium"
                    :logoPosition="0"
                    :labelPosition="0"
                    className="apple-signin"
                    :onSuccess="event => successApple(event)"
                    :onFailure="event => failureApple(event)"
                ></vue-apple-login>
                <!-- <div v-show="showAppleSignUp && $root.allow_apple" id="appleid-signin" class="apple-signin" data-color="black" data-border="true" data-type="sign-up"></div> -->
                <br/>
            </div>
        </div>
    </div>

    <div v-if="view == 'sign_up'">
        <div v-show="preference == ''" class="registration-wrapper container py-2">
            <section class="preferences mt-4">
                <div class="container">
                    <div class="row">
                    <div class="col-lg-12 text-center">
                        <h4>Please select an account type to sign up</h4>
                        <div @click.prevent="preference='client'" class="wrp" style="cursor: pointer;">
                            <div class="d-flex justify-content-center">
                                <!-- <img src="~@/assets/img/completingProfile.png">
                                <img src="~@/assets/img/completingProfile.png"> -->
                                <img src="~@/assets/img/ClientIcon.jpg" class="user-type-icon"/>
                            </div>
                            <h3 class="mt-3">Client</h3>
                            <h6 class="mt-4">Someone who post a job they need completing</h6>
                        </div>
                        <div @click.prevent="preference='merchant'" class="wrp"  style="cursor: pointer;">
                            <div class="d-flex justify-content-center">
                                <img src="~@/assets/img/MerchantIcon.png" class="user-type-icon"/>
                                <!-- <img src="~@/assets/img/mechanic.png" class="merchant-icon" style="margin-right: 3px">
                                <img src="~@/assets/img/mechanic.png" class="merchant-icon"> -->
                            </div>
                            <h3 class="mt-3">Merchant</h3>
                            <h6 class="mt-4">Someone who gets paid to provide services to others</h6>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-show="preference != '' && !showOtp && !appleSignUp" class="registration-wrapper container py-2">
            <form autocomplete="off" method="post" action="">
                <input autocomplete="false" name="hidden" type="text" style="display:none;">
                <input type="text" style="display:none">
                <input type="password" style="display:none">
                <input type="password" name="pwd" style="display:none" autocomplete="new-password">
                <div class="row">
                    <div class="col-12">
                        <div class="email-input">
                        <div class="form-group mb-2">
                            <label for="inputFirstName">First Name </label>
                            <input :class="'form-control ' + (errMsg.first_name? 'is-invalid' : '') "
                                    aria-describedby="firstNameHelp" id="inputFirstName"
                                    placeholder="e.g. William"
                                    type="text" v-model="formData.first_name">
                            <small class="text-danger" v-if=" errMsg.first_name">{{ errMsg.first_name }}</small>
                        </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="email-input">
                        <div class="form-group  mb-2">
                            <label for="inputLastName">Last Name </label>
                            <input :class="'form-control ' + (errMsg.last_name? 'is-invalid' : '') "
                                    aria-describedby="lastNameHelp" id="inputLastName"
                                    placeholder="e.g. Walace"
                                    type="text" v-model="formData.last_name">
                            <small class="text-danger" v-if=" errMsg.last_name">{{ errMsg.last_name }}</small>

                        </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="email-input">
                        <div class="form-group  mb-2">
                            <label for="inputEmailReg">Email </label>
                            <input :class="'form-control ' + (errMsg.email? 'is-invalid' : '') "
                                    aria-describedby="emailHelp"
                                    id="inputEmailReg"
                                    @change="isEmailValid"
                                    placeholder="tom@mail.com" type="email" v-model="formData.email">
                            <small class="text-danger" v-if=" errMsg.email">{{ errMsg.email }}</small>

                        </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="email-input">
                            <div class="form-group Password mb-1">
                                <label for="inputMobileNumber">Mobile Number</label>
                            </div>
                        </div>
                        <div class="form-group Password  mb-2">
                            <vue-tel-input v-model="phone" @input="phoneInput" ref="phone" id="telPhone" :validCharactersOnly="true"></vue-tel-input>
                            <small class="text-danger" v-if=" errMsg.phone_number">{{ errMsg.phone_number }}</small>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="email-input">
                        <div class="form-group Password mb-1">
                            <label for="inputPasswordReg">Password</label>
                            <input :class="'form-control ' + ( errMsg.password? 'error' : '') "
                            aria-describedby="passwordHelp"
                            id="inputPasswordReg"
                            placeholder="* * * * * * * * "
                            @input="passwordInput"
                            type="password" v-model="formData.password">
                            <span @click="toggleShowPass($event,'pwd')" class="spanshow">Show</span>
                            <!-- <small class="text-danger" v-if=" errMsg.password">{{ errMsg.password }}</small> -->
                        </div>
                        </div>
                        <small class="frmValidation" :class="formData.password && formData.password.length >= 8 ? 'passed' : 'failed'">
                        <span>{{formData.password && formData.password.length >= 8 ? '&#10004;' : '	&#10008;'}}</span>
                        <em> Longer than 8 characters</em>
                        </small>
                        <small class="frmValidation" :class="passwordValid.has_uppercase ? 'passed' : 'failed'">
                        <span>{{passwordValid.has_uppercase ? '&#10004;' : '	&#10008;'}}</span>
                        <em> Has a capital letter</em>
                        </small>
                        <small class="frmValidation" :class="passwordValid.has_lowercase ? 'passed' : 'failed'">
                        <span>{{passwordValid.has_lowercase ? '&#10004;' : '	&#10008;'}}</span>
                        <em> Has a lowercase letter</em>
                        </small>
                        <small class="frmValidation" :class="passwordValid.has_number ? 'passed' : 'failed'">
                        <span>{{passwordValid.has_number ? '&#10004;' : '	&#10008;'}}</span>
                        <em> Has a number</em>
                        </small>
                        <small class="frmValidation  mb-2" :class="passwordValid.has_special ? 'passed' : 'failed'">
                        <span>{{passwordValid.has_special ? '&#10004;' : '	&#10008;'}}</span>
                        <em> Has a special character</em>
                        </small>
                    </div>
                    <div class=" col-12">
                        <div class="email-input">
                        <div class="form-group Password  mb-4">
                            <label for="inputRePassword">Confirm Password</label>
                            <input :class="'form-control ' + (errMsg.password? 'error' : '') "
                                    aria-describedby="rePasswordHelp" id="inputRePassword"
                                    placeholder="* * * * * * * * "
                                    type="password" v-model="formData.confirm_password">
                            <span @click="toggleShowPass($event,'rePwd')" class="spanshow">Show</span>
                            <small class="text-danger" v-if=" errMsg.confirm_password">{{ errMsg.confirm_password
                            }}</small>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-check p-0">
                            <label class="form-check-label line-label" style="display: block;">
                                <input type="checkbox" name="i_agree" 
                                v-model="formData.i_agree" > &nbsp; 
                                <span style="font-size: 13px;">
                                    I hereby agree and consent to the collection, use and disclosure of my personal data by ovvy, its related companies & business partners, in relation to their product and services including notification of events, promotion and future delivery of marketing information. By proceeding, I have read and agreed with Ovvy's Privacy Policy.
                                </span>
                            </label>
                            <small class="text-danger" v-if="errMsg.i_agree">{{errMsg.i_agree}}</small>
                        </div>
                    </div>
                    <div class="col-lg-12 text-center">
                        <small class="text-danger" v-if="errMsg.global">{{errMsg.global}}</small>
                        <a @click.prevent="dontAskOtp ? checkSignUp() : createAccount()" class="btn btn-submit btn-100 mb-2 mt-3" href="#">
                            {{processing ? 'Loading...' : 'SIGN UP' }}
                        </a>
                        <small class="text-secondary">
                            By signing up, you agree to our <br/>
                            <a :href="'/#/terms-and-conditions?back=' + $route.name" :target="$root.app_display_view == 'ios' ? '_self' : '_blank'" style="text-decoration: underline;"><span>Terms and conditions and Privacy Policy</span></a>
                        </small>
                        <br/>
                    </div>
                </div>
            </form>
        </div>
        <div v-show="preference != '' && !showOtp && appleSignUp" class="registration-wrapper container py-2">
            <form autocomplete="off" method="post" action="">
                <input autocomplete="false" name="hidden" type="text" style="display:none;">
                <input type="text" style="display:none">
                <input type="password" style="display:none">
                <input type="password" name="pwd" style="display:none" autocomplete="new-password">
                <div class="row mt-4">
                <div class="col-lg-12 text-center">
                    <p style="color: #1D3557;font-weight: 600;">To verify your account, we need<br/> to SMS you an OTP code</p>
                    </div>
                    <div class="col-lg-12">
                        <div class="email-input">
                        <div class="form-group Password mb-1">
                            <label for="inputMobileNumber">Mobile Number</label>
                        </div>
                        </div>
                        <div class="form-group Password">
                        <vue-tel-input v-model="phone" @input="phoneInput" ref="phone" id="telPhone" :validCharactersOnly="true"></vue-tel-input>
                        <small class="text-danger" v-if=" errMsg.phone_number">{{ errMsg.phone_number }}</small>
                        </div>
                    </div>
                    <div class="col-lg-12 text-center">
                        <small class="text-danger" v-if="errMsg.global">{{errMsg.global}}</small>
                        <small class="text-danger" v-if="errMsg.email">{{errMsg.email}}</small>
                        <a @click.prevent="verifyAppleSignupOtp()" class="btn btn-submit btn-100 mb-2 mt-3" href="#">
                            {{processing ? 'Loading...' : 'Verify' }}
                        </a>
                    </div>
                </div>
            </form>
        </div>
        <div v-show="showOtp" class="otp-wrapper container py-5">
            <div class="list-top ">
                <div class="row justify-content-center space-content">
                    <div v-if="!otpSuccess && !appleNameForm" style="text-align: -webkit-center;">
                    <div class="left-section">
                        <h1>Verification Code</h1>
                        <p>Enter the 4 digits code that you received on your mobile number.</p>
                    </div>

                    <div class="Verification-input">
                        <input @keyup="validateNumber('n1',$event)" class="form-control Verification-input-first otp-number"
                                id="otpFirst"
                                maxlength="1" autocomplete="off" 
                                placeholder="" ref="otp" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n1">
                        <input @keyup="validateNumber('n2',$event)" class="form-control otp-number" maxlength="1" autocomplete="off" 
                                placeholder="" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n2">
                        <input @keyup="validateNumber('n3',$event)" class="form-control otp-number" maxlength="1" autocomplete="off" 
                                placeholder="" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n3">
                        <input @keyup="validateNumber('n4',$event)" class="form-control otp-number" maxlength="1" autocomplete="off" 
                                placeholder="" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n4">
                    </div>
                    <span v-if="errors.otp" class="form-error mt-4"><small>{{errors.otp}}</small></span>
                    <!-- {{ this.formData.otp}} -->
                    <a @click.prevent="appleSignUp ? checkIfAppleSignupComplete() : saveAndContinue()" class="btn-pink btn-pink-otp" href="#">
                        {{processing ? 'Verifying...' : 'Verify Account' }}
                    </a>

                    <p v-if="showResend" class="mt-5">Didn't receive it? <a @click.prevent="resendCode()" href="#">Resend Code</a></p>
                    <small class="text-danger" v-if="errors.global">{{errMsg.global}}</small>
                    </div>
                    <div v-if="!otpSuccess && appleNameForm" style="text-align: -webkit-center;">
                    <div class="left-section">
                        <h3 style="color: #1D3557;font-weight: 700;" class="mt-3 mb-5">Finalize Your Profile</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="email-input">
                            <div class="form-group">
                                <label for="inputFirstName">First Name </label>
                                <input :class="'form-control ' + (errMsg.first_name? 'is-invalid' : '') "
                                        aria-describedby="firstNameHelp" id="inputFirstName"
                                        placeholder="e.g. William"
                                        type="text" v-model="formData.first_name">
                                <small class="text-danger" v-if=" errMsg.first_name">{{ errMsg.first_name }}</small>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="email-input">
                            <div class="form-group ">
                                <label for="inputLastName">Last Name </label>
                                <input :class="'form-control ' + (errMsg.last_name? 'is-invalid' : '') "
                                        aria-describedby="lastNameHelp" id="inputLastName"
                                        placeholder="e.g. Walace"
                                        type="text" v-model="formData.last_name">
                                <small class="text-danger" v-if=" errMsg.last_name">{{ errMsg.last_name }}</small>

                            </div>
                            </div>
                        </div>
                    </div>

                    <a @click.prevent="checkAppleSignupNameForm()" class="btn-pink btn-pink-otp" href="#">
                        {{processing ? 'Loading...' : 'Sign Up' }}
                    </a>
                    <small class="text-danger" v-if="errors.global">{{errMsg.global}}</small>
                    </div>

                    <div v-if="otpSuccess" style="text-align: -webkit-center;">
                        <div class="verification-success-wrapper left-section">
                        <img alt="" src="~@/assets/images/logo-new.png" style="margin: 0 auto">
                        <br>
                        <br>
                        <h1>Verification and Registration Successful</h1>
                        <p>Welcome onboard</p>
                        <!-- <a v-if="preference == 'client'" @click.prevent="proceed" class="btn btn-submit btn-100">Continue</a>
                        <router-link v-else to="/merchant-registration" class="btn btn-submit btn-100">Continue</router-link> -->
                        <a v-if="preference == 'client'" @click.prevent="view = 'login'" href="#"  class="btn btn-submit btn-100">Log In</a>
                        <a v-else-if="preference == 'merchant' && isExternalWelcome" @click.prevent="redirectMerchantReg()" href="#"  class="btn btn-submit btn-100">Log In</a>
                        <router-link v-else-if="preference == 'merchant' && !isExternalWelcome" to="/merchant-registration" class="btn btn-submit btn-100">Log In</router-link>
                        <!-- <a v-if="!showHeader" href="#"  data-bs-toggle="modal" data-bs-target="#login" class="btn btn-submit btn-100">Log In</a>
                        <button v-else @click="doneProcess()" class="btn btn-submit btn-100">Log In</button> -->
                    </div>
                    <!-- <a @click.prevent="proceed()" class="btn-pink btn-pink-otp" href="#">Proceed</a> -->
                    </div>
                </div>
                </div>
        </div>
    </div>
    <div v-else-if="view == 'login'" class="login-form">
        <div class="container-fluid">
            <div class="row">
              <div v-if="!showForgotPass" class="col-lg-12">
                  <form class="login">
                       
                      <input type="email" class="form-control" 
                          v-model="login.form.email"
                          @keyup="login.hasError=false"
                          id="email" placeholder="Email" name="email">
                      <input type="password" class="form-control mt-1" 
                          v-model="login.form.password"
                          @keyup="login.hasError=false"
                          v-on:keyup.enter="logMeIn"
                          id="password" placeholder="Password" name="password">     
                      
                      <p v-show="login.hasError" class="error-details">{{login.errMsg}}</p>

                      <a @click.prevent="logMeIn()" href="#" class="btn login-btn mt-2">LOGIN</a>
                  </form>
                  <a @click.prevent="showForgotPass = true" href="#" class="forgot">Forgot Your Password?</a>  
              </div>
              <div v-else class="col-lg-12">
                <form class="login mt-2">
                  <input :class="'form-control ' + (fp.errors.email? 'is-invalid' : '') "
                    aria-describedby="emailHelp"
                    id="inputEmailReg"
                    placeholder="tom@mail.com" type="email" v-model="fp.form.email">
                  <small class="text-danger" v-if="fp.errors.email">{{ fp.errMsg.email }}</small>
                  <small class="text-danger" v-if="fp.errors.global">{{fp.errors.global}}</small>
                  <a @click.prevent="forgotPassword()" href="#" class="btn login-btn mt-2">Send Password Reset Link</a>
                </form>
                <a @click.prevent="showForgotPass = false" href="#" class="forgot">Log In</a>  
            </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import moment from 'moment'
import { getOTP, register, facebook, facebook2, check_facebook, forgot_password, login, apple, check_facebook2 } from "@/api";
import jwt_decode from "jwt-decode";
import $ from 'jquery'
import { lsWatcher as ls, ssWatcher } from "../../main";
import { Modal } from "bootstrap";

export default {
  name: 'WelcomeView',
  components: {
  },
  props: {
    hide_header: Boolean,
    hide_apple: Boolean,
    hide_facebook: Boolean,
    display: String,
  },
  data() {
    return {
        view: 'welcome',
        showHeader: true,
        hasError: false,
        errMsg: '',
        processing: false,
        showPass: false,
        formData: {
            first_name: null,
            last_name: null,
            phone_number: null,
            email: null,
            password: null,
            confirm_password: null,
            otp: null,
            sms_token: null,
            i_agree: null,
            apple_id: null,
            facebook_id: null,
            facebook_access_token: null,
            web_player_id: null,
            facebook_avatar: null
        },
        dontAskOtp: true,
        otp: {n1: null, n2: null, n3: null, n4: null},
        errors: {},
        showOtp: false,
        otpSuccess: false,
        userEncryptId: '',
        resend_processing: false,
        showResend: false,
        phone: null,
        phoneValid: true,
        passwordValid: {
            has_number:    false,
            has_lowercase: false,
            has_uppercase: false,
            has_special:   false,
        },
        platform: '',
        showAppleSignUp:false,
        appleSignUp:false,
        appleNameForm: false,
        preference: '',
        fbSignUp: false,

        showForgotPass: false,
        
        login: {
            hasError: false,
            errors: {},
            errMsg: '',
            form: {
                email: '',
                password: '',
                apple_id: null,
                facebook_id: null,
                facebook_access_token: null,
                web_player_id: null
            },
            processing: false
        },
        fp: {
            form: {
                email: '',
                source: 'marketplace'
            },
            errors: {},
            errMsg: '',
        },

        FB: {},
        FB_SCOPE: {},

        fb_error: ''
    }
  },
  methods: {
    toggleShowPass($event, input) {
        let el = $($event.target)
        if (input === 'pwd') {
        this.showPass = !this.showPass
        if (this.showPass) {
            el.closest('div').find('input').attr('type', 'text')
            el.text('Hide')
        } else {
            el.closest('div').find('input').attr('type', 'password')
            el.text('Show')
        }
        } else {
        this.showRePass = !this.showRePass
        if (this.showRePass) {
            el.closest('div').find('input').attr('type', 'text')
            el.text('Hide')
        } else {
            el.closest('div').find('input').attr('type', 'password')
            el.text('Show')
        }
        }
    },
    createAccount() {
        this.validateForm();
        
        if (!this.hasError) {
            // console.log('valid')
            this.getOTP(this.formData.phone_number)
        }
    },
    isEmailValid() {
        let emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (this.formData.email && !emailRe.test(this.formData.email)) {
        this.errMsg = {...this.errMsg, ...{email: 'The email must be a valid email address'}};
        }
    },
    validateForm() {
        this.hasError = false;
        this.errMsg = {};
        
        if (!this.formData.first_name) {
        this.errMsg = {...this.errMsg, ...{first_name: 'The fist name is required'}};
        }else {
        if(this.formData.first_name.length > 255) {
            this.errMsg = {...this.errMsg, ...{first_name: 'The fist name must not be greater than 255 characters'}};
        }
        }

        if (!this.formData.last_name) {
        this.errMsg = {...this.errMsg, ...{last_name: 'The last name is required'}};
        }else {
        if(this.formData.first_name.length > 255) {
            this.errMsg = {...this.errMsg, ...{first_name: 'The last name must not be greater than 255 characters'}};
        }
        }

        if (!this.formData.email) {
        this.errMsg = {...this.errMsg, ...{email: 'The email is required'}};
        }

        this.isEmailValid()
        // let emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        // if (this.formData.email && !emailRe.test(this.formData.email)) {
        // this.errMsg = {...this.errMsg, ...{email: 'The email must be a valid email address'}};
        // }

        // if (!this.formData.birthday) {
        //   this.errMsg = {...this.errMsg, ...{birthday: 'The date of birth is required'}};
        // }

        this.isPhoneValid()

        // if (!this.formData.phone_number) {
        // this.errMsg = {...this.errMsg, ...{phone_number: 'The mobile number is required'}};
        // }

        // let mobileRg = /^\+65(6|8|9)\d{7}$/
        // if (this.formData.phone_number && !mobileRg.test(this.formData.phone_number)) {
        //   this.errMsg = {...this.errMsg, ...{phone_number: 'The provided phone number is not a valid Singaporean phone number'}};
        // }

        if (!this.formData.password) {
        this.errMsg = {...this.errMsg, ...{password: 'The password is required'}};
        }
        if (this.formData.password && this.formData.password.length < 8) {
        this.errMsg = {...this.errMsg, ...{password: 'The password must be atleast 8 characters'}};
        }
        if (this.formData.password && !this.isPassValid) {
        this.errMsg = {...this.errMsg, ...{password: 'Password validation required'}};
        }

        if (!this.formData.confirm_password) {
        this.errMsg = {...this.errMsg, ...{confirm_password: 'The confirm password is required'}};
        }
        if (this.formData.password && this.formData.confirm_password && this.formData.password !== this.formData.confirm_password) {
        this.errMsg = {...this.errMsg, ...{confirm_password: 'The confirm password is not correct'}};
        }

        if (!this.formData.i_agree || this.formData.i_agree == null) {
        this.errMsg = {...this.errMsg, ...{i_agree: 'Check box if you want to proceed'}};
        }

        if (Object.keys(this.errMsg).length > 0) {
            this.hasError = true;
        }
    },
    async getOTP(phoneNumber) {
        if (this.processing === true) {
        return;
        }

        this.processing = true;

        const $this = this;
        
        let data = {
            phone_number: phoneNumber,
            email: this.formData.email,
            is_phone_verified: this.dontAskOtp ? 0 : 1
        }
        if(this.$root.enable_recaptcha) data.recaptcha_token = await this.$root.recaptcha('otp')
        await getOTP(data).then(response => {
        // console.log('getOtp', response.data.data);
        this.formData.otp = response.data.data.otp
        this.formData.sms_token = response.data.data.sms_token
        
        $this.processing = false;
        $this.resend_processing = false;

        if(!$this.hasError) {
            if($this.dontAskOtp) {
                // console.log('check signup complete')
                if($this.fbSignUp) {
                    this.saveAndContinue();
                }else {
                    this.checkIfAppleSignupComplete();
                }
            }else {
                $this.showOtp = true;
                setTimeout(function () {
                    $this.$refs.otp.focus()
                }, 800);
                setTimeout(function () {
                    $this.showResend = true;
                }, 60000);
            }
        }
        }).catch(error => {
        if(error.response.data.errors) {
            console.log(error.response.data);
            const errors = error.response.data.errors;
            $this.hasError = false;
            $this.errMsg = {};
            Object.keys(errors).forEach(key => {
            console.log(key, errors[key])
            $this.errMsg[key] = errors[key][0]
            $this.hasError = true
            });
        }else {
            $this.errMsg['global'] = 'An error occured. Please try again.'
        }

        $this.processing = false;
        $this.resend_processing = false;
        })
    },
    validateNumber(key, $event) {
        // console.log(key, $event.key)
        // console.log(this.otp[key])
        
        // this.otp[key] = this.otp[key].replace(/[^\d]/g, '')
        if ($event.key == 'Backspace') {
        if(key != 'n1') $event.target.previousElementSibling.focus()
        } else {
        let newKey = $event.key.replace(/[^\d]/g, '')
        if (!isNaN($event.key) && newKey) {
            // this.otp[key] = $event.key;
            // this.otp[key] = this.otp[key].replace(/[^\d]/g, '')
            if(key != 'n4') $event.target.nextElementSibling.focus()
            else $event.target.blur()
        } else {
            // this.otp[key] = this.otp[key].replace(/[^\d]/g, '')
            // this.otp[key] = newKey
        }
        }
    },
    verifyOtp() {

        if(this.dontAskOtp) return true;

        let otpInput = Object.values(this.otp).join('')
        let errors = [], errorCount = 0;

        // console.log(otpInput)

        if (otpInput != this.formData.otp) {
        errors['otp'] = 'Verification incorrect.';
        errorCount++
        }

        if (otpInput == '' || otpInput == null) {
        errors['otp'] = 'Verification is required.';
        errorCount++
        }

        console.log(errors)
        this.errors = errors

        if (errorCount > 0) return false;
        else return true;
    },
    checkSignUp() {
        this.validateForm();
        
        if (this.hasError) {
            return;
        }

        this.saveAndContinue()
    },
    async saveAndContinue() {

        if (!this.verifyOtp()) {
            return;
        }

        if (this.processing === true) {
            return;
        }

        // console.log('saveAndContinue')

        let otpInput = Object.values(this.otp).join(''),
        isValid = this.dontAskOtp ? true : (otpInput && otpInput == this.formData.otp);
        let recap_token = await this.$root.recaptcha('signup');
        // console.log(otpInput, this.formData.otp)
        this.processing = true;
        this.$root.isLoading = true;
        if (isValid) {
            const $this = this;
            $this.isLoading = true
            let formData = new FormData();
            formData.append('for_testing', 1)
            formData.append('type', this.preference)
            formData.append('registration_type', this.preference)
            formData.append('reg_source', 'organic');
            formData.append('is_phone_verified', this.dontAskOtp ? 0 : 1);
            if(this.$root.enable_recaptcha) formData.append('recaptcha_token', recap_token);
            if(this.$root.user_campaign_id) {
              formData.append('campaign_id', this.$root.user_campaign_id);
            }

            if(this.fbSignUp) {
                formData.append('fb_signup', 1)
            }else if(this.appleSignUp)  {
                formData.append('apple_signup', 1)
            }

            Object.keys(this.formData).forEach(key => {
                // console.log(key, this.formData[key])
                if(this.formData[key] !== null && this.formData[key] !== 'null')
                if(Array.isArray(this.formData[key])) {
                    for (var i = 0; i < this.formData[key].length; i++) {
                    if(this.formData[key][i] !== undefined)
                        formData.append(key+'[]', this.formData[key][i]);
                    }
                }else {
                    if(key == 'birthday') formData.append(key, moment(this.formData[key]).format('YYYY-MM-DD'))
                    else formData.append(key, this.formData[key])
                }
            });

            if(this.fbSignUp) {
                if(this.$root.app_display_view == 'ios') {
                    await facebook2(formData).then(response => {
                        // console.log(response)
                        let data = response.data.data;
                        // console.log(data)
                        ls.setItem("email", data.email)
                        ls.setItem("token",data.access_token) //6hours ra ni
                        ls.setItem("id",data.id)
                        ls.setItem("encrypted_id",data.encrypted)
                        ls.setItem("name",data.first_name+' '+data.last_name)
                        if(data.profile.avatar) {
                            ls.setItem("avatar", data.profile.avatar.medium_thumb_url)
                        }
                        ls.setItem("profile_type", data.profile.ovvy_verified ? 'merchant' : 'client')
                        ls.setItem("profile_view", data.profile.ovvy_verified ? 'merchant' : 'client')
                        ls.setItem("ovvy_verified", data.profile.ovvy_verified)
                        ls.setItem("referral_code", data.profile.referral_code)
                        ls.setItem("is_phone_verified", data.is_phone_verified)
                        ls.setItem("phone_number", data.phone_number)

                        $this.$root.setCookie($this.$root.user_id_cookie, data.encrypted, 6)
                        $this.$root.setCookie($this.$root.user_detail_cookie, JSON.stringify({
                            n: data.first_name+' '+data.last_name,
                            a: data.profile.avatar ? data.profile.avatar.medium_thumb_url : ''
                        }), 6);
                        // $this.$root.setCookie('_ov_pv', data.profile.ovvy_verified ? 'merchant' : 'client', 6);

                        if($this.$root.app_display_view == 'ios' && 
                            $this.formData.web_player_id != null && $this.formData.web_player_id != '') {
                            ls.setItem('ios_one_signal_set', true)
                        }

                        sessionStorage.setItem($this.$root.login_logger_name, 1);

                        $this.processing = false;
                        $this.$root.isLoading = false;  
                        
                        if(!data.profile.ovvy_verified) {
                            $this.$root.goTo('');
                        }else {
                            $this.$root.goTo('browse');
                        }
                    }).catch(error => {
                        $this.processing = false;
                        $this.otp = {n1: null, n2: null, n3: null, n4: null}
                        $this.errors['otp'] = ''

                        console.log(error)
                        alert(Object.values(error.response.data.errors).join('\n \n'))
                        $this.$root.isLoading = false;  
                    });
                }else {
                    await facebook(formData).then(response => {
                        // console.log(response)
                        let data = response.data.data;
                        // console.log(data)
                        ls.setItem("email", data.email)
                        ls.setItem("token",data.access_token) //6hours ra ni
                        ls.setItem("id",data.id)
                        ls.setItem("encrypted_id",data.encrypted)
                        ls.setItem("name",data.first_name+' '+data.last_name)
                        if(data.profile.avatar) {
                            ls.setItem("avatar", data.profile.avatar.medium_thumb_url)
                        }
                        ls.setItem("profile_type", data.profile.ovvy_verified ? 'merchant' : 'client')
                        ls.setItem("profile_view", data.profile.ovvy_verified ? 'merchant' : 'client')
                        ls.setItem("ovvy_verified", data.profile.ovvy_verified)
                        ls.setItem("referral_code", data.profile.referral_code)
                        ls.setItem("is_phone_verified", data.is_phone_verified)
                        ls.setItem("phone_number", data.phone_number)

                        $this.$root.setCookie($this.$root.user_id_cookie, data.encrypted, 6)
                        $this.$root.setCookie($this.$root.user_detail_cookie, JSON.stringify({
                            n: data.first_name+' '+data.last_name,
                            a: data.profile.avatar ? data.profile.avatar.medium_thumb_url : ''
                        }), 6);
                        // $this.$root.setCookie('_ov_pv', data.profile.ovvy_verified ? 'merchant' : 'client', 6);

                        if($this.$root.app_display_view == 'ios' && 
                            $this.formData.web_player_id != null && $this.formData.web_player_id != '') {
                            ls.setItem('ios_one_signal_set', true)
                        }

                        sessionStorage.setItem($this.$root.login_logger_name, 1);

                        $this.processing = false;
                        $this.$root.isLoading = false;  
                        
                        if(!data.profile.ovvy_verified) {
                            $this.$root.goTo('');
                        }else {
                            $this.$root.goTo('browse');
                        }
                    }).catch(error => {
                        $this.processing = false;
                        $this.otp = {n1: null, n2: null, n3: null, n4: null}
                        $this.errors['otp'] = ''

                        console.log(error)
                        alert(Object.values(error.response.data.errors).join('\n \n'))
                        $this.$root.isLoading = false;  
                    });
                }
            }else {
                await register(formData).then(response => {
                    // console.log(response)
                    this.$root.isLoading = false;
                    $this.processing = false;
                    if (response.data) {
                        $this.isLoading = false
                        let data = response.data.data

                        // ls.setItem("email", data.user.email)
                        // ls.setItem("token",data.access_token, 1440000 * 15) //6hours ra ni
                        // ls.setItem("id",data.user.id, 1440000 * 15)
                        // ls.setItem("encrypted_id",data.encrypted, 1440000 * 15)
                        // ls.setItem("name",data.user.first_name+' '+data.user.last_name, 1440000 * 15)
                        // ls.setItem("profile_type", data.profile.ovvy_verified ? 'merchant' : 'client', 1440000 * 15)
                        // ls.setItem("profile_view", data.profile.ovvy_verified ? 'merchant' : 'client', 1440000 * 15)
                        // ls.setItem("ovvy_verified", data.profile.ovvy_verified, 1440000 * 15)
                        // ls.setItem("referral_code", data.profile.referral_code, 1440000 * 15)
                        $this.$root.scrollToTop();
                        if(this.dontAskOtp) this.showOtp = true;
                        this.otpSuccess = true;
                        this.userEncryptId = data.encrypted;

                        if($this.$root.app_display_view == 'ios' && 
                            $this.formData.web_player_id != null && $this.formData.web_player_id != '') {
                            ls.setItem('ios_one_signal_set', true)
                        }
                    }
                }).catch(error => {
                    if(error.response.data.errors) {
                        console.log(error.response.data);
                        const errors = error.response.data.errors;
                        $this.hasError = false;
                        $this.errMsg = {};
                        Object.keys(errors).forEach(key => {
                        console.log(key, errors[key])
                        $this.errMsg[key] = errors[key][0]
                        $this.hasError = true
                        });
                    }else {
                        $this.errMsg['global'] = 'An error occured. Please try again.'
                    }
                    $this.processing = false;
                    $this.$root.isLoading = false;  
                    this.otp = {n1: null, n2: null, n3: null, n4: null}
                    this.errors['otp'] = ''

                    console.log(error)
                    // alert(Object.values(error.response.data.errors).join('\n \n'))
                    
                });
            }
        }
    },
    async resendCode() {
        if (this.resend_processing === true) {
        return;
        }

        this.resend_processing = true;

        // console.log('resend')

        this.getOTP(this.formData.phone_number);
    },
    proceed() {
        // this.showOtp = false
        location.reload();
    },
    phoneInput(phone, phoneObject) {
        // console.log(phone)
        // console.log(phoneObject)
        // console.log(input)
        if (phoneObject?.formatted) {
        this.phone = phoneObject.formatted;
        this.formData.phone_number = phoneObject.number
        this.phoneValid = phoneObject.valid

        this.isPhoneValid(phone)
        }
    },
    isPhoneValid() {
        delete this.errMsg.phone_number 
        if (!this.formData.phone_number) {
        this.errMsg = {...this.errMsg, ...{phone_number: 'The mobile number is required'}};
        }

        if (this.formData.phone_number && !this.phoneValid) {
        this.errMsg = {...this.errMsg, ...{phone_number: 'The provided phone number is not a valid'}};
        }
    },
    passwordInput() {
        let hasSpecial = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
        this.passwordValid = {
        has_number: /\d/.test(this.formData.password),
        has_lowercase: /[a-z]/.test(this.formData.password),
        has_uppercase: /[A-Z]/.test(this.formData.password),
        has_special: hasSpecial.test(this.formData.password),
        }
    },
    isIOS() {
        this.platform = navigator.platform;
        return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'MacIntel'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    async signUpViaFB() {
        /* eslint-disable */
        // const { authResponse } = await new Promise(FB.login);
        // if (!authResponse) return;
        // console.log(authResponse);
        const $this = this;
        $this.$root.isLoading = true
        FB.login(function(response) {
            // console.log(response);
            $this.$root.isLoading = false
            if(response.authResponse && response.status == "connected") {
                // console.log(response.authResponse.accessToken)
                $this.formData.facebook_access_token = response.authResponse.accessToken
                $this.formData.facebook_id = response.authResponse.userID
                $this.formData.email = 'temp@facebook.com'

                if($this.view == 'sign_up') {
                    $this.appleSignUp = true
                    $this.fbSignUp = true
                }

                $this.checkFB();
            }
        }, {scope: 'public_profile,email'});
        /* eslint-enable */
        // await apiAuthenticate(authResponse.accessToken);

        // // get return url from query parameters or default to home page
        // const returnUrl = router.currentRoute.value.query['returnUrl'] || '/';
        // router.push(returnUrl);
    },
    doneProcess() {
        this.isLoading = true
        window.location.href = 'https://ovvyapp.com/'
    },
    verifyAppleSignupOtp() {
        this.hasError = false;
        this.errMsg = {};

        this.isPhoneValid()
        if (Object.keys(this.errMsg).length > 0) {
            this.hasError = true;
        }

        // console.log(this.hasError)
        
        if (!this.hasError) {
            // console.log('valid')
            this.getOTP(this.formData.phone_number)
        }
    },
    checkIfAppleSignupComplete() {
        if (!this.verifyOtp()) {
        return;
        }
        // console.log('checkIfAppleSignupComplete')

        let appleCheckerMsg = {};

        //check first if name complete
        if (!this.formData.first_name) {
        appleCheckerMsg = {...appleCheckerMsg, ...{first_name: 'The fist name is required'}};
        }else {
        if(this.formData.first_name.length > 255) {
            appleCheckerMsg = {...appleCheckerMsg, ...{first_name: 'The fist name must not be greater than 255 characters'}};
        }
        }

        if (!this.formData.last_name) {
        appleCheckerMsg = {...appleCheckerMsg, ...{last_name: 'The last name is required'}};
        }else {
        if(this.formData.first_name.length > 255) {
            appleCheckerMsg = {...appleCheckerMsg, ...{first_name: 'The last name must not be greater than 255 characters'}};
        }
        }

        if (Object.keys(appleCheckerMsg).length > 0 && !this.fbSignUp) {
            if(this.dontAskOtp) this.showOtp = true;
            this.appleNameForm = true;
        }else {
            this.saveAndContinue()
        }
    },
    checkAppleSignupNameForm() {
        // console.log('checkAppleSignupNameForm')
        this.hasError = false;
        this.errMsg = {};
        
        if (!this.formData.first_name) {
        this.errMsg = {...this.errMsg, ...{first_name: 'The fist name is required'}};
        }else {
        if(this.formData.first_name.length > 255) {
            this.errMsg = {...this.errMsg, ...{first_name: 'The fist name must not be greater than 255 characters'}};
        }
        }

        if (!this.formData.last_name) {
        this.errMsg = {...this.errMsg, ...{last_name: 'The last name is required'}};
        }else {
        if(this.formData.first_name.length > 255) {
            this.errMsg = {...this.errMsg, ...{first_name: 'The last name must not be greater than 255 characters'}};
        }
        }

        if (Object.keys(this.errMsg).length > 0) {
         this.hasError = true;
        }else {
            this.saveAndContinue()
        }
    },
    redirectTo() {
        if(this.preference == 'client') {
            location.reload()
        }else {
            this.$root.goTo('merchant-registration');
        }
    },
    async checkFB() {
        
        const $this = this
        $this.$root.isLoading = true;

        let data = new FormData();
            data.append('facebook_access_token', $this.formData.facebook_access_token)
            data.append('web_player_id', $this.formData.web_player_id)
            data.append('is_phone_verified', this.dontAskOtp ? 0 : 1);

        await check_facebook(data).then(response => {
            // console.log(response)
            // console.log(response.data.exist)

            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
                window.webkit.messageHandlers.loggerHandler.postMessage({
                    "message": response
                });
            }

            if(response.data.exist) {
                //LOGIN
                let data = response.data.data;
                // console.log(data)
                ls.setItem("email", data.email)
                ls.setItem("token",data.access_token) //6hours ra ni
                ls.setItem("id",data.id)
                ls.setItem("encrypted_id",data.encrypted)
                ls.setItem("name",data.first_name+' '+data.last_name)
                if(data.profile.avatar) {
                    ls.setItem("avatar", data.profile.avatar.medium_thumb_url )
                }
                ls.setItem("profile_type", data.profile.ovvy_verified ? 'merchant' : 'client')
                ls.setItem("profile_view", data.profile.ovvy_verified ? 'merchant' : 'client')
                ls.setItem("ovvy_verified", data.profile.ovvy_verified)
                ls.setItem("referral_code", data.profile.referral_code)
                ls.setItem("is_phone_verified", data.is_phone_verified)
                ls.setItem("phone_number", data.phone_number)

                this.$root.setCookie($this.$root.user_id_cookie, data.encrypted, 6)
                this.$root.setCookie($this.$root.user_detail_cookie, JSON.stringify({
                    n: data.first_name+' '+data.last_name,
                    a: data.profile.avatar ? data.profile.avatar.medium_thumb_url : ''
                }), 6);
                this.$root.setCookie('_ov_pv', data.profile.ovvy_verified ? 'merchant' : 'client', 6);

                if($this.$root.app_display_view == 'ios' && 
                    $this.formData.web_player_id != null && $this.formData.web_player_id != '') {
                    ls.setItem('ios_one_signal_set', true)
                }

                sessionStorage.setItem($this.$root.login_logger_name, 1);

                $this.$root.isLoading = false;  
                
                if(!data.profile.ovvy_verified) {
                    $this.$root.goTo('');
                }else {
                    $this.$root.goTo('browse');
                }
                this.externalLogin(data.encrypted);
            }else {
                $this.$root.isLoading = false;
                if($this.view == 'login') {
                    $this.$swal.fire({
                        title: 'User does not exist!',
                        text: "Signup first",
                        icon: 'error',
                        confirmButtonText: 'Sign Up',
                        confirmButtonColor: '#f44336',
                        showCancelButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $this.view = 'sign_up'
                        }
                    })
                }
            }
        }).catch(error => {
            console.log(error.response.data);
            $this.$root.isLoading = false;
        });
    },
    async checkFB2(fb_code) {
        
        const $this = this
        $this.$root.isLoading = true;

        let data = new FormData();
            data.append('fb_code', fb_code)
            data.append('web_player_id', $this.formData.web_player_id)
            data.append('is_phone_verified', this.dontAskOtp ? 0 : 1);

        await check_facebook2(data).then(response => {
            // console.log(response)
            // console.log(response.data.exist)

            this.fb_error = response.data;

            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
                window.webkit.messageHandlers.loggerHandler.postMessage({
                    "message": response
                });
            }

            if(response.data.exist) {
                //LOGIN
                let data = response.data.data;
                // console.log(data)
                ls.setItem("email", data.email)
                ls.setItem("token",data.access_token) //6hours ra ni
                ls.setItem("id",data.id)
                ls.setItem("encrypted_id",data.encrypted)
                ls.setItem("name",data.first_name+' '+data.last_name)
                if(data.profile.avatar) {
                    ls.setItem("avatar", data.profile.avatar.medium_thumb_url)
                }
                ls.setItem("profile_type", data.profile.ovvy_verified ? 'merchant' : 'client')
                ls.setItem("profile_view", data.profile.ovvy_verified ? 'merchant' : 'client')
                ls.setItem("ovvy_verified", data.profile.ovvy_verified)
                ls.setItem("referral_code", data.profile.referral_code)
                ls.setItem("is_phone_verified", data.is_phone_verified)
                ls.setItem("phone_number", data.phone_number)

                this.$root.setCookie($this.$root.user_id_cookie, data.encrypted, 6)
                this.$root.setCookie($this.$root.user_detail_cookie, JSON.stringify({
                    n: data.first_name+' '+data.last_name,
                    a: data.profile.avatar ? data.profile.avatar.medium_thumb_url : ''
                }), 6);
                this.$root.setCookie('_ov_pv', data.profile.ovvy_verified ? 'merchant' : 'client', 6);

                if($this.$root.app_display_view == 'ios' && 
                    $this.formData.web_player_id != null && $this.formData.web_player_id != '') {
                    ls.setItem('ios_one_signal_set', true)
                }

                sessionStorage.setItem($this.$root.login_logger_name, 1);

                $this.$root.isLoading = false;  
                
                if(!data.profile.ovvy_verified) {
                    $this.$root.goTo('');
                }else {
                    $this.$root.goTo('browse');
                }
                this.externalLogin(data.encrypted);
            }else {
                $this.$root.isLoading = false;

                let data = response.data.data;
                console.log(data)
                this.formData.facebook_id = data.facebook_id
                this.formData.email = data.email
                this.formData.first_name = data.first_name
                this.formData.last_name = data.last_name
                this.formData.facebook_avatar = data.facebook_avatar
                // $this.FB.logout();
                
                $this.view = 'sign_up';
                $this.appleSignUp = true;
                $this.fbSignUp = true
                
            }
        }).catch(error => {
            console.log(error);
            $this.$root.isLoading = false;
        });
    },
    async forgotPassword() {
        const $this = this;
        let hasError = false;
        this.fp.errors = {};

        if (!this.fp.form.email) {
          this.fp.errors = {...this.fp.errors, ...{email: 'The email is required'}};
        }
        let emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (this.fp.form.email && !emailRe.test(this.fp.form.email)) {
          this.fp.errors = {...this.fp.errors, ...{email: 'The email must be a valid email address'}};
        }

        if (Object.keys(this.fp.errors).length > 0) {
          return hasError = true;
        }

        this.$root.isLoading = true;
        if(!hasError) {
          await forgot_password(this.fp.form).then(() => {
            // console.log(response);
            this.$root.isLoading = false;
            $this.$swal.fire({
              title: 'Reset Password Email Sent.',
              text: 'Please check your email',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#f44336'
            })
            $this.fp.form.email = '';
            $this.showForgotPass = false
          }).catch(error => {
              // $this.processing = false;
              console.log(error);
              if(error.response.data.errors) {
                $this.fp.errors['global'] = error.response.data.errors[0]
              }else {
                $this.fp.errors['global'] = 'An error occured. Please try again.'
              }
          })
        }
    },
    loginValidateForm() {
        this.login.hasError = false;
        this.login.errMsg = '';

        if (!this.login.form.password) {
          this.login.errMsg = 'The password is required';
        }

        let emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (this.login.form.email && !emailRe.test(this.login.form.email)) {
          this.login.errMsg = 'The email must be a valid email address';
        }

        if (!this.login.form.email) {
          this.login.errMsg = 'The email is required';
        }     
        if (this.login.errMsg != '') {
          this.login.hasError = true;
        }
    },
    async logMeIn() {
        this.loginValidateForm();
        
        if (this.login.hasError) {
           return;
        }

        if (this.login.processing === true) {
          return;
        }

        this.login.processing = true;
        this.$root.isLoading = true;

        const $this = this;

        let form = this.login.form;
        if(this.$root.enable_recaptcha) form.recaptcha_token = await this.$root.recaptcha('login');

        await login(form).then(response => {
        //   console.log(response.data.data);
          let data = response.data.data;
          // console.log(data.access_token)
          // console.log(data.encrypted)
          // console.log($this.form.email)
          // console.log($this.form.password)

          ls.setItem("email", $this.login.form.email)
          ls.setItem("pass", $this.login.form.password)
          ls.setItem("token",data.access_token)
          ls.setItem("id",data.id)
          ls.setItem("encrypted_id",data.encrypted)
          ls.setItem("name",data.first_name+' '+data.last_name)
          if(data.profile.avatar) {
            ls.setItem("avatar", data.profile.avatar.medium_thumb_url)
          }
          ls.setItem("profile_type", data.profile.ovvy_verified ? 'merchant' : 'client')
          ls.setItem("profile_view", data.profile.ovvy_verified ? 'merchant' : 'client')
          ls.setItem("ovvy_verified", data.profile.ovvy_verified)
          ls.setItem("referral_code", data.profile.referral_code)
          ls.setItem("is_phone_verified", data.is_phone_verified)
          ls.setItem("phone_number", data.phone_number)

          $this.$root.setCookie($this.$root.user_id_cookie, data.encrypted, 6)
          $this.$root.setCookie($this.$root.user_detail_cookie, JSON.stringify({
            n: data.first_name+' '+data.last_name,
            a: data.profile.avatar ? data.profile.avatar.medium_thumb_url : ''
            }), 6);
          $this.$root.setCookie('_ov_pv', data.profile.ovvy_verified ? 'merchant' : 'client', 6);

          if($this.$root.app_display_view == 'ios' && 
            $this.login.form.web_player_id != null && $this.login.form.web_player_id != '') {
            ls.setItem('ios_one_signal_set', true)
          }

          sessionStorage.setItem($this.$root.login_logger_name, 1);

          // localStorage.setItem("_ovvy_userEncryptId", data.encrypted);
          // localStorage.setItem("_ovvy_userToken", data.access_token);

          $this.login.processing = false;
          $this.$root.isLoading = false;  
        //   console.log('login')
        //   console.log(sessionStorage.getItem('_ovvy_backUrl'))
          if(sessionStorage.getItem('_ovvy_backUrl') == null) {
            // console.log('reload')
            // location.reload();

            // let redirect_url = data.profile.ovvy_verified ? '/browse' : '/';
            let redirect_url = '/';

            let orig = window.location.href;
            // console.log('orig: ' + orig)
            // console.log('redirect: ' + window.location.origin + '/#' +  redirect_url)
            if(orig == window.location.origin + '/#' +  redirect_url) {
              location.reload();
            }else {
              window.location.href = window.location.origin + '/#' + redirect_url ;
              location.reload();
            }
            
            
          }else {
            let redirect_url = '/';
            if(sessionStorage.getItem('_ovvy_backUrl') == null) {
              // redirect_url = data.profile.ovvy_verified ? '/browse' : '/';
              redirect_url = '/';
            }else {
              redirect_url = sessionStorage.getItem('_ovvy_backUrl');
            }
            sessionStorage.removeItem('_ovvy_backUrl')
            let orig = window.location.href;
            // console.log('orig: ' + orig)
            // console.log('redirect: ' + window.location.origin + '/#' + redirect_url)
            if(orig == window.location.origin + '/#' +  redirect_url) {
              location.reload();
            }else {
              window.location.href = window.location.origin + '/#' + redirect_url ;
              location.reload();
            }
          }
          this.externalLogin(data.encrypted);

        }).catch(error => {
            $this.$root.isLoading = false;
            $this.login.processing = false;
            console.log(error);
            $this.login.hasError = true
            if(error.response.data.errors) {
              $this.login.errMsg = error.response.data.errors[0]
            }else {
              $this.login.errMsg = 'An error occured. Please try again.'
            }
        })
    },
    async checkApple() {
        const $this = this
        $this.$root.isLoading = true;

        let data = new FormData();
            data.append('apple_id', $this.login.form.apple_id)
            data.append('email', $this.login.form.email)
            data.append('web_player_id', $this.login.form.web_player_id)

        await apple(data).then(response => {
            // console.log(response)
            // console.log(response.data.exist)

            if(response.data.exist) {
                //LOGIN
                let data = response.data.data;
                // console.log(data)
                ls.setItem("email", data.email)
                ls.setItem("token",data.access_token) //6hours ra ni
                ls.setItem("id",data.id)
                ls.setItem("encrypted_id",data.encrypted)
                ls.setItem("name",data.first_name+' '+data.last_name)
                if(data.profile.avatar) {
                    ls.setItem("avatar", data.profile.avatar.medium_thumb_url)
                }
                ls.setItem("profile_type", data.profile.ovvy_verified ? 'merchant' : 'client')
                ls.setItem("profile_view", data.profile.ovvy_verified ? 'merchant' : 'client')
                ls.setItem("ovvy_verified", data.profile.ovvy_verified)
                ls.setItem("referral_code", data.profile.referral_code)
                ls.setItem("is_phone_verified", data.is_phone_verified)
                ls.setItem("phone_number", data.phone_number)

                $this.$root.setCookie($this.$root.user_id_cookie, data.encrypted, 6)
                $this.$root.setCookie($this.$root.user_detail_cookie, JSON.stringify({
                    n: data.first_name+' '+data.last_name,
                    a: data.profile.avatar ? data.profile.avatar.medium_thumb_url : ''
                }), 6);
                $this.$root.setCookie('_ov_pv', data.profile.ovvy_verified ? 'merchant' : 'client', 6);

                if($this.$root.app_display_view == 'ios' && 
                    $this.login.form.web_player_id != null && $this.login.form.web_player_id != '') {
                    ls.setItem('ios_one_signal_set', true)
                }

                sessionStorage.setItem($this.$root.login_logger_name, 1);
                
                $this.$root.isLoading = false;  
                
                if(!data.profile.ovvy_verified) {
                  $this.$root.goTo('');
                }else {
                  $this.$root.goTo('browse');
                }
                this.externalLogin(data.encrypted);
            }else {
              $this.$root.isLoading = false;
              if($this.view == 'login') {
                $this.$swal.fire({
                    title: 'User does not exist!',
                    text: "Signup first",
                    icon: 'error',
                    confirmButtonText: 'Sign Up',
                    confirmButtonColor: '#f44336',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.view = 'sign_up'
                    }
                })
              }
             
            }
        }).catch(error => {
            console.log(error.response.data);
            $this.$root.isLoading = false;
        });
    },
    successApple(event) {
        const $this = this
        // Handle successful response.
        console.log(event)
        // console.log(event.detail)

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
            window.webkit.messageHandlers.loggerHandler.postMessage({
                "class": 'successApple',
                "message": event
            });
        }

        let token = event.authorization.id_token;
        let decoded = jwt_decode(token);
        // console.log(decoded)
        ls.setItem("apple_sub", decoded.sub)
        ls.setItem("apple_email", decoded.email)

        if($this.view == 'sign_up') {
            $this.formData.apple_id = decoded.sub
            $this.formData.email = decoded.email
        }else {
            $this.login.form.apple_id = decoded.sub
            $this.login.form.email = decoded.email
        }

        // console.log(event.detail.data);

        // if(event.detail.user) {
        //     if($this.view == 'sign_up') {
        //         $this.formData.first_name = event.detail.user.name.firstName
        //         $this.formData.last_name = event.detail.user.name.lastName
        //     }

        //     ls.setItem("apple_fname", event.detail.user.name.firstName)
        //     ls.setItem("apple_lname", event.detail.user.name.lastName)
        // }

        if($this.view == 'sign_up') {
            $this.appleSignUp = true
        }

        $this.checkApple()
    },
    failureApple(event) {
        // Handle error.
        console.log(event)
        // console.log(event.detail.error);
    },
    successFacebook(response) {
    //   console.log(this.FB)
    //   console.log(this.FB_SCOPE)
      const $this = this
    //   console.log(response);

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
            window.webkit.messageHandlers.loggerHandler.postMessage({
                "message": response
            });
        }

      if(response) {
        $this.formData.facebook_access_token = response.authResponse.accessToken
        $this.formData.facebook_id = response.authResponse.userID
        $this.formData.email = 'temp@facebook.com'

        if($this.view == 'sign_up') {
            $this.appleSignUp = true
            $this.fbSignUp = true
        }

        $this.checkFB();
        $this.FB.logout();
      }
    },
    isFBConnected(response) {
        // console.log('fb connected')
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
            window.webkit.messageHandlers.loggerHandler.postMessage({
                "class": 'isFBConnected',
                "message": response
            });
        }
    },
    handleFBInit({FB, Scope}) {
      this.FB = FB
      this.FB_SCOPE = Scope
    //   console.log(FB)
    //   console.log(Scope)

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
            window.webkit.messageHandlers.loggerHandler.postMessage({
                "class": 'handleFBInit'
            });
        }
    },
    skipBtn() {
        this.view = 'welcome'
        if(this.$route.name == 'External Welcome') {
            window.parent.postMessage("ovvyMP::modal::close", "*");
        }
    },
    externalLogin(external) {
        if(this.$route.name == 'External Welcome') {
            window.parent.postMessage("ovvyMP::token::" + external, "*");
        }
    },
    backToSignUpForm() {
        this.showOtp = false
        if(this.$route.name == 'External Welcome') {
            window.parent.postMessage("ovvyMP::display::sign_up_form", "*");
        }
    },
    redirectMerchantReg() {
        this.view = 'login'
        if(this.$route.name == 'External Welcome') {
            window.parent.postMessage("ovvyMP::redirect::merchant_registration", "*");
        }
    }
  },
  watch: {
    header: function(newVal) {
      console.log('show header' + newVal)
    },
    view: function(newVal) {
        if(this.$route.name == 'External Welcome') {
            window.parent.postMessage("ovvyMP::display::" + newVal, "*");
        }
    },
    showForgotPass: function(newVal) {
        if(this.$route.name == 'External Welcome' && newVal) {
            window.parent.postMessage("ovvyMP::display::forgot_password", "*");
        }
    },
    preference(newVal) {
        if(this.$route.name == 'External Welcome') {
            if(newVal == '') {
                window.parent.postMessage("ovvyMP::display::sign_up", "*");
            }else {
                window.parent.postMessage("ovvyMP::display::sign_up_form", "*");
            }
        }
    },
    showOtp: function(newVal) {
        if(this.$route.name == 'External Welcome' && newVal) {
            window.parent.postMessage("ovvyMP::display::sign_up_otp", "*");
        }
    },
    otpSuccess: function(newVal) {
        if(this.$route.name == 'External Welcome' && newVal) {
            window.parent.postMessage("ovvyMP::display::sign_up_success", "*");
        }
    },
    appleSignUp: function(newVal) {
        if(this.$route.name == 'External Welcome' && newVal) {
            window.parent.postMessage("ovvyMP::display::sign_up_apple", "*");
        }
    },
    appleNameForm: function(newVal) {
        if(this.$route.name == 'External Welcome' && newVal) {
            window.parent.postMessage("ovvyMP::display::sign_up_apple_form", "*");
        }
    },
  },
  computed: {
        title() {
            return this.view == 'sign_up' ? 'SIGN UP' : this.showForgotPass ? 'FORGOT PASSWORD' : 'LOG IN'
        },
        isPassValid() {
            return this.formData.password && this.formData.password.length >= 7 &&
                this.passwordValid.has_number && this.passwordValid.has_lowercase &&
                this.passwordValid.has_uppercase && this.passwordValid.has_special
        },
        isExternalWelcome() {
            return this.$route.name == 'External Welcome'
        }
    },
    async created() {
        // console.log(this.$route)
        if(this.hide_header) this.showHeader = !this.hide_header
        // this.getModels()
        $('.modal-backdrop.fade.show').remove()

        this.showAppleSignUp = this.isIOS();

        // if(this.$route.name == 'External Welcome') {
        //     window.parent.postMessage("Iframe Loaded", "*");
        // }
        
        //Check if already logged in
        if(this.$route.name == 'External Welcome') {
            let result = this.$root.isLoggedIn ? 'true' : 'false'
            window.parent.postMessage("ovvyMP::loggedin::" + result, "*");
        }

        // const $this = this

        // // Listen for authorization success.
        // document.addEventListener('AppleIDSignInOnSuccess', (event) => {
        //     // Handle successful response.
        //     console.log(event)
        //     console.log(event.detail)

        //     let token = event.detail.authorization.id_token;
        //     let decoded = jwt_decode(token);
        //     ls.setItem("apple_sub", decoded.sub)
        //     ls.setItem("apple_email", decoded.email)

        //     if($this.view == 'sign_up') {
        //         $this.formData.apple_id = decoded.sub
        //         $this.formData.email = decoded.email
        //     }else {
        //         $this.login.form.apple_id = decoded.sub
        //         $this.login.form.email = decoded.email
        //     }

        //     // console.log(event.detail.data);

        //     if(event.detail.user) {
        //         if($this.view == 'sign_up') {
        //             $this.formData.first_name = event.detail.user.name.firstName
        //             $this.formData.last_name = event.detail.user.name.lastName
        //         }

        //         ls.setItem("apple_fname", event.detail.user.name.firstName)
        //         ls.setItem("apple_lname", event.detail.user.name.lastName)
        //     }

        //     if($this.view == 'sign_up') {
        //         $this.appleSignUp = true
        //     }else {
        //         $this.checkApple()
        //     }
            
        // });

        // // Listen for authorization failures.
        // document.addEventListener('AppleIDSignInOnFailure', (event) => {
        //     // Handle error.
        //     console.log(event)
        //     console.log(event.detail.error);
        // });
        if(this.$root.app_display_view == 'ios') {
            this.login.form.web_player_id = this.$root.user_ios_one_signal_player_id;
            this.formData.web_player_id = this.$root.user_ios_one_signal_player_id;
        }else {
            this.login.form.web_player_id = await this.$OneSignal.getUserId();
            this.formData.web_player_id = await this.$OneSignal.getUserId();
        }

        // const $this = this
        // setTimeout(function() {
        //     console.log('fb check')
        //     $this.FB.getLoginStatus(function(response) {
        //         if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
        //             window.webkit.messageHandlers.loggerHandler.postMessage({
        //                 "class": 'getLoginStatus 1',
        //                 "message": response
        //             });
        //         }
        //         console.log(response);
        //         if (response.status === 'connected') {
        //             // The user is logged in and has authenticated your
        //             // app, and response.authResponse supplies
        //             // the user's ID, a valid access token, a signed
        //             // request, and the time the access token 
        //             // and signed request each expire.
        //             var uid = response.authResponse.userID;
        //             var accessToken = response.authResponse.accessToken;
        //             console.log(uid);
        //             console.log(accessToken);

        //             if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
        //                 window.webkit.messageHandlers.loggerHandler.postMessage({
        //                     "class": 'getLoginStatus',
        //                     "message": uid,
        //                     "accessToken": accessToken
        //                 });
        //             }
                    
        //         } else if (response.status === 'not_authorized') {
        //             // The user hasn't authorized your application.  They
        //             // must click the Login button, or you must call FB.login
        //             // in response to a user gesture, to launch a login dialog.
        //         } else {
        //             // The user isn't logged in to Facebook. You can launch a
        //             // login dialog with a user gesture, but the user may have
        //             // to log in to Facebook before authorizing your application.
        //         }
        //     });
        // }, 2000)


        if(this.$route.name == 'FBRedirect') {
            this.$root.isLoading = true;
            let url = new URL(window.location);
            this.checkFB2(url.searchParams.get("code"));
        }
        
    },
    mounted() {
        const $this = this
        setTimeout(function() {
            if($this.$root.sessionValue('show_welcome_modal')) {
                new Modal('#welcome-modal', {}).show()
                ssWatcher.removeItem('show_welcome_modal')
            }
        }, 500);
    }
}
</script>
<style scoped>
@import './../../assets/styles/signup.css';
</style>
<style scoped>

    .welcome-container {
        background: #1D3557;
        padding: 20px 0;
    }

    .btn-facebook:hover {
        background: #3b5998 !important;
        color: #fff !important;
    }
    .form-error, .form-error small {
        color: #e63945 !important;
    }
    .bw-div{
        background: #f6f6f6;
    }
    .btn-100 {
      width: 100%;
    }
    input[type=checkbox] {
        width: 30px;
        height: 30px;
        /* transform: scale(2); */
    }
    .header-text {
        font-weight: 600;
        font-size: 40px;
        color: #000;
    }
    .btn-pink-bg:hover, .btn-pink-otp:hover {
        color: #fff;
    }
    .vue-tel-input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
    .frmValidation {
      display:block;
    }
    .frmValidation.passed span {
      color: #009688;
    }
    .frmValidation.failed span{
      color: #f44336;
    }

    .frmValidation.passed em {
      color: rgb(78, 78, 78);
    }

    .frmValidation.failed em{
      color: #000;
    }

    .hiw-wrap {
      height: 100%;
    } 

    .how-it-works-slider-wrapper .carousel__pagination {
      display: none;
    }

    .verification-success-wrapper p {
      font-size: 18px;
    }

    .btn-facebook {
      background: #3B5998;
      color: #fff;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 7px;
    }

    .btn-submit:hover {
        background-color: #f44336 !important;
    }

    .btn-facebook:hover {
      color: #fff;
      opacity: 0.8;
    }

    .btn-apple {
      background: #000000;
      color: #fff;
      padding: 10px;
    }

    .btn-apple:hover {
      color: #fff;
      opacity: 0.8;
    }

    .btn-submit {
      background: #f44336;
      color: #fff;
      padding: 10px;
    }

    .btn-submit:hover {
      color: #fff;
      opacity: 0.8;
    }

    .apple-signin {
        width: 100% !important;
        height: 45px;
    }

    .apple-signin > div {
        width: 100% !important;
        max-width: 100% !important;
    }

    @media (max-width: 1280px) {
        .header-text {
            font-size: 35px;
        }
    }

    @media (max-width: 1125px) {
        .header-text {
            font-size: 30px;
        }
    }

    @media (max-width: 920px) {
      .how-it-works-slider-wrapper .carousel__pagination {
        display: flex;
      }
    }

    @media (max-width: 991px) {
      .hiw-wrap {
          margin-bottom: 10px;
      }
      .header-text {
          font-size: 25px;
      }
    }

    @media (max-width: 427px) {
      .verification-success-wrapper h1 {
        font-size: 30px;
      }

      .verification-success-wrapper p {
        font-size: 19px;
      }
    }

  
</style>