<template>
    <div v-if="showMe" class="">
        <section v-if="!showPromo">
            <section class="">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12 padding-none text-center">
                        <div class="nav-cleaning">
                        <div class="title">
                            <label class="text-white">OFFERS</label>
                        </div>
                            <div class="back">
                            <a @click.prevent="back()" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </section>
            <section class="offer-title">
                <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                    <h6>Promo Code</h6>
                    </div>
                </div>
                </div>
            </section>
            <section class="bg-white">
                <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                    <form class="promo-code-form">
                        <input v-model="code" type="text" class="form-control" id="promo-code" placeholder="Enter Promo Code" name="promo-code">
                        <a @click.prevent="submit()" class="btn apply-promo-btn">APPLY</a>
                    </form>
                    </div>
                </div>
                </div>
            </section>

            <section v-if="vouchers.length > 0" class="bg-white">
                <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <p>Select a Voucher</p>
                    </div>
                    <div v-for="(item, l) in vouchers" :key="l" class="col-lg-12 voucher-container">
                        <div class="img">
                            <img src="~@/assets/img/logo-red.png"/>
                        </div>
                        <div class="text">
                            <p>{{ item.promo_code }}</p>
                            <small>Min. ${{ $root.insertDecimal(Number(item.minimum_order_amount)) }} spend | Valid till {{displayDate(item.end_date)}}</small>
                            <button @click.prevent="selectVoucher(item)" class="btn btn-green btn-sm" style="margin-left: 5px">Use Now</button>
                        </div>
                    </div>
                </div>
                </div>
            </section>

        </section>
        <section v-else>
            <section class="">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12 padding-none text-center">
                        <div class="nav-cleaning">
                        <div class="title">
                            <label class="text-white">TERMS AND CONDITIONS</label>
                        </div>
                            <div class="back">
                            <a @click.prevent="showPromo=false" class="btn"><i class="fa fa-times white"></i></a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </section>
            <section class="margin-top-50">
                <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h6 class="pt-3 text-center">Offer Details</h6>
                        <h6 class="promo_code-cont">{{promo.promo_code}}</h6>
                    </div>
                    <div class="col-lg-12 mt-2">
                        <table style="width: 100%;border-bottom: 2px solid #c7c7c7;">
                            <tr>
                                <td>
                                    <p style="font-size: 14px;color: #5c5c5c;">OFF</p>
                                    <p v-if="promo.discount_percent > 0">
                                        {{promo.discount_percent + '% off up to $' + $root.insertDecimal(Number(promo.max_discount_limit))}}
                                    </p>
                                    <p v-else>
                                        {{'$' + $root.insertDecimal(Number(promo.discount_fixed_value))}}
                                    </p>
                                </td>
                                <td style="border-left: 2px solid #c7c7c7;text-align: right;width: 50%;">
                                    <p style="font-size: 14px;color: #5c5c5c;">Valid Till</p>
                                    <p>
                                        {{displayDate(promo.end_date)}}
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-lg-12 mt-2" v-html="promo.term_condition_text"></div>
                </div>
                </div>
            </section>
            <section class="">
                <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <a @click.prevent="confirm()" href="#" class="btn next-btn">USE NOW</a>
                    </div>
                </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import { verify as verifyPromo, getCategoryVouchers, getBidVouchers } from "@/api/promos"
import moment from 'moment'
export default {
  name: 'PromoVerifier',
  components: {
    
  },
  data() {
    return {
        showMe: this.visible,
        showPromo: false,
        hasError: false,
        errMsg: '',
        processing: false,
        code: '',
        promo: {},
        vouchers: []
    }
  },
  props: {
    visible: Boolean,
    data: Object,
  },
  watch: {
    visible: function (newVal) {
        this.showMe = newVal
        this.showPromo = false
    },
    items: function (newVal) {
        this.$emit('data', newVal)
    },
    'data.total': function(newVal) {
        if(newVal > 0) {
            this.getVouchers()
        }
    }
  },
  methods: {
    back() {
        this.$emit('close')
    },
    async submit() {
        const $this = this

        this.hasError = false;
        this.errMsg = '';

        if (!this.code) {
            this.hasError = true;
            this.$swal.fire(
                '',
                'Code is required',
                'error'
            )
            return;
        }

        if (this.processing === true) {
          return;
        }

        this.processing = true;
        $this.$root.isLoading = true;  
        
        let formData = new FormData();
        Object.keys(this.data).forEach(key => {
          if($this.data[key] !== null && $this.data[key] !== 'null') {
            if(Array.isArray($this.data[key])) {
              for (var i = 0; i < $this.data[key].length; i++) {
                if($this.data[key][i] !== undefined)
                  formData.append(key+'[]', $this.data[key][i]);
              }
            }else {
                if(key == 'is_default') {
                    formData.append(key, $this.data[key] ? 1 : 0)
                }
                else {
                    formData.append(key, $this.data[key])
                }
                
            }
          }
        });

        await verifyPromo(this.code, formData).then(response => {
            // console.log(response)
            $this.processing = false;
            $this.$root.isLoading = false; 

            if(response.data.data.promo.offer_type == '') {
                //meaning this is a credit code
                $this.$swal.fire({
                    title: 'Promo Code Can\'t Be Used',
                    text: "This promo code is not applicable to this transaction.",
                    icon: 'error',
                    confirmButtonText: 'Continue',
                    confirmButtonColor: '#9e9e9e'
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.code = ''
                        $this.$emit('close')
                    }
                })
            }else {
                $this.showPromo = true
                $this.promo = response.data.data.promo
            }
        }).catch(error => {
            console.log(error)
            $this.processing = false;
            $this.$root.isLoading = false;  

            $this.$swal.fire({
                title: 'Promo Code Can\'t Be Used',
                text: "This promo code is not applicable to this transaction.",
                icon: 'error',
                confirmButtonText: 'Continue',
                confirmButtonColor: '#9e9e9e'
            }).then((result) => {
                if (result.isConfirmed) {
                    $this.code = ''
                    $this.$emit('close')
                }
            })
        })
    },
    confirm() {
        this.code = ''
        this.$emit('apply', this.promo)
        this.$emit('close')
    },
    displayDate(date) {
        // console.log(date)
        // console.log(moment(date).format('DD MMM YYYY'))
        return moment(date).format('DD MMM YYYY');
    },
    async getVouchers() {
        
        if(!this.$root.isLoggedIn) return;

        const $this = this

        // $this.$root.isLoading = true;  
        if(this.data.bid) {
            await getBidVouchers(this.data.bid.id).then(response => {
                // console.log(response)
                $this.vouchers = response.data
                // $this.$root.isLoading = false; 
            }).catch(error => {
                console.log(error)
                // $this.$root.isLoading = false;  
            })
        }else {
            await getCategoryVouchers(this.data.category_id, {
                total_price: this.data.total
            }).then(response => {
                // console.log(response)
                $this.vouchers = response.data
                // $this.$root.isLoading = false; 
            }).catch(error => {
                console.log(error)
                // $this.$root.isLoading = false;  
            })
        }
    },
    selectVoucher(item) {
        this.showPromo = true
        this.promo = item
        // console.log(item)
    }
  },
  created() {
    if(this.data.bid) {
        this.getVouchers()
    }
  }
}
</script>

<style scoped>
    .next-btn {
        width: 50% !important;
    }

    .voucher-container {
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .voucher-container img {
        width: 80px;
    }

    .voucher-container .img {
        display: flex;
        align-items: center;
    }

    .voucher-container .text {
        padding-left: 10px;
    }

    .voucher-container .text p {
        margin-bottom: 0px;
    }
</style>