import ENDPOINT from "@/api/endpoints";
import { HTTP } from "@/api/http-common";
import { lsWatcher as ls } from "../main";

//CATEGORIES
export function getCategories(params = false) {
  let url = ENDPOINT.CATEGORIES.CATEGORIES
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET",
  })
}

export function getClientCategories(payload) {
  return HTTP({
    url: ENDPOINT.CATEGORIES.CLIENTS,
    method: "GET",
    params: payload
  })
}

export function getMerchantCategories(payload) {
  return HTTP({
    url: ENDPOINT.CATEGORIES.MERCHANTS,
    method: "GET",
    params: payload
  })
}

export function getCategoryQuestions(id, payload) {
  return HTTP({
    url: ENDPOINT.CATEGORIES.CATEGORIES + '/' + id + '/questions',
    method: "GET",
    params: payload,
    // headers: {
    //   Authorization: `Bearer ${localStorage.getItem('_ovvy_userToken')}`,
    // }
  })
}

export function getUserCategories(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
  id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.CATEGORIES.CATEGORIES,
    method: "GET",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getUserServiceTypes(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
  id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;

  let url = ENDPOINT.USERS + '/' + id + ENDPOINT.SERVICE_TYPES
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getUserCategoriesServiceTypes(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
  id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/categories_service_types',
    method: "GET",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//OTP
export function getOTP(payload) {
  return HTTP({
    url: ENDPOINT.OTP.SEND,
    method: "Post",
    data: payload
  })
}

export function verifyOTP(payload) {
  return HTTP({
    url: ENDPOINT.OTP.VERIFY,
    method: "Post",
    data: payload
  })
}

//BANKS
export function getBanks(payload) {
  return HTTP({
    url: ENDPOINT.BANKS,
    method: "GET",
    params: payload
  })
}

export function register(payload) {
  return HTTP({
    url: ENDPOINT.REGISTER,
    method: "POST",
    data: payload
  })
}

export function facebook(payload) {
  return HTTP({
    url: ENDPOINT.FACEBOOK,
    method: "POST",
    data: payload
  })
}

export function facebook2(payload) {
  return HTTP({
    url: ENDPOINT.FACEBOOK + '2',
    method: "POST",
    data: payload
  })
}

export function check_facebook(payload) {
  return HTTP({
    url: ENDPOINT.FACEBOOK  +'/check',
    method: "POST",
    data: payload
  })
}

export function check_facebook2(payload) {
  return HTTP({
    url: ENDPOINT.FACEBOOK  +'/check2',
    method: "POST",
    data: payload
  })
}

export function apple(payload) {
  return HTTP({
    url: ENDPOINT.APPLE,
    method: "POST",
    data: payload
  })
}

export function updateAuth(payload) {
  return HTTP({
    url: ENDPOINT.UPDATE_AUTH,
    method: "POST",
    data: payload
  })
}

//USER
export function getUser(id, params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  
  let url = ENDPOINT.USERS + '/' + id + '/details';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  })
}

export function updateUser(id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  console.log(ENDPOINT.USERS + '/' + id)
  // payload.append('_method', 'PATCH');
  console.log(payload)
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/details',
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function updateUserByEncryptedId(id, payload) {
  // console.log(ENDPOINT.USERS + '/' + id)
  payload.append('_method', 'PATCH');
  // console.log(payload)
  return HTTP({
    url: ENDPOINT.USERS + '/' + id,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function addUserDevice(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.USERS  +'/devices',
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function updateUserNotifications(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;

  payload.append('_method', 'PATCH');

  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/notifications',
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function updateUserBankDetails(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;

  payload.append('_method', 'PATCH');

  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/bank_details',
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function verifyUserPhone(id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/phone/verify',
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function verifyUserOtp(id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/otp/verify',
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getServicableLocations(payload) {
  
  return HTTP({
    url: ENDPOINT.SERVICEABLE_LOCATIONS,
    method: "GET",
    params: payload
  })
}

export function login(payload) {
  return HTTP({
    url: ENDPOINT.LOGIN,
    method: "Post",
    data: payload
  })
}

export function login_check() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.LOGIN_CHECK,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

export function verified_check() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.VERIFIED_CHECK,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

export function forgot_password(payload) {
  return HTTP({
    url: ENDPOINT.FORGOT_PASS,
    method: "Post",
    data: payload
  })
}

export function reset_password(payload) {
  return HTTP({
    url: ENDPOINT.RESET_PASS,
    method: "Post",
    data: payload
  })
}

//JOBS
export function getJobs(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let url = ENDPOINT.JOBS;
  if(payload) {
    url += '?' + new URLSearchParams(payload).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    data: payload,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getAvailableJobs(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let url = ENDPOINT.JOBS + '/available';
  if(payload) {
    url += '?' + new URLSearchParams(payload).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    data: payload,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getVendorJobs(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let url = ENDPOINT.JOBS + '/vendor';
  if(payload) {
    url += '?' + new URLSearchParams(payload).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    data: payload,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getDirecRequests(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let url = ENDPOINT.JOBS + '/direct_requests';
  if(payload) {
    url += '?' + new URLSearchParams(payload).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    data: payload,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function submitJob(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.JOBS,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function updateJob(id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  payload.append('_method', 'PATCH');
  return HTTP({
    url: ENDPOINT.JOBS + '/' + id,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function cancelJob(id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.JOBS + '/' + id + '/cancel',
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function requestCancelJob(id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.JOBS + '/' + id + '/cancel_request',
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getJob(id, params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.JOBS + '/' + id;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getJobBids(id, params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.JOBS + '/' + id + '/bids';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getMyBid(id, params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.JOBS + '/' + id + '/my_bid';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getVendorBid(id, vendor_id, params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.JOBS + '/' + id + '/vendors/' + vendor_id + '/bid';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getMyPendingCompletedJobs() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.JOBS + '/pending_completed';

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//ACTIVITES
export function getJobActivity(profile, type, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let url = ENDPOINT.JOBS + '/activities/' + profile + '/' + type;
  if(payload) {
    url += '?' + new URLSearchParams(payload).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    data: payload,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//BID
export function submitBid(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.BIDS,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function updateBid(bid_id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  payload.append('_method', 'PATCH');

  return HTTP({
    url: ENDPOINT.BIDS + '/' + bid_id,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function editBid(bid_id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  payload.append('_method', 'PATCH');

  return HTTP({
    url: ENDPOINT.BIDS + '/' + bid_id + '/edit',
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function cancelBid(id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.BIDS + '/' + id + '/cancel',
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function acceptBid(id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.BIDS + '/' + id + '/client/accept',
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function completeBid(id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.BIDS + '/' + id + '/complete',
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function clientCompleteBid(id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.BIDS + '/' + id + '/client/complete',
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getTotalUnreadClientActivity() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value, 
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/client/bid/unread',
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//USER
export function userActivity(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
  id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.JOBS + '/activity',
    method: "GET",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//LOCATIONS
export function userLocations() {
  const id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value,
    token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/locations',
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

export function updateUserDefaultLocation(location_id, ifDefault) {
  const id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value,
    token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  let payload = new FormData();
  payload.append('_method', 'PATCH');
  payload.append('is_default', ifDefault ? 1 : 0);

  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/locations/' + location_id + '/is_default',
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function addUserLocation(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value
    payload.append('user_id', id);
  return HTTP({
    url: ENDPOINT.USER_LOCATIONS,
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export function updateUserLocation(id, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  payload.append('_method', 'PATCH');
  return HTTP({
    url: ENDPOINT.USER_LOCATIONS + '/' + id,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function deleteUserLocation(id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let payload = new FormData();
  payload.append('_method', 'DELETE');

  return HTTP({
    url: ENDPOINT.USER_LOCATIONS + '/' + id,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function tokenLogin(payload) {
  return HTTP({
    url: ENDPOINT.TOKEN_LOGIN,
    method: "Post",
    data: payload
  })
}

export function sendDeleteRequest(encrypted) {
  return HTTP({
    url: ENDPOINT.USERS + '/' + encrypted + ENDPOINT.ACCOUNT_DELETE_REQUEST,
    method: "post",
  })
}

export function sendDeleteFeedback(encrypted, payload) {
  return HTTP({
    url: ENDPOINT.USERS + '/' + encrypted + ENDPOINT.ACCOUNT_DELETE_FEEDBACK,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function getDeleteRequest() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value, 
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;

  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.GET_ACTIVE_ACCOUNT_DELETE_REQUEST,
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//GALLERY
export function addUserGallery(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value, 
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value

  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.USER_GALLERIES,
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export function deleteUserGallery(id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let payload = new FormData();
  payload.append('_method', 'DELETE');

  return HTTP({
    url: ENDPOINT.USER_GALLERIES + '/' + id,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//DEALS
export function getDeals(params = false) {
  let url = ENDPOINT.DEALS;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET"
  })
}

//BANNERS
export function getBanners(params = false) {
  let url = ENDPOINT.BANNERS;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET"
  })
}

//LANGUAGES
export function getLanguages(payload) {
  return HTTP({
    url: ENDPOINT.LANGUAGES,
    method: "GET",
    params: payload
  })
}

export function addUserLanguage(user_id, language_id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.USERS + '/' + user_id + ENDPOINT.LANGUAGES + '/' + language_id,
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function deleteUserLanguage(user_id, language_id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let payload = new FormData();
  payload.append('_method', 'DELETE');

  return HTTP({
    url: ENDPOINT.USERS + '/' + user_id + ENDPOINT.LANGUAGES + '/' + language_id,
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  })
}

//NEWSFEEDS
export function getNewsFeeds() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  return HTTP({
    url: ENDPOINT.NEWSFEEDS + '/logs',
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getNewsFeed(log_id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  return HTTP({
    url: ENDPOINT.NEWSFEEDS + '/' + log_id + '/log',
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function trackNewsFeed(log_id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  return HTTP({
    url: ENDPOINT.NEWSFEEDS + '/' + log_id + '/track',
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getTotalUnreadNewsFeed() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.NEWSFEEDS + '/unread/count',
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getMediaFiles(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.MEDIA_FILES;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//Reviews
export function addReview(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.REVIEWS,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//Disputes
export function addDispute(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.DISPUTES,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//Favourites
export function addFave(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.FAVOURITES,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getFaves(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.FAVOURITES + '?active_users_only=1',
    method: "GET",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function deleteFave(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  payload.append('_method', 'DELETE');
  return HTTP({
    url: ENDPOINT.FAVOURITES,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//Notifications
export function getNotifications(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.NOTIFICATIONS;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getUnreadNotifications(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.NOTIFICATIONS + '/total_unread';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getUserUnreadNotifications(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.NOTIFICATIONS  + '/unread';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//CHATS
export function getChatMessages(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.CHATS;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getClientChats(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.CHATS + '/client';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getVendorChats(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.CHATS + '/vendor';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getUnreadClientChats(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.CHATS + '/client/unread';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getUnreadVendorChats(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.CHATS + '/vendor/unread';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function sendClientChat(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.CHATS + '/client',
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export function sendVendorChat(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  return HTTP({
    url: ENDPOINT.CHATS + '/vendor',
    method: "post",
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

//MERCHANT DASHBOARD
export function getMerchantStatistics(id, params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let url = ENDPOINT.USERS + '/' + id + '/vendor/statistic';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getMerchantRanking(id, params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let url = ENDPOINT.USERS + '/' + id + '/vendor/ranking';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  })
}

//INTERESTS
export function getInterests(payload) {
  return HTTP({
    url: ENDPOINT.INTERESTS,
    method: "GET",
    params: payload
  })
}

//BLOCKED MERCHANTS
export function getBlockedMerchants() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.BLOCKED_MERCHANTS,
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function deleteBlockedMerchant(id) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
  let payload = new FormData();
  payload.append('_method', 'DELETE');

  return HTTP({
    url: ENDPOINT.BLOCKED_MERCHANTS + '/' + id,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function addBlockedMerchant(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.BLOCKED_MERCHANTS,
    method: "POST",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}