<template>
<div class="numbers">
    <ul>
        <li>
            <button @click="subtract" type="button" class="btn nmbr_picker">-</button>
        </li>
        <li>
            <input :name="'question['+$props.question.id+']['+$props.data.id+']'" v-model="value" readonly/>
        </li>
        <li>
            <button @click="add" type="button" class="btn nmbr_picker">+</button>
        </li>
    </ul>
</div>
</template>

<script>
  // import $ from "jquery";
  export default {
    name: "NumberPicker",
    props: {
      question: Object,
      data: Object,
      min: Number,
      max: Number
    },
    emits: ['save'],
    data() {
      return {
        form: {},
        value: 0,
        min_value: 0,
        max_value: null
      }
    },
    watch: {
      data: function (newVal) {
        // console.log('num here')
        this.form = newVal
        // this.value = newVal.value
      },
      value: function(newVal) {
        // console.log('val here')
        this.form.value = newVal
        // this.data.value = newVal
        // console.log(this.form)
        this.$emit('save', newVal)
      },
      'data.value'(newValue) {
        this.value = newValue
      }
    },
    methods: {
      add() {
        this.value = Number(this.value) + 1
      },
      subtract() {
        if(this.value > this.min_value)
            this.value = Number(this.value) - 1
      }
    },
    created() {
      // console.log(this.min)
      if(this.min) {
        this.min_value = Number(this.min)
        this.value = Number(this.min_value)
      }

      if(this.data) {
        // console.log('cr')
        // console.log(this.data)
        this.form = Object.assign({},this.data)
      }

      if(this.data && this.data.value && Number(this.data.value) > 0) {
        // console.log('num')
        // console.log(this.data.value)
        this.value = this.data.value
        this.form.value = this.data.value
      }
    }
  }
</script>

<style scoped>
.numbers input {
    border: 0px;
    background: #F0F0F0;
    color: #000;
    font-size: 14px;
    height: 25px;
    width: 30px;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    justify-content: center;
    align-items: center;
    text-align: center;
}
.nmbr_picker:hover {
  background: #FE4D45 !important;
  color: #fff !important;
}
</style>
