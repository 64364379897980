// import { HTTP } from "@/api/http-common";
// import { lsWatcher as ls } from "../main";
import axios from "axios";

const url = process.env.VUE_APP_ONEMAP_API;
//     email = process.env.VUE_APP_ONEMAP_EMAIL,
//     password = process.env.VUE_APP_ONEMAP_PASSWORD

// export function getToken() {
//     // const token = ls.getItem('token').value
//     let payload = new FormData();
//     payload.append('email', email);
//     payload.append('password', password);
//     return HTTP({
//       url: url + '/privateapi/auth/post/getToken',
//       method: "post",
//       data: payload,
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       }
//     })
// }

export function searchLocation(search) {
    return axios({
        method: 'get',
        url: url + '/api/common/elastic/search?returnGeom=Y&getAddrDetails=Y&searchVal=' + search,
        // url: url + '/commonapi/search?returnGeom=Y&getAddrDetails=Y&searchVal=' + search,
    });
}