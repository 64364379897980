import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "aos/dist/aos.css"
import "./assets/styles/style.css"
import "./assets/styles/responsive.css"
import "./assets/styles/custom.css"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCryptojs from 'vue-cryptojs'
import { createWatcher }from 'next-vue-storage-watcher';
import Vue3TouchEvents from "vue3-touch-events";
import Maska from 'maska'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Notifications from '@kyvg/vue3-notification'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import VueSocialSharing from 'vue-social-sharing'
import VueAppleLogin from 'vue-apple-login';
import VFacebookLogin from 'vue-facebook-login-component-next'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueZoomer from 'vue-zoomer'
import VueGtag from "vue-gtag";

export const lsWatcher = createWatcher({
    prefix:"ovmp_"
})

export const ssWatcher = createWatcher({
    prefix:"ovmpss_",
    storage:"session"
})

export function cryptConverter(code) {
    return code;
    // let b64 = this.$CryptoJS.AES.encrypt(code, process.env.VUE_APP_SECRET_PASSPHRASE).toString();
    // return b64;
    // let e64 = this.$CryptoJS.enc.Base64.parse(b64);
    // let eHex = e64.toString(this.$CryptoJS.enc.Hex);
    // return eHex;
} 

const VueTelInputOptions = {
    mode: "international",
    onlyCountries: ["SG"],
    dropdownOptions: {
        disabled: true,
        showDialCodeInList: false
    },
    defaultCountry: "SG",
    inputOptions: {
        autocomplete: 'off'
    }
}

createApp(App).use(store).use(router).use(Vuex)
.use(VueCryptojs)
.use(VueSweetalert2)
.use(Vue3TouchEvents)
.use(lsWatcher)
.use(Maska)
.use(VueTelInput, VueTelInputOptions)
.component('Datepicker', Datepicker)
.use(Notifications)
.use(FloatingVue)
.use(OneSignalVuePlugin, {
    appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
    allowLocalhostAsSecureOrigin: true,
    persistNotification: true
})
.use(VueSocialSharing)
.use(VueAppleLogin, {
    clientId : 'com.ovvyapp.webregistration2023',
    scope : 'name email',
    redirectURI: process.env.VUE_APP_APPLE_RETURN_DIR, // As registered along with our service ID
    state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
    usePopup: true, // Important if we want to capture the data apple sends on the client side.
})
.component('VFacebookLogin', VFacebookLogin)
.use(VueReCaptcha, { 
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
    loaderOptions: {
        autoHideBadge: true
    }
})
.use(VueGtag, {
    appName: 'Ovvy',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
        return {
            page_title: to.name,
            page_path: to.path
        }
    },
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
}, router)
.use(VueZoomer)
.mount('#app')

import "bootstrap/dist/js/bootstrap.js"
import "aos/dist/aos.js"

// import "./assets/js/custom.js"