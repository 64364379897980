<template>
<div v-if="showMe && !showUserLocation" id="">
  <section class="">
    <div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 padding-none text-center">
        <div class="nav-cleaning">
            <div class="title">
            <label class="text-white">PICK LOCATION </label>
            </div>
                <div class="back">
                <a @click.prevent="this.$emit('close')" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>

                </div>
            </div>
        </div>
        </div>
    </div>
  </section>

  <section class="cleaning-type">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 px-0">
            <div class="input-group">
                <input ref="location" v-model="search.value" @keyup="searchLocation" @enter="searchLocation" @focus="search.show=true" @blur="closeBlur"
                    type="text" class="form-control" Placeholder="Search" style="width: 100%;border-top-left-radius: 0px;border-bottom-left-radius: 0px;">
                <button v-show="search.value != ''" @click="search.value='';search.show = false"
                    type="button" class="btn bg-transparent" style="margin-left: -40px; z-index: 100">
                    <i class="fa fa-times"></i>
                </button>
            </div>
                    
            <ul ref="list" v-show="search.value != '' && search.show" class="autocomplete-list-cont">
                <li v-for="(result, r) in search.results" :key="r"><a @click.prevent="setLocation(result)" href="#">{{result.ADDRESS}}</a></li>
            </ul>
          </div>
        </div>
      </div>
  </section>
  <section v-if="$root.isLoggedIn">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="add-address-btn">
              <div class="address-buttons-wrp d-flex" style="flex-wrap: wrap;gap: 5px;margin-top: 5px;">
                <div v-for="(uLoc, l) in locations" :key="l"  class="address-btn-cont">
                  <input type="radio" 
                    name="loc" 
                    :id="'add'+uLoc.id" 
                    :value="uLoc.id"
                    :checked="form.id == uLoc.id">
                  <label @click="changeLocation($event, uLoc)" :for="'add'+uLoc.id" >{{uLoc.name}}</label>
                </div>
              </div>
              <a @click.prevent="userLocView='form';showUserLocation=true;" href="#" class="btn add-btn"><img src="~@/assets/images/add.png"/></a>
             </div>
          </div>
        </div>
      </div>
  </section>
  <section v-if="$root.isLoggedIn" class="gary-divider">
  </section>
  <section v-if="$root.isLoggedIn">
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-lg-12" style="text-align:left">
          <button @click="userLocView='list';showUserLocation=true" type="button" class="btn save-location">
              <div class="d-flex">
                <i class="fa fa-bookmark-o save"></i>
                <h6> Saved Locations
                <span>Get to your favourite place easily</span>
                </h6>
                </div>
              <div class="icon-next">
                <i class="fa fa-angle-right"></i>
              </div>
          </button>
          </div>
        </div>
    </div>
  </section>
  <section class="cleaning-type">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12" style="text-align: left">
        <h6>Confirm Address</h6>
      </div>
      </div>
    </div>
  </section>
  <section class="address-form">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <form class="" action="index.html" method="post">
          <div class="mt-1">
            <input v-model="form.floor" type="text" class="form-control" id="floor" placeholder="Floor/Unit">
            </div>
            <div class="mt-1">
              <input v-model="form.building" type="text" class="form-control" id="building" placeholder="Building">
            </div>
            <div class="mt-1">
              <input v-model="form.street"  type="text" class="form-control" id="hno" placeholder="Street number">
              </div>
              <div class="mt-1">
                <input v-model="form.street_number"  type="text" class="form-control" id="landmark" placeholder="Street">
              </div>
              <div class="mt-1">
                <input v-model="form.postal_code" type="text" class="form-control" id="pin" placeholder="Postal code">
                </div>
                <div class="mt-1">
                  <input v-model="form.country" type="text" class="form-control" id="country" placeholder="Country" readonly>
                </div>
              </form>
            </div></div></div>
  </section>
  <section class="">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="">
            <div v-if="hasError" class="category-error">
                {{errMsg}}
            </div>
            <a @click.prevent="confirm()" href="#" class="btn next-btn">CONFIRM</a>
            <!-- <div class="row">
              <div class="col-lg-12">
                <div class="next bg-none">
                  <div class="next-bar">
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<UserLocation :visible="showUserLocation" :view="userLocView" @close="showUserLocation=false" @data="data => locations = data"/>
</template>

<script>
  import UserLocation from '@/components/UserLocation'
  import { searchLocation as oneMapSearchLocation } from "@/api/onemap"
  // import $ from "jquery";
  export default {
    name: "LocationPicker",
    components: {
      UserLocation
    },
    props: {
      visible: Boolean,
      data: Object,
    },
    emits: ['data', 'save', 'close'],
    data() {
      return {
        showMe: this.visible,
        showAdd: false,
        value: 0,
        form: {
          id: '',
          floor: '',
          building: '',
          street: '',
          street_number: '',
          postal_code: '',
          country: 'Singapore',
          location: '',
          latitude: '',
          longitude: '',
          name: '',
        },
        showUserLocation: false,
        userLocView: 'list',
        locations:[],
        search: {
            value: '',
            results: [],
            show: false,
        },
        hasError: false,
        errMsg: '',
      }
    },
    watch: {
      // data: function(newVal) {
      //   console.log('data')
      //   console.log(newVal)
      // },
      locations: function (newVal) {
        this.$emit('data', newVal)
      },
      visible: function (newVal) {
        this.showMe = newVal;
        let data = Object.assign({}, this.data);
        this.form = data
        this.form.country = 'Singapore'
        this.search.value = data.location
      },
      showAdd: function(newVal) {
        this.showMe = !newVal
      }
    },
    methods: {
    validateForm() {
        const $this = this
        this.hasError = false;
        this.errMsg = '';

        if (!this.form.postal_code) {
          this.errMsg = 'Postal code is required';
        }

        if (!this.form.street && this.form.id == '') {
          this.errMsg = 'Street is required';
        }

        if (!this.form.street_number) {
          this.errMsg = 'Street number is required';
        }
  
        if (this.errMsg != '') {
          this.hasError = true;
        }

        if(this.hasError) {
            setTimeout(function() {
                $this.hasError = false
            }, 3000);
        }
    },
    confirm() {
      this.validateForm();

      if (this.hasError) {
          return;
      }
      
      this.$emit('save', this.form)
      this.$emit('close')
    },
    async searchLocation() {
      this.$refs.list.style.width = this.$refs.location.offsetWidth + 'px';
      const $this = this
      await oneMapSearchLocation(this.search.value).then(response => {
          // console.log(response)
          $this.search.results = response.data.results
          $this.search.show = true
      }).catch(error => {
          console.log(error);
      })
    },
    setLocation(location) {
        // console.log('set')
        // console.log(location)
        this.search.value = location.SEARCHVAL
        this.search.show = false

        let loc = '';
        if(location.street != '') loc += location.BLK_NO + ' ';
        if(location.street_number != '') loc += location.ROAD_NAME + ', ';
        loc += 'SINGAPORE';

        this.form = {
          id: '',
          floor: '',
          building: '',
          street: '',
          street_number: '',
          postal_code: '',
          country: 'Singapore',
          location: '',
          latitude: '',
          longitude: '',
          name: '',
          is_default: ''
        }

        this.form.postal_code = location.POSTAL != 'NIL' ? location.POSTAL : ''
        this.form.location = loc
        this.form.latitude = location.LATITUDE != 'NIL' ? location.LATITUDE : ''
        this.form.longitude = location.LONGITUDE != 'NIL' ? location.LONGITUDE : ''
        this.form.street = location.BLK_NO != 'NIL' ? location.BLK_NO : ''
        this.form.street_number = location.ROAD_NAME != 'NIL' ? location.ROAD_NAME : ''
        this.form.building = location.BUILDING != 'NIL' ? location.BUILDING : ''
    },
    closeBlur() {
        const $this = this
        setTimeout(function() {
            $this.search.show=false
        }, 100);
    },
    changeLocation(event, location) {
      // console.log(event)
      // console.log(location)
      this.form = location
    }
  },
  created() {
    // console.log('location')
    // console.log(this.data)
  }
}
</script>

<style scoped>
#locationDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: #ffff;
}
</style>
