<template>
    <div v-if="showMe">
        <section v-if="!showForm">
            <section class="">
            <div class="container-fluid">
                <div class="row">
                <div class="col-lg-12 padding-none text-center">
                    <div class="nav-cleaning">
                    <div class="title">
                        <label class="text-white">LOCATIONS </label>
                    </div>
                        <div v-if="showBackBtn" class="back">
                        <a @click.prevent="back()" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <div class="bg-white">
            <section class="bg-white">
            <div class="container-fluid">
                <div class="row">
                <div class="col-lg-12">
                    <div class="add-address-btn">
                    <h6>Add New Location</h6>
                        <a @click.prevent="addLocation" href="#" class="btn add-btn"><img src="~@/assets/images/add.png"></a>
                    </div>
                </div>
                </div>
            </div>
            </section>
        <section class="gary-divider-slim"></section>
            
        <section class="location-details bg-white">
            <div class="container-fluid">
                <div class="row">
                    <div v-for="(location, l) in locations" :key="l" class="col-lg-12 mb-5">
                    <div class="wrp mb-2">
                        <h6>{{location.location}}<span class="loc-name-pill">{{location.name}}</span></h6>
                        <button class="loc-pill btn-red">{{location.name}}</button>
                        <div class="form-check form-switch">
                            <label class="form-check-label">Set as default</label>
                            <input class="form-check-input" type="checkbox" v-model="location.is_default" role="switch" @change="(event) => setDefault(event, location)" :checked="location.is_default">
                        </div>
                    </div>
                        <p v-if="location.floor != ''">{{location.floor}}</p>
                        <p v-if="location.building != ''">{{location.building}}</p>
                        <p v-if="location.street_number != ''">{{location.street_number}}</p>
                        <p v-if="location.street != ''">{{location.street}}</p>
                        <p v-if="location.postal_code != ''">{{location.postal_code}}</p>
                        <div class="flex-wrp">
                            <p>{{location.country}}</p>
                            <div class="icons">
                                <a @click.prevent="editLocation(location)" href="#"><img src="~@/assets/images/edit.png"></a>
                                <a @click="deleteLocation(location)" href="#" class="btn" data-bs-toggle="modal" data-bs-target="#del"><img src="~@/assets/images/del.png"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            </section>
            </div>
        </section>
        <section v-else>
            <section class="">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12 padding-none text-center">
                        <div class="nav-cleaning">
                        <div class="title">
                            <label class="text-white">{{form.id != '' ? 'EDIT LOCATION ' : 'ADD LOCATION '}}</label>
                        </div>
                            <div class="back">
                            <a @click.prevent="showForm=false" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="mt-2 bg-white">
            <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="input-group">
                        <input ref="location" v-model="search.value" @keyup="searchLocation" @enter="searchLocation" @focus="search.show=true" @blur="closeBlur"
                            type="text" class="form-control" Placeholder="Search" style="width: 100%;border-top-left-radius: 0px;border-bottom-left-radius: 0px;">
                        <button v-show="search.value != ''" @click="search.value='';search.show = false"
                            type="button" class="btn bg-transparent" style="margin-left: -40px; z-index: 100;">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                           
                    <ul ref="list" v-show="search.value != '' && search.show" class="autocomplete-list-cont">
                        <li v-for="(result, r) in search.results" :key="r" @click="setLocation(result)">
                            <a @click.prevent="setLocation(result)" href="#">{{result.ADDRESS}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            </section>
            
            <section class="cleaning-type mt-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 style="text-align: left">Name</h6>
                        </div>
                    </div>
                </div>
            </section> 
        
            <section class="name">
            <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                <input type="text" class="form-control" v-model="form.name" placeholder="Eg. Home, Office">
                </div>
                </div>
            </div>
            </section> 
        
            <section class="cleaning-type">
            <div class="container-fluid">
                <div class="row">
                <div class="col-lg-12" style="text-align: left;">
                    <h6>Confirm Address</h6>
                </div>
                </div>
                </div>
            </section>

            <section class="address-form">
            <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                <form class="" action="index.html" method="post">
                <div class="mt-1">
                    <input v-model="form.floor" type="text" class="form-control" id="floor" placeholder="Floor/Unit">
                    </div>
                    <div class="mt-1">
                    <input v-model="form.building" type="text" class="form-control" id="building" placeholder="Building">
                    </div>
                    <div class="mt-1">
                    <input v-model="form.street_number" type="text" class="form-control" id="hno" placeholder="Street number">
                    </div>
                    <div class="mt-1">
                    <input v-model="form.street" type="text" class="form-control" id="landmark" placeholder="Street">
                    </div>
                    <div class="mt-1">
                        <input v-model="form.postal_code" type="text" class="form-control" id="pin" placeholder="Postal code">
                    </div>
                    <div class="mt-1">
                        <input v-model="form.country" type="text" class="form-control" id="country" placeholder="Country" readonly>
                        </div>
                        <div class="mt-2 set-default">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Set as default</label>
                            <div class="form-check form-switch">
                                <input v-model="form.is_default" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                            </div>
                        </div>
                    </form>
                </div></div></div>
            </section>
    
            <section class="">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12 text-center">
                        <div>
                            <div v-if="hasError" class="category-error">
                                {{errMsg}}
                            </div>
                        <a @click.prevent="save" href="#" class="btn next-btn">Save</a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <!-- <ul>
                <li v-for="(result, r) in search.results" :key="r">
                    <a @click.prevent="setLocation(result)" href="#" style="display: block;    width: 100%;">{{result.ADDRESS}}</a></li>
            </ul> -->
        </section>
    </div>
    <div class="modal" id="del">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Confirm Delete</h4>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
            <h6>Do you want to delete this location?</h6>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button @click="to_be_deleted_id=''" type="button" class="btn text-danger" data-bs-dismiss="modal">CANCEL</button>
                <button @click="confirmDelete" type="button" class="btn text-danger" data-bs-dismiss="modal">OK</button>
            </div>

            </div>
        </div>
    </div>
</template>

<script>
import { userLocations, updateUserDefaultLocation, addUserLocation, updateUserLocation, deleteUserLocation } from "@/api"
import { searchLocation as oneMapSearchLocation } from "@/api/onemap"
import $ from 'jquery'

export default {
  name: 'UserLocationView',
  components: {
    
  },
  data() {
    return {
        showBackBtn:true,
        showMe: this.visible,
        showForm: false,
        hasError: false,
        errMsg: '',
        processing: false,
        search: {
            value: '',
            results: [],
            show: false,
        },
        locations: [],
        form:{
            id: '',
            floor: '',
            building: '',
            street: '',
            street_number: '',
            postal_code: '',
            country: 'Singapore',
            location: '',
            latitude: '',
            longitude: '',
            name: '',
            is_default: ''
        },
        to_be_deleted_id: ''
    }
  },
  props: {
    visible: Boolean,
    view: String,
    hide_back_btn: Boolean
  },
  emits: ['data', 'close'],
  watch: {
    visible: function (newVal) {
        this.showMe = newVal
    },
    view: function(newVal) {
        if(newVal == 'form') {
            this.showForm = true
        }else {
            this.showForm = false
        }
    }
  },
  methods: {
    back() {
        // console.log(this.$route)
        if(this.$route.name == 'Address') {
            window.location.href = window.location.origin + '/#/' ;
        }else {
            this.$emit('close')
        }
    },
    validateForm() {
        const $this = this
        this.hasError = false;
        this.errMsg = '';

        if (!this.form.postal_code) {
          this.errMsg = 'Postal code is required';
        }

        if (!this.form.street) {
          this.errMsg = 'Street is required';
        }

        if (!this.form.street_number) {
          this.errMsg = 'Street number is required';
        }

        if (!this.form.name) {
          this.errMsg = 'Name is required';
        }
  
        if (this.errMsg != '') {
          this.hasError = true;
        }

        if(this.hasError) {
            setTimeout(function() {
                $this.hasError = false
            }, 3000);
        }
        
    },
    async getModels() {
        const $this = this
        $this.$root.isLoading = true;  
        await userLocations().then(response => {
            $this.$root.isLoading = false;  
            let locations = []
            $(response.data).each(function(i, loc) {
                loc.is_default = loc.is_default == 1
                locations.push(loc)
            })
            // console.log(locations)
            $this.locations = locations
            this.$emit('data', locations)
        }).catch(error => {
            $this.processing = false;
            console.log(error.errors);
        })
    },
    async setDefault(event, location) {
        const $this = this
        $this.$root.isLoading = true;  
        // console.log(event.target.checked)
        // console.log(location)

        await updateUserDefaultLocation(location.id, event.target.checked).then(response => {
            $this.$root.isLoading = false;  
            // console.log(response)
            $this.locations = response.data
        }).catch(error => {
            $this.$root.isLoading = false;  
            console.log(error.errors);
        })
    },
    addLocation() {
        this.form = {
            id: '',
            floor: '',
            building: '',
            street: '',
            street_number: '',
            postal_code: '',
            country: 'Singapore',
            location: '',
            latitude: '',
            longitude: '',
            name: '',
            is_default: ''
        }

        this.search = {
            value: '',
            results: [],
            show: false,
        }

        this.showForm=true
    },
    editLocation(location) {
        this.showForm=true
        this.form = Object.assign({}, location);
        this.search.value = this.form.location
    },
    async save() {
        const $this = this

        this.validateForm();

        if (this.hasError) {
           return;
        }

        if (this.processing === true) {
          return;
        }

        this.processing = true;
        $this.$root.isLoading = true;  

        let formData = new FormData();
        Object.keys(this.form).forEach(key => {
          if($this.form[key] !== null && $this.form[key] !== 'null') {
            if(Array.isArray($this.form[key])) {
              for (var i = 0; i < $this.form[key].length; i++) {
                if($this.form[key][i] !== undefined)
                  formData.append(key+'[]', $this.form[key][i]);
              }
            }else {
                if(key == 'is_default') {
                    formData.append(key, $this.form[key] ? 1 : 0)
                }
                else {
                    formData.append(key, $this.form[key])
                }
                
            }
          }
        });

        if(this.form.id == '') { //Add
            await addUserLocation(formData).then(() => {
                $this.$root.isLoading = false;  
                $this.processing = false;
                $this.showForm = false;
                $this.getModels();
                
                // console.log(response)
            }).catch(error => {
                $this.$root.isLoading = false;  
                $this.processing = false;
                console.log(error);
                $this.hasError = true
                if(error.response.data.errors.name) {
                    $this.errMsg = error.response.data.errors.name[0]
                }else if(error.response.data) {
                    $this.errMsg = error.response.data.message
                }else {
                    $this.errMsg = 'An error occured. Please try again.'
                }

                setTimeout(function() {
                    $this.hasError = false
                }, 5000);
            })
        }else { //Edit
            await updateUserLocation(this.form.id, formData).then(() => {
                $this.$root.isLoading = false;  
                $this.processing = false;
                $this.showForm = false;
                $this.getModels();
                // console.log(response)
            }).catch(error => {
                $this.$root.isLoading = false;  
                $this.processing = false;
                console.log(error);
                $this.hasError = true
                if(error.response.data.errors.name) {
                    $this.errMsg = error.response.data.errors.name[0]
                }else if(error.response.data) {
                    $this.errMsg = error.response.data.message
                }else {
                    $this.errMsg = 'An error occured. Please try again.'
                }

                setTimeout(function() {
                    $this.hasError = false
                }, 5000);
            })
        }
    },
    deleteLocation(location) {
        console.log(location)
        this.to_be_deleted_id = location.id
    },
    async confirmDelete() {
        const $this = this
        $this.$root.isLoading = true;  
        await deleteUserLocation(this.to_be_deleted_id).then(() => {
            $this.$root.isLoading = false;  
            $this.getModels()
            // console.log(response)
        }).catch(error => {
            $this.$root.isLoading = false;  
            console.log(error);
            $this.hasError = true
            $this.errMsg = 'An error occured. Please try again.'
            setTimeout(function() {
                $this.hasError = false
            }, 5000);
        })
    },
    async searchLocation() {
        this.$refs.list.style.width = this.$refs.location.offsetWidth + 'px';
        const $this = this
        if(this.search.value == '') return;
        await oneMapSearchLocation(this.search.value).then(response => {
            // console.log(response)
            $this.search.results = response.data.results
            $this.search.show = true
        }).catch(error => {
            console.log(error);
        })
    },
    async onSearch(search, loading) {
        console.log(search)
        console.log(loading)
        if(search.length == 0) {
            return 0;
        }
        // this.$refs.list.style.width = this.$refs.location.offsetWidth + 'px';
        const $this = this
        await oneMapSearchLocation(search).then(response => {
            // console.log(response)
            $this.search.results = response.data.results
            $this.search.show = true
        }).catch(error => {
            console.log(error);
        })
    },
    setLocation(location) {
        // console.log('set')
        // console.log(location)
        this.search.value = location.SEARCHVAL
        this.search.show = false

        let loc = '';
        if(location.street != '') loc += location.BLK_NO + ' ';
        if(location.street_number != '') loc += location.ROAD_NAME + ', ';
        loc += 'SINGAPORE';

        this.form = {
            id: '',
            floor: '',
            building: '',
            street: '',
            street_number: '',
            postal_code: '',
            country: 'Singapore',
            location: '',
            latitude: '',
            longitude: '',
            name: '',
            is_default: ''
        }

        this.form.postal_code = location.POSTAL != 'NIL' ? location.POSTAL : ''
        this.form.location = loc
        this.form.latitude = location.LATITUDE != 'NIL' ? location.LATITUDE : ''
        this.form.longitude = location.LONGITUDE != 'NIL' ? location.LONGITUDE : ''
        this.form.street = location.BLK_NO != 'NIL' ? location.BLK_NO : ''
        this.form.street_number = location.ROAD_NAME != 'NIL' ? location.ROAD_NAME : ''
        this.form.building = location.BUILDING != 'NIL' ? location.BUILDING : ''
    },
    closeBlur() {
        const $this = this
        setTimeout(function() {
            $this.search.show=false
        }, 200);
    }
  },
  created() {
    $('.modal-backdrop.fade.show').remove()
    if(this.$root.isLoggedIn) this.getModels()

    if(this.hide_back_btn) this.showBackBtn = !this.hide_back_btn
  }
}
</script>

<style scoped>
.loc-name-pill {
    background: #FE4D45;
    color: #fff;
    border-radius: 22px;
    font-size: 10px;
    border: none;
    padding: 5px 10px;
    margin-left: 5px;
    display: none;
}
.dropdown-menu {    
    z-index:10030 !important;
}

@media (max-width: 425px) {
    .autocomplete-list-cont {
        max-width:385px;
    }

    .loc-name-pill {
        display: unset;
    }

    .loc-pill {
        display: none;
    }
}
</style>