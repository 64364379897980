import { createRouter, createWebHashHistory } from 'vue-router'
import ActivityView from '../views/ActivityView.vue'
import JobForm from '../views/Jobs/Form.vue'
import JobView from '../views/Jobs/View.vue'
import CompleteJobView from '../views/Jobs/Complete.vue'
import FavouritesView from '../views/FavouritesView.vue'
import { lsWatcher as ls, cryptConverter} from "../main";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/LandingView'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/LogInView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/sign_up',
    name: 'Sign Up',
    component: () => import('../views/Auth/SignUpView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../views/Auth/WelcomeView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/activity',
    name: 'activity',
    component: ActivityView,
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/jobs/new/:code',
    name: 'new job',
    component: JobForm,
    meta: {
      // shouldBeLoggedIn: true,
      forceToClient: true,
    }
  },
  {
    path: '/job/:code/edit',
    name: 'edit job',
    component: JobForm,
    meta: {
      // shouldBeLoggedIn: true,
      forceToClient: true,
    }
  },
  {
    path: '/job/:code',
    name: 'job view',
    component: JobView,
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/job/:code/chat/:vendor',
    name: 'job chat view',
    component: () => import('../views/Jobs/Chat'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/job/:code/vendor/:vendor',
    name: 'job vendor profile',
    component: () => import('../views/Jobs/Vendor'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/job',
    name: 'job sample view',
    component: JobView
  },
  {
    path: '/job/complete',
    name: 'complete job',
    component: CompleteJobView
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: FavouritesView,
    meta: {
      shouldBeLoggedIn: true,
      forceToClient: true,
    }
  },
  {
    path: '/blocked-merchants',
    name: 'blocked merchants',
    component: () => import('../views/BlockedMerchantsView'),
    meta: {
      shouldBeLoggedIn: true,
      forceToClient: true,
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/my-address',
    name: 'Address',
    component: () => import('../views/User/AddressView.vue'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/my-payments',
    name: 'Payments',
    component: () => import('../views/User/PaymentView.vue'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/my-profile',
    name: 'Profile',
    component: () => import('../views/User/ProfileView.vue'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/edit-profile',
    name: 'Edit Profile',
    component: () => import('../views/User/EditProfileView.vue'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/logout',
    name: 'Log Out',
    component: () => import('../views/LandingView'),
    meta: {
      forceLogOut: true
    }
  },
  {
    path: '/request-account-deletion',
    name: 'Account Deletion',
    component: () => import('../views/User/RequestAccountDeletionView'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/reset_password/:code?',
    name: 'Reset Password',
    component: () => import('../views/Auth/ResetPassView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/help-support',
    name: 'Help & Support',
    component: () => import('../views/HelpSupportView'),
  },
  {
    path: '/news-alerts',
    name: 'News & Alerts',
    component: () => import('../views/NewsAlerts/Index'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/news-alerts/:code',
    name: 'View News & Alerts',
    component: () => import('../views/NewsAlerts/View'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/browse',
    name: 'Browse',
    component: () => import('../views/Merchant/Browse'),
    meta: {
      shouldBeLoggedIn: true,
      shouldBeVerified: true,
      forceToMerchant: true,
    }
  },
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('../views/ChatsView'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/client-rewards',
    name: 'Client Rewards',
    component: () => import('../views/Client/Rewards.vue'),
    meta: {
      shouldBeLoggedIn: true,
      forceToClient: true,
    }
  },
  {
    path: '/merchant-rewards',
    name: 'Merchant Rewards',
    component: () => import('../views/Merchant/Rewards.vue'),
    meta: {
      shouldBeLoggedIn: true,
      shouldBeVerified: true,
      forceToMerchant: true,
    }
  },
  {
    path: '/merchant-reward-logs',
    name: 'Merchant Reward Logs',
    component: () => import('../views/Merchant/Cashout.vue'),
    meta: {
      shouldBeLoggedIn: true,
      shouldBeVerified: true,
      forceToMerchant: true,
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/User/SettingsView'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/notification-settings',
    name: 'Notification Settings',
    component: () => import('../views/Settings/NotificationView'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms & Conditions',
    component: () => import('../views/Settings/TermsView'),
  },
  {
    path: '/client-guide',
    name: 'Client Guide',
    component: () => import('../views/Settings/ClientGuideView')
  },
  {
    path: '/merchant-guide',
    name: 'Merchant Guide',
    component: () => import('../views/Settings/MerchantGuideView')
  },
  {
    path: '/merchant-registration',
    name: 'Merchant Registration',
    component: () => import('../views/Merchant/Register'),
    meta: {
      shouldBeLoggedIn: true
    }
  },
  {
    path: '/dashboard',
    name: 'Merchant Dashboard',
    component: () => import('../views/Merchant/Dashboard'),
    meta: {
      shouldBeLoggedIn: true,
      shouldBeVerified: true,
      forceToMerchant: true,
    }
  },
  {
    path: '/free-credits',
    name: 'free credits',
    component: () => import('../views/FreeCreditsView'),
    meta: {
      shouldBeLoggedIn: true,
      forceToClient: true,
    }
  },
  {
    path: '/services/:id',
    redirect: to => {
      console.log(to)
      console.log(to.params.id)
      console.log(cryptConverter(to.params.id))
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/'}
    },
  },
  {
    path: '/external/welcome',
    name: 'External Welcome',
    component: () => import('../views/External/WelcomeView')
  },
  {
    path: '/token/:token',
    name: 'token login',
    component: () => import('../views/LoadingView'),
  },
  {
    path: '/loggedinchecker',
    name: 'loggedinchecker',
    component: () => import('../views/LoadingView'),
  },
  {
    path: '/external/sidebar/:token',
    name: 'External Sidebar',
    component: () => import('../views/External/Sidebar')
  },
  {
    path: '/redirect',
    name: 'redirecter',
    component: () => import('../views/LoadingView'),
  },
  {
    path: '/forceLogout',
    name: 'forceLogout',
    component: () => import('../views/LoadingView'),
  },
  {
    path: '/facebook-redirect',
    name: 'FBRedirect',
    component: () => import('../views/Auth/WelcomeView'),
    meta: {
      disableIfLoggedIn: true
    }
  },
  {
    path: '/_logx',
    name: 'Logging',
    component: () => import('../views/LogView'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('before')
  // console.log(from)
  // console.log(to)
  sessionStorage.setItem('_ovvy_prevUrlName', from.name);

  if(from.fullPath != '/') {
    ls.setItem('routePrev', from.fullPath)
  }
  if(to.fullPath == '/') {
    ls.setItem('routePrev', to.fullPath)
  }

  if(to.meta.forceToClient) {
    ls.setItem("profile_view", 'client');
  }else if(to.meta.forceToMerchant) {
    ls.setItem("profile_view", 'merchant');
  }

  if(to.meta.shouldBeVerified) {
    const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;
    const ifVerified = typeof ls.getItem('ovvy_verified').value == 'object' ? ls.getItem('ovvy_verified').value.value : ls.getItem('ovvy_verified').value;
    // console.log(ifVerified)
    
    if(!token) { //check first if logged in
      sessionStorage.setItem('_ovvy_backUrl', to.fullPath);
      next({name: 'Login'});
    }else {
      if (ifVerified == 1) {
        next();
      }else {
        ls.setItem("profile_view", 'client');
        next({name: 'Merchant Registration'});
      }
    }
  }

  //Login Val
  if(to.meta.disableIfLoggedIn) {
    const encryptId = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value : ls.getItem('token');
    if (encryptId.value) {
      next({name: 'Profile'});
    }else {
      next();
    }
  }else if(to.meta.shouldBeLoggedIn) {
    const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value, 
      id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;
    if (token && id) {
      next();
    }else {
      sessionStorage.setItem('_ovvy_backUrl', to.fullPath);
      next({name: 'Login'});
    }
  }else if(to.meta.forceLogOut) {
    let token = this.$root.user_id_cookie;
    document.cookie = token + "=;path=/;domain=ovvyapp.com;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    ls.removeItem('token');
    ls.removeItem('id');
    ls.removeItem('encrypted_id');
    next();
  }else {
    next();
  }

  if(document.querySelector('.offcanvas-backdrop.show')) {
    document.querySelector('.offcanvas-backdrop.show').remove()
    document.querySelector('body').style.removeProperty('overflow');
    document.querySelector('body').style.removeProperty('padding-right');
  }
});

router.afterEach((to, from) => {
  // console.log('after')
  // console.log(from)
  // console.log(to)
  let hash = to.hash

  if(from.name == to.name && to.name != 'home') {
    location.reload();
  }

  // next();

  // console.log('route')
  // console.log(to)

  if(to.name == 'token login') {
    // console.log('here')
    document.querySelector('body').style.background = '#1d3557';
  }else {
    document.querySelector('body').style.removeProperty('background');
  }

  setTimeout(function() {
    //For Mobile Navigation
    const mobileNavShow = document.querySelector('.mobile-nav-show');
    const mobileNavHide = document.querySelector('.mobile-nav-hide');
    document.querySelector('body').classList.remove('mobile-nav-active');
    if(mobileNavShow)
      mobileNavShow.classList.remove('d-none');
    if(mobileNavHide)
      mobileNavHide.classList.add('d-none');
    
  }, 200);
  

  if(document.querySelector('.modal-backdrop.show')) 
    document.querySelector('.modal-backdrop.show').remove()

  if(document.querySelector('.offcanvas-backdrop.show')) 
    document.querySelector('.offcanvas-backdrop.show').remove()

    document.querySelector('body').classList.remove('modal-open')
    document.querySelector('body').style.removeProperty('overflow');
    document.querySelector('body').style.removeProperty('padding-right');

    document.querySelector('body').classList.remove('tablet_only_overflow_hidden')
  
    setTimeout(function() {
      if(to.name == 'home' && (hash == '#services' || hash == '#deals')) {
        //none
      }else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      
    }, 200);

  setTimeout(function() {
    // console.log('get height')
    let body = document.body, html = document.documentElement;
    let height = Math.max( body.scrollHeight, body.offsetHeight, 
        html.clientHeight, html.scrollHeight, html.offsetHeight);
    let adjusted = height - 210;
    // console.log(height)
    if(height > 1072 && document.getElementsByClassName("main-container").length > 0) {
      // console.log(adjusted)
      document.getElementsByClassName("main-container")[0].style.minHeight = adjusted + 'px'
    }
  }, 500);
  
});


export default router
