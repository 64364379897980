import ENDPOINT from "@/api/endpoints";
import { HTTP } from "@/api/http-common";
import { lsWatcher as ls } from "../main";

export function get() {
  const id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value,
    token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.PAYMENT_METHODS,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

export function add(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.PAYMENT_METHODS,
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export function setDefault(payment_method) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
    id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;

  let payload = new FormData();
  payload.append('_method', 'PATCH');

  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.PAYMENT_METHODS + '/' + payment_method  + '/default',
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function deletePaymentMethod(payment_method) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value,
  id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value;

  let payload = new FormData();
  payload.append('_method', 'DELETE');

  return HTTP({
    url: ENDPOINT.USERS + '/' + id + ENDPOINT.PAYMENT_METHODS + '/' + payment_method,
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}