<template>
    <section v-if="showHeader">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
                <div class="title">
                    <label class="text-white">VERIFY</label>
                </div>
                <div class="back">
                    <a @click.prevent="back()" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                </div>
               </div>
            </div>
          </div>
        </div>
    </section>

    <div class="otp-wrapper container py-5">
        <div class="list-top ">
            <div class="row justify-content-center space-content">
                <div style="text-align: -webkit-center;">
                    <div class="left-section">
                        <h1>Verification Code</h1>
                        <p>Enter the 4 digits code that we sent to <strong>{{ this.$root.user_phone_display }}</strong>.
                            <br/> Not your phone number? 
                            <a :href="edit_mobile_link">Edit</a>
                        </p>
                    </div>

                    <div class="Verification-input">
                        <input @keyup="validateNumber('n1',$event)" class="form-control Verification-input-first otp-number"
                                id="otpFirst"
                                maxlength="1" autocomplete="off" 
                                placeholder="" ref="otp" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n1">
                        <input @keyup="validateNumber('n2',$event)" class="form-control otp-number" maxlength="1" autocomplete="off" 
                                placeholder="" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n2">
                        <input @keyup="validateNumber('n3',$event)" class="form-control otp-number" maxlength="1" autocomplete="off" 
                                placeholder="" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n3">
                        <input @keyup="validateNumber('n4',$event)" class="form-control otp-number" maxlength="1" autocomplete="off" 
                                placeholder="" type="number" pattern="[0-9]*"
                                :class="errors.otp ? 'otp-error' : ''"
                                v-model="otp.n4">
                    </div>
                    <span v-if="errors.otp" class="form-error mt-4"><small>{{errors.otp}}</small></span>
                    <!-- {{ this.formData.otp}} -->
                    <a @click.prevent="verifyOtp()" class="btn-pink btn-pink-otp" href="#">
                        {{processing ? 'Verifying...' : 'Verify Account' }}
                    </a>

                    <p v-if="showResend" class="mt-5">Didn't receive it? <a @click.prevent="resendOtp()" href="#">Resend Code</a></p>
                    <small class="text-danger" v-if="errMsg.global">{{errMsg.global}}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { verifyUserPhone, verifyUserOtp} from "@/api";
import $ from 'jquery'
import { lsWatcher as ls } from "../../main";

export default {
  name: 'OtpVerify',
  components: {
  },
  props: {
    hide_header: Boolean,
    hide_apple: Boolean,
    hide_facebook: Boolean
  },
  data() {
    return {
        showHeader: true,
        hasError: false,
        errMsg: {global: ''},
        processing: false,
        otp: {n1: null, n2: null, n3: null, n4: null},
        errors: {},
        otpSuccess: false,
        userEncryptId: '',
        resend_processing: false,
        showResend: false,
        phone: null,
    }
  },
  methods: {
    back() {
        this.$router.push('/')
    },
    async verifyPhone() {
        const $this = this
        $this.$root.isLoading = true;
        await verifyUserPhone(this.$root.user_id).then(response => {
            // console.log(response.data)
            $this.$root.isLoading = false;
            if(response.data.verified) {
                ls.setItem("is_phone_verified", 1, 1440000 * 15)
                if($this.$route.name == 'new job') {
                    location.reload();
                }
            }else {
                setTimeout(function () {
                    $this.showResend = true;
                }, 60000);
            }
            
        }).catch(error => {
          console.log(error.response.data);
          $this.$root.isLoading = false;
          if(error.response.data.message == 'Send SMS Failed') {
              $this.errMsg['global'] = 'Unable to send Verification Code. Please make sure you put the right mobile number.'
          }else if(error.response.data.errors) {
              const errors = error.response.data.errors;
              $this.hasError = false;
              $this.errMsg = {};
              Object.keys(errors).forEach(key => {
                console.log(key, errors[key])
                $this.errMsg[key] = errors[key][0]
                $this.hasError = true
              });
          }else {
              $this.errMsg['global'] = 'An error occured. Please try again.'
          }
        })
    },
    async verifyOtp() {
        const $this = this
       
        let otpInput = Object.values(this.otp).join('')
        let errors = [], errorCount = 0;

        // console.log(otpInput)
        if(this.otp.n1 === '' || this.otp.n1 == null || this.otp.n2 === '' || this.otp.n2 == null 
            || this.otp.n3 === '' || this.otp.n3 == null || this.otp.n4 === '' || this.otp.n4 == null) {
                errors['otp'] = 'Verification is required.';
                errorCount++
        }
        // console.log(this.otp)
        // console.log(errors)
        this.errors = errors

        if (errorCount > 0) return false;

        $this.$root.isLoading = true;

        let formData = new FormData();
        formData.append('otp', otpInput)

        await verifyUserOtp(this.$root.user_id, formData).then(response => {
            // console.log(response.data)
            $this.$root.isLoading = false;
            if(response.data.verified) {
                ls.setItem("is_phone_verified", 1, 1440000 * 15)
                if($this.$route.name == 'new job') {
                    location.reload();
                }
            }
            
        }).catch(error => {
            console.log(error.response.data);
            $this.errors['otp'] = error.response.data.message
            $this.otp.n1 = '';
            $this.otp.n2 = '';
            $this.otp.n3 = '';
            $this.otp.n4 = '';
            $this.$root.isLoading = false;
        })
    },
    resendOtp() {
        this.showResend = false;
        this.verifyPhone();
    },
    validateNumber(key, $event) {
        // console.log(key, $event.key)
        // console.log(this.otp[key])
        
        // this.otp[key] = this.otp[key].replace(/[^\d]/g, '')
        if ($event.key == 'Backspace') {
        if(key != 'n1') $event.target.previousElementSibling.focus()
        } else {
        let newKey = $event.key.replace(/[^\d]/g, '')
        if (!isNaN($event.key) && newKey) {
            // this.otp[key] = $event.key;
            // this.otp[key] = this.otp[key].replace(/[^\d]/g, '')
            if(key != 'n4') $event.target.nextElementSibling.focus()
            else $event.target.blur()
        } else {
            // this.otp[key] = this.otp[key].replace(/[^\d]/g, '')
            // this.otp[key] = newKey
        }
        }
    }
  },
  watch: {
    header: function(newVal) {
      console.log('show header' + newVal)
    },
  },
  computed: {
        edit_mobile_link() {
            return this.$route.name == 'new job' ? '/#/edit-profile?_s=mobile&_b=njob&_jid=' + this.$route.params.code
            : '/#/edit-profile?_s=mobile&_b=ajob&_jid='  + this.$route.params.code
        }
    },
    async created() {
        
        if(this.hide_header) this.showHeader = !this.hide_header
        // this.getModels()
        $('.modal-backdrop.fade.show').remove()

        this.verifyPhone()
    }
}
</script>
<style scoped>
@import './../../assets/styles/signup.css';
</style>
<style scoped>
    .btn-facebook:hover {
        background: #3b5998 !important;
        color: #fff !important;
    }
    .form-error, .form-error small {
        color: #e63945 !important;
    }
    .bw-div{
        background: #f6f6f6;
    }
    .btn-100 {
      width: 100%;
    }
    input[type=checkbox] {
        width: 30px;
        height: 30px;
        /* transform: scale(2); */
    }
    .header-text {
        font-weight: 600;
        font-size: 40px;
        color: #000;
    }
    .btn-pink-bg:hover, .btn-pink-otp:hover {
        color: #fff;
    }
    .vue-tel-input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
    .frmValidation {
      display:block;
    }
    .frmValidation.passed span {
      color: #009688;
    }
    .frmValidation.failed span{
      color: #f44336;
    }

    .frmValidation.passed em {
      color: rgb(78, 78, 78);
    }

    .frmValidation.failed em{
      color: #000;
    }

    .hiw-wrap {
      height: 100%;
    } 

    .how-it-works-slider-wrapper .carousel__pagination {
      display: none;
    }

    .verification-success-wrapper p {
      font-size: 18px;
    }

    .btn-facebook {
      background: #3B5998;
      color: #fff;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 7px;
    }

    .btn-submit:hover {
        background-color: #f44336 !important;
    }

    .btn-facebook:hover {
      color: #fff;
      opacity: 0.8;
    }

    .btn-apple {
      background: #000000;
      color: #fff;
      padding: 10px;
    }

    .btn-apple:hover {
      color: #fff;
      opacity: 0.8;
    }

    .btn-submit {
      background: #f44336;
      color: #fff;
      padding: 10px;
    }

    .btn-submit:hover {
      color: #fff;
      opacity: 0.8;
    }

    .apple-signin {
        width: 100% !important;
        height: 45px;
    }

    .apple-signin > div {
        width: 100% !important;
        max-width: 100% !important;
    }

    @media (max-width: 1280px) {
        .header-text {
            font-size: 35px;
        }
    }

    @media (max-width: 1125px) {
        .header-text {
            font-size: 30px;
        }
    }

    @media (max-width: 920px) {
      .how-it-works-slider-wrapper .carousel__pagination {
        display: flex;
      }
    }

    @media (max-width: 991px) {
      .hiw-wrap {
          margin-bottom: 10px;
      }
      .header-text {
          font-size: 25px;
      }
    }

    @media (max-width: 427px) {
      .verification-success-wrapper h1 {
        font-size: 30px;
      }

      .verification-success-wrapper p {
        font-size: 19px;
      }
    }

  
</style>