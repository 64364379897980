import ENDPOINT from "@/api/endpoints";
import { HTTP } from "@/api/http-common";
import { lsWatcher as ls } from "../main";

export function verify(code, payload) {
  const id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value,
    token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
    
  payload.append('user_id', id)

  return HTTP({
    url: ENDPOINT.PROMOS + '/' + code + '/verify',
    method: "post",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getCategoryVouchers(category, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  let url = ENDPOINT.CATEGORIES.CATEGORIES + '/' + category + '/getVouchers';
  if(payload) {
    url += '?' + new URLSearchParams(payload).toString()
  }

  return HTTP({
    url: url,
    method: "get",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function getBidVouchers(bid, payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  let url = ENDPOINT.BIDS + '/' + bid + '/getVouchers';
  if(payload) {
    url += '?' + new URLSearchParams(payload).toString()
  }

  return HTTP({
    url: url,
    method: "get",
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}