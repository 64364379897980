<template>
    <!-- <div 
      class="app-banner text-center p-2"
    >
        <h6 class="mr-2">Turn on notification!</h6>
    </div> -->

    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">
        <div id="header-container" class="container d-flex align-items-center justify-content-between">
            <a v-if="showBack" @click.prevent="back()" href="#" class="back-btn-responsive"><img src="~@/assets/img/back.png"></a>
            <h6 v-if="theTitle != ''" class="heading-nav-hide">{{theTitle}}</h6>
            <!-- <router-link to="/" class="logo" :class="theTitle == '' ? '' : 'logo-responsive'">
                <img src="~@/assets/img/logo-white-lg.png" alt="" :class="theTitle == '' ? '' : 'logo-responsive'">
            </router-link> -->
            
            <a v-if="$root.isLoggedIn" href="/#/" class="logo hide_on_mobile" :class="theTitle == '' ? '' : 'logo-responsive'">
                <img src="~@/assets/img/logo-white-lg.png" alt="" :class="theTitle == '' ? '' : 'logo-responsive'">
            </a>
            <a v-else href="https://ovvyapp.com" class="logo hide_on_mobile" :class="theTitle == '' ? '' : 'logo-responsive'">
                <img src="~@/assets/img/logo-white-lg.png" alt="" :class="theTitle == '' ? '' : 'logo-responsive'">
            </a>
            <a href="/#/" class="logo show_on_mobile" :class="theTitle == '' ? '' : 'logo-responsive hide_me'">
                <img src="~@/assets/img/logo-white-lg.png" alt="" :class="theTitle == '' ? '' : 'logo-responsive'">
            </a>

            <div class="d-flex" style="gap: 20px">
                <nav id="navbar" class="navbar">
                    <ul>
                        <section v-if="$root.isLoggedIn"  class="user-info favourite-info user-nav-wrp mt-0" style="margin-bottom: 0px">
                            <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="user-info-wrp  p-0">
                                        <router-link to="/my-profile" class="img-wrp border-0 avatar-cont">
                                            <div v-if="$root.user_avatar" class="circle-avatar" :style="'background-image: url('+$root.user_avatar+')'"></div>
                                            <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user">
                                            <img v-if="$root.profile_view == 'merchant' && $root.merchant_verified == 1" src="~@/assets/img/verified_user.png" class="header-varification-img"/>
                                        </router-link>
                                        <h5>{{$root.user_full_name}}</h5>
                                    <!-- <small class="text-white mt-1">Last online: Thu 6 Sept 16.09</small> -->
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                        <li v-if="$root.isLoggedIn" class="main-link">
                            <a href="/#/">Home</a>
                        </li>
                        <li v-else class="main-link"><a href="https://ovvyapp.com">Home</a></li>
                        <!-- <li class="main-link"><router-link to="/">Home</router-link></li> -->

                        <li v-if="$route.name == 'home'" class="main-link"><a @click.prevent="scrollTo('services')" href="/#services">Services</a></li>
                        <li v-else class="main-link"><router-link to="/#services">Services</router-link></li>
                        
                        <li v-if="$root.profile_view == 'client' && $route.name == 'home'" class="main-link"><a @click.prevent="scrollTo('deals')" href="/#deals">Promo/Deals</a></li>
                        <li v-else-if="$root.profile_view == 'client' && $route.name != 'home'" class="main-link"><router-link to="/#deals">Promo/Deals</router-link></li>
                        <li v-else-if="$root.profile_view == 'merchant'" class="main-link">
                            <router-link @click="redirectToClientForPromo()" to="/#deals">Promo/Deals</router-link>
                        </li>

                        <li class="main-link"><a href="https://merchant.ovvyapp.com/#/" target="_blank">Merchant</a></li>
                        
                        <li class="main-link"><a href="https://ovvyapp.com/faq/" target="_blank">FAQ</a></li>
                        <li class="main-link"><a href="https://ovvyapp.com/ovvy-blog/" target="_blank">Blog</a></li>
                        <li class="side-link">
                            <router-link to="/my-profile">
                                <img src="~@/assets/icons/iconProfile.png" class="img-fluid"/>
                                Profile
                            </router-link>
                        </li>
                        <li v-if="$root.profile_view == 'client'" class="side-link">
                            <router-link to="/settings">
                                <img src="~@/assets/icons/iconSetting.png" class="img-fluid"/>
                                Settings
                            </router-link>
                        </li>
                        <li class="side-link">
                            <router-link to="/my-address">
                                <img src="~@/assets/icons/ic_address.png" class="img-fluid"/>
                                Address
                            </router-link>
                        </li>
                        <li class="side-link">
                            <router-link to="/my-payments">
                                <img src="~@/assets/icons/ic_payment.png" class="img-fluid"/>
                                Payments
                            </router-link>
                        </li> 
                        <!-- <li v-if="$root.profile_view == 'merchant'"><a href="#">Corporate Projects</a></li> -->
                        <li v-if="$root.profile_view == 'merchant'" class="side-link">
                            <router-link to="/settings">
                                <img src="~@/assets/icons/iconSetting.png" class="img-fluid"/>
                                Settings
                            </router-link>
                        </li>
                        <li v-if="$root.profile_view == 'merchant'" class="side-link">
                            <router-link to="/merchant-rewards">
                                <img src="~@/assets/icons/rewardsIcon.png" class="img-fluid"/>
                                Merchant Rewards
                            </router-link>
                        </li>
                        <li v-if="$root.profile_view == 'client'" class="side-link">
                            <router-link to="/client-rewards">
                                <img src="~@/assets/icons/rewardsIcon.png" class="img-fluid"/>
                                Client Rewards
                            </router-link>
                        </li>
                        <li v-if="$root.profile_view == 'client'" class="side-link">
                            <router-link to="/free-credits">
                                <img src="~@/assets/icons/referalIcon.png" class="img-fluid"/>
                                Free Credits
                            </router-link>
                        </li>
                        <li class="side-link d-flex justify-content-between align-items-center">
                            <router-link to="/news-alerts">
                                <img src="~@/assets/icons/iconEveryone.png" class="img-fluid"/>
                                News & Alerts
                            </router-link>
                            <div v-if="$root.news_unread > 0" class="news-count">{{$root.news_unread}}</div>
                        </li>
                        <li class="side-link show-mobile">
                            <router-link to="/help-support">
                                <img src="~@/assets/icons/contactUs.png" class="img-fluid"/>
                                Help & Support
                            </router-link>
                        </li> 
                        <li v-if="!$root.isLoggedIn" class="show-mobile">
                            <router-link v-if="$route.name == 'Welcome' || $route.name == 'Login' || $route.name == 'Sign Up'" to="/welcome" class="text-primary border">Login/Sign Up</router-link>
                            <a v-else href="#" class="text-primary border" data-bs-toggle="modal" data-bs-target="#welcome-modal">Login/Sign Up</a>
                        </li> 
                        <li v-if="$root.isLoggedIn" class="show-mobile "><a @click.prevent="$root.changeProfileView()" href="#" class="btn btn-switch" :class="$root.app_display_view"><img src="~@/assets/img/iswitching.png">  Switch to {{switch_profile}} Side</a></li>      
                    </ul>
                </nav><!-- .navbar -->
                <div class="buttons-wrp">
                    <div class="inner d-flex">
                        
                        <router-link v-if="!$root.isLoggedIn && $route.name == 'Welcome' || $route.name == 'Login' || $route.name == 'Sign Up' || $route.name == 'FBRedirect'" to="/welcome" class="btn-book-a-table buttons-hide">Login/Sign Up</router-link>
                        <a v-else-if="!$root.isLoggedIn && $route.name != 'Welcome'" class="btn-book-a-table buttons-hide" href="#" data-bs-toggle="modal" data-bs-target="#welcome-modal">Login/Sign Up</a>
                        <div v-if="$root.isLoggedIn" class="notification">
                            <a @click.prevent="$root.isLoggedIn ? clickNotifBtn() : ''" ref="notifBtn" href="notification.html" 
                                class="dropdown-toggle notification-dropdown" data-bs-toggle="dropdown" data-toggle="dropdown"><img src="~@/assets/img/bell.png" class="bell"></a>
                            <ul class="dropdown-menu dropdown-menu-lg-end" id="notif-infinite-list">
                                <li v-for="(item, i) in $root.notifs.items" :key="i" >
                                    <a :href="$root.getNotifLink(item.type, item.data_decode)">
                                        <div class="notification-wrp">
                                            <div v-if="item.sender" class="img-wrp">
                                                <div v-if="item.sender.profile && item.sender.profile.avatar" 
                                                    class="notif-circle-avatar" 
                                                    :style="'background-image: url('+item.sender.profile.avatar.medium_thumb_url+')'"></div>
                                                <div v-else-if="item.sender_id == 1" 
                                                class="notif-circle-avatar" 
                                                :style="{ backgroundImage: 'url(' + require('@/assets/images/store_icon.png') + ')' }"></div>
                                                <img v-else src="~@/assets/img/profile-pic.png" class="user">
                                                <img v-if="item.sender.profile && item.sender.profile.ovvy_verified == 1" src="~@/assets/img/verified_user.png" class="varification-img"/>
                                            </div>
                                            <div v-else
                                                class="notif-circle-avatar" 
                                                :style="{ backgroundImage: 'url(' + require('@/assets/images/store_icon.png') + ')' }"></div>
                                            <div class="icon">
                                                <h6 class="title">{{item.message}}</h6>
                                                <p class="time">{{item.created_at_display}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li v-if="$root.notifs.items.length == 0" class="px-2">
                                    <small><em>No notifications found.</em></small>
                                </li>
                            </ul>
                            <div v-if="$root.notifs.unread > 0" class="count">{{$root.notifs.unread}}</div>
                        </div>
                        <i @click="showMobileNav()" class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                        <i @click="showMobileNav()" class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
                        <a href="#" class="toggle-btn-nav hide-mobile desktop-nav" data-bs-toggle="offcanvas" data-bs-target="#side-bar"><i class="bi bi-list"></i></a>
                    </div>
                    
                </div>    
            </div>
        </div>
    </header>

    <div class="offcanvas offcanvas-end" id="side-bar">
        <div class="offcanvas-header">
            <h1 class="offcanvas-title"></h1>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div class="offcanvas-body">
            <ul>
                <section v-if="$root.isLoggedIn"  class="user-info favourite-info mt-0" style="width: 100%">
                    <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="user-info-wrp  p-0">
                                <router-link to="/my-profile" class="img-wrp border-0 avatar-cont">
                                    <div v-if="$root.user_avatar" class="circle-avatar" :style="'background-image: url('+$root.user_avatar+')'"></div>
                                    <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user">
                                    <img v-if="$root.profile_view == 'merchant' && $root.merchant_verified == 1" src="~@/assets/img/verified_user.png" class="header-varification-img2"/>
                                </router-link>
                                <h5>{{$root.user_full_name}}</h5>
                            <!-- <small class="text-white mt-1">Last online: Thu 6 Sept 16.09</small> -->
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <li>
                    <router-link to="/my-profile">
                        <img src="~@/assets/icons/iconProfile.png" class="img-fluid"/>
                        Profile
                    </router-link>
                </li>
                <li v-if="$root.profile_view == 'client'">
                    <router-link to="/settings">
                        <img src="~@/assets/icons/iconSetting.png" class="img-fluid"/>
                        Settings
                    </router-link>
                </li>
                <li>
                    <router-link to="/my-address">
                        <img src="~@/assets/icons/ic_address.png" class="img-fluid"/>
                        Address
                    </router-link>
                </li>
                <li>
                    <router-link to="/my-payments">
                        <img src="~@/assets/icons/ic_payment.png" class="img-fluid"/>
                        Payments
                    </router-link>
                </li> 
                <!-- <li v-if="$root.profile_view == 'merchant'"><a href="#">Corporate Projects</a></li> -->
                <li v-if="$root.profile_view == 'merchant'">
                    <router-link to="/settings">
                        <img src="~@/assets/icons/iconSetting.png" class="img-fluid"/>
                        Settings
                    </router-link>
                </li>
                <li v-if="$root.profile_view == 'merchant'">
                    <router-link to="/merchant-rewards">
                        <img src="~@/assets/icons/rewardsIcon.png" class="img-fluid"/>
                        Merchant Rewards
                    </router-link>
                </li>
                <li v-if="$root.profile_view == 'client'">
                    <router-link to="/client-rewards">
                        <img src="~@/assets/icons/rewardsIcon.png" class="img-fluid"/>
                        Client Rewards
                    </router-link>
                </li>
                <li v-if="$root.profile_view == 'client'">
                    <router-link to="/free-credits">
                        <img src="~@/assets/icons/referalIcon.png" class="img-fluid"/>
                        Free Credits
                    </router-link>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                    <router-link to="/news-alerts">
                        <img src="~@/assets/icons/iconEveryone.png" class="img-fluid"/>
                        News & Alerts
                    </router-link>
                    <div v-if="$root.news_unread > 0" class="news-count">{{$root.news_unread}}</div>
                </li>
                <li>
                    <router-link to="/help-support">
                        <img src="~@/assets/icons/contactUs.png" class="img-fluid"/>
                        Help & Support
                    </router-link>
                </li> 
                <!-- <li v-if="$root.profile_view == 'merchant'">
                  <router-link to="/browse">
                    <span>Browse</span>
                  </router-link>
                </li> -->
                <!-- <li v-else><a href="#"><span>Post</span></a></li>
                <li><router-link to="/activity"><span>Activity</span></router-link></li>
                <li>
                  <a v-if="$root.isLoggedIn" @click.prevent="$root.getRecentJobs()" href="#" class="plus"  data-bs-toggle="modal" data-bs-target="#rebook">
                    <span class="rebook">ReBook</span>
                  </a>
                  <router-link v-else to="/login" class="plus">
                    <span class="rebook">ReBook</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/chats" class="chat-link">
                    <div v-if="$root.chat_unread > 0" class="chat-count">{{$root.chat_unread}}</div>
                    <span>Chat</span>
                  </router-link>
                </li>
                <li v-if="$root.profile_view == 'merchant'">
                    <router-link to="/dashboard"><span>Dashboard</span></router-link>
                </li>
                <li v-else>
                  <router-link to="/favourites">
                    <span>Favourites</span>
                  </router-link>
                </li> -->

                <!-- <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/my-profile">Profile</router-link></li>
                <li v-if="$root.profile_view == 'merchant'"><a href="#">Corporate Projects</a></li>
                <li><a href="#">Settings</a></li>
                <li><router-link to="/my-address">Address</router-link> </li>
                <li><router-link to="/my-payments">Payments</router-link> </li> 
                <li v-if="$root.profile_view == 'merchant'"><router-link to="/merchant-rewards">Merchant Rewards</router-link></li>
                <li v-if="$root.profile_view == 'client'"><router-link to="/client-rewards">Client Rewards</router-link></li>
                <li v-if="$root.profile_view == 'client'"><a href="#">Free Credits</a></li>
                <li><router-link to="/news-alerts">News & Alerts</router-link></li> -->
                <li class="show-mobile"><router-link to="/help-support">Help & Support</router-link></li> 
                <li v-if="$root.isLoggedIn">
                    <div class="form-check form-switch d-flex form-switch-btn" style="cursor: pointer;">
                        <label class="switch">
                            <input type="checkbox">
                            <span @click="switchWithDelay()" class="slider round" ></span>
                        </label>
                        <p>Switch to {{switch_profile}} Side</p>    
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!-- welcome Modal -->
    <div v-if="$route.name != 'Welcome' && $route.name != 'Login' && $route.name != 'Sign Up' && $route.name != 'FBRedirect'" 
        class="modal" id="welcome-modal" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
                <Welcome/>
            </div>
        </div>
    </div>
  </template>
  
  <script>
    import Welcome from '@/components/auth/Welcome'
    import { lsWatcher as ls } from "../../main";
    
    export default {
      name: "TheHeader",
      components: {
        Welcome
      },
      props: {
        title: String,
        hide_back: Boolean,
      },
      emits: ['back'],
      data() {
        return { 
          showBack: true,
          theTitle: '',
          mobileNav: false,
          login_header: 'LOG IN',
          signup_preference: ''
        }
      },
      watch: {
        title: function (newVal) {
          this.theTitle = newVal;
        }
      },
      methods: {
        showMobileNav() {
          const mobileNavShow = document.querySelector('.mobile-nav-show');
          const mobileNavHide = document.querySelector('.mobile-nav-hide');
          document.querySelector('body').classList.toggle('mobile-nav-active');
          mobileNavShow.classList.toggle('d-none');
          mobileNavHide.classList.toggle('d-none');
        },
        back() {

          this.$emit('back')
          //  : window.location.href = window.location.origin + '/#/' ;
        },
        switchWithDelay() {
            const $this = this
            setTimeout(function(){
                $this.$root.changeProfileView()
            }, 200);
            
        },
        clickNotifBtn() {
            // console.log('click')
            //this.$refs.notifBtn.classList.contains('show') && 

            if(document.getElementById('notif-infinite-list').classList.contains('show')) {
                console.log('here')
                document.getElementById('notif-infinite-list').classList.remove('show')
                this.$refs.notifBtn.setAttribute('aria-expanded', false)
                this.$refs.notifBtn.classList.remove('show')
            }else {
                console.log('here2')
                document.getElementById('notif-infinite-list').classList.add('show')
                this.$refs.notifBtn.setAttribute('aria-expanded', true)
                this.$refs.notifBtn.classList.add('show')
            }
            if(this.$root.notifs.items.length == 0) {
                // console.log('get init')
                this.$root.getNotifs();
            }

            
        },
        scrollTo(id) {
            if(this.$root.profile_view == 'client') {
                this.$root.home_tab = id
                this.$root.scrollToElement('selection-link');
            }else {
                ls.setItem("profile_view", 'client');
                this.$root.setCookie('_ov_pv', 'client', 6);
                window.location.href = window.location.origin + '/#/#' + id 
                location.reload();
            }
            
        },
        redirectToClientForPromo() {
            ls.setItem("profile_view", 'client');
            this.$root.setCookie('_ov_pv', 'client', 6);
            window.location.href = window.location.origin + '/#/#deals';
            location.reload();
        },
        adjustNotif() {
            if(this.$root.isLoggedIn) {
                if(window.innerWidth < 400) {
                    document.getElementById('notif-infinite-list').style.maxWidth = window.innerWidth + 'px';
                }
            }
        }
      },
      created() {
        // console.log(this.$root.isLoggedIn)
        if(this.hide_back) this.showBack = !this.hide_back
        if(this.title) this.theTitle = this.title
      },
      mounted() {
        const $this = this
        if(this.$root.isLoggedIn) {
            const listElm = document.querySelector('#notif-infinite-list');
            listElm.addEventListener('scroll', () => {
                // console.log('scroll')
                // console.log(listElm.scrollTop + listElm.clientHeight)
                // console.log(listElm.scrollHeight - 1)
                // console.log('--------')
                if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 1) {
                    // console.log(e.target)
                    $this.$root.getNotifs();
                }
            });
        }

        // if(!this.isLoggedIn) {
        //     if(this.$route.query._open && this.$route.query._open == 'welcome') {
        //         setTimeout(function() {

        //             // document.getElementById("welcome").classList.add("show");
        //             // document.getElementById("welcome").style.display = "block";
                    
        //             // $this.$root.openThisModal('welcome');
                   
        //             document.getElementById('welcome').classList.add('show')
        //             document.getElementById('welcome').style.display = 'block';
        //             // document.getElementById('welcome').setAttribute('aria-hidden', false)
        //             document.getElementById('welcome').setAttribute('role', 'dialog')
        //             document.getElementById('welcome').setAttribute('aria-modal', true)
                    
        //             document.querySelector('body').classList.add('modal-open')
        //             document.querySelector('body').style.overflow = 'hidden'
        //             document.querySelector('body').style.paddingRight = '0px'
        //             // // $('body').addClass('modal-open modal-open-additional').append('<div class="modal-backdrop fade show"></div>');
        //             document.querySelector('body').insertAdjacentHTML('beforeend','<div class="modal-backdrop fade show"></div>');
        //             // $('body').append('<div class="modal-backdrop fade show"></div>');
        //         }, 1000);
        //     }
        // }
        this.adjustNotif()
        window.addEventListener('resize', function() {
          $this.adjustNotif()
        }, true);

        setTimeout(function() {
            let sideBarOffCanvas = document.getElementById('side-bar')
            sideBarOffCanvas.addEventListener('hidden.bs.offcanvas', function () {
                // console.log('hidden')
                if(document.querySelector('.offcanvas-backdrop.show')) {
                    document.querySelector('.offcanvas-backdrop.show').remove()
                    document.querySelector('body').style.removeProperty('overflow');
                    document.querySelector('body').style.removeProperty('padding-right');
                }
            })

            sideBarOffCanvas.addEventListener('shown.bs.offcanvas', function () {
                // console.log('shown')
                if(!document.querySelector('.offcanvas-backdrop.show')) {
                    let div = document.createElement("div");
                    div.classList.add('offcanvas-backdrop');
                    div.classList.add('fade');
                    div.classList.add('show');
                    document.getElementById("app").appendChild(div)
                    sideBarOffCanvas.setAttribute('aria-modal', true)
                    sideBarOffCanvas.setAttribute('role', 'dialog')

                    //body -> style="overflow: hidden; padding-right: 0px;" data-bs-overflow="hidden" data-bs-padding-right="15px"

                    //header ->  style="padding-right: 0px;" data-bs-padding-right="15px"
                }
            })
        }, 500);
        
      },
      computed: {
        switch_profile() {
            return this.$root.profile_view == 'client' ? 'Merchant' : 'Client';
        },
      }
    }
  </script>
  
  <style scoped>
  .app-banner h6 {
        color: #E63946;
        display: inline;
        font-weight: 800;
        position: fixed;
        top: 0;
        z-index: 10004;
        width: 100%;
    }
    .circle-avatar {
        width: 85px;
        height: 85px;
        -webkit-border-radius: 85px;
        -webkit-background-clip: padding-box;
        -moz-border-radius: 85px;
        -moz-background-clip: padding;
        border-radius: 85px;
        background-clip: padding-box;
        margin: 7px 0 0 5px;
        float: left;
        background-size: cover;
        background-position: center center;
    }

    #side-bar {
        z-index: 99999;
    }

    .desktop-nav {
        color: #fff;
        /* margin: 0 10px 0 20px; */
        font-size: 30px;
        cursor: pointer;
        line-height: 0;
        transition: 0.5s;
        padding-left: 40px;
    }

.dropdown-menu{
   max-height:500px;
   overflow-y: scroll;
}

.notif-circle-avatar {
    width: 45px;
    height: 45px;
    min-width: 45px;
    -webkit-border-radius: 45px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 45px;
    -moz-background-clip: padding;
    border-radius: 45px;
    background-clip: padding-box;
    margin: 7px 0px 0 0px;
    float: left;
    background-size: cover;
    background-position: center center;
}

.notification-wrp .img-wrp {
    position: relative;
}

.notification-wrp .varification-img {
    position: absolute;
    bottom: 0;
    width: 15px;
    right: 0px;
}

.header-varification-img {
    position: absolute;
    bottom: 8px;
    width: 25px;
    right: 22px;
}

.header-varification-img2 {
    position: absolute;
    bottom: -2px;
    width: 25px;
    right: 0px;
}

.navbar a:not(.btn-switch), .navbar a:focus:not(.btn-switch) {
    justify-content: flex-start !important;
    gap: 10px;
}

.navbar a:not(.btn-switch):not(.avatar-cont) img {
    display:none;
    width: 25px;
}

.side-link {
    display: none !important;
}

#side-bar ul a:not(.btn-switch):not(.avatar-cont) img {
    width: 25px;
}

#notif-infinite-list.show {
    /* position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate(0px, 25px); */
    position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(-261px, 23px);
}

@media(max-width:1279px)
{
    .navbar a:not(.btn-switch) img {
        display: block;
    }

    .navbar .main-link {
        display: none;
    }

    .side-link {
        display: flex !important;
    }

}
</style>
  