<template>
  <Loading v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    loader="dots"
    color="#e73644"
    :z-index="99999999"/>
  <router-view/>
  <notifications/>
  <notifications group="notif" id="customNotif-wrapper">
    <template #body="props">
      <a :href="props.item.data.link" class="vue-notification-template vue-notification my-notify">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="notify-wrp">
                <div class="icon">
                  <img src="~@/assets/img/logo-small.png">
                </div>
                <div class="text">
                  <h6>Ovvy Notification</h6>
                  <small v-html="props.item.title"></small>
                </div>
                <div class="small-text">
                  <small>Now</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </template>
  </notifications>
  <!-- Rebook Modal -->
  <div class="modal" id="rebook">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <a @click.prevent="" class="rebook-wrap bg-red">
            <h6>Rebook</h6>
          </a>
          <a @click="selectRecentJob(job)" 
            v-for="(job, key) in recent_jobs" :key="key" class="rebook-wrap" data-bs-toggle="modal" data-bs-target="#detail-rebook">
            <img :src="job.category.link_icon">
            <h6>{{job.title}}</h6>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="detail-rebook">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <div class="wrape bg-gray">
            <img :src="recent_job.category.link_icon">
              <div class="text">
              <h6>{{recent_job.title}}</h6>
              <p v-if="recent_job.locations[0]">{{recent_job.locations[0].location}}</p>
              </div>
            </div>
            <ul id="recent-job-choice-list">
              <li>
                <input type="radio" v-model="chosen_recent_job" :value="'all'"/>
                <label>
                  <span style="margin-right: 10px;position: relative;top: 10px;margin-left: 20px;">
                    <img src="~@/assets/img/ic_everyone.png" style="width: 40px;">
                  </span>
                  <span style="position: relative;top: 12px;">
                    <strong>Send to Everyone</strong>
                  </span>
                </label>
              </li>
              <li v-if="recent_job.bid">
                <input type="radio" v-model="chosen_recent_job" :value="'vendor'"/>
                <label v-if="recent_job.bid.vendor.profile.avatar">
                  <span style="margin-right: 10px;position: relative;top: 5px;left: 20px;">
                    <div class="circle-avatar-40" :style="'background-image: url('+recent_job.bid.vendor.profile.avatar.medium_thumb_url+');margin-right:0px;position: relative;'"></div>
                    <img v-if="recent_job.bid.vendor.profile.ovvy_verified" src="~@/assets/img/verified_user.png" class="verify-img" style="bottom: -13px;"> 
                  </span>
                  <span style="position: relative;top: 18px;left: 20px;">
                    <strong>Send to {{ recent_job.bid.vendor.full_name }}</strong>
                  </span>
                </label>
                <label v-else>
                  <span style="margin-right: 10px;position: relative;top: 10px;left: 20px;">
                    <img src="~@/assets/img/profile-pic.png"  style="width: 40px;">
                    <img v-if="recent_job.bid.vendor.profile.ovvy_verified" src="~@/assets/img/verified_user.png" class="verify-img" style="bottom: -13px;"> 
                  </span>
                  <span style="position: relative;top: 10px;left: 20px;">
                    <strong>Send to {{ recent_job.bid.vendor.full_name }}</strong>
                  </span>
                </label>
              </li>
            </ul>
            <div class="buttons">
              <a href="#" class="close btn"  data-bs-dismiss="modal">CLOSE</a>
              <a v-if="chosen_recent_job == ''" href="#" @click.prevent="repostError" class="repost btn">REPOST</a>
              <router-link v-else-if="chosen_recent_job == 'all'" :to="'/jobs/new/' + this.encrypt((recent_job.category_id).toString()) + '?_jid=' + this.encrypt((recent_job.id).toString())" @click="repostJob()" class="repost btn">REPOST</router-link>
              <router-link v-else-if="chosen_recent_job == 'vendor'" :to="'/jobs/new/' + this.encrypt((recent_job.category_id).toString()) + '?_vid=' + this.encrypt((recent_job.bid.vendor_id).toString())" @click="repostJob()" class="repost btn">REPOST</router-link>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <button @click="testNotif()">TEST</button> -->
  <PendingCompletedJobs v-if="showPendingCompletedJobs"/>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import OneSignal from '@onesignal/onesignal-vue3'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { lsWatcher as ls, ssWatcher } from "./main";
  import $ from 'jquery'
  import moment from 'moment'
  import PendingCompletedJobs from '@/components/PendingCompletedJobs'
  import { getUnreadClientChats, getUnreadVendorChats, getJobs, getNotifications, getUnreadNotifications, getTotalUnreadNewsFeed, 
    getTotalUnreadClientActivity, getUserUnreadNotifications, addUserDevice, login_check, verified_check} from "@/api"
  import Pusher from 'pusher-js';
  import Echo from 'laravel-echo';
  // import _ from 'lodash';


  export default {
    name: 'App',
    components: {
      Loading,
      PendingCompletedJobs,
    },
    data() {
      return {
        appVer: '3.0.2',
        codeVer: '1.8.8',
        isLoading: false,
        secret_pass: process.env.VUE_APP_SECRET_PASSPHRASE,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        user_web_player_id: null,
        chat_unread: 0,
        notif_enabled: false,
        recent_jobs: [],
        chosen_recent_job: '',
        recent_job: {
          id:'',
          category_id: '',
          category: {
            link_icon: ''
          },
          title: '',
          locations: [],
        },
        allow_fb: false,
        allow_apple: false,
        rebook_job_to: '',
        notifs: {
          unread: 0,
          items: [],
          page: 0,
          pageSize: 20,
          no_more: false
        },
        news_unread: 0,
        client_activity_unread: 0,
        home_tab: 'services',
        apple_redirect_url: process.env.VUE_APP_APPLE_RETURN_DIR,
        facebook_app_id: '819545849096455',
        mobile_device_token: '',
        fb_redirect_url: '',
        login_logger_name: '_ovvy_login_checker',
        pusherLogs: []
      }
    },
    methods: {
      encrypt(code) {
        let b64 = this.$CryptoJS.AES.encrypt(code, this.secret_pass).toString();
        let e64 = this.$CryptoJS.enc.Base64.parse(b64);
        let eHex = e64.toString(this.$CryptoJS.enc.Hex);
        return eHex;
      },
      decrypt(code) {
        let reb64 = this.$CryptoJS.enc.Hex.parse(code);
        let bytes = reb64.toString(this.$CryptoJS.enc.Base64);
        let decrypt = this.$CryptoJS.AES.decrypt(bytes, this.secret_pass);
        let plain = decrypt.toString(this.$CryptoJS.enc.Utf8);
        return plain;
      },
      insertDecimal(num) {
        return (Number(num)).toFixed(2);
      },
      toLocalString(num) {
        return Number(num).toLocaleString();
      },
      commaDecimal(num) {
        return Number(num).toLocaleString('en-US', {minimumFractionDigits: 2});
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      modalShow() {
        $('body').addClass('modal-open modal-open-additional').append('<div class="modal-backdrop fade show"></div>');
      },
      modalClose() { 
        $('body').removeClass('modal-open modal-open-additional');
        $('.modal-backdrop').remove()
      },
      closeThisModal(id) { 
        document.getElementById(id).classList.remove('show')
        document.getElementById(id).style.display = 'none';
        document.getElementById(id).setAttribute('aria-hidden', true)
        document.getElementById(id).removeAttribute('role')
        document.getElementById(id).removeAttribute('aria-modal')
        $('body').removeClass('modal-open modal-open-additional');
        $('.modal-backdrop').remove()
      },
      openThisModal(id) {
        document.getElementById(id).classList.add('show')
        document.getElementById(id).style.display = 'block';
        document.getElementById(id).setAttribute('aria-hidden', false)
        document.getElementById(id).setAttribute('role', 'dialog')
        document.getElementById(id).setAttribute('aria-modal', true)
        $('body').addClass('modal-open modal-open-additional').append('<div class="modal-backdrop fade show"></div>');
      },
      formatDate(date, format) {
        return moment(date).format(format);
      },
      convertUTCToLocal(date, format) {
        let toDt = moment.utc(date).toDate();
        // let toDt = moment(time, 'HH:mm:ss').toDate();
        // console.log(time);
        // console.log(toDt);
        return moment(toDt).format(format);
      },
      convertTimeToLocal(time, format = 'hh:mm A') {
        let toDt = moment.utc(time, 'HH:mm:ss').toDate();
        // let toDt = moment(time, 'HH:mm:ss').toDate();
        // console.log(time);
        // console.log(toDt);
        return moment(toDt).format(format);
      },
      convertTimeTo12(time, format = 'hh:mm A'){
        let toDt = moment(time, 'HH:mm').toDate();
        return moment(toDt).format(format);
      },
      goTo(url) {
        let orig = window.location.href;
        window.location.href = window.location.origin + '/#/' + url
        
        if(url == '' || orig == window.location.href) {
          location.reload();
        }
      },
      reloadPage() {
        location.reload();
      },
      previewImage(file) {
        return file.full_url ? file.full_url : URL.createObjectURL(file)
      },
      triggerFileUpload(name) {
        $('[name="'+name+'"]').trigger('click');
      },
      async verifyCheck() {
        const $this = this;
        await verified_check().then((response) => {   
          if(response.data.verified) {
            ls.setItem("ovvy_verified", 1);
            ls.setItem("profile_view", 'merchant');
            $this.goTo('');
          }else {
            // ls.setItem("ovvy_verified", 0);
            // ls.setItem("profile_view", 'client');
            $this.goTo('merchant-registration');
          }
        });
      },
      changeProfileView() {
        const $this = this
        //For Mobile Navigation
        const mobileNavShow = document.querySelector('.mobile-nav-show');
        const mobileNavHide = document.querySelector('.mobile-nav-hide');
        document.querySelector('body').classList.remove('mobile-nav-active');
        mobileNavShow.classList.remove('d-none');
        mobileNavHide.classList.add('d-none');

        this.scrollToTop()

        if(this.isLoggedIn) {
          let newVal = this.profile_view == 'client' ? 'merchant' : 'client';
          // console.log(newVal)
          

          if(newVal == 'client') {
            this.goTo('');
            ls.setItem("profile_view", newVal);
            this.setCookie('_ov_pv', newVal, 6)
          }else {
            if(this.merchant_verified == 1) {
              this.goTo('');
              ls.setItem("profile_view", newVal);
              this.setCookie('_ov_pv', newVal, 6)
            }else {
              this.verifyCheck();
            }
            // this.goTo('browse');
          }
        }else {
          $this.$swal.fire({
            title: 'Login first!',
            text: "Guest user can't use merchant",
            icon: 'error',
            confirmButtonText: 'LOGIN',
            confirmButtonColor: '#f44336',
            showCancelButton: true,
          }).then((result) => {
              if (result.isConfirmed) {
                  $this.goTo('login')
              }
          })
        }
      },
      forceChangeProfileView(newVal) {
        ls.setItem("profile_view", newVal);
        this.setCookie('_ov_pv', newVal, 6)
        //For Mobile Navigation
        const mobileNavShow = document.querySelector('.mobile-nav-show');
        const mobileNavHide = document.querySelector('.mobile-nav-hide');
        document.querySelector('body').classList.remove('mobile-nav-active');
        mobileNavShow.classList.remove('d-none');
        mobileNavHide.classList.add('d-none');
      },
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
          window.webkit.messageHandlers.loggerHandler.postMessage({
              "message": 'scroll to top'
          });
        }
      },
      showNotif(data) {
        console.log(data);

        let showNotif = true;

        if(!this.isLoggedIn) return;

        if(data.data.type == 3) {
          location.reload();
        }else if(data.data.type == 5) { //CLIENT_ACCEPT_BID or Fee
          if(this.$route.name == 'activity') {
            location.reload();
          }else if(this.$route.name == 'job view') {
            let job_id = Number(this.$root.decrypt(this.$route.params.code));
            if(data.data.data.job_id == job_id) {
              location.reload();
            }
          }
        }else if(data.data.type == 12) { 

          this.getUnreadChat();

          if(this.$route.name == 'job chat view') {
            let job_id = Number(this.$root.decrypt(this.$route.params.code));
            if(data.data.data.job_id == job_id) {
              showNotif = false
            }
            
          }
        }else if(data.data.type == 20) { //VENDOR_ADDED_SERVICE_FEE
          if(this.$route.name == 'job view') {
            let job_id = Number(this.$root.decrypt(this.$route.params.code));
            if(data.data.data.job_id == job_id) {
              showNotif = false
              location.reload();
            }
          }
        }else if(data.data.type == 118) {
          this.logMeOut();
        }else if(data.data.type == 102) {
          if(data.data.data.status == 'verified') {
            //merchant verified
            ls.setItem("ovvy_verified", 1);
            this.forceChangeProfileView('merchant');
          }else {
            ls.setItem("ovvy_verified", 0);
            this.hardRefresh()
          }
        }else if(data.data.type == 120) {
          //hard refresh
          this.hardRefresh()
        }
        if(showNotif) {
          this.$notify({
            title: data.content,
            duration: 5000,
            group: 'notif',
            data: {
              link: this.getNotifLink(data.data.type, data.data.data)
            }
          });
        }
        
      },
      getNotifLink(type, data) {
        // console.log(type);
        // console.log(data);
        if(type == 1 || type == 5 || type == 9 || type == 20  || type == 121) {
          return '/#/job/' + this.encrypt((data.job_id).toString())
        }else if(type == 12) { //CHAT
          return '/#/job/' + this.encrypt((data.job_id).toString()) + '/chat/' + this.encrypt((data.vendor_id).toString())
        }else if(data.job_id) {
          return '/#/job/' + this.encrypt((data.job_id).toString())
        }else if(type == 101){
          return '/#/merchant-reward-logs/'
        }else if(type == 102){
          if(data.status == 'verified') {
            // ls.setItem("ovvy_verified", 1);
            return '/#/browse/'
          }else {
            // ls.setItem("ovvy_verified", 0);
            return '/#/'
          }
        }else if(type == 100) {
          return '/#/news-alerts/' + this.encrypt((data.new_feed_id).toString())
        }else if(type == 26) {
          return '/#/chats/'
        }else if(type == 19){
          if(this.profile_view == 'merchant') {
            return '/#/merchant-rewards/'
          }else {
            return '/#/client-rewards/'
          }
        }else {
          return '/#/'
        }
      },
      async getUnreadChat() {
        if(this.profile_view == 'client') {
          await getUnreadClientChats().then(response => {   
              this.chat_unread = Number(response.data.unread)
          })
        }else {
          await getUnreadVendorChats().then(response => {   
              // console.log(response.data)
              this.chat_unread = Number(response.data.unread)
          })
        }
      },
      async getRecentJobs() {
        this.$root.isLoading = true;
        this.recent_jobs = [];
        let payload = new FormData();
        payload.append('client_id', this.$root.user_id);
        payload.append('limit', 5);
        payload.append('with', 'category,locations,bid.vendor.profile.avatar');
        payload.append('custom', 'completed_only,active_categories_only');
        await getJobs(payload).then(response => {
          // console.log(response)
          this.recent_jobs = response.data
          this.$root.isLoading = false;

          if(this.recent_jobs.length == 0) {
            this.closeThisModal('rebook');
            this.$notify({
              title: "You don't have a job to Rebook",
              type: 'error'
            });
          }
        })
      },
      selectRecentJob(job) {
        this.recent_job = job
      },
      repostJob() {
        this.closeThisModal('detail-rebook')

        this.chosen_recent_job = '';
        this.recent_job = {
          id:'',
          category_id: '',
          category: {
            link_icon: ''
          },
          title: '',
          locations: [],
        }
      },
      repostError() {
        this.$notify({
          title: "Please select object to send job",
          type: 'error'
        });
      },
      logMeOut() {
        ls.removeItem('token');
        ls.removeItem('id');
        ls.removeItem('encrypted_id');
        ls.setItem("profile_view", 'client');
        ls.setItem("profile_type", 'client');
        ls.removeItem('name');
        ls.removeItem('avatar');
        ls.removeItem('ovvy_verified');
        ls.removeItem('referral_code');

        let token = this.user_id_cookie;
        document.cookie = token + "=;path=/;domain=ovvyapp.com;expires=Thu, 01 Jan 1970 00:00:01 GMT";
        // console.log('delete cookie')
        // console.log(document.cookie)
        
        this.goTo('');
      },
      strTrimmer(string, length = 55) {
        let val = String(string);

        val = val.substring(0, length);

        if(String(string).length > length) {
          val += '...'
        }

        return val
      },
      hexAToRGBA(h, perc = 100) {
        let r = 0, g = 0, b = 0, a = 1;

        if (h.length == 5) {
          r = "0x" + h[1] + h[1];
          g = "0x" + h[2] + h[2];
          b = "0x" + h[3] + h[3];
          a = "0x" + h[4] + h[4];

        } else if (h.length == 9) {
          r = "0x" + h[1] + h[2];
          g = "0x" + h[3] + h[4];
          b = "0x" + h[5] + h[6];
          a = "0x" + h[7] + h[8];
        }
        a = +(a / 255).toFixed(3);

        if(perc != 100) {
          a = perc / 100;
        }

        return "rgba(" + +r + "," + +g + "," + +b + "," + a + ")";
      },
      async getNotifs() {
        if(this.notifs.no_more) return;
        await getNotifications({
          page: this.notifs.page,
          pageSize: this.notifs.pageSize
        }).then(response => {
            this.notifs.items = this.notifs.items.concat(response.data.items);
            this.notifs.page += this.notifs.pageSize;
            if(response.data.items.length == 0) this.notifs.no_more = true
            this.notifs.unread = 0
        })
      },
      getGMapsLink(location) {
        return 'https://www.google.com/maps/place/' + this.getFullAddress(location)
        // return 'https://www.google.com/maps/place/' + location.location + ' ' + location.postal_code
      },
      async getUnreadNewsCount() {
          await getTotalUnreadNewsFeed().then(response => {   
              // console.log(response.data)
              this.news_unread = Number(response.data.total_unread)
          })
      },
      async getUnreadClientActivity() {
          await getTotalUnreadClientActivity().then(response => {   
              // console.log(response.data)
              this.client_activity_unread = Number(response.data.total_count)
          })
      },
      scrollToElement(id) {
        let scrollDiv = document.getElementById(id).offsetTop;
        window.scrollTo({ top: scrollDiv - 100, behavior: 'smooth'});
      },
      testNotif() {
        this.$notify({
          title: 'TEst',
          duration: 50000000000000000000000,
          group: 'notif',
          data: {
            link: 'https://google.com'
          }
        });
      },
      getFullUrl(link) {
        link = link.replace('_mediumThumb', '')
        link = link.replace('_smallThumb', '')
        return link
      },
      isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      },
      getNotifManually() {
        const $this = this
        $this.getUnreadNotifications(false);
        setTimeout(function() {
          if($this.$root.app_display_view == 'ios' && $this.$root.ios_one_signal_set) {
            //Do not do
            $this.pusherLogs.push('Manually Added. Notif not working');
          }else {
            console.log('manual checking')
            window.Echo.private(`user-channel.${$this.$root.user_id}`)
            .listen("Users\\Notification", (e) => {
              console.log('new notification!!!')
              console.log(e)
              $this.getUnreadNotifications()
              $this.getUnreadChat()
              $this.getUnreadNotifs()
              $this.getUnreadNewsCount()
              if($this.profile_view == 'client' && !$this.isInIframe) {
                $this.getUnreadClientActivity()
              }
            })
            .listen("Users\\AccountDeleted", (e) => {
              console.log('logout')
              console.log(e)
              $this.logMeOut();
            });
          }
        }, 1000)
        // const forGet = setInterval(function() {
        //   if($this.$root.notif_enabled || $this.$root.ios_one_signal_set) clearInterval(forGet);
        //   $this.getUnreadNotifications()
        //   $this.getUnreadChat()
        //   $this.getUnreadNotifs()
        //   $this.getUnreadNewsCount()
        //   if($this.profile_view == 'client' && !$this.isInIframe) {
        //     $this.getUnreadClientActivity()
        //   }
        // }, 60000);
     
      },
      async getUnreadNotifs() {
        //total unread count
        await getUnreadNotifications().then(response => {   
            // console.log(response.data)
            this.notifs.unread = Number(response.data.total_unread)
        })
      },
      async getUnreadNotifications(showNotification = true) {
        // console.log('get notif manually')
        const $this = this
        await getUserUnreadNotifications().then(response => {
          // console.log(response.data.items)
          
          // let notif_shown = !ls.getItem($this.safari_notif_name).value ? [] : Object.values(ls.getItem($this.safari_notif_name).value)
          let notif_shown = !this.storageValue($this.safari_notif_name) ? [] : Object.values(this.storageValue($this.safari_notif_name))
          
          // console.log(notif_shown)
          $(response.data.items).each(function(i, item) {
            if(!notif_shown.includes(item.id)) {
              if($this.app_display_view == 'ios' && $this.ios_one_signal_set) {
                //dont show notif
              }else {
                //show
                if(showNotification) {
                  $this.showNotif({
                    heading: 'Ovvy Notification',
                    content: item.message,
                    data: {
                      type: item.type,
                      alert: item.message,
                      badgeCount: 1,
                      badgeType: 'SetTo',
                      data: item.data_decode
                    }
                  })
                }
              }
             
              notif_shown.push(item.id)
              // console.log(notif_shown)
            }
          });

          // console.log(notif_shown)

          ls.setItem($this.safari_notif_name, notif_shown);
        })
      },
      setCookie(cname, cvalue, exHours) {
        const d = new Date();
        exHours = 8760; //1 year
        d.setTime(d.getTime() + (exHours*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=ovvyapp.com";
      },
      getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      async recaptcha(action) {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "signup".
        const token = await this.$recaptcha(action)

        // Do stuff with the received token.
        //   console.log(token)
        return token
      },
      async addIOSDevice() {
        const $this = this
        let payload = new FormData();
        payload.append('device_token', $this.$root.user_ios_one_signal_player_id);
        payload.append('device_platform', 'web');

        await addUserDevice(payload).then(() => {
          
          ls.setItem('ios_one_signal_set', true);
        })
      },
      adjustScreen() {
        // console.log('get height')
        let body = document.body, html = document.documentElement;
        let height = Math.max( body.scrollHeight, body.offsetHeight, 
            html.clientHeight, html.scrollHeight, html.offsetHeight);
        let adjusted = height - 210;
        // let adjusted = height;
        // console.log(height)
        if(height > 1072 && document.getElementsByClassName("main-container").length > 0) {
          console.log(adjusted)
          document.getElementsByClassName("main-container")[0].style.minHeight = adjusted + 'px'
        }

        window.addEventListener('resize', function() {
          // console.log('resize')
          // console.log(event)
          // let body = document.body, html = document.documentElement;
          // let height = Math.max( body.scrollHeight, body.offsetHeight, 
          //   html.clientHeight, html.scrollHeight, html.offsetHeight ),
          //   adjusted = height - 210;
          //   // console.log(adjusted)
          //   if(height > 1072 && document.getElementsByClassName("main-container").length > 0)
          //     document.getElementsByClassName("main-container")[0].style.minHeight = adjusted + 'px'

          if(document.getElementsByClassName("main-container")[0]) {
            let height = document.getElementsByClassName("main-container")[0].firstChild.scrollHeight;
            let adjusted = height < 862 ? 900 : height + 100;
            // console.log(height)
            // console.log(adjusted)
            if(document.getElementsByClassName("main-container").length > 0) {
              document.getElementsByClassName("main-container")[0].style.minHeight = adjusted + 'px'
            }
          }
          
        }, true);
      },
      manualAdjustScreen(size = 75) {
        // console.log('adjust')
        // console.log(size)
        let height = document.getElementsByClassName("main-container")[0].firstChild.scrollHeight;
        let adjusted = height < 862 ? 862 : height + size;
        // let adjusted = height + size;
        // console.log(height)
        // console.log(adjusted)
        if(document.getElementsByClassName("main-container").length > 0) {
          // console.log(adjusted)
          document.getElementsByClassName("main-container")[0].style.minHeight = adjusted + 'px'
        }
      },
      async getOneSignalPlayerId() {
        return await this.$OneSignal.getUserId();
      },
      async addWebDevice() {
        const $this = this
        let payload = new FormData();
        payload.append('device_token', $this.$root.one_signal_player_id);
        payload.append('device_platform', 'web');

        await addUserDevice(payload).then(() => {
          
          ls.setItem('one_signal_player_id_set', true);
        })
      },
      getFullAddress(address) {
        let full = '';

        if(address.location && address.location != '' && address.location != null) {
          let flrbld = '';
          if(address.floor && address.floor != '' && address.floor != null) {
            flrbld += address.floor
          }

          if(address.building && address.building != '' && address.building != null) {
            flrbld += ' ' + address.building
          }

          if(flrbld != '' && !address.location.includes((flrbld).trim())) {
            full = flrbld;
          }

          full += ' ' + address.location;
        }else {
          if(address.floor && address.floor != '' && address.floor != null) {
            full += address.floor
          }

          if(address.building && address.building != '' && address.building != null) {
            full += ' ' + address.building
          }

          if(address.street && address.street != '' && address.street != null) {
            full += ' ' + address.street
          }else if(address.street_line_one && address.street_line_one != '' && address.street_line_one != null) {
            full += ' ' + address.street_line_one
          }

          if(address.street_number && address.street_number != '' && address.street_number != null) {
            full += ' ' + address.street_number
          }else if(address.street_line_two && address.street_line_two != '' && address.street_line_two != null) {
            full += ' ' + address.street_line_two
          }

          // if(full.trim() == '') {
          //   return (address.location).toUpperCase();
          // }

          if(address.postal_code && address.postal_code != ''  && address.postal_code != null) {
            full += ' ' + address.postal_code
          }

          if(address.country && address.country != '' && address.country != null) {
            full += ', ' + address.country
          }
        }

        return (full).toUpperCase();
      },
      logChecker() {
        const $this = this;

        if(this.isLoggedIn) {
          if(sessionStorage.getItem($this.login_logger_name) == null) {
            $this.loginLog()
          }
          setInterval(function() {
            if(sessionStorage.getItem($this.login_logger_name) == null) {
              $this.loginLog()
            }
          },900000) //15mins
        }
      },
      async loginLog() {
        // console.log('login log')
        await login_check().then(() => {   
          // console.log(response.data)
          // if(response.data.status == 'success') {
          //   console.log('login log updated')
          // }
          sessionStorage.setItem(this.login_logger_name, 1);
          // console.log(sessionStorage.getItem(this.login_logger_name))
        }).catch(error => {
          this.$notify({
            title: "An error occured.",
            text: error.response.data.message,
            type: 'error'
          });
          const $this = this
          setTimeout(function() {
            $this.logMeOut();
          }, 1000);
        })
      },
      hardRefresh() {
        this.$notify({
            title: "Refreshing page",
            type: 'success'
        });
        sessionStorage.setItem("_hardRefreshMe", "1");
        setTimeout(function() {
          window.location.reload(true)
        }, 500)
      },
      storageValue(name) {
        if(typeof ls.getItem(name).value == 'object') {
          return ls.getItem(name).value.value
        }else {
          return ls.getItem(name).value
        }
      },
      sessionValue(name) {
        if(typeof ssWatcher.getItem(name).value == 'object') {
          return ssWatcher.getItem(name).value.value
        }else {
          return ssWatcher.getItem(name).value
        }
      },
      checkVisible(elm) {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
      },
      checkIfAndroid() {
        return navigator.userAgent.match(/Android/i);
      }
    },
    computed: {
      app_env() {
        return process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : 'live'
      },
      user_id() {
        return this.storageValue('id') ? this.storageValue('id') : '';
        // return ls.getItem('id') ? ls.getItem('id').value : '';
      },
      user_encrypted_id() {
        return this.storageValue('encrypted_id') ? this.storageValue('encrypted_id') : '';
        // return ls.getItem('encrypted_id') ? ls.getItem('encrypted_id').value : '';
      },
      user_full_name() {
        return this.storageValue('name') ? this.storageValue('name') : '';
        // return ls.getItem('name') ? ls.getItem('name').value : '';
      },
      user_email() {
        return this.storageValue('email') ? this.storageValue('email') : '';
        // return ls.getItem('email') ? ls.getItem('email').value : '';
      },
      user_avatar() {
        return this.storageValue('avatar') ? this.storageValue('avatar') : '';
        // return ls.getItem('avatar') ? ls.getItem('avatar').value : '';
      },
      merchant_verified() {
        return this.storageValue('ovvy_verified') ? this.storageValue('ovvy_verified') : 0;
        // return ls.getItem('ovvy_verified') ? ls.getItem('ovvy_verified').value : 0;
      },
      phone_verified() {
        return this.storageValue('is_phone_verified') ? this.storageValue('is_phone_verified') : 0;
        // return ls.getItem('is_phone_verified') ? ls.getItem('is_phone_verified').value : 0;
      },
      user_phone() {
        return this.storageValue('phone_number') ? this.storageValue('phone_number') : 0;
        // return ls.getItem('phone_number') ? ls.getItem('phone_number').value : 0;
      },
      user_phone_display() {
        return '+' + this.user_phone.replace(/\D/g,'').replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
      },
      isLoggedIn() {
        const token = this.storageValue('token'), id = this.storageValue('id');
        return token !== null && id !== null
        // const token = ls.getItem('token').value, id = ls.getItem('id').value;
        // return token !== null && id !== null
      },
      profile_view() {
        if(this.isLoggedIn) {
          return this.storageValue('profile_view') ? this.storageValue('profile_view') : 'client';
          // return ls.getItem('profile_view') ? ls.getItem('profile_view').value : 'client';
        }else {
          return 'client'
        }
        
      },
      date_now() {
        return moment().format('YYYY-MM-DD')
      },
      user_referral_code() {
        return this.storageValue('referral_code') ? this.storageValue('referral_code') : '';
        // return ls.getItem('referral_code') ? ls.getItem('referral_code').value : '';
      },
      merchant_browse_filter_name() {
        return "merchant_"+this.$root.user_id+"_browse_filter3"
      },
      safari_notif_name() {
        return 'sfr_notif_' + this.$root.user_id
      },
      isInIframe() {
        // return true;
        return window.location !== window.parent.location
      },
      previous_route() {
        return this.storageValue('routePrev') ? this.storageValue('routePrev') : '';
        // return ls.getItem('routePrev') ? ls.getItem('routePrev').value : '';
      },
      user_id_cookie() {
        return process.env.VUE_APP_COOKIE_ID;
      },
      user_detail_cookie() {
        return process.env.VUE_APP_COOKIE_DETAILS;
      },
      user_ios_one_signal_player_id() {
        if(this.$route.query._dToken) {
          return this.$route.query._dToken;
        }else {
          return this.storageValue('one_signal_ios_player_id') ? this.storageValue('one_signal_ios_player_id') : '';
          // return ls.getItem('one_signal_ios_player_id') ? ls.getItem('one_signal_ios_player_id').value : '';
        }
      },
      app_display_view() {
        return this.storageValue('app_display_view') ? this.storageValue('app_display_view') : 'web';
        // return ls.getItem('app_display_view').value != null ? ls.getItem('app_display_view').value : 'web';
      },
      in_app_display(){
        let displayModex = 'browser';
        const mqStandAlone = '(display-mode: standalone)';
        if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
          displayModex = 'standalone';
        }
        return displayModex == 'standalone'
      },
      enable_recaptcha() {
        return this.app_display_view == 'web' && !this.in_app_display;
        // return this.app_display_view != 'ios' && !this.checkIfAndroid();
      },
      ios_one_signal_set() {
        return this.storageValue('ios_one_signal_set') ? this.storageValue('ios_one_signal_set') : false;
        // return ls.getItem('ios_one_signal_set').value != null ? ls.getItem('ios_one_signal_set').value : false;
      },
      one_signal_player_id_set() {
        return this.sessionValue('one_signal_player_id_set') ? this.sessionValue('one_signal_player_id_set') : false;
        // return ssWatcher.getItem('one_signal_player_id_set').value != null ? ssWatcher.getItem('one_signal_player_id_set').value : false;
      },
      one_signal_player_id() {
        return this.sessionValue('one_signal_player_id') ? this.sessionValue('one_signal_player_id') : false;
        // return ssWatcher.getItem('one_signal_player_id').value != null ? ssWatcher.getItem('one_signal_player_id').value : null;
      },
      showPendingCompletedJobs() {
        if((this.isLoggedIn && !this.isInIframe) || (this.isLoggedIn && this.app_display_view == 'ios') ) {
          if(this.$route.name == 'job chat view' && this.$route.query._b && this.$route.query._b == 'complete') {
            return false;
          }else{
            return true;
          }
        }else {
          return false;
        }
      },
      user_campaign_id() {
        return this.getCookie(this.cookie_campaign_id_name) != '' ? this.getCookie(this.cookie_campaign_id_name) : null;
        // return ssWatcher.getItem('campaignid').value != null ? ssWatcher.getItem('campaignid').value : null;
      },
      cookie_campaign_id_name() {
        return process.env.VUE_APP_CAMPAIGN_ID_COOKIE_NAME;
      },
      login_logger() {
        return sessionStorage.getItem(this.login_logger_name)
      }
    },
    watch: {
      profile_view: function (newVal) {
        if(this.isLoggedIn) {
          this.getUnreadChat()

          if(newVal == 'client') {
            this.getUnreadClientActivity()
          }
        }

        // this.home_tab = newVal == 'client' ? 'services' : 'all_jobs'
      }
    },
    async mounted() {
      
      const $this = this

      if(!this.isInIframe) {
        const notificationsEnabled =
        await this.$OneSignal.isPushNotificationsEnabled()
        console.log('Push enabled', notificationsEnabled)
        this.notif_enabled = notificationsEnabled;
        if(!notificationsEnabled) this.$OneSignal.showNativePrompt()
        else this.$OneSignal.showSlidedownPrompt()
        console.log(await this.$OneSignal.getUserId())
        // this.user_web_player_id = await this.$OneSignal.getUserId();
        // console.log(this.user_web_player_id)
        this.user_web_player_id = this.one_signal_player_id;
        if(this.one_signal_player_id == null) {
          let player_id = await this.$OneSignal.getUserId();
          if(player_id != null) {
            this.user_web_player_id = player_id;
            ls.setItem("one_signal_player_id", player_id);
          }
        }
        console.log(this.user_web_player_id)

        this.$OneSignal.on('subscriptionChange', function(isSubscribed) {
          console.log("The user's subscription state is now:", isSubscribed);
        });

        this.$OneSignal.on('notificationDisplay', function(data) {
          // console.log("one signale notif");
          $this.showNotif(data);

          $this.getUnreadNotifs();
          $this.notifs.page = 0;
          $this.notifs.items = [];
          $this.getNotifs();
          $this.getUnreadNewsCount();
        });
      }

      $this.allow_apple = true;

      setTimeout(function() {
        if (document.querySelector('.scroll-top')) { 
          const togglescrollTop = function() {
            // console.log('tog:' + window.scrollY)
            // console.log(window.scrollY > 100)
            window.scrollY > 100 ? document.querySelector('.scroll-top').classList.add('active') : document.querySelector('.scroll-top').classList.remove('active');
          }
          window.addEventListener('load', togglescrollTop);
          document.addEventListener('scroll', togglescrollTop);
          document.querySelector('.scroll-top').addEventListener('click', window.scrollTo({
            top: 0,
            behavior: 'smooth'
          }));
        }
      }, 200);
      
      if(sessionStorage.getItem("_hardRefreshMe")) {
        sessionStorage.removeItem("_hardRefreshMe");
        this.$notify({
            title: "Refreshing page...",
            type: 'success'
        });
        setTimeout(function() {
          window.location.reload(true)
        }, 500)
      }
    },
    created() {
      this.fb_redirect_url = encodeURIComponent(window.location.origin + '/#/facebook-redirect');
     if(this.isLoggedIn) {
      //Check cookie refresh
      if(ssWatcher.getItem('cookieSet').value == null) {
        this.$root.setCookie(this.$root.user_id_cookie, this.user_encrypted_id, 6)
        this.$root.setCookie(this.$root.user_detail_cookie, JSON.stringify({
          n: this.user_full_name,
          a: this.user_avatar
        }), 6);
        ssWatcher.setItem('cookieSet', 1);
        // console.log(this.getCookie(this.user_id_cookie))
        // console.log(this.getCookie(this.user_detail_cookie))
      }
      if(!this.isInIframe) {
        this.getUnreadChat()
        this.getUnreadNotifs()
      }
      
      this.getUnreadNewsCount()

      if(this.profile_view == 'client' && !this.isInIframe) {
        this.getUnreadClientActivity()
      }

      if(!this.isInIframe) {
        //get notif
        this.getNotifManually();
      }
     }

     const $this = this
     setTimeout(function() {
        // console.log($this.$route.query._dToken ? 'exists' : 'nope')
        if($this.$route.query._dToken) {
          ls.setItem("one_signal_ios_player_id", $this.$route.query._dToken);
        }

        if($this.$route.query._mbView) {
          ls.setItem("app_display_view", $this.$route.query._mbView);
        }

        if($this.$route.query.campaignid) {
          // ssWatcher.setItem('campaignid', $this.$route.query.campaignid);
          $this.setCookie($this.cookie_campaign_id_name, $this.$route.query.campaignid);
        }

        console.log($this.user_campaign_id);
        $this.$gtag.config({ campaignid: $this.user_campaign_id});

        $this.logChecker();

        console.log('Current code ver: ' + $this.codeVer)
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
          window.webkit.messageHandlers.loggerHandler.postMessage({
              "message": 'Current code ver : ' + $this.codeVer
          });
        }
        if($this.$route.query.updateVer) {
          console.log('Current code ver: ' + $this.codeVer)
          console.log('Updated code ver: ' + $this.$route.query.updateVer)
          if($this.$route.query.updateVer != $this.codeVer) {
            window.location.reload(true);
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.loggerHandler) {
              window.webkit.messageHandlers.loggerHandler.postMessage({
                  "message": 'Updated code ver : ' + $this.$route.query.updateVer
              });
            }
          }
        }
       
      }, 1000);
      
      setTimeout(function() {
        if($this.app_display_view == 'ios' && $this.isLoggedIn && !$this.ios_one_signal_set
         && $this.user_ios_one_signal_player_id != null && $this.user_ios_one_signal_player_id != 'null' && $this.user_ios_one_signal_player_id != '') {
          $this.addIOSDevice();
        }else if($this.app_display_view == 'web' && $this.isLoggedIn && !$this.one_signal_player_id_set
         && $this.one_signal_player_id != null && $this.one_signal_player_id != 'null' && $this.one_signal_player_id != '') {
          $this.addWebDevice();
        }
      }, 2000);

      this.home_tab = this.profile_view == 'client' ? 'services' : 'all_jobs'

      setTimeout(function() {
        $this.adjustScreen();
      }, 500);

      const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
      window.Pusher = Pusher;
      // Pusher.logToConsole = true;
      Pusher.log = function(message) { 
        console.log(message)
        $this.pusherLogs.push(message)
      };

      //pusherLogs

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_APP_KEY,
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        forceTLS: true,
        encrypted: true,
        authEndpoint : process.env.VUE_APP_API_URL + '/broadcasting/auth',
        auth: {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + token
          },
        }
      });
      
      window.Echo.channel('public')
      .listenToAll((event, data) => {
          console.log('new public pusher')
          console.log(event)
          console.log(data)
          if(event == "HardRefreshApp") {
            window.location.reload(true)
          }else if(event == "Jobs\\NewJob") {
            if($this.$root.profile_view == 'merchant' && ($this.$route.name == 'home' || $this.$route.name == 'Browse')) {
              location.reload();
            }
          }
        });
    }
  }
</script>
<style>
@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
</style>
