<template>
<!-- <div class="time-toolbar row">
    <div v-for="(slot, i) in times" :key="i" class="col-sm-4 mb-1">
        <input @change="checkIfValid($event, slot)" :type="fieldType" :id="'time-' + classId + '-' + i" :name="$props.name" :value="slot.display">
        <label :for="'time-' + classId + '-' + i">{{slot.display}}</label>
    </div>
</div> -->

<div class="time-toolbar" style="flex-wrap: wrap;gap: 2px">
    <div v-for="(slot, i) in times" :key="i" class="mb-1" style="flex: 1 0 30%;">
        <input @change="checkIfValid($event, slot)" 
            :type="fieldType" :id="'time-' + classId + '-' + i" 
            :name="$props.name" 
            :value="slot.display" 
            class="time-inputs"
            :checked="isChecked(slot)">
        <label :for="'time-' + classId + '-' + i">{{slot.display}}</label>
    </div>
</div>
</template>

<script>
    // import { $ } from 'dom7'
    import moment from 'moment'
   import $ from "jquery";
  export default {
    name: "TimePicker",
    props: {
      name: String,
      option: Object,
      modelValue: Object,
      date: String,
      field_type: String,
      data: Object
    },
    data() {
      return {
        fieldType: 'checkbox',
        range: [],
        values: {},
        times: [
            {
                display: '7am - 8am',
                from: '07:00:00',
                to: '08:00:00'             
            },
            {
                display: '8am - 9am',     
                from: '08:00:00',
                to: '09:00:00'               
            },
            {
                display: '9am - 10am',      
                from: '09:00:00',
                to: '10:00:00'              
            },
            {
                display: '10am - 11am',   
                from: '10:00:00',
                to: '11:00:00'            
            },
            {
                display: '11am - 12pm',    
                from: '11:00:00',
                to: '12:00:00'          
            },
            {
                display: '12pm - 1pm',     
                from: '12:00:00',
                to: '13:00:00'          
            },
            {
                display: '1pm - 2pm',    
                from: '13:00:00',
                to: '14:00:00'                
            },
            {
                display: '2pm - 3pm',  
                from: '14:00:00',
                to: '15:00:00'                  
            },
            {
                display: '3pm - 4pm',  
                from: '15:00:00',
                to: '16:00:00'                     
            },
            {
                display: '4pm - 5pm',      
                from: '16:00:00',
                to: '17:00:00'                 
            },
            {
                display: '5pm - 6pm',   
                from: '17:00:00',
                to: '18:00:00'                    
            },
            {
                display: '6pm - 7pm',    
                from: '18:00:00',
                to: '19:00:00'                   
            },
            {
                display: '7pm - 8pm',    
                from: '19:00:00',
                to: '20:00:00'                   
            },
            {
                display: '8pm - 9pm',    
                from: '20:00:00',
                to: '21:00:00'                   
            },
            {
                display: '9pm - 10pm',      
                from: '21:00:00',
                to: '22:00:00'                 
            },
        ],
        question_name: this.name,
      }
    },
    computed: {
        classId() {
            return moment().unix()
        }
    },
    watch: {
        date: function(newVal) {
            console.log(newVal)
            this.timeValidate()
        },
        field_type: function(val) {
            this.fieldType = val == 'single' ? 'radio' : 'checkbox'
        },
        data: function() {
            this.range = this.data
        }
    },
    methods: {
        checkIfValid(event, slot) {
            let now = moment(), checker = moment(this.date + ' ' + slot.to),
                question_name = this.question_name;
            // console.log(event)
            // console.log(slot)
            // console.log(this.date + ' ' + slot.from)
            if(checker.isBefore(now)) {
                event.target.checked = false
            }else {
                const $this = this
                let checked = [];
                $this.times.map(function(time) {
                    if($('[name="'+question_name+'"][value="'+time.display+'"]').is(':checked')) {
                        checked.push(time);
                    }
                });
                this.range = checked
                this.$emit('save', this.range)
            }
        },
        timeValidate() {
            const $this = this
            let checked = [], now = moment();
            $this.times.map(function(time) {
                let checker = moment($this.date + ' ' + time.to)
                if($('[name="'+$this.question_name+'"][value="'+time.display+'"]').is(':checked')) {
                    if(checker.isBefore(now)) {
                        $('[name="'+$this.question_name+'"][value="'+time.display+'"]').prop('checked', false)
                    }else{
                        checked.push(time);
                    }
                }
            });
            this.range = checked
            this.$emit('save', this.range)
        },
        isChecked(slot) {
            let valid = false;
            if(this.range.length > 0) {
                this.range.map(function(time) {
                    if(time.display == slot.display) {
                        return valid = true
                    }
                });
            }

            return valid
        }
    },
    created() {
        // console.log(moment().unix())
        this.fieldType = this.field_type == 'single' ? 'radio' : 'checkbox'
        // console.log(this.data)
        // console.log(this.data.length)
        if(this.data && this.data.length > 0) {
            this.range = this.data
        }
    }
  }
</script>