<template>
  <TheHeader :hide_back="true"/>
  <div class="main-container">
  <div v-if="!showCompleted" class="div-resposnsive">
    <section class="header-inner">
      <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h6>Activity</h6>
        </div>
      </div>
      </div>
    </section>   
    <div class="">
      <section class="post-tabs tabs-bg-white bg-white">
      <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
              <ul id="myTab" class="nav nav-tabs">
                <li v-if="$root.profile_view == 'merchant'" class="nav-item">
                  <a @click="activeTab='bid'" class="nav-link" :class="activeTab == 'bid' ? 'active' : ''" data-bs-toggle="tab" href="#bid-tab">BID</a>
                </li>
                <li v-else class="nav-item">
                  <a @click="activeTab='posts'" class="nav-link" :class="activeTab == 'posts' ? 'active' : ''" data-bs-toggle="tab" href="#posts-tab">POSTS</a>
                </li>
                <li v-if="$root.profile_view == 'merchant'" class="nav-item">
                  <a @click="activeTab='won'" class="nav-link" :class="activeTab == 'won' ? 'active' : ''" data-bs-toggle="tab" href="#won-tab">WON</a>
                </li>
                <li v-else class="nav-item">
                  <a @click="activeTab='accepted'" class="nav-link" :class="activeTab == 'accepted' ? 'active' : ''" data-bs-toggle="tab" href="#accepted-tab">ACCEPTED</a>
                </li>
                <li class="nav-item">
                  <a @click="activeTab='complete'" class="nav-link" :class="activeTab == 'complete' ? 'active' : ''" data-bs-toggle="tab" href="#complete-tab">COMPLETE</a>
                </li>
              </ul>
              <!-- Tab panes -->
              <div id="tab-container" class="tab-content">
                <div class="tab-pane container" :class="activeTab == 'posts' ? 'active' : 'fade'" id="posts-tab">
                  <router-link v-for="(item, l) in jobs.posts" :key="l" :to="'/job/' + $root.encrypt((item.id).toString())">
                    <div class="wrp align-items-start">
                      <div class="inner">
                        <div class="icon">
                            <img :src="item.category.link_icon">
                        </div>
                        <div class="text">
                          <h4 class="text-dark">{{item.title}}</h4>
                          <p>
                            {{item.locations.length > 0 ? $root.getFullAddress(item.locations[0]) : ''}} <br/> 
                            <span>{{$root.formatDate(item.date_from, 'ddd Do MMM')}}</span>
                            <span v-if="item.date_from != item.date_to"> - {{$root.formatDate(item.date_to, 'ddd Do MMM')}}</span> <br/>
                            <span>{{$root.convertTimeToLocal(item.time_from)}}</span>
                            <span v-if="item.time_from != item.time_to"> - {{$root.convertTimeToLocal(item.time_to)}}</span>
                          </p>
                        </div>
                      </div>
                      <div v-if="item.type == 'fixed'" class="tag-wrp-2">
                        <h4>${{$root.commaDecimal(fixedPricePostJobTotal(item))}}</h4>
                        <div class="tag btn">
                          <h6>Posted</h6>
                        </div>
                      </div>
                      <div v-else class="inner-flex">
                        <div class="red-dot">{{item.bid_count}}</div>
                            <small>Offers</small>    
                        <div class="tag btn">
                          <h6>Posted</h6>
                        </div>
                      </div>  
                    </div>
                  </router-link>
                  <p v-if="jobs.posts.length == 0" class="text-center mt-5"><em>No jobs have been posted yet</em></p>
                </div>
                <div class="tab-pane container" :class="activeTab == 'accepted' ? 'active' : 'fade'" id="accepted-tab">
                  <router-link v-for="(item, l) in jobs.accepted" :key="l" :to="'/job/' + $root.encrypt((item.id).toString()) ">
                    <div class="wrp align-items-start">
                      <div class="inner">
                        <div class="icon">
                          <img v-if="item.bid.vendor.profile.avatar" :src="item.bid.vendor.profile.avatar.medium_thumb_url" class="circle-avatar-40">
                          <img v-else src="~@/assets/img/profile-pic.png" class="profile-img"/>
                          <img v-if="item.bid.vendor.profile.ovvy_verified" src="~@/assets/img/verified_user.png" class="verify-img" style="position: absolute;bottom: 5px;width: 20px;right: -7px;"> 
                        </div>
                        <div class="text">
                          <h4 class="text-dark">{{item.title}}</h4>
                          <p>
                            {{item.locations.length > 0 ? $root.getFullAddress(item.locations[0]) : ''}} <br/> 
                            <span>{{$root.formatDate(item.bid.date_start, 'ddd Do MMM')}}</span>
                            <br/>
                            <span>{{$root.convertTimeTo12(item.bid.time_start)}}</span>
                            <!-- <span>{{$root.formatDate(item.date_from, 'ddd Do MMM')}}</span>
                            <span v-if="item.date_from != item.date_to"> - {{$root.formatDate(item.date_to, 'ddd Do MMM')}}</span> <br/>
                            <span class="mb-2 time-cont">
                              <span>{{$root.convertTimeTo12(item.bid.time_start)}}</span>
                            </span> -->
                          </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4>{{jobPriceText(item)}}</h4>
                        <small>{{jobStatusText(item)}}</small>
                        <div class="tag btn" :class="jobStateText(item) == 'View' ? 'btn-green' : ''">
                          <h6>{{jobStateText(item)}}</h6>
                        </div>
                      </div> 
                    </div>
                  </router-link>
                  <p v-if="jobs.accepted.length == 0" class="text-center mt-5"><em>You have not accepted any jobs yet</em></p>
                </div>
                <div class="tab-pane container" :class="activeTab == 'complete' ? 'active' : 'fade'" id="complete-tab">
                  <router-link v-for="(item, l) in jobs.complete" :key="l" :to="'/job/' + $root.encrypt(($root.profile_view == 'client' ? item.id : item.job.id).toString()) ">
                    <div v-if="$root.profile_view == 'client'" class="wrp align-items-start">
                      <div class="inner">
                        <div class="icon">
                          <img v-if="item.bid && item.bid.vendor.profile.avatar" :src="item.bid.vendor.profile.avatar.medium_thumb_url" class="circle-avatar-40">
                          <img v-else-if="item.bid" src="~@/assets/img/profile-pic.png" class="profile-img"/>
                          <img v-else :src="item.category.link_icon"/>

                          <img v-if="item.bid && item.bid.vendor.profile.ovvy_verified" src="~@/assets/img/verified_user.png" class="verify-img" style="position: absolute;bottom: 5px;width: 20px;right: -7px;"> 
                        </div>
                        <div class="text">
                          <h4 class="text-dark">{{item.title}}</h4>
                          <p>
                            {{item.locations.length > 0 ? $root.getFullAddress(item.locations[0]) : ''}} <br/> 
                            <span v-if="item.bid">
                              <span>{{$root.formatDate(item.bid.date_start, 'ddd Do MMM')}}</span>
                              <br/>
                              <span>{{$root.convertTimeTo12(item.bid.time_start)}}</span>
                            </span>
                            <span v-else>
                              <span>{{$root.formatDate(item.date_from, 'ddd Do MMM')}}</span>
                              <span v-if="item.date_from != item.date_to"> - {{$root.formatDate(item.date_to, 'ddd Do MMM')}}</span> <br/>
                              <span v-if="item.bid">{{$root.convertTimeTo12(item.bid.time_start)}}</span>
                              <span v-else>{{$root.convertTimeToLocal(item.time_from)}}</span>
                              <span v-if="!item.bid && item.time_from != item.time_to"> - {{$root.convertTimeToLocal(item.time_to)}}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4>{{jobPriceText(item)}}</h4>
                        <!-- <h4>{{$root.insertDecimal(item.fixed_price)}}</h4> -->
                        <div class="tag btn">
                          <h6>{{jobCompleteStatusText(item)}}</h6>
                        </div>
                      </div>
                    </div>
                    <div v-else class="wrp">
                      <div class="inner">
                        <div class="icon">
                          <img v-if="item.client_profile.avatar" :src="item.client_profile.avatar.medium_thumb_url" class="circle-avatar-40">
                          <img v-else src="~@/assets/img/profile-pic.png" class="profile-img"/>
                        </div>
                        <div class="text">
                          <h4 class="text-dark">{{item.job.title}}</h4>
                          <p>
                            {{item.job.locations.length > 0 ? $root.getFullAddress(item.job.locations[0]) : ''}} <br/> 
                            <span>{{$root.formatDate(item.date_start, 'ddd Do MMM')}}</span>
                            <br/>
                            <span>{{$root.convertTimeTo12(item.time_start)}}</span>
                          </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4>${{$root.commaDecimal(item.merchant_price_display)}}</h4>
                        <div class="tag btn">
                          <h6>{{jobCompleteStatusText(item)}}</h6>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <p v-if="jobs.complete.length == 0" class="text-center mt-5"><em>You have no completed jobs yet</em></p>
                  <!-- <router-link to="/job?status=paid" class="wrp">
                    <div class="inner">
                      <div class="icon">
                          <img src="~@/assets/images/profile-pic.png">
                      </div>
                      <div class="text">
                        <h4 class="text-dark">General Cleaning</h4>
                        <p>740 Yishun Avenue<br>
                          <span>Wed jul10</span> <br>
                          <span class="time-slot-red">9.06pm</span> </p>
                      </div>
                    </div>
                    <div class="tag-wrp-2">
                      <h4>$240.00</h4>
                      <small>Service Fee</small>
                      <div class="tag btn">
                        <h6>Paid</h6>
                      </div>
                    </div>
                  </router-link> -->
                </div>
                <div class="tab-pane container" :class="activeTab == 'bid' ? 'active' : 'fade'" id="bid-tab">
                  <router-link v-for="(item, l) in jobs.bid" :key="l" :to="'/job/' + $root.encrypt((item.job.id).toString()) ">
                    <div class="wrp align-items-start">
                      <div class="inner">
                        <div class="icon">
                          <img v-if="item.client_profile.avatar" :src="item.client_profile.avatar.medium_thumb_url" class="circle-avatar-40">
                          <img v-else src="~@/assets/img/profile-pic.png" class="profile-img"/>
                        </div>
                        <div class="text">
                          <h4 class="text-dark">{{item.job.title}}</h4>
                          <p>
                            {{item.job.locations.length > 0 ? $root.getFullAddress(item.job.locations[0]) : ''}}
                            <br/>
                            <span>{{$root.formatDate(item.date_start, 'ddd Do MMM')}}</span>
                            <br/>
                            <span>{{$root.convertTimeTo12(item.time_start)}}</span>
                          </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4>${{$root.commaDecimal(item.callout_fee)}}</h4>
                        <div class="tag btn">
                          <h6>Quoted</h6>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <p v-if="jobs.bid.length == 0" class="text-center mt-5"><em>You have not bid on any jobs yet</em></p>
                </div>
                <div class="tab-pane container" :class="activeTab == 'won' ? 'active' : 'fade'" id="won-tab">
                  <router-link v-for="(item, l) in jobs.won" :key="l" :to="'/job/' + $root.encrypt((item.job.id).toString()) ">
                    <div class="wrp align-items-start">
                      <div class="inner">
                        <div class="icon">
                          <img v-if="item.client_profile.avatar" :src="item.client_profile.avatar.medium_thumb_url" class="circle-avatar-40">
                          <img v-else src="~@/assets/img/profile-pic.png" class="profile-img"/>
                        </div>
                        <div class="text">
                          <h4 class="text-dark">{{item.job.title}}</h4>
                          <p>
                            {{item.job.locations.length > 0 ? $root.getFullAddress(item.job.locations[0]) : ''}} <br/> 
                            <span>{{$root.formatDate(item.date_start, 'ddd Do MMM')}}</span>
                            <br/>
                            <span>{{$root.convertTimeTo12(item.time_start)}}</span>
                          </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4 class="text-center">${{$root.commaDecimal(item.merchant_price_display)}}</h4>
                        <div class="btn btn-green" style="margin-bottom: 15px;padding: 4px;">
                          <h6 class="mb-0" style="font-size: 13px;">Mark Complete</h6>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <p v-if="jobs.won.length == 0" class="text-center mt-5"><em>You have not won any jobs yet</em></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div  v-else class="div-resposnsive">
    <section class="header-inner">
      <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h6>Activity</h6>
        </div>
      </div>
      </div>
    </section>   
    <div class="main-container">
      <section class="">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 p-0">
              <div class="nav-wrp">
                <div class="toggle">
                </div>
                <div class="">
                <h6 class="text-white pt-2">YOU NEED COMPLETE THESE JOBS</h6>
                </div>
                <div class="notification">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="main-content-wrp">  
        <section class="general-cleaning bg-white">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="wrp d-flex general-cleaning-wrp">
                  <div class="d-flex">
                    <img src="~@/assets/img/c2.png" style="width:40px;">
                    <div class="column">
                      <h6> General Cleaning</h6>
                      <small>740 demo address, Singapore</small>
                    </div>
                  </div>
                  <i class="fa fa-angle-right	"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-lightgray job-complete">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <h2>PLEASE CONFIRM THE JOB IS COMPLETE</h2>
                <div class="buttons">
                  <a  href="complete-jobs.html" class="btn complete"><img src="~@/assets/img/complete_white.png">Complete</a>
                  <a href="reason.html" class="btn not-complete"><img src="~@/assets/img/incomplete_white.png">Not Complete</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> 
      <!-- <section class="post-tabs tabs-bg-white bg-white">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
                <div class="tab-content">
                  <div class="tab-pane container active" id="tab2">
                    <router-link to="/job/complete" class="wrp">
                      <div class="inner">
                        <div class="icon">
                            <img src="~@/assets/images/profile-pic.png" class="profile-img">
                            <img src="~@/assets/images/verified_user.png" class="verified">
                        </div>
                        <div class="text">
                          <h4 class="text-dark">General Cleaning</h4>
                          <p>740 Yishun Avenue<br>
                            <span>Wed jul10</span> <br>
                            <span class="time-slot-red">9.06pm</span> </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4>$240.00</h4>
                        <small>Service Fee</small>
                        <div class="tag btn">
                          <h6>Paid</h6>
                        </div>
                      </div>
                    </router-link>
                    <router-link to="/job/complete" class="wrp">
                      <div class="inner">
                      <div class="icon">
                            <img src="~@/assets/images/profile-pic.png" class="profile-img">
                            <img src="~@/assets/images/verified_user.png" class="verified">
                        </div>
                        <div class="text">
                          <h4 class="text-dark">Spring Cleaning</h4>
                          <p>111, dummy address<br>
                            <span>Wed jul11</span> <br>
                            <span class="time-slot-red">11.00am</span> </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4>$350.00</h4>
                        <small>Service Fee</small>
                        <div class="tag btn">
                          <h6>Paid</h6>
                        </div>
                      </div>
                    </router-link>
            </div>
          </div>
        </div>
        </div></div>
      </section> -->
    </div>
  </div>
  </div>
  <TheFooter/>
</template>

<script>
import TheHeader from '@/components/common/TheHeader'
import TheFooter from '@/components/common/TheFooter'
import { userActivity, getJobActivity } from "@/api"
import $ from "jquery";

export default {
  name: 'ActivityView',
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      activeTab: '',
      jobs: {
        posts: [],
        accepted: [],
        complete: [],
        bid: [],
        won: []
      },
      showCompleted: false,
      completed: {
        page: 0,
        pageSize: 20,
        no_more: false,
        loading: false
      }
    }
  },
  methods: {
    closeModal() {
      this.openModal = false;
      this.$emit('close', 'bankAccount');
    },
    async getModels() {
      this.$root.isLoading = true;
      await userActivity().then(response => {
        // console.log(response.data)
        this.jobs = response.data
        this.$root.isLoading = false;
      })
    },
    jobStatusText(item) {
      if(item.bid) {
        if(item.bid.type == 'callout') {
          if(Number(item.bid.price) > 0) {
            if(item.bid.paid_status == 'paid_callout') {
              return 'Extra Fee';
            }else if(item.bid.paid_status == 'paid_service_fee'){
              return 'Total'
            }
          }else {
            return 'Service Fee';
          }
        }else {
          return 'Total';
        }
      }
      
      return '';

    },
    jobStateText(item) {
      if(item.bid) {
        if(item.bid.type == 'callout') {
          if(Number(item.bid.price) > 0) {
            if(item.bid.paid_status == 'paid_callout') {
              return 'View';
            }else if(item.bid.paid_status == 'paid_service_fee'){
              return 'Paid'
            }
          }else {
            return 'Paid';
          }
        }else {
          return 'Paid';
        }
      }
      
      return '';
      // return  item.client_job_status;
    },
    jobPriceText(item) {
      let price = 0;
      if(item.bid) {
        if(item.bid.type == 'callout') {
          if(item.bid.paid_status == 'paid_callout' && Number(item.bid.price) > 0) {
            price = Number(item.bid.price);
            return '$' + this.$root.commaDecimal(price)
          }else {
            price = Number(item.bid.callout_fee);
          }

          if(item.bid.paid_status == 'paid_service_fee') {
            price += Number(item.bid.price);
          }

          let credit_used = Number(item.bid.callout_credit) + Number(item.bid.service_fee_credit) + Number(item.fixed_price_credit);
          price -= credit_used;

          if(item.bid.promo) {
            price -= Number(item.bid.promo.discount)
          }

          if(item.promo) {
            price -= Number(item.promo.discount)
          }
        }
        return '$' + this.$root.commaDecimal(price)
        // if(item.bid.type == 'callout') {
        //   if(Number(item.bid.price) > 0) {
        //     if(item.bid.paid_status == 'paid_callout') {
        //       if(item.type && item.type == 'fixed') {
        //         return '$' + this.$root.insertDecimal(Number(item.bid.price) + Number(item.bid.price) * Number(item.deduction_amount_percent) / 100);
        //       }else if(item.type && item.type == 'bidding') {
        //         return '$' + this.$root.insertDecimal(Number(item.bid.price) + Number(item.bid.price) * Number(item.deduction_amount_percent) / 100);
        //       }else {
        //         return '$' + this.$root.insertDecimal(Number(item.bid.price) * 1.1)
        //       }
        //     }else if(item.bid.paid_status == 'paid_service_fee'){
        //       return '$' + this.$root.insertDecimal(Number(item.bid.callout_fee) + Number(item.bid.price))
        //     }
        //   }else {
        //     return '$' + this.$root.insertDecimal(item.bid.payment_amount)
        //   }
        // }else {
        //   return '$' + this.$root.insertDecimal(item.bid.payment_amount)
        // }
      }
      return '';
      // return '$' + this.$root.insertDecimal(item.fixed_price)
    },
    jobCompleteStatusText(item) {
      if(this.$root.profile_view == 'client') {
        return item.client_status;
      }else {
        if(item.job.status == 'cancel') {
          return 'Cancelled';
        }else {
          return item.merchant_status;
        }
      }
    },
    async getJobs() {
      this.$root.isLoading = true;
      // getJobActivity(profile, type, payload)
      let profile = this.$root.profile_view == 'merchant' ? 'vendor' : 'client',
      tab = this.activeTab;

      await getJobActivity(profile, tab).then(response => {
        // console.log(response.data)
        this.jobs[this.activeTab] = response.data
        this.$root.isLoading = false;
      })
    },
    fixedPricePostJobTotal(item) {
      let total = item.fixed_price;

      total -= item.fixed_price_credit

      if(item.promo) {
        total -= Number(item.promo.discount)
      }

      return total
    },
    handleScroll() {
      if(this.activeTab == 'complete') {

        let element = document.querySelector('#complete-tab')
        
        if (element && element.getBoundingClientRect().bottom < window.innerHeight) {
          // console.log(element.getBoundingClientRect().bottom)
        // console.log(window.innerHeight)
        // console.log(element.getBoundingClientRect().bottom < window.innerHeight)
          this.loadMoreCompleted()
        }
      }
    },
    async loadMoreCompleted() {
      if(this.completed.loading) {
        return;
      }

      if(this.completed.no_more) {
        return;
      }

      // console.log('completed');
      this.$root.isLoading = true;
      this.completed.loading = true;
      // getJobActivity(profile, type, payload)
      let profile = this.$root.profile_view == 'merchant' ? 'vendor' : 'client',
      tab = this.activeTab;

      await getJobActivity(profile, tab, {
        page: this.completed.page,
        pageSize: this.completed.pageSize
        }).then(response => {
        // console.log(response.data)
        this.jobs[this.activeTab] = this.jobs[this.activeTab].concat(response.data)
        this.completed.page += this.completed.pageSize;
        if(response.data.length == 0) this.completed.no_more = true

        this.$root.isLoading = false;
        this.completed.loading = false;
      })

      
    }
  },
  watch: {
    activeTab: function(newVal) {
      console.log(newVal)
      $('#myTab .nav-link').removeClass('active')
      
      if(newVal == 'complete') {
        this.loadMoreCompleted()
      }else {
        this.getJobs()
      }
    },
  },
  mounted() {

    if(this.$root.profile_view == 'merchant') {
      if(this.$route.hash != '' && ['bid', 'won', 'complete'].includes(this.$route.hash.substring(1))) {
        this.activeTab = this.$route.hash.substring(1)
      }else {
        this.activeTab = 'bid'
      }
    }else {
      if(this.$route.hash != '' && ['posts', 'accepted', 'complete'].includes(this.$route.hash.substring(1))) {
        this.activeTab = this.$route.hash.substring(1)
      }else {
        this.activeTab = 'posts'
      }
    }

    // this.getModels();
    if(this.$route.query.complete) {
      this.showCompleted = true
    }else if(this.$route.query.tab){
      this.activeTab = this.$route.query.tab
    }else {
      // this.getModels()
      // $('#myTab li:first-child a').tab('show')
      $('#myTab li a').removeClass('active')
      $('#myTab li:first-child a').addClass('active')
    }

    // const listElm = document.querySelector('#complete-tab');
    // listElm.addEventListener('scroll', e => {
    //   console.log(e.target)
    //   // if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 1) {
    //   //     console.log(e.target)
    //   //     $this.$root.getNotifs();
    //   // }
    // });
    
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  }
}

</script>

<style scoped>
@media (max-width: 768px) {
    .post-tabs {
      padding-bottom: 85px;
    }
  }
</style>