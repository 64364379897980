<template>
<section class="header">
    <div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 padding-none text-center">
        <div class="nav-cleaning">
            <div class="title">
            <label class="text-white">SERVICE COMPLETION</label>
            </div>
                <div class="back">
                <router-link to="/activity?complete=1" class="btn"><i class="fa fa-long-arrow-left text-white" ></i></router-link>
                </div>
            </div>
        </div>
        </div>
    </div>
</section>

<section class="general-cleaning bg-white">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrp d-flex general-cleaning-wrp">
            <div class="d-flex">
              <img src="~@/assets/images/dummies/c2.png" style="width:40px;">
              <div class="column">
                <h6> General Cleaning</h6>
                <small>740 demo address, Singapore</small>
              </div>
            </div>
            <i class="fa fa-angle-right	"></i>
          </div>
        </div>
      </div>
    </div>
  </section>
<section class="bg-lightgray job-complete">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h2>PLEASE CONFIRM THE JOB IS COMPLETE</h2>
        <div class="buttons">
          <a @click.prevent="showReview=true" href="#" class="btn complete"><img src="~@/assets/images/incomplete_white.png">Complete</a>
          <a @click.prevent="showDispute=true" href="#" class="btn not-complete"><img src="~@/assets/images/incomplete_white.png">Not Complete</a>
        </div>
      </div>
    </div>
  </div>
</section>

<div v-show="showReview" class="full-screen-section">
  <section class="header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
                <div class="title">
                    <label class="text-white">REVIEW (Optional)</label>
                </div>
                <div class="close-back">
                    <a @click.prevent="showReview=false;showFave=true" href="#" class="btn"><i class="fa fa-close text-white"></i></a>
                </div>
               </div>
            </div>
          </div>
        </div>
  </section>

  <section class="rate-user">
    <div class="container-fluid">
        <div class="row">
        <div class="col-lg-12">
            <div class="wrp">
            <img src="~@/assets/images/profile-pic.png" class="user">
            <h5>Jhojie Merchant</h5>
            <small>712, Dummy address</small>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Add a review"></textarea>
            <h6>Rate your service provider</h6>
            <ul>
                <li> <i class="fa fa-star-o"></i> </li>
                <li> <i class="fa fa-star-o"></i> </li>
                <li> <i class="fa fa-star-o"></i> </li>
                <li> <i class="fa fa-star-o"></i> </li>
                <li> <i class="fa fa-star-o"></i> </li>
            </ul>
            </div>
        
        </div>
        </div>
    </div>
    </section>

<section class="footer">
   <div class="container-fluid">
         <div class="fixed-footer">
           <div class="row">
             <div class="col-lg-12 text-center">
                <button @click="showReview=false;showFave=true" type="button" class="btn review-btn">
                SUBMIT
               </button>
               
               <div class="next bg-none">
                 <div class="next-bar">
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
 </section>
</div>

<div v-show="showFave" class="full-screen-section">
  <section class="header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
                <div class="title">
                    <label class="text-white">FAVOURITE</label>
                </div>
                <div class="close-back">
                    <router-link to="/activity?tab=complete" class="btn btn-white">Done</router-link>
                </div>
               </div>
            </div>
          </div>
        </div>
  </section>

<section  class="user-info favourite-info">
    <div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
        <div class="user-info-wrp">
            <div class="like-buttons">
            <button type="button" class="btn fav"> <i class="fa fa-heart"></i> <span>Favourite</span></button>
            <button type="button" class="btn share"> <i class="fa fa-share"></i> <span>Share</span></button>
            </div>
            <div class="img-wrp">
            <img src="~@/assets/images/default_avatar@3x.png" class="img-fluid user">
            </div>
            <h5>Jhoile Merchant</h5>
            <p>50 Raffels, abc, dummy text</p>
        </div>
        </div>
    </div>
    </div>
</section>

<section class="fav-info">
    <div class="container-fluid">
        <div class="row">
        <div class="col-lg-12">
            <p>Favouriting this Merchant allow you to book them directly in the future, without having to publicly post the job</p>
            <i class="fa fa-heart"></i>
            <h6>Favourite</h6>
        </div>
        </div>
    </div>
</section>
</div>

<div v-show="showDispute" class="full-screen-section">
  <section class="header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
                <div class="title">
                    <label class="text-white">OPEN DISUPUTE</label>
                </div>
                <div class="back">
                    <a @click.prevent="showDispute=false" href="#" class="btn"><i class="fa fa-long-arrow-left text-white" ></i></a>
                </div>
               </div>
            </div>
          </div>
        </div>
  </section>

<section class="row-gray margin-top-50-imp">
    <div class="container-fluid">
       <div class="row">
         <div class="col-lg-12">
           <h6>What type of cleaning?</h6>
         </div>
      </div>
    </div>
  </section>
  <section class="reason">
   <div class="container-fluid">
     <div class="row">
       <div class="col-lg-12">
         <div class="check-box">
            <label class="form-check-label" for="reason1">We mutually agreed not to complete the job. </label>
            <input type="radio" class="form-check-input" id="reason1" name="reason" value="option1">
            </div>
           <div class="check-box">
              <label class="form-check-label" for="reason2">The job was not completed to an accpetable standard</label>
              <input type="radio" class="form-check-input" id="reason2" name="reason" value="landed">
             </div>
             <div class="check-box">
                <label class="form-check-label" for="reason3">The merchant did not show</label>
                <input type="radio" class="form-check-input" id="reason3" name="reason" value="condominium">
               </div>
            </div>
          </div>
        </div>
      </section>
      <section class="row-gray mt-3">
        <div class="container-fluid">
           <div class="row">
             <div class="col-lg-12">
               <h6>Other</h6>
             </div>
          </div>
        </div>
      </section>

      <section class="other">
        <div class="container-fluid">
           <div class="row">
             <div class="col-lg-12">
               <p>Please write any other useful information below, which may help us help resolve ypur dispute.</p>
               <textarea class="form-control" rows="5" id="comment"></textarea>
             </div>
          </div>
        </div>
      </section>

    
    <section class="upload-img">
        <div class="container-fluid">
           <div class="row">
             <div class="col-lg-12">
               <!-- <form action="/action_page.php">
                <input type="file" id="myFile" name="filename" multiple>
                </form> -->
              <div class="photo">
                <div class="img-wrp">
                  <img src ="~@/assets/images/icamera.png">
                </div>
                <h5>Upload Photos</h5>
              </div>
              <small>Have you try to contact the merchant to resolve?</small>
             </div>
          </div>
        </div>
      </section>
    

    <section class="footer">
        <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12 text-center">
                <div class="fixed-footer">
                    <button @click="this.submitDispute()" type="button" class="btn review-btn">
                        SUBMIT
                    </button>
                <div class="row">
                    <div class="col-lg-12">
                    <div class="next bg-none">
                        <div class="next-bar">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import $ from "jquery";
import { getClientCategories } from "@/api"
export default {
  name: 'CompleteJobView',
  data() {
    return {
        showReview: false, 
        showFave: false,
        showDispute: false,
    }
  },
   watch: {
    // useCredits: function (newVal) {
    //     if(newVal) {
    //         this.total = 0
    //     }
    // }
  },
  methods: {
    closeModal() {
      this.openModal = false;
      this.$emit('close', 'bankAccount');
    },
    async getModels() {
      this.$root.isLoading = true;
      await getClientCategories().then(response => {
        // console.log(response)
        this.categories = response.data
        this.$root.isLoading = false;
      })
    },
    complete() {
        
    },
    submitDispute() {
        const $this = this
        this.$swal({
            // icon: 'warning',
            // title: 'You have unsaved changes!',
            text: "The Ovvy Support Team will review your dispute and inform you once a decision has been made.",
            confirmButtonText: 'OK',
            confirmButtonColor: '#e73644',
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
                $this.showDispute = false
                $this.showFave = true
            }
        })
    }
  },
  mounted() {
    $('body').css('background', '#ECECEC');
  }
}
</script>

<style scoped>
.general-cleaning {
    margin-top: 50px
}
body {
    background: #ECECEC !important;
}
.review-btn {
    background: #FE4D45;
    color: #ffff;
    width: 97%;
    border-radius: 25px;
}
</style>