<template>
    <section v-if="showHeader">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
              <div class="title">
                <label class="text-white">{{title}}</label>
               </div>
                <div v-if="showBack" class="back">
                  <a @click.prevent="back()" href="#" class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                </div>
               </div>
            </div>
          </div>
        </div>
    </section>

    <div v-if="showMainPage">
      <section v-if="showJobDetails" class="complete-job-container general-cleaning bg-white mt-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div @click="showJobDetailsPage=true" class="wrp d-flex general-cleaning-wrp">
                <div class="d-flex">
                  <div class="img-cont">
                    <img :src="job.category.link_icon">
                  </div>
                  <div class="column d-flex">
                    <h6>{{job.title}}</h6>
                    <small>{{$root.getFullAddress(job.locations[0])}}</small>
                  </div>
                </div>
                <i class="fa fa-angle-right "></i>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-lightgray job-complete">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <h2>PLEASE CONFIRM THE JOB IS COMPLETE</h2>
              <div class="buttons">
                <a @click.prevent="complete()" href="activity-merchant.html" class="btn complete">
                  <img src="~@/assets/img/complete_white.png">Complete </a>
                <a @click.prevent="notComplete()" href="reason.html" class="btn not-complete">
                  <img src="~@/assets/img/incomplete_white.png">Not Complete </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="job.client_id == $root.user_id" class="row-white mb-2 posted-by">
        <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
                <!-- <a href="#" @click.prevent="goToChat()">Chat</a> -->
                <div @click="showChatPage=true" class="wrp d-flex merchant-wrp">
                  <div class="wrp flex-row">
                    <img v-if="job.bid.vendor.profile.avatar" :src="job.bid.vendor.profile.avatar.medium_thumb_url">
                    <img v-else src="~@/assets/images/posted.png">
                    <div>
                        <p>Chat with Merchant</p>
                        <h6>{{job.bid.vendor.full_name}}</h6>
                    </div>
                  </div>   
                  <i class="fa fa-angle-right "></i>
                </div>
                 
              </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else-if="showJobDetailsPage" class="p-2">
      <JobDetails :job="job" />
    </div>
    <div v-else-if="showDisputePage" class="p-2">
      <section class="row-gray">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <h6>Reason</h6>
            </div>
          </div>
        </div>
      </section>
      <section class="reason">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="check-box">
                <label class="form-check-label" for="reason1">We mutually agreed not to complete the job. </label>
                <input v-model="dispute_form.reason" type="radio" class="form-check-input" id="reason1" name="reason" value="We mutually agreed not to complete the job.">
              </div>
              <div class="check-box">
                <label class="form-check-label" for="reason2">The job was not completed to an acceptable standard.</label>
                <input v-model="dispute_form.reason" type="radio" class="form-check-input" id="reason2" name="reason" value="The job was not completed to an acceptable standard.">
              </div>
              <div class="check-box">
                <label class="form-check-label" for="reason3">The merchant did not show up.</label>
                <input v-model="dispute_form.reason" type="radio" class="form-check-input" id="reason3" name="reason" value="The merchant did not show up.">
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="row-gray mt-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <h6>Other</h6>
            </div>
          </div>
        </div>
      </section>
      <section class="other">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <p>Please write any other useful information below which may help us resolve your dispute</p>
              <textarea v-model="dispute_form.memo" class="form-control" rows="5" id="comment"></textarea>
            </div>
          </div>
        </div>
      </section>
      <section class="upload-img-cont" style="padding-bottom: 15px;">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 text-center" style="background: #ECECEC;">
                <input @change="updateMultiFileField($event)"
                    accept="image/jpeg,image/jpg,image/png" name="dispute_files"
                    style="display:none"
                    type="file"
                />
                <div class="d-flex" style="flex-wrap: wrap;gap: 5px;">
                  <div v-for="(file, fKey) in dispute_form.files" :key="fKey" class="img-wrp img-upload-cont">
                    <img :src ="$root.previewImage(file)">
                    <a @click.prevent="removeFileFromMultiField(file)" href="#"
                      title="Remove file" class="img-upload-remove">x</a>
                  </div>
                  <a @click.prevent="showFileSelect()" href="#" class="photo-upload-cont">
                  <div class="img-wrp">
                    <img src ="~@/assets/images/icamera.png">
                  </div>
                  </a>
      
                  <h6 v-if="dispute_form.files.length == 0" style="margin-left: 10px;padding-top: 45px;"><small>Upload Photos</small></h6>
                </div>
                <!-- <br/>
                <small>Have you tried to contact the merchant to resolve before opening a dispute? We recommend to try and resolve before proceeding.</small> -->
              </div>
          </div>
        </div>
      </section>
      <section class="text-center">
        <a @click.prevent="submitDispute()" href="#" class="btn next-btn">SUBMIT</a>
      </section>
    </div>
    <div v-else-if="showReviewPage" class="p-2">
      <section class="rate-user">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="wrp rating-wrp">
                <img v-if="job.bid.vendor.profile.avatar" :src="job.bid.vendor.profile.avatar.medium_thumb_url" class="circle-avatar"/>
                <img v-else src="~@/assets/img/profile-pic.png" class="user">
                <h5>{{this.job.bid.vendor.full_name}}</h5>
                <small>{{$root.getFullAddress(this.job.locations[0])}}</small>
                <textarea v-model="review_form.review" class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Add a review"></textarea>
                <h6>Rate your service provider</h6>
                <star-rating :star-size="30" :show-rating="false" @update:rating="review_form.rating = $event"/>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center">
        <a @click.prevent="submitReview()" href="#" class="btn next-btn">SUBMIT</a>
      </section>
    </div>
    <div v-else-if="showFavePage" class="p-2">
      <section class="user-info favourite-info">
        <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12">
                <div class="user-info-wrp">
                <div class="like-buttons">
                    <button @click.prevent="''" type="button" class="btn fav"> 
                      <i :class="job.bid.vendor.if_user_is_fan ? 'fa fa-heart': 'fa fa-heart-o'" ref="heart_mini"></i> <span>Favourite</span>
                    </button>
                    <button v-if="$root.app_display_view != 'ios'" @click="this.$emit('showShare')" type="button" class="btn share" style="margin-left: auto;"> <i class="fa fa-share"></i> <span>Share</span></button> 
                    <a v-else-if="$root.app_display_view == 'ios'" 
                        :href="'https://share.ovvyapp.com?text=' + job.bid.vendor.full_name + ': Book my service on Ovvy! https://ovvyapp.com/?vendor_id=' + job.bid.vendor.id" 
                        class="btn share" style="margin-left: auto;"> 
                        <i class="fa fa-share"></i> <span>Share</span>
                    </a> 
                </div>
                <div class="img-wrp">
                  <img v-if="job.bid.vendor.profile.avatar" :src="job.bid.vendor.profile.avatar.medium_thumb_url" class="circle-avatar"/>
                    <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user">
                </div>
                <h5>{{this.job.bid.vendor.full_name}}</h5>
                <p>{{$root.getFullAddress(this.job.locations[0])}}</p>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section class="fav-info">
        <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12">
                <p>Favouriting this Merchant allow you to book them directly in the future, without having to publicly post the job</p>
                <a @click.prevent="addFave" href="#"><i class="fa fa-heart-o" ref="heart"></i></a>
                <h6>Favourite</h6>
            </div>
            </div>
        </div>
        </section>
    </div>
    <div v-else-if="showChatPage" class="p-2">
      <div class="">
        <section id="chat" class="button-fee">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <ul id="completed-job-chat-infinite-list" class="chat-placeholder chat-list" @scroll="onScroll" style="min-height: 350px">
                    <li v-for="(item, i) in chat.reverse_items" :key="i">
                      <div class="chat-timestamp">{{$root.formatDate(item.created_at, 'ddd Do MMM')}}</div>
                      <div v-if="item.sender == 'admin'" class="chat-admin-user">
                        <p>{{item.message}}</p>
                        <div style="clear:both"></div>
                      </div>
                      <div v-else-if="item.sender == 'client'" :class="item.client_id == $root.user_id ? 'chat-user' : 'chat-other-user'">
                        <a v-if="item.file" :href="item.file.full_url" target="_blank">
                          <video v-if="item.file.extension == 'mp4' || item.file.extension == 'mov'" width="180" controls>
                            <source :src="item.file.full_url">
                            Your browser does not support the video tag.
                          </video>
                          <img v-else :src="item.file.medium_thumb_url"/>
                        </a>
                        <p v-else>{{item.message}}</p>
                        <div style="clear:both"></div>
                        <span v-if="item.client_id == $root.user_id">{{item.is_vendor_read == 1 ? 'Read' : 'Delivery'}}</span>
                        <div v-if="item.client_id == $root.user_id" style="clear:both"></div>
                      </div>
                      <div v-else-if="item.sender == 'vendor'" :class="item.vendor_id == $root.user_id ? 'chat-user' : 'chat-other-user'">
                        <a v-if="item.file" :href="item.file.full_url" target="_blank">
                          <video v-if="item.file.extension == 'mp4' || item.file.extension == 'mov'" width="180" controls>
                            <source :src="item.file.full_url" >
                            Your browser does not support the video tag.
                          </video>
                          <img v-else :src="item.file.medium_thumb_url"/>
                        </a>
                        <p v-else>{{item.message}}</p>
                        <div style="clear:both"></div>
                        <span v-if="item.vendor_id == $root.user_id">{{item.is_vendor_read == 1 ? 'Read' : 'Delivery'}}</span>
                        <div v-if="item.vendor_id == $root.user_id" style="clear:both"></div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section class="chat-footer completed-job">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="input-group">
                  <input v-model="chat.form.message" ref="chatSearch" type="text" class="form-control" placeholder="Type here...." v-on:keyup.enter="sendChat">
                  <input type="file" id="chat_file" name="chat_file" @change="updateChatMultiFileField($event)"
                    accept="image/jpeg,image/jpg,image/png,video/mp4,video/quicktime" class="upload" style="display:none"/>
                  <span  @click.prevent="showChatFileSelect()" class="input-group-text bg-white cursor-pointer">
                    <img src="~@/assets/img/icamera.png">
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

</template>

<script>
import JobDetails  from '@/components/jobs/details'
import StarRating from 'vue-star-rating'
import { completeBid, clientCompleteBid, addReview, addDispute, addFave, sendClientChat, getChatMessages } from "@/api"
import $ from 'jquery'

export default {
  name: 'IfJobComplete',
  components: {
    JobDetails,
    StarRating
  },
  props: {
    job: Object,
    hide_header: Boolean,
    hide_job_details: Boolean
  },
  data() {
    return {
      title: 'Service Completion',
      showMainPage: true,
      showJobDetailsPage: false,
      showDisputePage: false,
      showReviewPage: false,
      showFavePage: false,
      showChatPage: false,
      showHeader: true,
      showJobDetails: true,
      showBack: false,
      dispute_form: {
        reason: '',
        memo: '',
        files: []
      },
      review_form: {
        rating: '',
        review: ''
      },
      chat: {
        items: [],
        reverse_items: [],
        form: {
          message: '',
          file: ''
        },
        page: 0,
        pageSize: 20,
        no_more: false,
      }
    }
  },
  methods: {
    back() {
      if(this.showJobDetailsPage) {
        this.$root.scrollToTop()
        this.showMainPage = true
      }else if(this.showDisputePage) {
        this.$root.scrollToTop()
        this.showMainPage = true
      }else if(this.showFavePage) {
        this.$emit('close');
      }else if(this.showReviewPage) {
        this.$emit('close');
      }else if(this.showChatPage) {
        this.$root.scrollToTop()
        this.showMainPage = true
      }

    },
    notComplete() {
      if(this.job.client_id == this.$root.user_id) {
        this.showDisputePage = true
      }else {
        this.$emit('back');
      }
    },
    complete() {
      if(this.job.client_id == this.$root.user_id) {
        this.clientComplete()
      }else {
        this.merchantComplete()
      }
    },
    async merchantComplete() {
      const $this = this;

      $this.$root.isLoading = true;
      await completeBid(this.job.bid_id).then(() => {
        $this.$root.isLoading = true;

        $this.$notify({
            title: "Bid is Completed",
            type: 'success'
        });
        $this.$root.goTo('activity#complete')
      }).catch(error => {
        console.log(error.response.data)
        $this.$root.isLoading = false

        this.$notify({
            title: "An error occured.",
            text: error.response.data.message,
            type: 'error'
        });
      })

      
    },
    async clientComplete() {
      const $this = this;

      $this.$root.isLoading = true;
      await clientCompleteBid(this.job.bid_id).then(() => {
        $this.$root.isLoading = false;

        // $this.$notify({
        //     title: "Bid is Completed",
        //     type: 'success'
        // });
        // $this.$root.goTo('activity#complete')
        $this.showJobDetails = false;
        $this.showReviewPage = true;

      }).catch(error => {
        console.log(error.response.data)
        $this.$root.isLoading = false

        this.$notify({
            title: "An error occured.",
            text: error.response.data.message,
            type: 'error'
        });
      })
    },
    showFileSelect() {
      $('[name="dispute_files"]').trigger('click');
    },
    updateMultiFileField($event) {
        
      // console.log($event)
      
      this.dispute_form.files.push($event.target.files[0])
      $event.target.value = ''
    },
    removeFileFromMultiField(value) {
      this.dispute_form.files.splice(this.dispute_form.files.indexOf(value), 1)
    },
    async submitDispute() {
      const $this = this

      if(!this.dispute_form.reason) {
        this.$notify({
            title: "An error occured.",
            text: 'Reason is required',
            type: 'error'
        });
        return
      }

      let formData = new FormData();
      formData.append('reason', this.dispute_form.reason);
      formData.append('memo', this.dispute_form.memo);
      formData.append('bid_id', this.job.bid_id);
      Object.keys(this.dispute_form.files).forEach(key => {
        formData.append('files['+key+']', this.dispute_form.files[key])
      });

      $this.$root.isLoading = true;
      await addDispute(formData).then(() => {
        $this.$root.isLoading = false;

        $this.$swal.fire({
            text: "The Ovvy Support Team will review your dispute and inform you once a decision has been made.",
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#f44336',
        }).then((result) => {
            if (result.isConfirmed) {
              $this.showDisputePage = false
              $this.showReviewPage = true
              $this.title = 'REVIEW (Optional)'
              $this.showBack = true
            }
        })
      }).catch(error => {
        console.log(error.response.data)
        $this.$root.isLoading = false

        this.$notify({
            title: "An error occured.",
            text: error.response.data.message,
            type: 'error'
        });
      })
      
    },
    async submitReview() {
      const $this = this

      if(!this.review_form.rating) {
        this.$notify({
            title: "An error occured.",
            text: 'Rating is required',
            type: 'error'
        });
        return
      }

      let formData = new FormData();
      formData.append('job_id', this.job.id);
      formData.append('rating', this.review_form.rating);
      formData.append('review', this.review_form.review);

      $this.$root.isLoading = true;
      await addReview(formData).then(() => {
        $this.$root.isLoading = false;

        if($this.review_form.rating >= 3) {
          $this.showReviewPage = false
          $this.showFavePage = true
        }else {
          $this.showReviewPage = false
          $this.$emit('close');
          $this.$root.goTo('activity#complete')
        }
        
      }).catch(error => {
        console.log(error.response.data)
        $this.$root.isLoading = false

        this.$notify({
            title: "An error occured.",
            text: error.response.data.message,
            type: 'error'
        });
      })
    },
    async addFave() {
      const $this = this
      // console.log(this.$refs.heart)

      this.$refs.heart.classList.remove('fa-heart-o');
      this.$refs.heart.classList.add('fa-heart')

      this.$refs.heart_mini.classList.remove('fa-heart-o');
      this.$refs.heart_mini.classList.add('fa-heart')

      $this.$root.isLoading = true;

      let formData = new FormData();
      formData.append('favourite_id', this.job.bid.vendor_id);

      await addFave(formData).then(() => {
        $this.$root.isLoading = false;

        $this.$emit('close');
        $this.$root.goTo('activity#complete')
        
      }).catch(error => {
        console.log(error.response.data)
        $this.$root.isLoading = false

        this.$notify({
            title: "An error occured.",
            text: error.response.data.message,
            type: 'error'
        });
      })
    },
    goToChat() {
      window.location.href = window.location.origin + '/#/' + 'job/' + this.$root.encrypt((this.job.id).toString()) 
        + '/chat/'+this.$root.encrypt((this.job.bid.vendor_id).toString())+'?_b=complete'
        
      location.reload();
    },
    async sendChat() {
        // console.log('Send')
        if(this.chat.form.message.trim() == '' && !this.chat.form.file) {
          this.$notify({
            title: "The message is required",
            type: 'warning'
          });
          return;
        }
        // const $this = this
        this.$root.isLoading = true;

        let formData = new FormData();
        formData.append('job_id', this.job.id);
        formData.append('vendor_id', this.job.bid.vendor_id);
        formData.append('message', this.chat.form.message);
        if(this.chat.form.file) {
            formData.append('file', this.chat.form.file);
        }

        this.$refs.chatSearch.blur()
        
        await sendClientChat(formData).then(() => {
            // console.log(response.data)
            this.$root.isLoading = false;
            this.chat.form.message = '';
            this.chat.form.file = '';

            this.chat.items = []
            this.chat.page = 0
            this.getMessages();
            setTimeout(function() {
              var objDiv = document.getElementById("completed-job-chat-infinite-list");
              if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);
        }).catch(error => {
            this.$root.isLoading = false;
            this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'An error was encountered. Please try again.',
            confirmButtonText: 'OK',
            }).then(() => {
              location.reload();
            });
            console.log(error)
        })
    },
    async getMessages(showLoading = true) {
        // if(this.no_more) return;

        if(showLoading) this.$root.isLoading = true;
        await getChatMessages({
            job_id: this.job.id,
            vendor_id: this.job.bid.vendor_id,
            page: this.chat.page,
            pageSize: this.chat.pageSize
        }).then(response => {
            // console.log(response.data)
            if(showLoading) this.$root.isLoading = false;
            // console.log(this.items);
            // console.log(response.data.items);
            
            this.chat.items = this.chat.items.concat(response.data.items);
            this.chat.reverse_items = JSON.parse(JSON.stringify(this.chat.items)).reverse();

            this.chat.page += this.chat.pageSize;
            // if(response.data.items.length == 0) this.no_more = true

            if(this.chat.page == this.chat.pageSize) {
              setTimeout(function() {
                var objDiv = document.getElementById("completed-job-chat-infinite-list");
                if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
              }, 500);
            }
        }).catch(error => {
            this.$root.isLoading = false;
            this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'An error was encountered. Please try again.',
            confirmButtonText: 'OK',
            }).then(() => {
                location.reload();
            });
            console.log(error)
        })
    },
    showChatFileSelect() {
      $('[name="chat_file"]').trigger('click');
    },
    updateChatMultiFileField($event) {
      // console.log($event)
      // console.log($event.target.files[0])
      if(['image/jpeg','image/jpg','image/png','video/mp4', 'video/quicktime'].includes($event.target.files[0].type)) {
        this.chat.form.file = $event.target.files[0];
        this.sendChat();
      }else {
        this.$notify({
          title: "An error occured.",
          text: 'Data type not valid',
          type: 'error'
        });
      }
      $event.target.value = ''
    },
    forceGetChat() {
      const $this = this
      const forceChat = setInterval(function() {
        if($this.$root.notif_enabled) clearInterval(forceChat);
        // console.log('force get chat');
        $this.chat.items = []
        $this.chat.page = 0
        $this.getMessages(false);
      }, 15000);
    }
  },
  watch: {
    header: function(newVal) {
      console.log('show header' + newVal)
    },
    job: function(newVal) {
      console.log('show job' + newVal)
    },
    showMainPage: function(newVal) {
      if(newVal) {
        this.title = 'SERVICE COMPLETION'
        this.showJobDetailsPage = !newVal
        this.showDisputePage = !newVal
        this.showReviewPage = !newVal
        this.showChatPage = !newVal
        this.showBack = false
      }
    },
    showJobDetailsPage: function (newVal) {
      if(newVal) {
          this.title = 'JOB DETAIL'
          this.showBack = true
          this.$root.scrollToTop()
          this.showMainPage = !newVal
      }
      
    },
    showDisputePage: function (newVal) {
      if(newVal) {
          this.title = 'OPEN DISPUTE'
          this.showBack = true
          this.$root.scrollToTop()
          this.showMainPage = !newVal
      }
    },
    showReviewPage: function (newVal) {
      if(newVal) {
          this.$root.scrollToTop()
          this.showMainPage = !newVal
      }
      
    },
    showFavePage: function (newVal) {
      if(newVal) {
          this.title = 'FAVORITE'
          this.showBack = true
          this.$root.scrollToTop()
          this.showMainPage = !newVal
      }
      
    },
    showChatPage: function (newVal) {
      if(newVal) {
          this.title = this.job.bid.vendor.full_name
          this.showBack = true
          this.$root.scrollToTop()

          this.getMessages();
          if(!this.$root.notif_enabled) {
            this.forceGetChat()
          }
      }
      this.showMainPage = !newVal
    },
  },
  created() {
    if(this.hide_header) this.showHeader = !this.hide_header
    if(this.hide_job_details) this.showJobDetails = !this.hide_job_details

    if(this.job.review == 1) {
      this.title = 'REVIEW'
      this.showReviewPage = true
    }else {
      this.showMainPage = true
    }

    // this.getModels()
    $('.modal-backdrop.fade.show').remove()
    this.$emit('header', this.title);
  },
  mounted() {
    const $this = this

    this.$OneSignal.on('notificationDisplay', function(data) {
      if(data.data.type == 12 && data.data.data.sender_id != $this.$root.user_id) {
        if(data.data.data.job_id == $this.job.id && data.data.data.vendor_id == $this.job.bid.vendor_id) {
          $this.chat.items = []
          $this.chat.page = 0
          $this.getMessages();
        }
      }
    });
  }
}
</script>
<style scoped>
.circle-avatar {
    width: 100px;
    height: 100px;
    -webkit-border-radius: 100px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 100px;
    -moz-background-clip: padding;
    border-radius: 100px;
    background-clip: padding-box;
    margin: 7px 0 0 5px;
    float: left;
    background-size: cover;
    background-position: center center;
}

.merchant-wrp {
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.general-cleaning .wrp {
  cursor: pointer;
}
</style>