<template>
<div>
<div class="modal" :class="showMe ? 'show' : ''" :style="showMe ? 'display: block;' : ''">
    <div class="modal-lg modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <section v-if="showListPage" class="title">
            <div class="container-fluid">
                <div class="row">
                <div class="col-lg-12 padding-none text-center">
                    <div class="nav-cleaning">
                    <div class="title">
                        <label class="text-white">{{title}}</label>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal-body p-0" >
            <div v-if="showListPage" class="tab-content  p-3">
                <a v-for="(item, l) in items" :key="l" @click.prevent="selectJob(item)" href="#">
                    <div class="wrp">
                      <div class="inner">
                        <div class="icon">
                            <img :src="item.category.link_icon">
                        </div>
                        <div class="text">
                          <h4 class="text-dark">{{item.title}}</h4>
                          <p>
                            {{item.locations[0].location}} <br/> 
                            <span>{{$root.formatDate(item.date_from, 'ddd Do MMM')}}</span>
                            <span v-if="item.date_from != item.date_to"> - {{$root.formatDate(item.date_to, 'ddd Do MMM')}}</span> <br/>
                            <span class="mb-2 time-cont">
                              <span>{{$root.convertTimeToLocal(item.time_from)}}</span>
                              <span v-if="item.time_from != item.time_to"> - {{$root.convertTimeToLocal(item.time_to)}}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="tag-wrp-2">
                        <h4>{{jobPriceText(item)}}</h4>
                        <small>{{jobStatusText(item)}}</small>
                        <div class="tag btn">
                          <h6>{{jobStateText(item)}}</h6>
                        </div>
                      </div> 
                    </div>
                </a>
            </div>
            <div v-else-if="showJobPage">
                <IfJobComplete :job="job" @back="showJobPage = false" @close="showMe = false" @showShare="showShare = true"/>
            </div>
        </div>
      </div>
    </div>
</div>

<div v-if="job.bid" :class="showShare ? 'show modal' : 'modal'" :style="showShare ? 'display: block;' : ''" id="complete-share-profile-modal">
    <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <div class="title">
                    <h6 class="modal-title">Share via</h6>
                </div>
                <div class="close">
                    <button @click="showShare = false" type="button" class="btn-close"></button>
                </div>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <ul id="shareList">
                    <li>
                        <ShareNetwork
                            network="facebook"
                            :url="'https://ovvyapp.com/?vendor_id=' + job.bid.vendor.id"
                            :title="job.bid.vendor.full_name + ': Book my service on Ovvy!'"
                        >
                            Facebook
                        </ShareNetwork>
                    </li>
                    <li>
                        <ShareNetwork
                            network="messenger"
                            :url="'https://ovvyapp.com/?vendor_id=' + job.bid.vendor.id"
                            :title="job.bid.vendor.full_name + ': Book my service on Ovvy!'"
                        >
                            Messenger
                        </ShareNetwork>
                    </li>
                    <li>
                        <ShareNetwork
                            network="sms"
                            :url="'https://ovvyapp.com/?vendor_id=' + job.bid.vendor.id"
                            :title="job.bid.vendor.full_name + ': Book my service on Ovvy!'"
                        >
                            SMS
                        </ShareNetwork>
                    </li>
                    <li>
                        <ShareNetwork
                            network="whatsapp"
                            :url="'https://ovvyapp.com/?vendor_id=' + job.bid.vendor.id"
                            :title="job.bid.vendor.full_name + ': Book my service on Ovvy!'"
                        >
                            WhatsApp
                        </ShareNetwork>
                    </li>
                </ul>
                
            </div>
        </div>
    </div>
</div>
</div>
</template>
  
  <script>
  import IfJobComplete  from '@/components/jobs/if_complete'
  import { getMyPendingCompletedJobs } from "@/api" 
  
  export default {
    name: 'PendingCompletedJobs',
    components: {
        IfJobComplete
    },
    props: {
    },
    data() {
      return {
        showMe: false,
        showListPage: true,
        showJobPage: false,
        showReviewPage: false,
        showDisputePage: false,
        showShare: false,
        loaded: false,
        items: [],
        job: {},
        title: 'SERVICE COMPLETION',
      }
    },
    watch: {
        showMe: function(newVal) {
            if(newVal) this.$root.modalShow()
            else this.$root.modalClose();
        },
        showListPage: function(newVal) {
            if(newVal) {
                this.pageTitle = 'COMPLETE PENDING JOBS'
                this.showJobPage = !newVal
            }
        },
        showJobPage: function (newVal) {
            if(newVal) {
                this.pageTitle = 'SERVICE COMPLETION'
                this.$root.scrollToTop()
            }
            this.showListPage = !newVal
        },
    },
    computed: {
        jobActivityType() {
            return this.job.client_activity_type
        },
    },
    methods: {
        selectJob(job) {
            this.job = job
            this.job.client_activity_type = 'accepted'
            this.showJobPage = true
        },
        async getModels() {
            const $this = this
            this.creditsLoading = true

            await getMyPendingCompletedJobs().then(response => {
                let pending = response.data.pending;

                if(response.data.review) {
                    $this.showMe = true
                    $this.job = response.data.review
                    $this.job.client_activity_type = 'accepted'
                    $this.showJobPage = true
                }else if(pending.length > 0) {
                    $this.showMe = true
                    if(pending.length > 1) {
                        $this.showListPage = true
                        $this.items = pending
                    }else {
                        $this.job = pending[0]
                        $this.job.client_activity_type = 'accepted'
                        $this.showJobPage = true
                    }
                }else {
                    $this.showMe = false
                }
                
            }).catch(error => {
                console.log(error)
            })
        },
        jobStatusText(item) {
            if(item.bid) {
                if(item.bid.type == 'callout') {
                if(Number(item.bid.price) > 0) {
                    if(item.bid.paid_status == 'paid_callout') {
                    return 'Extra Fee';
                    }else if(item.bid.paid_status == 'paid_service_fee'){
                    return 'Total'
                    }
                }else {
                    return 'Service Fee';
                }
                }else {
                return 'Total';
                }
            }
            
            return '';

        },
        jobStateText(item) {
            if(item.bid) {
                if(item.bid.type == 'callout') {
                if(Number(item.bid.price) > 0) {
                    if(item.bid.paid_status == 'paid_callout') {
                    return 'Accept';
                    }else if(item.bid.paid_status == 'paid_service_fee'){
                    return 'Paid'
                    }
                }else {
                    return 'Paid';
                }
                }else {
                return 'Paid';
                }
            }
            
            return '';
            // return  item.client_job_status;
        },
        jobPriceText(item) {
            
            if(item.bid) {
                let price = 0;
                if(item.bid.type == 'callout') {
                price = Number(item.bid.callout_fee);

                if(item.bid.paid_status == 'paid_service_fee') {
                    price += Number(item.bid.price);
                }

                let credit_used = Number(item.bid.callout_credit) + Number(item.bid.service_fee_credit);
                price -= credit_used;
                }
                return '$' + this.$root.insertDecimal(price)
                // if(item.bid.type == 'callout') {
                //   if(Number(item.bid.price) > 0) {
                //     if(item.bid.paid_status == 'paid_callout') {
                //       if(item.type && item.type == 'fixed') {
                //         return '$' + this.$root.insertDecimal(Number(item.bid.price) + Number(item.bid.price) * Number(item.deduction_amount_percent) / 100);
                //       }else if(item.type && item.type == 'bidding') {
                //         return '$' + this.$root.insertDecimal(Number(item.bid.price) + Number(item.bid.price) * Number(item.deduction_amount_percent) / 100);
                //       }else {
                //         return '$' + this.$root.insertDecimal(Number(item.bid.price) * 1.1)
                //       }
                //     }else if(item.bid.paid_status == 'paid_service_fee'){
                //       return '$' + this.$root.insertDecimal(Number(item.bid.callout_fee) + Number(item.bid.price))
                //     }
                //   }else {
                //     return '$' + this.$root.insertDecimal(item.bid.payment_amount)
                //   }
                // }else {
                //   return '$' + this.$root.insertDecimal(item.bid.payment_amount)
                // }
            }
            return '';
            // return '$' + this.$root.insertDecimal(item.fixed_price)
        }
    },
    created() {
        const $this = this;
        if(this.$root.isLoggedIn) this.getModels()

        if(!this.$root.notif_enabled) {
            const forGet = setInterval(function() {
                if($this.$root.notif_enabled || $this.$root.ios_one_signal_set) clearInterval(forGet);
                $this.getModels();
            }, 60000);
        }
    }
  }
  </script>