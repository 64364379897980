import ENDPOINT from "@/api/endpoints";
import { HTTP } from "@/api/http-common";
import { lsWatcher as ls } from "../main";

export function getUserCredits(type) {
  const id = typeof ls.getItem('id').value == 'object' ? ls.getItem('id').value.value : ls.getItem('id').value,
    token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value
  return HTTP({
    url: ENDPOINT.USERS + '/' + id + '/credits/' + type,
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//Rewards
export function getRewardRules(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.REWARDS + '/rules';
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

//Redeems
export function getRedeems(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.REDEEMS;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function redeemPoints(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  return HTTP({
    url: ENDPOINT.REDEEMS + '/convert',
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export function redeemReferral(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  return HTTP({
    url: ENDPOINT.REDEEMS + '/referral',
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export function redeemCredit(payload) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  return HTTP({
    url: ENDPOINT.REDEEMS + '/credit',
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export function getCashOutHistory(params = false) {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value;

  let url = ENDPOINT.CASH_OUTS;
  if(params) {
    url += '?' + new URLSearchParams(params).toString()
  }
  return HTTP({
    url: url,
    method: "GET",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}

export function withdrawCashout() {
  const token = typeof ls.getItem('token').value == 'object' ? ls.getItem('token').value.value : ls.getItem('token').value

  return HTTP({
    url: ENDPOINT.CASH_OUTS + '/withdraw',
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    }
  })
}
