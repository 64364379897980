<template>
  <TheHeader :title="title" @back="backBtn()"/>
  <div class="main-container">
  <div v-if="$root.isLoggedIn && $root.phone_verified == 0" class="div-resposnsive">
    <section class="header-inner">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
              <div class="title">
                <label class="text-white">PHONE VERIFY</label>
              </div>
              <!-- <div class="back">
                <a @click.prevent="backBtn()" href="#"  class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
              </div> -->
            </div>
            </div>
          </div>
        </div>
    </section>
    <div class="main-content-wrp">
      <OtpVerify :hide_header="true"/>
    </div>
  </div>
  <div v-else-if="showJobPosted" class="div-resposnsive">
    <section class="header-inner">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 padding-none text-center">
            <div class="nav-cleaning">
              <div class="title">
                <label class="text-white">POSTED</label>
              </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    <div class="main-content-wrp">
      <section class="confirmation-title">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="wrp">
                <div class="icon">
                  <img :src="category.link_icon">
                </div>
                <div class="text">
                    <h4>{{form.type_of_service}}</h4>
                    <small>{{$root.getFullAddress(form.location.from)}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="promo-details">
                <img src="~@/assets/images/confirm-order.png">
                <h4>Your service request has <br>been posted</h4>
                <!-- <h6>Please wait while we obtain offers</h6> -->
                <router-link to="/activity">OK</router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div v-else>
    <div class="div-resposnsive">
      <section class="header-inner">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 padding-none text-center">
              <div class="nav-cleaning">
                <div class="title">
                  <label class="text-white">{{title}}</label>
                </div>
                <div class="back">
                  <a @click.prevent="backBtn()" href="#"  class="btn"><i class="fa fa-long-arrow-left text-white"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 

      <div class="main-content-wrp" :class="total > 0 ? 'has-total-wrp' : ''">  
        <section v-for="(screen, key) in form.screens" :key="key" :class="'form_screens screen_' + key" :ref="'screen_' + key">
          <div v-for="(question, qKey) in screen" :key="question.id" :id="'question_' + question.id"
            class="container-fluid question"
            :class="Object.keys(errors).includes((question.id).toString()) ? 'has_error question_' + question.id : 'question_' + question.id " >
            <div v-if="question.type == 'type_of_services'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}}</h6>
              </div>
              <div class="col-lg-12">
                <div v-for="option in question.type_of_services" :key="option.id" class="check-box">
                  <label class="form-check-label" :for="'tos_' + option.id">{{option.name}}</label>
                  <input type="radio" class="form-check-input" :name="'question['+question.id+']'" 
                    :id="'tos_' + option.id"
                    v-model="question.value" :value="option" @change="getTypeOfService(option)">
                  <!-- <input type="radio" class="form-check-input" :name="'question['+question.id+']'" v-model="form.type_of_service" :value="option.name"> -->
                </div>
              </div>
            </div>
            <div v-else-if="question.type == 'yes-no'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}} <small v-show="question.is_required == 0"><em>Optional</em></small></h6>
              </div>
              <div class="col-lg-12">
                <div class="yes-no-toolbar">
                  <input type="radio" :id="'questionYes-' + question.id" :name="'question['+question.id+']'" v-model="question.value" value="yes" :checked="question.value == 'yes'">
                  <label :for="'questionYes-' + question.id">Yes</label>
                  <input type="radio" :id="'questionNo-' + question.id" :name="'question['+question.id+']'" v-model="question.value" value="no" :checked="question.value == 'no'">
                  <label :for="'questionNo-' + question.id">No</label>
                </div>
              </div>
            </div>
            <div v-else-if="question.type == 'date_description'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}} <small v-show="question.is_required == 0"><em>Optional</em></small></h6>
              </div>
              <div class="col-lg-12">
                <!-- <DateRangePicker :date_name="'question['+question.id+']'" date_from="2021-10-05"/> -->
              </div>
            </div>
            <div v-else-if="question.type == 'date'" class="row">
              <div class="col-lg-12 question-cont" style="height: 85px;">
                <h6>
                  {{question.question_text}}
                  <br/>
                  <small v-if="form.time.length > 0" style="color: #f84c45">[Note: This post will expire on {{ $root.formatDate(form.date.to, 'ddd Do MMM YYYY') }} at {{ $root.convertTimeTo12(form.time[form.time.length - 1]['to']) }} if no bid is accepted.]</small>
                  <small v-else style="color: #f84c45">[Note: This post will expire on {{ $root.formatDate(form.date.to, 'ddd Do MMM YYYY') }} if no bid is accepted.]</small>
                </h6>
              </div>
              <div class="col-lg-12">
                
                <div id="displayDate" class="row">
                  <div class="col pt-2">
                    <Datepicker v-model="form.date.range[0]" :enableTimePicker="false" 
                      @update:modelValue="updateDate('from')" :clearable="false" 
                      :minDate="new Date()"
                      :maxDate="new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate() + 1)"
                      autoApply hideInputIcon>
                      <template #trigger>
                        <a @click.prevent="" href="#">
                          <span>From</span>
                          <h5 class="mt-2">{{$root.formatDate(form.date.from, 'ddd Do MMM')}}</h5>
                        </a>
                      </template>
                    </Datepicker>
                  </div>
                  <div class="col pt-2">
                    <Datepicker v-model="form.date.range[1]" :enableTimePicker="false" 
                      @update:modelValue="updateDate('to')" :clearable="false" 
                      :minDate="new Date()"
                      :maxDate="new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate() + 1)"
                      autoApply hideInputIcon>
                      <template #trigger>
                        <a @click.prevent="" href="#">
                          <span>To</span>
                          <h5 class="mt-2">{{$root.formatDate(form.date.to, 'ddd Do MMM')}}</h5>
                        </a>
                      </template>
                    </Datepicker>
                  </div>
                </div>
                <!-- <DateRangePicker :date_from="form.date.from" :date_to="form.date.to" 
                  :date_name="'question['+question.id+']'" v-model="question.value"
                  @save="data => form.date = data "/> -->
              </div>
            </div>
            <div v-else-if="question.type == 'time'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}} <small v-show="question.is_required == 0"><em>Optional</em></small></h6>
              </div>
              <div class="col-lg-12">
                <TimePicker :name="'question['+question.id+'][]'" 
                  :field_type="question.time_type == 'single_time_slot' ? 'single' : 'multiple'" 
                  :date="form.date.to" 
                  v-model="question.value"
                  :data="form.time"
                  @save="data => form.time = data"/>
              </div>
              <div v-show="category.type == 'bidding'" class="col-lg-12 question-cont">
                <!-- <h6 style="color: #000 !important;">Allow Merchants to counter propose time</h6> -->
                <h6 style="color: #000 !important;">Allow Merchants to counter-propose within a window of plus or minus 3 hours from the time you've selected above</h6>
              </div>
              <div v-show="category.type == 'bidding'" class="col-lg-12">
                <div class="yes-no-toolbar">
                  <input type="radio" :id="'allow_counter_proposal_yes'" :name="'allow_counter_proposal'" v-model="form.allow_counter_proposal" :value="1" :checked="form.allow_counter_proposal == 1">
                  <label :for="'allow_counter_proposal_yes'">Yes</label>
                  <input type="radio" :id="'allow_counter_proposal_no'" :name="'allow_counter_proposal'" v-model="form.allow_counter_proposal" :value="0" :checked="form.allow_counter_proposal != 1">
                  <label :for="'allow_counter_proposal_no'">No</label>
                </div>
              </div>
            </div>
            <div v-else-if="question.type == 'image_video'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}} <small v-show="question.is_required == 0"><em>Optional</em></small></h6>
              </div>
              <div class="col-lg-12" style="background: #ECECEC;">
                <input @change="updateMultiFileField($event, question, qKey)"
                    accept="image/jpeg,image/jpg,image/png,video/mp4,video/quicktime" :name="'question-'+question.id"
                    style="display:none"
                    type="file"
                />
                <div class="d-flex" style="flex-wrap: wrap; row-gap: 10px;column-gap: 10px;align-items:center">
                  <div v-for="(file, fKey) in question.value" :key="fKey" class="img-wrp img-upload-cont">
                    <video v-if="file.type == 'video/mp4' || file.type == 'video/quicktime' || file.mimetype == 'video/mp4' || file.mimetype == 'video/quicktime'" width="125" height="108">
                      <source :src ="$root.previewImage(file)" id="video_here">
                        Your browser does not support HTML5 video.
                    </video>
                    <img v-else-if="file.id" :src ="file.full_url"/>
                    <img v-else :src ="$root.previewImage(file)">
                    <a @click.prevent="removeFileFromMultiField(file, question, qKey)" href="#"
                      title="Remove file" class="img-upload-remove">x</a>
                  </div>
                  <a @click.prevent="showFileSelect(question)" href="#" class="photo-upload-cont">
                  <div class="img-wrp">
                    <img src ="~@/assets/images/icamera.png">
                  </div>
                  </a>
      
                  <h6 v-if="question.value.length == 0" class="gal-upload-info"><small><em>Providing photo of the issue will facilitate better assignment of a suitable merchant.</em></small></h6>
                </div>
                <br/>
              </div>
            </div>
            <div v-else-if="question.type == 'product_single_choice'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>
                  {{question.question_text}} 
                  <small v-show="question.is_required == 0">
                    <em>Optional</em>
                  </small>
                </h6>

                <h6 v-show="question.is_required == 0 && question.value != ''">
                  <button @click="clearOptional(question)" type="button" class="btn btn-red">X</button>
                </h6>
              </div>
              <div v-if="question.show_counter_on_fe == 0" class="col-lg-12">
                <div v-for="product in question.products" :key="product.id" class="check-wrp">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="check-box">
                        <label class="form-check-label" :for="'psc_' + question.id + '_' + product.id">{{product.name}}</label>
                        <div class="d-flex">
                          <h6 v-if="product.price > 0">${{insertDecimal(product.price)}}</h6>
                          <input @change="computeTotal()" :name="'question['+question.id+']'" :value="product.id" 
                            :id="'psc_' + question.id + '_' + product.id"
                            v-model="question.value" type="radio" class="form-check-input" >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="product.description != ''" class="row recommednded">
                    <div class="col-lg-12">
                      <small v-html="this.renderLineBreak(product.description)"></small>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="col-lg-12">
                <div v-for="product in question.products" :key="product.id" class="options">
                  <div class="option-title">
                    <h6>{{product.name}}</h6>
                  </div>
                  <div class="d-flex">
                    <NumberPicker :question="question" :data="product" :min="1" @save="(data) => product.value = data"/>
                    <input v-show="question.products.length > 1" @change="computeTotal()" :name="'question['+question.id+']'"
                      :value="product.id" v-model="question.value" type="radio" class="form-check-input" style="margin-left: 5px" 
                      :checked="question.products.length == 1" />
                  </div>
                </div>
              </div>
              <div v-if="question.has_other_answer == 1" class="check-wrp">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="check-box">
                      <label class="form-check-label" :for="'psco_' + question.id + '_other'">Other</label>
                      <div class="d-flex">
                        <input @change="computeTotal()" :name="'question['+question.id+']'" :id="'psco_' + question.id + '_other'"
                          v-model="question.value"  value="other" type="radio" class="form-check-input" >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12" :class="'other-text-box-cont-' + question.id" :style="question.value == 'other' ? '' : 'display:none'">
                    <hr class="my-1"/>
                    <small>Please Specify</small>
                    <textarea class="form-control mt-2" rows="2" :name="'question['+question.id+'][other]'" v-model="question.other_value"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="question.type == 'product_multi_choice'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}} <small v-show="question.is_required == 0"><em>Optional</em></small></h6>
              </div>
              <div v-if="question.show_counter_on_fe == 0" class="col-lg-12">
                <div v-for="product in question.products" :key="product.id" class="check-wrp">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="check-box">
                        <label class="form-check-label" :for="'pmc_' + question.id + '_' + product.id">{{product.name}}</label>
                        <div class="d-flex">
                          <h6 v-show="product.price > 0">${{insertDecimal(product.price)}}</h6>
                          <input @change="computeTotal()" :name="'question['+question.id+'][]'" :value="product.id" 
                            :id="'pmc_' + question.id + '_' + product.id"
                            v-model="question.value" type="checkbox" class="form-check-input" >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="product.description != ''" class="row recommednded">
                    <div class="col-lg-12">
                      <small v-html="this.renderLineBreak(product.description)"></small>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="col-lg-12">
                <div v-for="product in question.products" :key="product.id" class="options">
                  <div class="option-title">
                    <h6>{{product.name}}</h6>
                  </div>
                  <NumberPicker :question="question" :data="product" @save="(data) => product.value = data"/>
                </div>
              </div>
              <div v-if="question.has_other_answer == 1" class="check-wrp">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="check-box pt-4">
                        <label class="form-check-label" :for="'pmc_' + question.id + '_other'">Other</label>
                        <div class="d-flex">
                          <input @change="computeTotal()" :name="'question['+question.id+'][]'" value="other" 
                            :id="'pmc_' + question.id + '_other'"
                            v-model="question.value" type="checkbox" class="form-check-input" >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12" :class="'other-text-box-cont-' + question.id" :style="question.value.includes('other') ? '' : 'display:none'">
                      <hr class="my-1"/>
                      <small>Please Specify</small>
                      <textarea class="form-control mt-2" rows="2" :name="'question['+question.id+'][other]'" v-model="question.other_value" ></textarea>
                    </div>
                  </div>
              </div>
            </div>
            <div v-else-if="question.type == 'instruction'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.label}}</h6>
              </div>
              <div class="row term-wrp pt-3">
                <div class="col-lg-12 instruction-container" v-html="question.question_text"></div>
              </div>
            </div>
            <div v-else-if="question.type == 'text-field'" class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}}  <small v-show="question.is_required == 0"><em>Optional</em></small></h6>
              </div>
              <div class="col-lg-12">
                <textarea class="form-control mt-2" rows="5" :name="'question['+question.id+']'" v-model="question.value"></textarea>
              </div>
            </div>
            <div v-else-if="question.type == 'location_from' " class="row">
              <div class="col-lg-12 question-cont">
                <h6>{{question.question_text}}  <small v-show="question.is_required == 0"><em>Optional</em></small></h6>
              </div>
              <div class="col-lg-12">
                <section class="address-buttons">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="address-buttons-wrp" style="flex-wrap: wrap; gap: 5px;">
                          <div v-for="(uLoc, l) in user_locations" :key="l"  class="address-btn-cont">
                            <input type="radio" 
                              :name="'question['+question.id+']'" 
                              :id="'q'+question.id+'add'+uLoc.id" 
                              :value="uLoc.id"
                              :checked="!isEdit ? form.location.from.id == uLoc.id : form.location.from.location == uLoc.location">
                            <label @click="changeUserLocation($event, uLoc)" :for="'q'+question.id+'add'+uLoc.id" >{{uLoc.name}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section @click.prevent="showFromLocationPicker = true"  class="cleaning-type mt-3">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-12" style="text-align:left">
                        <a @click.prevent="showFromLocationPicker = true" href="#" class="address-details">
                          <i class="fa fa-compass" aria-hidden="true" style="margin-right: 10px;"></i>
                          {{$root.getFullAddress(form.location.from) == '' ? question.question_text : $root.getFullAddress(form.location.from)}}
                        </a>
                      </div>
                    </div>
                    </div>
                    </section>
              </div>
            </div>
            <div v-else-if="question.type == 'location_to' && question.show_on_fe == 1" class="row">
              <div class="col-lg-12">
                <section @click.prevent="showToLocationPicker = true"  class="cleaning-type mt-3">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-11" style="text-align:left">
                        <a @click.prevent="showToLocationPicker = true" href="#" class="address-details">
                          <i class="fa fa-map-marker" aria-hidden="true" style="margin-right: 10px;"></i> 
                          {{$root.getFullAddress(form.location.to).trim() == '' ? question.question_text : $root.getFullAddress(form.location.to)}}
                        </a>
                      </div>
                    </div>
                    </div>
                    </section>
              </div>
            </div>
          </div>
        </section>

        <section :class="'form_screens summary screen_' + (category.screens ? category.screens.length : '3')">
          <div class="summary-header general-cleaning container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="wrp">
                  <h4>{{form.type_of_service == '' ? category.name : form.type_of_service}}</h4>
                  <img class="cat-icon" :src="category.link_icon" >
                </div>
              </div>
            </div>
          </div> 
          <div class="summary-question-cont">
          <div v-for="(sum, s) in summary" :key="s">
            <!-- Title -->
            <div v-if="sum.question.is_required == 1 || (sum.question.is_required == 0 && sum.has_answer) || (sum.question.type == 'text-field' && sum.answer != '')" class="row-gray">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <h6 v-if="sum.question.type != 'instruction'">{{sum.question.question_text}}</h6>
                    <h6 v-else>{{sum.question.label}}</h6>
                  </div>
                </div>
              </div>
            </div>
      
            <div v-if="sum.question.type == 'product_single_choice'" class="row-white">
              <div class="container-fluid">
                <div v-if="sum.answer.id == 'other'" class="row">
                    <div class="col-lg-12">
                      <div class="check-box pt-0">
                        <label class="form-check-label">Other</label>
                        <div class="d-flex">
                          <!-- None -->
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <small>{{sum.answer.value}}</small>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-lg-12">
                      <div class="check-box pt-0">
                        <label class="form-check-label">{{sum.answer.name}}</label>
                        <div class="d-flex">
                          <h6 v-if="sum.answer.price > 0">${{insertDecimal(sum.answer.price)}}</h6>
                          <h6 v-if="sum.answer.value > 0">x{{sum.answer.value}}</h6>
                        </div>
                      </div>
                    </div>
                    <div v-if="sum.answer.description && sum.answer.description != ''" class="col-lg-12">
                      <small v-html="this.renderLineBreak(sum.answer.description)"></small>
                    </div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'yes-no'" class="row-white">
              <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                      <div class="check-box pt-0">
                        <label class="form-check-label">{{sum.answer == 'yes' ? 'Yes' : 'No'}}</label>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'allow_counter_proposal'" class="row-white">
              <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                      <div class="check-box pt-0">
                        <label class="form-check-label">{{sum.answer}}</label>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'product_multi_choice'" v-for="(answer, a) in sum.answer" :key="a" class="row-white">
              <div class="container-fluid">
                <div v-if="answer.id == 'other'" class="row">
                    <div class="col-lg-12">
                      <div>
                        <label class="form-check-label">Other</label>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <small>{{answer.value}}</small>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-lg-12">
                      <div v-if="sum.question.show_counter_on_fe == 0" class="check-box pt-0">
                        <label class="form-check-label">{{answer.name}}</label>
                        <div class="d-flex">
                          <h6 v-if="answer.price > 0">${{insertDecimal(answer.price)}}</h6>
                        </div>
                      </div>
                      <div v-else class="d-flex justify-content-between">
                        <h6>{{answer.name}}</h6>
                        <h6>x{{answer.value}}</h6>
                      </div>
                    </div>
                    <div v-if="answer.description && answer.description != ''" class="col-lg-12">
                      <small v-html="this.renderLineBreak(answer.description)"></small>
                    </div>
                    <div v-show="(sum.answer.length - 1) > a" class="col-lg-12">
                      <hr class="my-1"/>
                    </div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'text-field' && sum.answer != ''" class="row-white">
              <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                      <div class="check-box pt-0">
                        <label class="form-check-label">{{sum.answer}}</label>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'instruction' " class="row-white">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12 instruction-container" style="font-size:80%" v-html="sum.question.question_text"></div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'date'" class="row-white">
              <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                      <div class="check-box pt-0">
                        <label class="form-check-label">{{sum.answer.display_from}} {{sum.answer.from != sum.answer.to ? '- ' + sum.answer.display_to : ''}}</label>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'time'" class="row-white">
              <div class="container-fluid">
                <div class="d-flex">
                    <div v-for="(answer, a) in sum.answer" :key="a" class="mb-2 time-cont">
                      <span class="">{{answer}}</span>
                    </div>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'location_from'" class="row-white">
              <div class="container-fluid">
                <div class="d-flex">
                  <h6>{{$root.getFullAddress(form.location.from)}}</h6>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'location_to'" class="row-white">
              <div class="container-fluid">
                <div class="d-flex">
                  <h6>{{$root.getFullAddress(form.location.to)}}</h6>
                </div>
              </div>
            </div>
            <div v-else-if="sum.question.type == 'image_video'" class="row-white">
              <div class="container-fluid">
                <div class="d-flex" style="flex-wrap: wrap;gap: 5px;">
                  <div v-for="(file, fKey) in sum.answer" :key="fKey" class="img-wrp img-upload-cont">
                    <video v-if="file.type == 'video/mp4' || file.type == 'video/quicktime' || file.mimetype == 'video/mp4' || file.mimetype == 'video/quicktime'" width="125" height="108">
                      <source :src ="$root.previewImage(file)" id="video_here">
                        Your browser does not support HTML5 video.
                    </video>
                    <img v-else-if="file.id" :src ="file.full_url"/>
                    <img v-else :src ="$root.previewImage(file)">
                  </div>
                </div>
              </div>
            </div>
            
          </div>
            <section v-if="$root.isLoggedIn" class="row-white mt-2 posted-by">
              <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                      <div class="wrp flex-row">
                      
                        <img v-if="$root.user_avatar != '' && $root.user_avatar !== null" :src="$root.user_avatar"/>
                        <img v-else src="~@/assets/images/posted.png"/>
                        <div>
                            <p>Posted By</p>
                            <h6>{{$root.user_full_name}}</h6>
                        </div>
                      </div>    
                    </div>
                </div>
              </div>
            </section>
          </div>
          <div v-if="total > 0" class="row-white hide_tablet">
            <div class="container-fluid">
              <div class="row">
                  <div class="col-lg-12">
                    <div class="d-flex justify-content-between mt-2">
                        <h4>Total</h4>
                        <h4>${{insertDecimal(total)}}</h4>
                    </div>
                    <div class="note">
                      <p class="text-danger">** Please note that payment will be charged to the card selected when merchant accepts job**</p>
                    </div>
                    <div class="payment">
                      <p>By proceeding, you accept the latest version of <a href="#" data-bs-toggle="modal" data-bs-target="#clients-cancellation-policy"> Cancellation Policy</a></p>
                    </div>
                    <div class="payment-methods d-flex justify-content-between">
                      <div class="select-option">
                        <div class="dropdown  card-dropdown">
                          <button @click="paymentMethodModalShow=true" class="btn dropdown-toggle" type="button">
                            <img v-if="form.payment.card && form.payment.card.brand == 'visa'" src="~@/assets/images/visa.png" style="max-width: 50px;"/> 
                            <img v-else-if="form.payment.card && form.payment.card.brand == 'mastercard'" src="~@/assets/images/mastercard.png" style="max-width: 50px;"/>
                            <i v-else-if="form.payment.card" class="fa fa-credit-card-alt" aria-hidden="true" style="font-size: 20px;"></i>
                            <i v-else class="fa fa-credit-card" aria-hidden="true" style="font-size: 20px;"></i>
                            {{form.payment.card ? form.payment.card.last4 : 'Payment'}}
                          </button>
                        </div>
                      </div>
                      <div class="gray-divider-vertical">
                      </div>
                      <div class="credits">
                        <button @click="openCreditsModal" class="btn" data-bs-toggle="modal" >{{form.credits_used > 0 ? 'Credits used $' + insertDecimal(form.credits_used) : 'Use Credits'}}</button>
                      </div>
                    </div>
                    <div class="apply-offers">
                      <a @click.prevent="clickApplyOffer" type="button" class="btn" :class="form.promo.id ? 'applied' : ''">{{ form.promo.id ? 'Offer Applied' : 'Apply Offers' }}</a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div v-if="total > 0" class="space-maker summary_space show_tablet"></div>
        </section>

        <section v-if="total > 0 && screen != screenCount" class="total hide_tablet">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="price">
                  <h4>Total</h4>
                  <h4>${{insertDecimal(total)}}</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> 
    </div>
    <section class="footer-button" >
      <div class="container">
        <div class="row">
          <div v-if="total > 0 && screen == screenCount" class="col-lg-12 show_tablet">
            <div class="d-flex justify-content-between mt-2">
                <h4>Total</h4>
                <h4>${{insertDecimal(total)}}</h4>
            </div>
            <div class="note">
              <p class="text-danger">** Please note that payment will be charged to the card selected when merchant accepts job**</p>
            </div>
            <div class="payment">
              <p>By proceeding, you accept the latest version of <a href="#" data-bs-toggle="modal" data-bs-target="#clients-cancellation-policy"> Cancellation Policy</a></p>
            </div>
            <div class="payment-methods d-flex justify-content-between">
              <div class="select-option">
                <div class="dropdown  card-dropdown">
                  <button @click="paymentMethodModalShow=true" class="btn dropdown-toggle" type="button">
                    <img v-if="form.payment.card && form.payment.card.brand == 'visa'" src="~@/assets/images/visa.png" style="max-width: 50px;"/> 
                    <img v-else-if="form.payment.card && form.payment.card.brand == 'mastercard'" src="~@/assets/images/mastercard.png" style="max-width: 50px;"/>
                    <i v-else-if="form.payment.card" class="fa fa-credit-card-alt" aria-hidden="true" style="font-size: 20px;"></i>
                    <i v-else class="fa fa-credit-card" aria-hidden="true" style="font-size: 20px;"></i>
                    {{form.payment.card ? form.payment.card.last4 : 'Payment'}}
                  </button>
                </div>
              </div>
              <div class="gray-divider-vertical">
              </div>
              <div class="credits">
                <button @click="openCreditsModal" class="btn" data-bs-toggle="modal" >{{form.credits_used > 0 ? 'Credits used $' + insertDecimal(form.credits_used) : 'Use Credits'}}</button>
              </div>
            </div>
            <div class="apply-offers">
              <a @click.prevent="clickApplyOffer" type="button" class="btn" :class="form.promo.id ? 'applied' : ''">{{ form.promo.id ? 'Offer Applied' : 'Apply Offers' }}</a>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <div v-if="total > 0 && screen != screenCount" class="fixed_footer_details">
              <h4>Total</h4>
              <h4 class="total-price">${{insertDecimal(total)}}</h4>
            </div>
            <div v-if="showError" class="text-center category-error">
              {{errors[Object.keys(errors)[0]]}}
            </div>
            <a v-if="screen == screenCount" @click.prevent="submitForm()" href="#" class="btn next-btn">{{total > 0 ? 'CONFIRM $' + insertDecimal(final_total) : 'CONFIRM'}}</a>
            <a v-else @click.prevent="submitForm()" href="#" class="btn next-btn">{{button}}</a>
          </div>
        </div>
      </div>
    </section> 
    <!-- <div class="space-maker"></div>    -->
  </div>
  </div>
  <div class="modal" :class="creditsModalShow ? 'show' : ''" :style="creditsModalShow ? 'display: block;' : ''">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <div class="title">
            <h6 class="modal-title"><strong>Use Credits</strong></h6>
          </div>
          <div class="close">
            <button @click="creditsModalShow=false" type="button" class="btn-close"></button>
          </div>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <h6>
            Your credits <br/>
            <strong>${{insertDecimal(credit.credits)}}</strong>
          </h6>
          <p class="amonut-title"><strong>Enter the amount to use</strong></p>
          <input v-model="credit.field" type="number" class="amount-field hide_number_arrow" />
          <em v-if="credit.error != ''" class="text-danger text-center" style="justify-content: center;display: flex;">{{credit.error}}</em>
          <p class="mt-4">Service fee charge <span>${{insertDecimal(total)}}</span></p>
          <p>Promo: {{ promo_discount > 0 ? '-' : '' }}<span>${{insertDecimal(promo_discount)}}</span> </p>
          <p>Credits Used: {{ form.credits_used > 0 ? '-' : '' }}<span>${{insertDecimal(form.credits_used)}}</span> </p>
          <p class="amonut-title"><strong>Total Due</strong></p>
          <h2 class="text-danger">$ {{insertDecimal(final_total)}}</h2>
          <button @click="verifyCredits" type="button" class="btn save apply-promo-btn">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="paymentMethodModalShow ? 'show' : ''" :style="paymentMethodModalShow ? 'display: block;' : ''">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <PaymentMethods :visible="true" @data="setDefaultPayment" @close="paymentMethodModalShow=false"/>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="showApplyOffer ? 'show' : ''" :style="showApplyOffer ? 'display: block;' : ''">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <PromoVerifier :visible="showApplyOffer" :data="promoData" @close="showApplyOffer=false" @apply="(data) => verifyPromo(data)"/>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="showFromLocationPicker ? 'show' : ''" :style="showFromLocationPicker ? 'display: block;' : ''">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <LocationPicker :data="form.location.from" :visible="showFromLocationPicker" 
            @data="setUserLocations"
            @save="(data) => form.location.from = data" @close="showFromLocationPicker=false"/>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="showToLocationPicker ? 'show' : ''" :style="showToLocationPicker ? 'display: block;' : ''">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <LocationPicker :data="form.location.to" :visible="showToLocationPicker" 
            @save="(data) => form.location.to = data" @close="showToLocationPicker=false"/>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal" id="extra-fee">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body text-center">
        <b> Your money will not be released untill the job has been confirmed completed.</b>
        <div class="buttons">
          <button type="button" class="btn text-info p-0" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn text-info border-0 p-0" data-bs-dismiss="modal">Ok</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  
  <TheFooter :hide_mobile="true"/>
</template>

<script>
import TheHeader from '@/components/common/TheHeader'
import TheFooter from '@/components/common/TheFooter'
import NumberPicker from '@/components/NumberPicker'
// import DateRangePicker from '@/components/DateRangePicker'
import TimePicker from '@/components/TimePicker'
import OtpVerify from '@/components/auth/OtpVerify'
import LocationPicker from '@/components/LocationPicker'
import PaymentMethods from '@/components/PaymentMethods'
import PromoVerifier from '@/components/PromoVerifier'
import { getCategoryQuestions, submitJob, getJob, updateJob, getMediaFiles } from "@/api"
import { getUserCredits } from "@/api/credits"
import { ssWatcher as ssWatcher } from "../../main";
import moment from 'moment'
import $ from "jquery";

export default {
  name: 'JobFormView',
  components: {
    TheHeader,
    TheFooter,
    // DateRangePicker,
    NumberPicker,
    TimePicker,
    LocationPicker,
    PaymentMethods,
    PromoVerifier,
    OtpVerify
  },
  data() {
    return {
      isEdit: false,
      timezone: 'Asia/Singapore',
      title: '',
      button: 'NEXT',
      id: '',
      showError: false,
      hasErrors: false,
      errors:{},
      category: {},
      screen: 0,
      screenCount: 3,
      form: {
        type_of_service: '',
        typeofservice_id: '',
        screens: [],
        questions:[],
        date: {
          range: [new Date(moment()), new Date(moment())],
          from: moment().format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD')
        },
        time: [],
        location: {
          from_question: {},
          from:{
            id: '',
            floor: '',
            building: '',
            street: '',
            street_number: '',
            postal_code: '',
            country: '',
            location: '',
            latitude: '',
            longitude: '',
            name: '',
          },
          to_question: {},
          to: {
            id: '',
            floor: '',
            building: '',
            street: '',
            street_number: '',
            postal_code: '',
            country: '',
            location: '',
            latitude: '',
            longitude: '',
            name: '',
          }
        },
        payment: {},
        promo: {},
        credits_used: 0,
        vendor_id: '',
        allow_counter_proposal: 1
      },
      total: 0,
      summary: [],
      showFromLocationPicker: false,
      showToLocationPicker: false,
      showApplyOffer: false,
      showJobPosted:false,
      user_locations: [],
      payments_methods: [],
      creditsModalShow: false,
      credit: {
        credits: 0,
        points: 0,
        field: 0,
        error: ''
      },
      paymentMethodModalShow: false,
      job: {}
    }
  },
  watch: {
    'form.question.*': function (newVal) {
      console.log(newVal)
      // this.formData = newVal
      // if (!this.formData.otp) {
      //   Object.keys(this.otp).forEach(item => {this.otp[item] = null})
      // }
    },
    'form.type_of_service': function (newVal) {
      console.log('new tye')
      console.log(newVal)
      this.updateQuestionDisplay()
    },
    'form.promo': function (newVal) {
      // console.log('update promo')
      console.log(newVal)
    },
    'form.date.range[0]': function(newVal) {
      console.log('new date from' + newVal)
    },
    'form.allow_counter_proposal': function(newVal) {
      if(this.category.type == 'bidding' && !newVal){
        this.$swal.fire({
            html:
            "<h5> Choosing 'No' will narrow down the pool of suitable merchants available for selection</h5>" +
            'Do you wish to proceed?',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#FE4D45',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
              //do nothing
            }else {
              const $this = this
              setTimeout(function() {
                document.querySelector('[for="allow_counter_proposal_no"]').blur()
                document.querySelector('#allow_counter_proposal_no').blur()
              }, 300)

              setTimeout(function() {
                $this.form.allow_counter_proposal = 1;
              }, 500)
              
              // document.querySelector('#allow_counter_proposal_yes').focus();
              // document.querySelector('[for="allow_counter_proposal_yes"]').focus()
            }
        })
      }
    },
    screen: function(newVal) {
      // console.log('screen' + newVal)
      let screenCount = this.screenCount;
      
      this.title = this.isEdit ? 'JOB ID: ' + this.job.id : this.category.name;
      if(screenCount == newVal) {
        this.title = this.isEdit ? 'JOB ID: ' + this.job.id + ' - SUMMARY' : 'SUMMARY';
      
        if(this.total > 0) {
          this.button = 'CONFIRM $' + this.insertDecimal(this.final_total)
        }else {
          this.button = 'CONFIRM'
        }
        this.generateSummary()
      }else if(screenCount - 1 == newVal) {
        if(this.$root.isLoggedIn) {
          this.button = 'REVIEW DETAILS';
        }else {
          this.button = 'PROCEED';
        }
      }else {
        this.button = 'NEXT'
      }
      const $this = this
      setTimeout(function() {
        $this.$root.manualAdjustScreen();
      }, 100)

      // this.$gtag.screenview({
      //   app_name: 'Create Job',
      //   screen_name: this.button
      // })

      //Track Event
      if(newVal == this.screenCount) {
        this.$gtag.event('job_summary', {
          'category': this.id,
          'campaignid': this.$root.user_campaign_id
        });
      }else if(screenCount - 1 == newVal) {
        this.$gtag.event('job_date_location_setup', {
          'category': this.id,
          'campaignid': this.$root.user_campaign_id
        });
      }else {
        this.$gtag.event('job_category_setup_' + newVal, {
          'category': this.id,
          'campaignid': this.$root.user_campaign_id
        });
      }

    },
    creditsModalShow: function(newVal) {
      if(newVal) {
        this.$root.modalShow()
        this.credit.field = this.form.credits_used > 0 ? this.form.credits_used : ''
      }
      else this.$root.modalClose();
    },
    paymentMethodModalShow: function(newVal) {
      if(newVal) this.$root.modalShow()
      else this.$root.modalClose();
    },
    showApplyOffer: function(newVal) {
      if(newVal) this.$root.modalShow()
      else this.$root.modalClose();
    },
    showFromLocationPicker: function(newVal) {
      if(newVal) this.$root.modalShow()
      else this.$root.modalClose();
    },
    showToLocationPicker: function(newVal) {
      if(newVal) this.$root.modalShow()
      else this.$root.modalClose();
    },
  },
  methods: {
    async getModels() {
        const $this = this
        this.$root.isLoading = true;
      await getCategoryQuestions(this.id).then(response => {
        // console.log(response)
        this.category = response.data
        this.screenCount = this.category.screens.length
        this.title = this.category.name

        let the_screens = []
        $(this.category.screens).each(function(i, screen) {
          let the_questions = []
          $(screen).each(function(j, question) {
            if(question.type == 'yes-no') question.value = 'no'
            else if(question.type == 'product_single_choice') {
              if(question.show_counter_on_fe == 1 && question.products.length == 1) {
                question.value = question.products[0].id
              }else {
                question.value = ''
              }
            }else if(question.type == 'product_multi_choice') {
              $(question.products).each(function(k, product) {
                product.value = ''
              });
              question.value = []
            }
            else if(question.type == 'type_of_services' || question.type == 'text-field') question.value = ''
            else if(question.type == 'image_video') {
              question.value = []
              question.b64 = []
              question.val_names = []
            }
            else question.value = []
            the_questions.push(question)

            if(question.type == 'location_from') $this.form.location.from_question = question
            if(question.type == 'location_to' && question.show_on_fe == 1) $this.form.location.to_question = question
          });
          the_screens.push(the_questions)
        });
        this.form.screens = the_screens
        
        //this.$root.isLoggedIn && 
        if(this.$root.sessionValue('_ovJForm') && this.$root.sessionValue('_ovJCat') 
          && this.category.id == this.$root.sessionValue('_ovJCat')) { //not logged in but already filled
          // console.log('has saved')
          let form = this.$root.sessionValue('_ovJForm');
          console.log(form)
          this.form.type_of_service = form.type_of_service
          this.form.typeofservice_id = form.typeofservice_id
          this.form.location.from = form.location.from
          this.form.location.to = form.location.to
          this.form.time = form.time
          this.form.date.from = form.date.from
          this.form.date.to = form.date.to
          this.form.date.range[0] = new Date(form.date.range[0])
          this.form.date.range[1] = new Date(form.date.range[1])
          // console.log(new Date(form.date.range[0]))
          // console.log(new Date(form.date.range[1]))
          // console.log(this.form.date)

          setTimeout(function () {
            $this.convertToForm();
          }, 500);
        }else {
          ssWatcher.removeItem('_ovJForm');
          ssWatcher.removeItem('_ovJSummary');
          ssWatcher.removeItem('_ovJCat');
          if($this.$route.query._jid) { //for rebook
            $this.getSpecificJob();
          }else { //normal
            if($this.$route.query._tsid) {
              //skip timeout
            }else {
              setTimeout(function() {
                $this.$root.isLoading = false;
                $('.form_screens').hide();
                $('.screen_0').show();
              }, 200);
            }
          }

          if($this.$route.query._tsid) { //for landing banners or deals
            $this.setSpecificTypeService();
          }
        }

        if($this.isEdit) {
          if($this.job.typeofservice_id !== null) {
            $this.form.type_of_service = $this.job.title
            $this.form.typeofservice_id = $this.job.typeofservice_id
          }
          
          $this.screen = 0;//this.screenCount

          let total = 0;
          $(this.form.screens).each(function(i, screen) {
            $(screen).each(function(j, question) {
              let detail = null;
              $($this.job.details).each(function(jd, deets){
                if(question.id == deets.question_id) detail = deets
              });
              
              if(question.type == 'type_of_services') {
                let service_type = {}
                $(question.type_of_services).each(function(ts, serType){
                  if($this.job.typeofservice_id == serType.id) service_type = serType
                });
                question.value = service_type
              }else if(question.type == 'yes-no' && detail) {
                question.value = detail.answer.toLowerCase()
              }else if(question.type == 'product_single_choice' && detail) {
                if(detail.answer_array.length > 0) {
                  question.value = detail.answer_array[0].id == 0 ? 'other' : detail.answer_array[0].id

                  $(question.products).each(function(k, product) {
                    if(product.id == detail.answer_array[0].id) {
                      total += product.price
                      product.value = detail.answer_array[0].quantity
                    }
                  });

                  if(detail.answer_array[0].id == 0) {
                    question.other_value = detail.answer_array[0].name
                  }
                }
              }else if(question.type == 'product_multi_choice' && detail) {
                if(question.show_counter_on_fe == 1) {
                  $(question.products).each(function(qp, product){
                    let prodAns = null;
                    $(detail.answer_array).each(function(daa, dAns){
                      if(product.id == dAns.id) prodAns = dAns
                    });
                    // console.log(prodAns)
                    if(prodAns) {
                      product.value = Number(prodAns.quantity)
                    }
                  });
                }else {
                  let vals = [];
                  $(detail.answer_array).each(function(daa, dAns){
                    vals.push(dAns.id == 0 ? 'other' : dAns.id)

                    if(dAns.id == 0) {
                      question.other_value = dAns.name
                    }
                  });
                  question.value = vals
                }
              }else if(question.type == 'image_video' && detail) {
                $this.getMediaFiles(detail, i, j);
              }else if(detail){
                question.value = detail.answer
              }

              // console.log(question)
              // console.log($this.canShow(question))
              // if($this.canShow(question)) {
              //   $('.question_' + question.id).show();
              // }else {
              //   console.log('.question_' + question.id)
              //   $('.question_' + question.id).hide();
              // }
            });
          });

          this.total = total;
          setTimeout(function() {
            $this.updateQuestionDisplay();
            $this.generateSummary();
            $this.$root.isLoading = false;
            $('.form_screens').hide();
            $('.screen_' + $this.screen).show();
          }, 500);
          
        }
      })
    },
    backBtn()  {
      // window.location.href = window.location.origin + '/#/';
      
      if(this.screen == 0) {
        if(this.isEdit) {
          this.$root.goTo('job/' + this.$root.encrypt((this.job.id).toString()))
        }else {
          this.$router.push('/')
        }
      }else {
        let screen = this.screen - 1;
        $('.form_screens').hide();
        $('.screen_' + screen).show();
        this.screen = screen
      }
    },
    async submitForm() {
      // console.log('submit')
      // console.log(this.screen)
      // console.log(this.category.screens[this.screen])

      const $this = this
      let errors = {}, hasErrors = false;
      let screen = this.category.screens[this.screen];
      $(screen).each(function(i, question) {
        // if(question.is_required) console.log(question)
        if(question.type == 'type_of_services') {
          if($('[name="question['+question.id+']"]:checked').val() === undefined) {
            errors[question.id] = 'Please answer all required questions'
            hasErrors = true;
          }
        }else if(question.type == 'time') {
          // console.log('time')
          // console.log($('[name="question['+question.id+'][]"]:checked').length)
          if($('[name="question['+question.id+'][]"]:checked').length == 0) {
            errors[question.id] = 'Please answer all required questions'
            hasErrors = true;
          }
        }else if(question.type == 'location_from') {
          // console.log('location_from')
          if($this.form.location.from.id == '' && $this.form.location.from.postal_code == '' && $this.form.location.from.street == '') {
            errors[question.id] = 'Please answer all required questions'
            hasErrors = true;
          }
        }else if(question.is_required == 1 && $this.canShow(question)){
          if(question.type == 'yes-no' || question.type == 'product_single_choice') {
            if(question.show_counter_on_fe == 1) {
              let multiHasAnswer = false;
              $(question.products).each(function(p, product) {
                // console.log(product)
                if(product.value && product.value > 0) {
                  multiHasAnswer = true;
                }
              });
              if(question.value == 'other') {
                multiHasAnswer = true;
              }
              if(!multiHasAnswer) {
                errors[question.id] = 'Please answer all required questions'
                hasErrors = true;
              }
            }else {
              if($('[name="question['+question.id+']"]:checked').val() === undefined) {
                errors[question.id] = 'Please answer all required questions'
                hasErrors = true;
              }
            }
          }else if(question.type == 'product_multi_choice') {
            if(question.show_counter_on_fe == 1) {
                let multiHasAnswer = false;
                $(question.products).each(function(p, product) {
                  // console.log(product)
                  if(product.value && product.value > 0) {
                    multiHasAnswer = true;
                  }
                });
                if(question.value.includes('other')) {
                  multiHasAnswer = true;
                }
                if(!multiHasAnswer) {
                  errors[question.id] = 'Please answer all required questions'
                  hasErrors = true;
                }

                // $(question.products).each(function(p, product) {
                //   if($('[name="question['+question.id+']['+product.id+']"]').val() == 0) {
                //     errors[question.id] = 'Please answer all required questions'
                //     hasErrors = true;
                //   }
                // });
            }else {
              if($('[name="question['+question.id+'][]"]:checked').length == 0) {
                errors[question.id] = 'Please answer all required questions'
                hasErrors = true;
              }
            }
          }else if(question.type == 'image_video') {
            if(question.value.length == 0) {
                errors[question.id] = 'Please answer all required questions'
                hasErrors = true;
            }
          }else if(question.type == 'text-field') {
            if(question.value.trim() == '') {
                errors[question.id] = 'Please answer all required questions'
                hasErrors = true;
            }
          }
        }
      });
      this.errors = errors;
      this.hasErrors = hasErrors;
      this.showError = hasErrors;

      // console.log(errors)

      if(!hasErrors) {
        if(this.screen == this.screenCount) {
          let valid = true
          //check if final total > 0 then require payment 
          if(this.final_total > 0) {
            if(Object.keys(this.form.payment).length === 0) {
              valid = false
              this.errors['payment'] = 'Payment Details required'
              this.hasErrors = true;
              this.showError = true;
              setTimeout(function() {
                $this.showError = false
              }, 3000);
            }
          }
          if(valid) {
            this.$root.isLoading = true

            let formData = new FormData();
            if(this.$root.enable_recaptcha) formData.append('recaptcha_token', await this.$root.recaptcha('jobc'));
            formData.append('title', this.form.type_of_service == '' ? this.category.name : this.form.type_of_service)
            formData.append('category_id', this.category.id)
            formData.append('typeofservice_id', this.form.typeofservice_id)
            if(this.category.type == 'fixed') formData.append('is_fastest_finger', 1)
            formData.append('budget', 0)
            formData.append('date_from', this.form.date.from)
            formData.append('date_to', this.form.date.to)
            if(this.category.type == 'fixed') formData.append('source', this.form.payment.id)
            formData.append('allow_counter_proposal', this.category.type == 'fixed' ? 0 : (this.form.allow_counter_proposal ? 1 : 0))

            let time_from = '', time_to = '', time_range = [];
            $(this.form.time).each(function(i, time) {
              if(i == 0) time_from = time.from;
              time_to = time.to

              time_range.push({
                title: time.display,
                desc: time.display
              })
              formData.append('time_range['+i+'][title]', time.display)
              formData.append('time_range['+i+'][desc]', time.display)
            });
            formData.append('time_from', time_from)
            formData.append('time_to', time_to)
            formData.append('timezone', this.timezone)

            //credits
            formData.append('credit', this.form.credits_used)
            //promo
            if(Object.keys(this.form.promo).length > 0) {
              formData.append('offer_id', this.form.promo.id)
            }

            //locations
            let location_from = this.form.location.from_question;
            formData.append('locations[0][type]', location_from.type)
            formData.append('locations[0][question_id]', location_from.id)
            formData.append('locations[0][question]', location_from.question_text)
            
            Object.keys(this.form.location.from).forEach(key => {
              formData.append('locations[0]['+key+']', this.form.location.from[key] == null ? '' : this.form.location.from[key])
            });

            if(this.form.location.to_question) {
              if(this.form.location.to.street != '') {
                let location_to= this.form.location.to_question;
                formData.append('locations[1][type]', location_to.type)
                formData.append('locations[1][question_id]', location_to.id)
                formData.append('locations[1][question]', location_to.question_text)
                
                Object.keys(this.form.location.to).forEach(key => {
                  formData.append('locations[1]['+key+']', this.form.location.to[key] == null ? '' : this.form.location.to[key])
                });
              }
            }

            let counter = 0;
            $(this.form.screens).each(function(i, screen) {
              $(screen).each(function(j, question) {
                if(['type_of_services','yes-no', 'image_video','product_single_choice', 'product_multi_choice', 'instruction', 'date', 'time', 'text-field'].includes(question.type)){
                  let question_type_of_service = question.type_of_service.map(element => {
                    return element.trim();
                  });
                  if(!['type_of_services', 'date', 'time'].includes(question.type) 
                    && ($this.form.type_of_service != '' && !question_type_of_service.includes($this.form.type_of_service))) {
                    return; //skip to next itereation
                  }

                  // console.log(question)
                  formData.append('job_postings['+counter+'][question_type]', question.type)
                  formData.append('job_postings['+counter+'][question_id]', question.id)
                  if(question.type == 'instruction') {
                    formData.append('job_postings['+counter+'][question]', question.label)
                  }
                  else {
                    formData.append('job_postings['+counter+'][question]', question.question_text)
                  }
                  
                  if(question.type == 'type_of_services') {
                    formData.append('job_postings['+counter+'][answer]', JSON.stringify([{
                      id: question.value.id,
                      name: question.value.name
                    }]))
                  }else if(question.type == 'yes-no' || question.type == 'text-field') {
                    formData.append('job_postings['+counter+'][answer]', question.value)
                  }else if(question.type == 'image_video') {
                    $(question.value).each(function(k, upload) {
                      if(upload.id) {
                        formData.append('job_postings['+counter+'][existing][]', upload.id);
                      }else {
                        formData.append('job_postings['+counter+'][upload][]', upload);
                      }
                    });
                  }else if(question.type == 'product_single_choice') {
                    let product_id = question.value, product = {};
                    if(product_id == 'other') {
                      let answer = {
                        id: 0,
                        name: question.other_value,
                        description: '',
                        price: 0,
                        quantity: 0,
                        showCounterOnFe: question.show_counter_on_fe
                      }
                      formData.append('job_postings['+counter+'][answer]', JSON.stringify([answer]))
                    }else {
                      if(product_id != '') {
                        $(question.products).each(function(k, p) {
                          if(p.id == product_id) {
                            return product = p
                          }
                        });
                        if(product) {
                          let answer = {
                            id: product.id,
                            name: product.name,
                            description: product.description,
                            price: product.price,
                            quantity: question.show_counter_on_fe == 1 ? product.value : 1,
                            showCounterOnFe: question.show_counter_on_fe
                          };
                          if(product.file) {
                            answer.file = {
                              id: product.file.id,
                              extension: product.file.extension,
                              filename: product.file.filename,
                              filesize: product.file.filesize,
                              medium_thumb_file_url: product.file.medium_thumb_url,
                              mimetype: product.file.mimetype,
                              raw_file_url: product.file.full_url,
                              thumb_file_url: product.file.small_thumb_url,
                            }
                          }
                          formData.append('job_postings['+counter+'][answer]', JSON.stringify([answer]))
                        }
                      }
                    }
                  }else if(question.type == 'product_multi_choice') {
                    if(question.show_counter_on_fe == 0) {
                      let product_ids = question.value, answers = [], hasOtherAnswer = question.value.includes('other');
                      // console.log(product_ids)
                      if(product_ids.length > 0) {
                        $(question.products).each(function(l, p) {
                          if(product_ids.includes(p.id)) {
                            answers.push({
                              id: p.id,
                              name: p.name,
                              description: p.description,
                              price: p.price,
                              quantity: 1,
                              showCounterOnFe: question.show_counter_on_fe
                            })
                          }
                        });

                        if(hasOtherAnswer) {
                          answers.push({
                            id: 0,
                            name: question.other_value,
                            description: '',
                            price: 0,
                            quantity: 0,
                            showCounterOnFe: question.show_counter_on_fe
                          })
                        }

                        formData.append('job_postings['+counter+'][answer]', JSON.stringify(answers))
                      }
                    }else {
                      let answers = [], hasOtherAnswer = question.value.includes('other');
                      $(question.products).each(function(l, p) {
                        if(p.value !== undefined && p.value > 0) {
                          answers.push({
                            id: p.id,
                            name: p.name,
                            description: p.description,
                            price: p.price,
                            quantity: p.value,
                            showCounterOnFe: question.show_counter_on_fe,
                            isSelected: 1
                          })
                        }
                      });

                      if(hasOtherAnswer) {
                          answers.push({
                            id: 0,
                            name: question.other_value,
                            description: '',
                            price: 0,
                            quantity: 0,
                            showCounterOnFe: question.show_counter_on_fe
                          })
                        }

                        formData.append('job_postings['+counter+'][answer]', JSON.stringify(answers))
                    }
                    
                  }else if(question.type == 'date') {
                    formData.append('job_postings['+counter+'][date_type]', question.date_type)
                    formData.append('job_postings['+counter+'][answer]', JSON.stringify([{
                      date_from: $this.form.date.from,
                      date_to: $this.form.date.to
                    }]))
                  }else if(question.type == 'time') {
                    formData.append('job_postings['+counter+'][time_type]', question.time_type)
                    formData.append('job_postings['+counter+'][answer]', JSON.stringify(time_range))
                  }else if(question.type == 'instruction') {
                    formData.append('job_postings['+counter+'][answer]', question.question_text)
                  }

                  counter++;
                }
                
              });
            });

            //if has vendor
            if(this.$route.query._vid) {
              formData.append('vendor_id', this.$root.decrypt(this.$route.query._vid));
            }

            if(this.$root.user_campaign_id) {
              formData.append('campaign_id', this.$root.user_campaign_id);
            }

            if(this.isEdit) {
              await updateJob(this.job.id, formData).then((response) => {
                console.log(response)

                $this.$swal.fire({
                  text: "Job successfully updated!",
                  icon: 'success',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#f44336',
                }).then((result) => {
                    if (result.isConfirmed) {
                      $this.$root.goTo('job/' + $this.$root.encrypt(($this.job.id).toString()))
                    }
                })
                
              }).catch(error => {
                console.log(error.response.data)
                $this.$root.isLoading = false

                if(error.response.data.errors) {
                  Object.keys(error.response.data.errors).forEach(key => {
                    this.$notify({
                      title: "An error occured.",
                      text: error.response.data.errors[key][0],
                      type: 'error'
                    });
                  });
                }

                this.$notify({
                  title: "An error occured.",
                  text: error.response.data.message,
                  type: 'error'
                });
              })
            }else {
              await submitJob(formData).then(() => {
                // console.log(response)
                // console.log('done')
                //Done na
                
                $this.$root.forceChangeProfileView('client')
                $this.showJobPosted = true
                $this.$root.isLoading = false

                $this.$gtag.event('job saved', {
                  'category': $this.id,
                  'campaignid': $this.$root.user_campaign_id
                });

                setTimeout(function() {
                  $this.$root.scrollToTop()
                }, 500);

                ssWatcher.removeItem('_ovJForm');
                ssWatcher.removeItem('_ovJSummary');
                ssWatcher.removeItem('_ovJCat');
              }).catch(error => {
                console.log(error.response.data)
                $this.$root.isLoading = false

                this.$notify({
                    title: "An error occured.",
                    text: error.response.data.message,
                    type: 'error'
                });
              })
            }
            
            
          }
        }else {
          if(this.screen == (this.screenCount - 1)) { //CHECK DATE AND TIME
            let now = moment().add(1, 'days'), checker = moment(this.form.date.to + ' ' + this.form.time[this.form.time.length - 1]['to']);
            // console.log(now)
            // console.log(checker)
            if(checker.isBefore(now)) {
              $this.$swal.fire({
                  html:
                  '<h5>We encourage you to consider later timing options so that our merchants have sufficient time to attend to your job requirements.</h5>' +
                  'Do you wish to proceed?',
                  icon: 'warning',
                  confirmButtonText: 'Yes',
                  confirmButtonColor: '#FE4D45',
                  showCancelButton: true,
              }).then((result) => {
                  if (result.isConfirmed) {
                    //Check if Logged in
                    if($this.$root.isLoggedIn) {
                      this.screen = this.screen + 1;
                      $('.form_screens').hide();
                      $('.screen_' + this.screen).show();
                      this.$root.scrollToTop()
                    }else {
                      $this.$root.scrollToTop()
                      // console.log('save form');
                      //save form
                      ssWatcher.setItem('_ovJForm', this.form);
                      ssWatcher.setItem('_ovJSummary', this.summary);
                      ssWatcher.setItem('_ovJCat', this.category.id);
                      sessionStorage.setItem('_ovvy_backUrl', location.href.replace(location.origin + '/#', ''));
                      //redirect to login
                      // console.log('login?_back=job&cat=' + $this.$route.params.code)
                      
                      $this.$root.goTo('login?_back=job&cat=' + $this.$route.params.code);
                    }
                  }
              })
            }else {
              // console.log('next')

              //Check if Logged in
              if($this.$root.isLoggedIn) {
                this.screen = this.screen + 1;
                $('.form_screens').hide();
                $('.screen_' + this.screen).show();
                this.$root.scrollToTop()
              }else {
                $this.$root.scrollToTop()
                // console.log('save form');
                //save form
                this.generateSummary();
                ssWatcher.setItem('_ovJForm', this.form);
                ssWatcher.setItem('_ovJSummary', this.summary);
                ssWatcher.setItem('_ovJCat', this.category.id);
                sessionStorage.setItem('_ovvy_backUrl', location.href.replace(location.origin + '/#', ''));

                //redirect to login
                // console.log('login?_back=job&cat=' + $this.$route.params.code)
                
                $this.$root.goTo('login?_back=job&cat=' + $this.$route.params.code);

              }
              
            }
          }else {
            // console.log('next')
            this.screen = this.screen + 1;
            $('.form_screens').hide();
            $('.screen_' + this.screen).show();
            this.$root.scrollToTop()
          }
        }
      }else {
        let first_id = Object.keys(this.errors)[0];
        this.scrollToQuestion(first_id)
        // this.$root.scrollToTop()
        const $this = this
        setTimeout(function() {
          $this.showError = false
        }, 3000);
      }
    },
    scrollToQuestion(id) {
      let name = 'question_' + id;

      let scrollDiv = document.getElementById(name).offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    },
    updateQuestionDisplay() {
      // let type_of_service = this.form.type_of_service
      const _this = this
      $(this.category.screens).each(function (i, screen) {
        $(screen).each(function(j, question) {
          // console.log(question)
          // console.log(_this.canShow(question))
          if(_this.canShow(question)) {
            $('.question_' + question.id).show();
          }else {
            $('.question_' + question.id).hide();
          }
        });
      });
    },
    canShow(question) {
      if(question.type_of_service) {
        // console.log(this.form.type_of_service)
        // console.log(question)
        // console.log(question.type_of_service.length)
        // console.log(question.type_of_service.includes(this.form.type_of_service))
        // console.log('----')
        if(question.type_of_service.length > 0) {
          let question_type_of_service = question.type_of_service.map(element => {
            return element.trim();
          });
          if(!question_type_of_service.includes(this.form.type_of_service.trim())) return false
        }
      }
      return true
    },
    insertDecimal(num) {
      return (Number(num)).toFixed(2);
    },
    renderLineBreak(str) {
      return str.replace(/\n/g, "<br />");
    },
    computeTotal() {
      //Compute Total
      let total = 0;
      $(this.category.screens).each(function (i, screen) {
        $(screen).each(function(j, questions) {
          $(questions).each(function(k, question) {
            if(question.type == 'product_single_choice') {
              if($('[name="question['+question.id+']"]:checked').val() !== undefined) {
                let product_id = $('[name="question['+question.id+']"]:checked').val(),
                  product = {};
                $(question.products).each(function(l, p) {
                  if(p.id == product_id) {
                    return product = p
                  }
                });
                // console.log(product)
                if(product) {
                  total += Number(product.price)
                  // console.log('total ' + total)
                }
              }
            }else if(question.type == 'product_multi_choice') {
              let product_ids = [];
              $('[name="question['+question.id+'][]"]:checked').each(function () {
                product_ids.push(Number($(this).val()))
              });
              // console.log(product_ids)
              if(product_ids.length > 0) {
                $(question.products).each(function(l, p) {
                  if(product_ids.includes(p.id)) {
                    // console.log(p)
                    total += Number(p.price)
                  }
                });
              }
            }
          });
        });
      });
      this.total = total
      // console.log(question)
      // //Process Other
      // if(question.has_other_answer == 1) {
      //   if(question.type == "product_single_choice") {
      //     if($('[name="question['+question.id+']"]:checked').val() == 'other') {
      //       $('.other-text-box-cont-' + question.id).show()
      //     }else {
      //       $('.other-text-box-cont-' + question.id).hide()
      //     }
      //   }else if(question.type == "product_multi_choice"){
      //     if($('[name="question['+question.id+'][]"][value="other"]').is(":checked")) {
      //       $('.other-text-box-cont-' + question.id).show()
      //     }else {
      //       $('.other-text-box-cont-' + question.id).hide()
      //     }
      //   }
      // }
    },
    showFileSelect(question) {
      $('[name="question-'+question.id+'"]').trigger('click');
    },
    updateMultiFileField($event, question, index) {
        
      // console.log($event)
      // console.log($event.target.files[0])
      // console.log($event.target.files[0].name)
      // console.log($event.target.files[0].type)
      const $this = this
      if(['image/jpeg','image/jpg','image/png','video/mp4','video/quicktime'].includes($event.target.files[0].type)) {
        this.form.screens[this.screen][index]['value'].push($event.target.files[0])
        this.form.screens[this.screen][index]['val_names'].push($event.target.files[0].name)

        const FR = new FileReader();
    
        FR.addEventListener("load", function(evt) {
          // console.log(evt.target.result)
          $this.form.screens[$this.screen][index]['b64'].push(evt.target.result)
        }); 
          
        FR.readAsDataURL($event.target.files[0]);
      }else {
        this.$notify({
            title: "An error occured.",
            text: 'Data type not valid',
            type: 'error'
        });
      }
      // console.log(question)
      // console.log(index)
      // console.log('here')
      
      $event.target.value = ''

      // console.log(this.form.screens[this.screen][index])
    },
    removeFileFromMultiField(value,question, index) {
      this.form.screens[this.screen][index]['value'].splice(this.form.screens[this.screen][index]['value'].indexOf(value), 1)
    },
    setUserLocations(data) {
      this.user_locations = data
      if(data.length > 0) {
        if(this.form.location.from.postal_code == '' && this.form.location.from.street == '' && this.form.location.from.street_number == '') {
          this.form.location.from = data[0]
        }
      }
    },
    changeUserLocation(event, location) {
      console.log(event)
      console.log(location)
      this.form.location.from = location
    },
    setDefaultPayment(data) {
      this.payments_methods = data
      if(data.length > 0) {
        this.form.payment = data[0]
      }else this.form.payment = {}
    },
    clickApplyOffer() {
      const $this = this
      if(Object.keys(this.form.promo).length > 0) {
        $this.$swal.fire({
            title: 'Offers',
            text: "Are you sure you want to remove existing voucher or promo code?",
            icon: 'warning',
            confirmButtonText: 'Continue',
            confirmButtonColor: '#f44336',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                $this.form.promo = {}
            }
        })
      }else {
        this.showApplyOffer = true
      }
    },
    async openCreditsModal() {
      this.$root.isLoading = true
      this.creditsLoading = true
      const $this = this
      $this.credit.field = 0
      $this.credit.error = ''

      await getUserCredits('client').then(response => {
        // console.log(response.data.data)
        $this.$root.isLoading = false
        $this.creditsLoading = false
        $this.creditsModalShow = true
        $this.credit = {
          credits: response.data.data.credits,
          points: response.data.data.points
        }
      }).catch(error => {
        console.log(error)
        $this.$root.isLoading = false
        $this.creditsLoading = false
      })
    },
    verifyCredits() {
      this.credit.error = ''
      if(this.credit.field == '') {
        this.form.credits_used = 0
        this.creditsModalShow = false
      }else {
        let field = Number(this.credit.field)
        if(isNaN(this.credit.field)) {
          //error
          this.credit.error = 'Error'
        }else {
          let total = this.total - this.promo_discount
          //proceed
          if(field < 0) {
            this.credit.error = 'Amount should be greater than 0'
          }else {
            if(field > this.credit.credits) {
              this.credit.error = 'Insufficient Credits'
            }else if(field > total) {
              this.credit.error = 'Credit is greater than service fee'
            }
          }
        }

        if(this.credit.error == '') {
          this.form.credits_used = field
          this.creditsModalShow = false
        }
      }
    },
    getTypeOfService(option) {
      this.form.type_of_service = (option.name).trim()
      this.form.typeofservice_id = option.id
      this.total = 0
      this.form.date = {
        range: [new Date(moment()), new Date(moment())],
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      }
      this.form.time = {}

      // //credits
      // formData.append('credit', this.form.credits_used)
      // //promo
      // if(Object.keys(this.form.promo).length > 0) {
      //   formData.append('offer_id', this.form.promo.id)
      // }

      $(this.form.screens).each(function(i, screen) {
        $(screen).each(function(j, question) {
          if(['yes-no', 'image_video','product_single_choice', 'product_multi_choice', 'instruction', 'text-field'].includes(question.type)){

            if(question.type == 'product_single_choice' || question.type == 'text-field') {
              question.value = ''

              if(question.type == 'product_single_choice') {
                $(question.products).each(function(k, p) {
                  if('quantity' in p) p.quantity = 0
                  if('value' in p) p.value = 0
                });

                if(question.show_counter_on_fe == 1 && question.products.length == 1) {
                  question.value = question.products[0].id
                }
              }
            }else if(question.type == 'yes-no') {
              question.value = 'no'
            }else if(question.type == 'image_video') {
              question.value = []
            }else if(question.type == 'product_multi_choice') {
              question.value = []
              $(question.products).each(function(k, p) {
                if('quantity' in p) p.quantity = 0
                if('value' in p) p.value = 0
              });
            }
          }

          if('other_value' in question) {
            if(question.other_value != '') {
              question.other_value = ''
              $('.other-text-box-cont-' + question.id).hide()
            }
          }
          
        });
      });
    },
    async getSpecificJob() {
      const $this = this
      let id = this.$root.decrypt(this.$route.query._jid)
      await getJob(id).then(response => {
        // console.log(response.data)
        $this.job = response.data

        this.form.type_of_service = this.job.title

        //check if type of serviceid exists

        this.form.typeofservice_id = this.job.typeofservice_id
        if(this.job.service_type) {
          this.screen = this.form.screens.length - 2;
        }else {
          this.screen = 0
        }

        $this.form.location.from = $this.job.locations[0]
        if($this.job.locations.length == 2) {
          $this.form.location.to  = $this.job.locations[1]
        }

        let total = 0;
        $(this.form.screens).each(function(i, screen) {
          $(screen).each(function(j, question) {
            let detail = null;
            $($this.job.details).each(function(jd, deets){
              if(question.id == deets.question_id) detail = deets
            });
            
            if(question.type == 'type_of_services') {
              let service_type = {}
              $(question.type_of_services).each(function(ts, serType){
                if($this.job.typeofservice_id == serType.id) service_type = serType
              });
              question.value = service_type
            }else if(question.type == 'yes-no' && detail) {
              question.value = detail.answer.toLowerCase()
            }else if(question.type == 'product_single_choice' && detail) {
              if(detail.answer_array.length > 0) {
                question.value = detail.answer_array[0].id
                
                $(question.products).each(function(k, product) {
                  if(product.id == detail.answer_array[0].id) {
                    total += product.price
                    product.value = Number(detail.answer_array[0].quantity)
                  }
                  
                });
              }
            }else if(question.type == 'product_multi_choice' && detail) {
              if(question.show_counter_on_fe == 1) {
                $(question.products).each(function(qp, product){
                  let prodAns = null;
                  $(detail.answer_array).each(function(daa, dAns){
                    if(product.id == dAns.id) prodAns = dAns
                  });
                  // console.log(prodAns)
                  if(prodAns) {
                    product.value = Number(prodAns.quantity)
                  }
                });
              }else {
                let vals = [];
                $(detail.answer_array).each(function(daa, dAns){
                  vals.push(dAns.id)
                });
                question.value = vals
              }
            }
          });
        });
        this.$root.isLoading = false;
        this.total = total;
        $('.form_screens').hide();
        $('.screen_' + this.screen).show();
      })
    },
    updateDate(type) {
      if(type == 'from') {
        this.form.date.from = moment(this.form.date.range[0]).format('YYYY-MM-DD')

        if(!moment(this.form.date.to).isSameOrAfter(moment(this.form.date.from))) {
          this.form.date.to = this.form.date.from
          this.form.date.range[1] = this.form.date.range[0]
        }
      }else {
        this.form.date.to = moment(this.form.date.range[1]).format('YYYY-MM-DD')

        if(!moment(this.form.date.to).isSameOrAfter(moment(this.form.date.from))) {
          this.form.date.from = this.form.date.to
          this.form.date.range[0] = this.form.date.range[1]
        }
      }
    },
    setSpecificTypeService() {
      const $this = this
      let tsid = this.$root.decrypt(this.$route.query._tsid), type_service = {};
      // console.log(tsid)
      $(this.form.screens[0][0]['type_of_services']).each(function(i, st) {
        if(st.id == tsid) {
          type_service = st
        }
      });
      // console.log(type_service)
      this.form.type_of_service = type_service.name
      this.form.typeofservice_id = tsid
      this.form.screens[0][0]['value'] = type_service
      
      setTimeout(function() {
        $this.updateQuestionDisplay();
        $this.$root.isLoading = false;
        $('.form_screens').hide();
        $('.screen_1').show();
        $this.screen = 1
      }, 200);

      // $('.form_screens').hide();
      // $('.screen_' + this.screen).show();
    },
    generateSummary() {
      const $this = this;
      let summary = [];
      let type_of_service = (this.form.type_of_service).trim()
      // console.log(type_of_service)
      $(this.form.screens).each(function(i, screen) {
        $(screen).each(function(j, question) {

          let addToSum = true;

          let question_type_of_service = question.type_of_service.map(element => {
            return element.trim();
          });

          // console.log(question)
          // console.log(question_type_of_service)
          // console.log(question_type_of_service.includes(type_of_service))
          if(question.type != 'type_of_services' && (type_of_service == '' || question_type_of_service.includes(type_of_service) || i == $this.form.screens.length - 1)) {
            let answer = {}, has_answer = true;
            if(question.type == 'yes-no') {
              answer = question.value
              // answer = $('[name="question['+question.id+']"]:checked').val()
            }else if(question.type == 'product_single_choice') {
              let prod_id = question.value,
              product = {};
              $(question.products).each(function(p, prod) {
                if(prod.id == prod_id) product = prod
              });
              answer = product

              //check if answer is other
              if(prod_id == 'other') {
                answer = {
                  id: 'other',
                  value: question.other_value
                }
              }

              if(prod_id == '') has_answer = false

              // let prod_id = $('[name="question['+question.id+']"]:checked').val(),
              // product = {};
              // $(question.products).each(function(p, prod) {
              //   if(prod.id == prod_id) product = prod
              // });
              // answer = product

              // //check if answer is other
              // if(prod_id == 'other') {
              //   answer = {
              //     id: 'other',
              //     value: $('[name="question['+question.id+'][other]"]').val()
              //   }
              // }

              // if(prod_id === undefined) has_answer = false
            }else if(question.type == 'product_multi_choice') {
              if(question.show_counter_on_fe == 0) {
                let prod_ids = question.value, products = [];
                
                $(question.products).each(function(p, prod) {
                  if(prod_ids.includes(prod.id)) products.push(prod)
                });

                if(prod_ids.includes('other')) {
                  products.push({
                    id: 'other',
                    value: $('[name="question['+question.id+'][other]"]').val()
                  })
                }
                answer = products

                if(prod_ids.length == 0) has_answer = false
                // let prod_ids = [], products = [];
                // $('[name="question['+question.id+'][]"]:checked').each(function() {
                //   prod_ids.push($(this).val() == 'other' ? 'other' : Number($(this).val()));
                // });

                // $(question.products).each(function(p, prod) {
                //   if(prod_ids.includes(prod.id)) products.push(prod)
                // });

                // if(prod_ids.includes('other')) {
                //   products.push({
                //     id: 'other',
                //     value: $('[name="question['+question.id+'][other]"]').val()
                //   })
                // }
                // answer = products

                // if($('[name="question['+question.id+'][]"]:checked').length == 0) has_answer = false
              }else {
                let products = [];
                $(question.products).each(function(p, prod) {
                  if(Number(prod.value) > 0) products.push(prod)
                });
                answer = products;

                if(question.has_other_answer == 1) {
                  if(question.value.includes('other')) {
                    products.push({
                      id: 'other',
                      value: question.other_value
                    })
                  }
                }

                if(products.length == 0) has_answer = false;

                // let products = [];
                // $(question.products).each(function(p, prod) {
                //   prod.value = $('[name="question['+question.id+']['+prod.id+']"]').val()
                //   if(prod.value > 0) products.push(prod)
                // });
                // answer = products;

                // if(question.has_other_answer == 1) {
                //   if(question.value.includes('other')) {
                //     products.push({
                //       id: 'other',
                //       value: $('[name="question['+question.id+'][other]"]').val()
                //     })
                //   }
                // }

                // if(products.length == 0) has_answer = false;
              }
            }else if(question.type == 'text-field') {
              answer = question.value;
              if(answer == '') has_answer = false
              // answer = $('[name="question['+question.id+']"]').val()
              // if($('[name="question['+question.id+']"]').val() == '')  has_answer = false
            }else if(question.type == 'date') {
              // answer = {
              //   from : $('[name="question['+question.id+'][from]"]').val(),
              //   to: $('[name="question['+question.id+'][to]"]').val(),
              //   display_from : moment($('[name="question['+question.id+'][from]"]').val()).format('ddd Do MMM, YYYY'),
              //   display_to : moment($('[name="question['+question.id+'][to]"]').val()).format('ddd Do MMM, YYYY')
              // }
              answer = {
                from : $this.form.date.from,
                to: $this.form.date.to,
                display_from : moment($this.form.date.from).format('ddd Do MMM, YYYY'),
                display_to : moment($this.form.date.to).format('ddd Do MMM, YYYY')
              }
            }else if(question.type == 'time') {
              let times = [];
              $($this.form.time).each(function(t, time) {
                times.push(time.display)
              });
              answer = times;
              // let ids = [];
              // $('[name="question['+question.id+'][]"]:checked').each(function() {
              //   ids.push($(this).val())
              // });
              // answer = ids
            }else if(question.type == 'image_video') {
              answer = question.value
              if(question.value.length == 0) has_answer = false
            }else if(question.type == 'location_to') {
              if($this.form.location.to.street == '') {
                addToSum = false;
              }
            }
            if(addToSum) {
              summary.push({
                question: question,
                answer: answer,
                has_answer: has_answer
              })
            }

            //add allow counter proposal after time
            if(question.type == 'time' && $this.category.type == 'bidding') {
              summary.push({
                question: {
                  type: 'allow_counter_proposal',
                  // question_text: 'Allow Merchants to counter propose time',
                  question_text: "Allow Merchants to counter-propose within a window of plus or minus 3 hours from the time you've selected above",
                  is_required: 1
                },
                answer: $this.form.allow_counter_proposal ? 'Yes' : 'No',
                has_answer: true
              })
            }
          }
        });
      });
      this.summary = summary
    },
    convertToForm() {
      const $this = this;
      let form = this.$root.sessionValue('_ovJForm');

      // this.form.type_of_service = form.type_of_service
      // this.form.typeofservice_id = form.typeofservice_id
      // this.form.location = form.location
      // this.form.time = form.time
      // this.form.date = form.date
     
      let total = 0;

      $(this.form.screens).each(function(i, screen) {
        $(screen).each(function(j, question) {
          if(question.type == 'type_of_services') {
            question.value = form['screens'][i][j]['value']
          }else if(question.type == 'yes-no') {
            question.value = form['screens'][i][j]['value']
          }else if(question.type == 'product_single_choice') {
            question.value = form['screens'][i][j]['value'];

            $(question.products).each(function(k, product) {
              if(product.id == Number(form['screens'][i][j]['value'])) {
                total += product.price
              }
            });
          }else if(question.type == 'text-field') {
            question.value = form['screens'][i][j]['value']
          }else if(question.type == 'product_multi_choice') {
            if(question.show_counter_on_fe == 1) { //Number Picker
              $(question.products).each(function(k, product) {
                product.value = Number(form['screens'][i][j]['products'][k]['value'])
                // console.log(product)
              });
            }
            question.value = form['screens'][i][j]['value']
            if(form['screens'][i][j]['has_other_answer'] == 1) {
              question.has_other_answer = 1;
              question.other_value = form['screens'][i][j]['other_value']
            }
          }else if(question.type == 'image_video') {
            // console.log(form['screens'][i][j])
            //image 
            // question.value = form['screens'][i][j]['value']
            $(form['screens'][i][j]['b64']).each(function(k, val) {
              let dataurl = val, filename = form['screens'][i][j]['val_names'][k];

              var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
              while(n--){
                  u8arr[n] = bstr.charCodeAt(n);
              }
            
              let data = new File([u8arr], filename, {type:mime});

              // console.log(data);
              question.value.push(data)

            });
          }
        })
      });
      if(sessionStorage.getItem('_ovvy_prevUrlName') == 'home') {
        this.screen = 0;
      }else {
        if($this.$root.isLoggedIn) {
          if($this.$root.phone_verified == 1) {
            this.screen = this.screenCount;
          }else {
            this.screen = 0
          }
        }else {
          this.screen = this.screenCount - 1;
        }
      }
            
      this.total = total
      //getTotal
      
      setTimeout(function () {
        $this.updateQuestionDisplay()
        //Check if previous page is from homepage
        if(sessionStorage.getItem('_ovvy_prevUrlName') == 'home') {
          $('.form_screens.screen_0').show();
        }else {
          if($this.$root.isLoggedIn) {
            if($this.$root.phone_verified == 1) {
              $('.form_screens.summary').show();
            }else {
              $('.form_screens.screen_0').show();
            }
          }else {
            let num = $this.screenCount - 1;
            $('.form_screens.screen_' + num).show();
          }
        }
        
        $this.$root.isLoading = false;
        $this.$root.scrollToTop()
      }, 500);

      
    },
    async getEditJob(id) {
      const $this = this
      this.$root.isLoading = true
      await getJob(id, {
        with: 'bid.vendor.profile.avatar, client.profile.avatar, bid.promo.promo, promo.promo',
        custom: 'vendor_review_stats,payment_method'
      }).then(response => {
        // console.log(response.data)
        $this.job = response.data

        if($this.job.client_id != $this.$root.user_id) {
          $this.$swal.fire({
            text: "You are not allowed to edit this job.",
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#f44336',
          }).then((result) => {
              if (result.isConfirmed) {
                $this.$root.goTo('job/' + $this.$root.encrypt(($this.job.id).toString()))
              }
          })
          return false;
        }

        if(($this.job.type == 'fixed' && $this.job.bid) || ($this.job.type == 'bidding' && $this.job.bid_count > 0)) {
          $this.$swal.fire({
            text: "You are not allowed to edit this job anymore.",
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#f44336',
          }).then((result) => {
              if (result.isConfirmed) {
                $this.$root.goTo('job/' + $this.$root.encrypt(($this.job.id).toString()))
              }
          })
          return false;
        }

        $this.id = this.job.category_id;

        if($this.job.fixed_price_credit > 0) {
          $this.form.credits_used = $this.job.fixed_price_credit;
        }

        if($this.job.promo && $this.job.promo.promo) {
          $this.form.promo = $this.job.promo.promo
        }

        $this.form.location.from = $this.job.locations[0]
        if($this.job.locations.length == 2) {
          $this.form.location.to  = $this.job.locations[1]
        }

        $this.form.date.from = $this.job.date_from
        $this.form.date.to = $this.job.date_to
        $this.form.date.range = [
          new Date($this.job.date_from),
          new Date($this.job.date_to)
        ]

        
        $($this.job.time_range_array).each(function(e, time) {
          $this.form.time.push($this.getTimeValByDisplay(time.desc));
        });

        $this.form.allow_counter_proposal = $this.job.allow_counter_proposal

        $this.getModels();
      })
    },
    async getMediaFiles(detail, screenKey, questionKey) {
      // console.log(detail)
      // console.log(screenKey)
      // console.log(questionKey)
      if(detail.answer_array.length == 0) return;
      const $this = this
      await getMediaFiles({
        ids: detail.answer_array.join()
      }).then(response => {
        // console.log(response.data)
        $this['form']['screens'][screenKey][questionKey]['value'] = response.data
      });
    },
    getTimeValByDisplay(display) {
      let times = [
        {
            display: '7am - 8am',
            from: '07:00:00',
            to: '08:00:00'             
        },
        {
            display: '8am - 9am',     
            from: '08:00:00',
            to: '09:00:00'               
        },
        {
            display: '9am - 10am',      
            from: '09:00:00',
            to: '10:00:00'              
        },
        {
            display: '10am - 11am',   
            from: '10:00:00',
            to: '11:00:00'            
        },
        {
            display: '11am - 12pm',    
            from: '11:00:00',
            to: '12:00:00'          
        },
        {
            display: '12pm - 1pm',     
            from: '12:00:00',
            to: '13:00:00'          
        },
        {
            display: '1pm - 2pm',    
            from: '13:00:00',
            to: '14:00:00'                
        },
        {
            display: '2pm - 3pm',  
            from: '14:00:00',
            to: '15:00:00'                  
        },
        {
            display: '3pm - 4pm',  
            from: '15:00:00',
            to: '16:00:00'                     
        },
        {
            display: '4pm - 5pm',      
            from: '16:00:00',
            to: '17:00:00'                 
        },
        {
            display: '5pm - 6pm',   
            from: '17:00:00',
            to: '18:00:00'                    
        },
        {
            display: '6pm - 7pm',    
            from: '18:00:00',
            to: '19:00:00'                   
        },
        {
            display: '7pm - 8pm',    
            from: '19:00:00',
            to: '20:00:00'                   
        },
        {
            display: '8pm - 9pm',    
            from: '20:00:00',
            to: '21:00:00'                   
        },
        {
            display: '9pm - 10pm',      
            from: '21:00:00',
            to: '22:00:00'                 
        }
      ];
      let value = {};
      $(times).each(function(i, time) {
        if(time.display == display) {
          value = time;
          return false;
        }
      });
      return value;
    },
    verifyPromo(promo) {
      let total = this.total

      // console.log(total)

      if(Number(promo.discount_percent) > 0) {
        let perc = Number(promo.discount_percent) / 100,
          discount = total * perc;
        if(Number(promo.max_discount_limit) < discount) discount = Number(promo.max_discount_limit)
        total -= discount;
        console.log(discount)
      }else {
        let discount = Number(promo.discount_fixed_value);
        if(Number(promo.max_discount_limit) < discount) discount = Number(promo.max_discount_limit)
        total -= discount;
        console.log(discount)
      }
      
      // console.log(total)

      if(this.form.credits_used > 0) {
        total -= this.form.credits_used
      }
      // console.log(total)

      if(total <= 0) {
        this.$swal.fire({
          text: "Promo value is greater than service fee",
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#f44336',
          showCancelButton: true,
        })
        
      }else {
        this.form.promo = promo
      }
    },
    clearOptional(question) {
      const $this = this
      question.value = ''; 
      setTimeout(function() {
        $this.computeTotal()
      }, 100)
    }
  },
  computed: {
    locationFromDisplay() {
      let location = '';

      if(this.form.location.from.location != '') {
        location = this.form.location.from.location
      }else {
        if(this.form.location.from.street != '') {
          location += this.form.location.from.street + ', '
        }

        location += this.form.location.from.country;
        
        if(this.form.location.postal_code != '') {
          location += ' ' + this.form.location.from.postal_code
        }
      }

      return location
    },
    locationToDisplay() {
      let location = '';

      if(this.form.location.to.location != '') {
        location = this.form.location.to.location
      }else {
        if(this.form.location.to.street != '') {
          location += this.form.location.to.street + ', '
        }

        location += this.form.location.to.country;
        
        if(this.form.location.postal_code != '') {
          location += ' ' + this.form.location.to.postal_code
        }
      }

      return location
    },
    promoData() {
      return {
        category_id: this.category.id,
        total: this.total,
        category: this.category,
        job: this.job
      }
    },
    final_total() {
      let total = this.total
      if(this.form.promo.id) {
        if(Number(this.form.promo.discount_percent) > 0) {
          let perc = Number(this.form.promo.discount_percent) / 100,
            discount = total * perc;
            if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
          total -= discount;
        }else {
          let discount = Number(this.form.promo.discount_fixed_value);
          if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
          total -= discount;
        }
      }

      if(this.form.credits_used > 0) {
        total -= this.form.credits_used
      }
      return total
    },
    promo_discount() {
      let discount = 0;
      if(this.form.promo.id) {
        if(Number(this.form.promo.discount_percent) > 0) {
          let perc = Number(this.form.promo.discount_percent) / 100;
          discount = this.total * perc;
          if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
        }else {
          discount = Number(this.form.promo.discount_fixed_value);
          if(Number(this.form.promo.max_discount_limit) < discount) discount = Number(this.form.promo.max_discount_limit)
        }
      }
      return discount
    },
  },
  created() {
    // const encryptedText = this.$CryptoJS.AES.encrypt("1", "Secret Passphrase").toString()
    // const decryptedText = this.$CryptoJS.AES.decrypt(encryptedText, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)
    // console.log(encryptedText);
    // console.log(decryptedText);

    if(this.$route.name == 'edit job') {
      let id = this.$route.params.code
      this.isEdit = true;
      this.getEditJob(this.$root.decrypt(id));
    }else {
      if(this.$route.params.code == 'redirect') {
        // id = this.$route.params.code
        //redirect
        // console.log(this.$route.query._cid)
        // console.log(this.$root.encrypt(this.$route.query._cid))
        let redirect = window.location.origin + '/#/jobs/new/' + this.$root.encrypt(this.$route.query._cid);

        if(this.$route.query._tsid) {
          redirect += '?_tsid=' + this.$root.encrypt(this.$route.query._tsid)
        }

        if(this.$route.query.campaignid) {
          redirect += '&campaignid=' + this.$route.query.campaignid
        }

        window.location.href = redirect
      }else {
        let id = this.$route.params.code
        this.id = this.$root.decrypt(id);


      this.$gtag.event('job_create', {
        'category': this.id,
        'campaignid': this.$root.user_campaign_id
      });

        this.$gtag.pageview('create_job');
      }

      this.getModels();
    }
    
    

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(timezone); // Asia/Karachi
    this.timezone = timezone;

    if(this.$route.query._vid) {
      this.form.vendor_id = this.$root.decrypt(this.$route.query._vid);
    }
  }
}
</script>
<style scoped>

  .question.has_error .question-cont h6 {
    color: #f44336;
  }
  
  @media (max-width: 768px){
    .main-content-wrp {
      padding-top: 0px;
      /* padding-bottom: 100px; */
      padding-bottom: 75px;
    }
    .summary_space {
      height: 300px;
    }

    .has-total-wrp {
      padding-bottom: 100px;
    }
  }

  @media (max-width: 615px){
    .summary_space {
      height: 360px;
    }
  }
  
  @media (max-width: 500px){
    .summary_space {
      height: 400px;
    }
  }

  @media (max-width: 450px){
    .summary_space {
      height: 260px;
    }
  }
</style>