<template>
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer" :class="hideMobile ? 'hide_on_mobile' : ''">
    <div class="fixed-footer">
    <div class="container">
      <div class="row gy-3">
        <!-- <div class="col-lg-3 col-md-4 d-flex hide-footer-col">
          <div>
            <img src="~@/assets/img/logo-white-lg.png" class="logo-footer">
            <p class="text-white">
              7 Straits View <br>
              Singapore, 018936<br>
            </p>
          </div>

        </div> -->

        <div class="col-lg-12 footer-links mobile-links pt-2 ">
          <div class="w-100">
            <!-- <h4 class="hide-footer-col">Important Links</h4> -->
             <ul>
                <li v-if="$root.profile_view == 'merchant'">
                  <router-link to="/browse" :class="$route.name == 'Browse' ? 'active' : ''">
                    <img src="~@/assets/img/ic_search.png"><span>Browse</span>
                  </router-link>
                </li>
                <li v-else><a href="#" :class="$route.name == 'home' ? 'active' : ''"><img src="~@/assets/img/post.png"><span>Post</span></a></li>
                <li>
                  <router-link to="/activity" class="activity-link" :class="$route.name == 'activity' ? 'active' : ''">
                    <div v-if="$root.profile_view == 'client' && $root.client_activity_unread > 0" class="client_activity-count">{{$root.client_activity_unread}}</div>
                    <img src="~@/assets/img/activity.png"><span>Activity</span>
                  </router-link></li>
                <li v-if="$root.profile_view != 'merchant'">
                  <a v-if="$root.isLoggedIn" @click.prevent="$root.getRecentJobs()" href="#" class="plus"  data-bs-toggle="modal" data-bs-target="#rebook">
                    <img src="~@/assets/img/plus.png"><span class="rebook">ReBook</span>
                  </a>
                  <router-link v-else to="/login" class="plus">
                    <img src="~@/assets/img/plus.png"><span class="rebook">ReBook</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/chats" class="chat-link" :class="$route.name == 'Chats' ? 'active' : ''">
                    <div v-if="$root.chat_unread > 0" class="chat-count">{{$root.chat_unread}}</div>
                    <img src="~@/assets/img/chat.png"><span>Chat</span>
                  </router-link>
                </li>
                <li v-if="$root.profile_view == 'merchant'">
                  <router-link to="/dashboard" :class="$route.name == 'Merchant Dashboard' ? 'active' : ''"><img src="~@/assets/img/ic_dashboard.png"><span>Dashboard</span></router-link>
                </li>
                <li v-else>
                  <router-link to="/favourites" :class="$route.name == 'favourites' ? 'active' : ''">
                    <img src="~@/assets/img/fav.png"><span>Favourites</span>
                  </router-link>
                </li>
              </ul>
          </div>
        </div>

        <!-- <div class="col-lg-3 col-md-4 footer-links d-flex pt-2  hide-footer-col">
          <div>
            <h4>More Links</h4>
             <ul>
              <li><router-link to="/terms-and-conditions">Terms Of Use</router-link></li>
              <li><a href="https://ovvyapp.com/wp-content/uploads/2017/03/Ovvy-TC-Privacy.pdf">Privacy Policy</a></li>
               <li><router-link to="/news-alerts">News & Alerts</router-link></li>         
              </ul>
          </div>
        </div> -->

        <div class="col-lg-12 footer-links pt-2 hide-footer-col">
          <div class="d-flex justify-content-between">
            <div class="d-flex ">
              <ul class="social-icons-list">
                <li><h4 style="display: inline-block;">Follow us on </h4></li>
                <li><a href="https://www.facebook.com/ovvyapp" target="_blank"><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                <li><a href="https://www.instagram.com/ovvyapp" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                <li><a href="https://www.youtube.com/@OvvyApp" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                <!-- <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li> -->
                <!-- <li><a href="https://sg.linkedin.com/company/ovvy"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li> -->
              </ul>
            </div>
            <h4>2022 Copyrights & Protected</h4>
          </div>
          <!-- <h4>Follow Us</h4> -->
          <!-- <div class="social-links d-flex">
            <a href="https://www.twitter.com/ovvyapp" class="twitter"><i class="bi bi-twitter"></i></a>
            <a href="https://www.facebook.com/ovvyapp" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/ovvyapp" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="https://sg.linkedin.com/company/ovvy" class="linkedin"><i class="bi bi-linkedin"></i></a>
          </div> -->
        </div>
      </div>
    </div>
   </div>  

  </footer><!-- End Footer -->
  <!-- End Footer -->

  <a @click="scrollToTop()" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  <!-- <a href="#" class="plus floating-action-btn" data-bs-toggle="modal" data-bs-target="#floating-action-modal"><img src="~@/assets/img/plus.png"></a> -->


  <div v-if="$root.isLoggedIn" class="floating-container" :class="$root.profile_view == 'client' ? 'client-floating-container' : 'merchant-floating-container'">
    <!-- <div class="floating-button">+</div> -->
    <div class="element-container">
      <ul class="floating-action-list">
        
          <!-- <li v-if="$root.profile_view == 'merchant'">
            <router-link to="/browse" :class="$route.name == 'Browse' ? 'active' : ''">
              <span>Browse</span>
              <div class="icon-cont"><img src="~@/assets/img/ic_search.png"/></div>
            </router-link>
          </li> -->
          <!-- <li v-else>
            <a href="#" :class="$route.name == 'home' ? 'active' : ''">
              <span>Post</span>
              <div class="icon-cont"><img src="~@/assets/img/post.png"/></div>
              
            </a>
          </li> -->
          <li>
            <a href="https://wa.me/6581261187" target="_blank" class="activity-link">
              <img src="~@/assets/img/whatsapp.png" style="width: 60px;    margin-bottom: 15px;">
            </a>
          </li>
          <li v-if="$route.name != 'activity'">
            <a v-if="$route.name == 'activity'" @click.prevent="$root.reloadPage" href="#" class="activity-link">
              <span>Activity</span>
              <div v-if="$root.profile_view == 'client' && $root.client_activity_unread > 0" class="float-client_activity-count">{{$root.client_activity_unread}}</div>
              <div class="icon-cont activity">
                <i class="bi bi-activity"></i>
                <!-- <img src="~@/assets/img/activity.png"/> -->
              </div>
            </a>
            <router-link v-else to="/activity" class="activity-link">
              <span>Activity</span>
              <div v-if="$root.profile_view == 'client' && $root.client_activity_unread > 0" class="float-client_activity-count">{{$root.client_activity_unread}}</div>
              <div class="icon-cont activity">
                <i class="bi bi-activity"></i>
                <!-- <img src="~@/assets/img/activity.png"/> -->
              </div>
            </router-link>
          </li>
          <li v-if="$root.profile_view != 'merchant'">
            <a @click.prevent="$root.getRecentJobs()" href="#" class="plus"  data-bs-toggle="modal" data-bs-target="#rebook">
              <span class="rebook">ReBook</span>
              <div class="icon-cont rebook">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg> -->
                <i class="bi bi-plus-lg"></i>
                <!-- + -->
                <!-- <img src="~@/assets/img/plus.png"/> -->
              </div>
            </a>
          </li>
          <li v-if="$route.name != 'Chats'">
            <a v-if="$route.name == 'Chats'" @click.prevent="$root.reloadPage" href="#" class="chat-link">
              <span>Chat</span>
              <div v-if="$root.chat_unread > 0" class="floating-chat-count">{{$root.chat_unread}}</div>
              <div class="icon-cont chat-icon-cont">
                <i class="bi bi-chat-left"></i>
                <!-- <img src="~@/assets/img/chat.png"/> -->
              </div>
            </a>
            <router-link v-else to="/chats" class="chat-link">
              <span>Chat</span>
              <div v-if="$root.chat_unread > 0" class="floating-chat-count">{{$root.chat_unread}}</div>
              <div class="icon-cont chat-icon-cont">
                <i class="bi bi-chat-left"></i>
                <!-- <img src="~@/assets/img/chat.png"/> -->
              </div>
            </router-link>
          </li>
          <li v-if="$root.profile_view == 'merchant' && $route.name != 'Merchant Dashboard'">
            <router-link to="/dashboard" :class="$route.name == 'Merchant Dashboard' ? 'active' : ''">
              <span>Dashboard</span>
              <div class="icon-cont">
                <i class="bi bi-pie-chart"></i>
                <!-- <img src="~@/assets/img/ic_dashboard.png"/> -->
              </div>
            </router-link>
          </li>
          <li v-if="$root.profile_view == 'client' && $route.name != 'favourites'">
            <a v-if="$route.name == 'favourites'" @click.prevent="$root.reloadPage" href="#">
              <span>Favourites</span>
              <div class="icon-cont heart-icon-cont">
                <i class="bi bi-heart"></i>
                <!-- <img src="~@/assets/img/fav.png"/> -->
              </div>
            </a>
            <router-link v-else to="/favourites" :class="$route.name == 'favourites' ? 'active' : ''">
              <span>Favourites</span>
              <div class="icon-cont heart-icon-cont">
                <i class="bi bi-heart"></i>
                <!-- <img src="~@/assets/img/fav.png"/> -->
              </div>
            </router-link>
          </li>
        </ul>
    </div>
  </div>

<div class="modal cancellation-policy" id="merchants-cancellation-policy">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <div class="close">
          <button type="button" class="btn-close" data-bs-dismiss="modal">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div class="title">
          <h6 class="modal-title">CANCELLATION POLICY</h6>
        </div>
        <div class="refresh">
          <!-- <button type="button" class="btn-close" data-bs-refresh="modal">
            <i class="fa fa-refresh"></i>
          </button> -->
        </div>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <h4>Cancellation Policy for Merchants</h4>
        <p>At Ovvy, we strive to maintain a fair marketplace connecting both clients and service providers. That means we try our level best to minimise the inconvenience and potential loss of revenue to both parties.</p>
        <p>You may cancel your bid at no cost prior to job acceptance. However, once the job has been accepted, the client would have made the necessary arrangements to remain available during the schedule date and time, or applied leave to be present at the venue. As a result, although you may still submit a request to cancel the job, the cancellation will be subjected to approval and the following cancellation terms. </p>
        <ul>
          <li>Cancellation request made at least 48 hours prior to the scheduled date and time of the job, a cancellation penality of $25 or 15% of the job price will be imposed, whichever is higher. Payments made will be refunded after deduction the cancellation fee.</li>
          <li>Cancellation request made within 48 hours from the scheduled date and time of the job, there will be no refund.</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="modal cancellation-policy" id="clients-cancellation-policy">
  <div class="modal-dialog">
    <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
        <div class="close">
            <button type="button" class="btn-close" data-bs-dismiss="modal"> <i class="fa fa-close"></i> </button>
        </div>
        <div class="title">
            <h6 class="modal-title">CANCELLATION POLICY</h6>
        </div>
        <div class="refresh">
            <!-- <button type="button" class="btn-close" data-bs-refresh="modal"><i class="fa fa-refresh"></i> </button> -->
        </div>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
        <h4>Cancellation Policy for Clients</h4>
        <p>At Ovvy, we strive to maintain a fair marketplace connecting both clients and service providers. That means we try our level best to minimise the inconvenience and potential loss of revenue to both parties.</p>
        <p>You may cancel the job post at no cost prior to job acceptance. However, once the job has been accepted, our merchant would have made the necessary arrangements to allocate sufficient time, or may have purchased the necessary equipment and tools required for your service request. As a result, although you may still submit a request to cancel the job, the cancellation request will be subjected to approval and the following cancellation terms:</p>
        <ul>
            <li>Cancellation request made at least 48 hours prior to the scheduled date and time of the job, a cancellation fee of $25 or 15% of the job price will be imposed, whichever is higher. Payments made will be refunded after deduction the cancellation fee.</li>
            <li>Cancellation request made within 48 hours from the scheduled date and time of the job, there will be no refund.</li>
        </ul>
        </div>
    </div>
  </div>
</div>

</template>

<script>
  export default {
    name: "TheFooter",
    props: {
      hide_mobile: Boolean
    },
    data() {
      return { 
        hideMobile: this.hide_mobile
      }
    },
    watch: {
      hide_mobile: function(value) {
        this.showMobile = value
      }
    },
    mounted() {
      
    },
    created() {
      
    },
    methods: {
      scrollToTop() {
        let scrollTop = document.querySelector('.scroll-top')
        window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }
</script>

<style scoped>
@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
            transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
            transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
.floating-container {
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 30%;
  right: 0;
  margin: 35px 25px;
}
/* .floating-container:hover {
  height: 220px;
} */

/* .merchant-floating-container:hover {
  height: 220px;
}

.client-floating-container:hover {
  height: 270px;
} */
/* .floating-container:hover .floating-button {
  box-shadow: 0 10px 25px rgb(190 56 51);
  -webkit-transform: translatey(5px);
          transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
} */
/* .floating-container:hover .element-container .floating-action-list {
  -webkit-animation: come-in 0.4s forwards 0.2s;
          animation: come-in 0.4s forwards 0.2s;
} */

/* .floating-action-list {
  opacity: 0
} */
.floating-container .floating-button {
  position: absolute;
  width: 65px;
  height: 65px;
  background: #f84c45;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  color: white;
  line-height: 65px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgb(219 65 59);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.floating-chat-count {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #FE4D45;
  color: #fff;
  font-size: 10px;
  top: -5px;
  right: -8px;
  display: flex;
  justify-content: center;
  padding: 3px;
}

.floating-action-list li a span {
  opacity: 0;
}

.floating-container .element-container .floating-action-list li a:hover span {
  -webkit-animation: come-in 0.4s forwards 0.2s;
  animation: come-in 0.4s forwards 0.2s;
}

@media (max-width: 768px) {
  .floating-container {
    display: none;
  }
}

@media (max-height: 993px) {
  .floating-container {
    bottom: 35%;
  }
}

@media (max-height: 859px) {
  .floating-container {
    bottom: 40%;
  }
}

@media (max-height: 750px) {
  .floating-container {
    bottom: 45%;
  }
}
</style>
