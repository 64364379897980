<template>
  <TheHeader :title="pageTitle" @back="back()"/>
  <section v-if="$root.user_id != job.client_id && $root.merchant_verified == 0 && loaded">
    <div class="main-container">
      <div class="div-resposnsive">
        <section class="header-inner">
          <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 padding-none text-center">
              <div class="nav-cleaning">
                <div class="title">
                  <label class="text-white">{{pageTitle}}</label>
                </div>
                <div class="back">
                  <a @click.prevent="back()" href="#" class="btn"><i class="fa fa-long-arrow-left text-white" ></i></a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>   
        <div class="main-content-wrp merchant-comlete-wrp">
          You have no permission to view this job. Please verify your account first <a href="/#/merchant-registration">here</a>.
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <div class="main-container">
      <div v-if="loaded" class="div-resposnsive">
        <section class="header-inner">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 padding-none text-center">
                <div class="nav-cleaning">
                  <div class="title">
                    <label class="text-white">{{pageTitle}}</label>
                  </div>
                  <div class="back">
                    <a @click.prevent="back()" href="#" class="btn"><i class="fa fa-long-arrow-left text-white" ></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>   

        <div v-if="showMainPage" class="main-content-wrp" 
          :class="$root.profile_view == 'merchant' && (jobActivityType == 'complete' || jobActivityType == 'bid')  ? 
            'merchant-comlete-wrp' : 
              $root.profile_view == 'client' && jobActivityType == 'complete' && (job.client_status == 'Cancelled' || job.client_status == 'Expired') ? 
                'client-comlete-wrp' : ''"
          >
          <!-- CLIENT BREADCRUMBS -->
          <section v-if="job.client_id == $root.user_id && job.client_activity_type == 'accepted' || 
            (job.client_activity_type == 'post' && job.bid_count > 0) ||
            (job.client_activity_type == 'complete' && (job.client_status == 'Paid' || job.client_status == 'Complete' || job.client_status == 'Incomplete'))
            " 
            class="job-status">
            <div class="container-fluid">
              <div class="row">
              <div class="col-lg-12">
                <ul v-if="job.client_id == $root.user_id">
                  <li>
                  <img src="~@/assets/images/postjob.png">
                  <span>1. Post job</span>
                  </li>
                  <li :class="job.client_activity_type == 'post' ? 'active' : ''">
                  <img src="~@/assets/images/compare.png" style="width:38px;">
                  <span>2. Compare</span>
                  </li>
                  <li :class="job.client_activity_type == 'accepted' ? 'active' : ''">
                  <img src="~@/assets/images/acceptandpay.png">
                  <span>3. Accept & Pay</span>
                  </li>
                  <li :class="job.client_activity_type == 'complete' ? 'active' : ''">
                  <img src="~@/assets/images/complete.png">
                  <span>4. Complete</span>
                  </li>
                </ul>
                <ul v-else>
                  <li :class="jobActivityType == 'bid' ? '' : 'done'">
                  <img src="~@/assets/img/ic_merchant_bid_selected.png">
                  <span>1. Bid</span>
                  </li>
                  <li :class="job.client_activity_type == 'post' ? 'active' : ''">
                  <img src="~@/assets/img/ic_merchant_win_selected.png" style="width:38px;">
                  <span>2. Win</span>
                  </li>
                  <li :class="job.client_activity_type == 'accepted' ? 'active' : ''">
                  <img src="~@/assets/images/complete.png">
                  <span>3. Complete</span>
                  </li>
                  <li :class="job.client_activity_type == 'complete' ? 'active' : ''">
                  <img src="~@/assets/img/ic_merchant_get_paid_selected.png">
                  <span>4. Get Paid</span>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          </section>
          <section v-if="job.client_id != $root.user_id && job.merchant_activity_type != 'browse'" 
            class="job-status">
            <div class="container-fluid">
              <div class="row">
              <div class="col-lg-12">
                <ul >
                  <li :class="merchantBreadcrumbClass('bid')">
                    <div style="height: 30px;"><img src="~@/assets/img/ic_merchant_bid_selected.png"/></div>
                    <span>1. Bid</span>
                  </li>
                  <li :class="merchantBreadcrumbClass('win')">
                    <div style="height: 30px;"><img src="~@/assets/img/ic_merchant_win_selected.png" style="width:38px;"/></div>
                    <span>2. Win</span>
                  </li>
                  <li :class="merchantBreadcrumbClass('complete')">
                    <div style="height: 30px;"><img src="~@/assets/images/complete.png" style="margin-top: 5px;"/></div>
                    <span>3. Complete</span>
                  </li>
                  <li :class="merchantBreadcrumbClass('paid')">
                    <div style="height: 30px;"><img src="~@/assets/img/ic_merchant_get_paid_selected.png" /></div>
                    <span>4. Get Paid</span>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          </section>

          <!--VENDOR DETAILS-->
          <section v-if="loaded && job.bid && (job.client_activity_type == 'accepted' || (job.client_activity_type == 'complete' && (job.client_status == 'Paid' || job.client_status == 'Complete' || job.client_status == 'Incomplete')))"  class="user-info">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="user-info-wrp height-more">
                      <div class="img-wrp">
                        <div v-if="job.bid.vendor.profile.avatar" class="circle-avatar" :style="'background-image: url('+job.bid.vendor.profile.avatar.medium_thumb_url+')'"></div>
                        <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user">
                        <img v-if="loaded && job.bid.vendor.profile.ovvy_verified == 1" src="~@/assets/img/verified_user.png" class="varification-img">
                      </div>
                        <h5>{{job.bid.vendor.full_name}}</h5>
                        <ul class="rating">
                            <li v-for="n in 5" :key="n"><i :class="n <= Math.round(Number(job.bid.vendor.merchant_reviews_avg_rating)) ? 'fa fa-star' : 'fa fa-star-o'"></i> </li>
                            <li> <label class="text-white">{{Number(job.bid.vendor.merchant_reviews_avg_rating).toFixed(2)}} Rating</label> </li>
                        </ul>
                        <h6>Last Online: {{$root.formatDate(job.bid.vendor.last_login, 'ddd Do MMMM, hh:mm A')}}</h6>
                        <div class="buttons">
                          <a :href="'/#/job/' + $root.encrypt((job.bid.job_id).toString()) + '/vendor/'+ $root.encrypt((job.bid.vendor_id).toString()) +'?_b=job'" type="button" class="btn view-btn">View Profile</a>
                          <a :href="'/#/job/' + $root.encrypt((job.bid.job_id).toString()) + '/chat/'+ $root.encrypt((job.bid.vendor_id).toString()) +'?_b=job'" type="button" class="btn chat-btn">Chat</a>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </section>

          <!--JOB DETAILS-->
          <a v-if="job.client_activity_type == 'post' && job.bid_count > 0  && job.type == 'bidding'" @click.prevent="showJobDetailsPage = true" href="#" class="confirmation-title mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="flex-wrp-center">
                    <div class="wrp">
                      <div class="icon">
                        <img :src="job.category.link_icon" style="width:45px;margin-right: 10px;"/>
                      </div>
                      <div class="text">
                        <h4>{{job.title}}</h4>
                        <small>{{$root.getFullAddress(job.locations[0])}}</small>
                      </div>
                    </div>
                    <i class="fa fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </a>
          <JobDetails v-else :job="job" />

          <!--BIDS-->
          <section v-if="job.client_activity_type == 'post' && job.bid_count > 0 && job.type == 'bidding'" class="post-tabs tabs-bg-white extra-lightgray mt-0">
            <div class="bids-container container-fluid">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <vSelect :options="bid_orders" 
                      label="label" 
                      :clearable="false"
                      v-model="bids_filter.order" 
                  ></vSelect>
                </div>
              </div>
              <div class="row bg-white  bid">
                <div class="col-lg-12">
                  <!-- Tab panes -->
                  <div v-for="(bid, j) in bids" :key="j" class="tab-content">
                    <div class="tab-pane container active">
                      <a @click.prevent="showBidDetails(bid)" href="view-job-elect.html" class="wrp">
                        <div class="inner">
                          <div class="icon">
                            <div v-if="bid.vendor.profile.avatar" class="circle-avatar-40" :style="'background-image: url('+bid.vendor.profile.avatar.medium_thumb_url+')'"></div>
                            <img v-else src="~@/assets/img/default_avatar@3x.png" class="img-fluid user circle-avatar-40">
                            <img v-if="bid.vendor.profile.ovvy_verified == 1" src="~@/assets/img/verified_user.png" class="varification-img" style="position: absolute;bottom: 5px;width: 20px;right: -7px;">
                          </div>
                          <div class="text">
                            <h4 class="text-dark">{{bid.vendor.full_name}}</h4>
                            <small>{{$root.formatDate(bid.date_start, 'ddd Do MMM')}}, {{$root.convertTimeTo12(bid.time_start)}}</small>
                            <ul class="stars-small">
                              <li v-for="n in 5" :key="n"><i :class="n <= Math.round(Number(bid.vendor.merchant_reviews_avg_rating)) ? 'fa fa-star' : 'fa fa-star-o'"></i> </li>
                              <li><small>{{Number(bid.vendor.merchant_reviews_avg_rating).toFixed(2)}} Rating</small> </li>
                            </ul>
                          </div>
                        </div>
                        <div class="tag-wrp-2">
                          <h4>$ {{$root.commaDecimal(bid.callout_fee)}}</h4>
                          <small>Service Fee</small>
                          <div @click.prevent="showBidDetails(bid)" class="tag btn btn-green">
                            <h6>Book</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Bid Details -->
          <section v-if="jobActivityType == 'bid' && $root.user_id != job.client_id" class="bg-white view-white">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12 mt-2">
                  <hr/>
                  <h5><strong>Your Bid</strong></h5>
                  <h6><strong>Date:</strong> {{$root.formatDate(bid.date_start, 'ddd Do MMM')}}</h6>
                  <h6><strong>Time:</strong> {{$root.convertTimeTo12(bid.time_start)}}</h6>
                  
                </div>
              </div>
            </div>
          </section>

          <!-- Payment Details -->
          <section v-if="job.bid && $root.user_id == job.bid.vendor_id" class="bg-white view-white">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12 mt-2">
                  <hr/>
                  <h5><strong>Payment</strong></h5>
                  <h6><strong>Service Fee ${{$root.commaDecimal(job.bid.callout_fee)}}</strong></h6>
                  <h6 v-if="job.bid.paid_status == 'paid_service_fee' && job.bid.price > 0"><strong>Extra Fee ${{$root.commaDecimal(job.bid.price)}}</strong></h6>
                  <h6><strong>Total ${{$root.commaDecimal(final_total)}}</strong></h6>
                  <h6>
                    <strong>
                      Transaction Fee
                      <VDropdown :showTriggers="['hover', 'click']" :hideTriggers="['click','hover']" class="custom-tooltip">
                          <!-- This will be the popover reference (for the events and position) -->
                          <a href="#" @click.prevent=""><img src="~@/assets/img/outline-alert.png" style="width: 20px;"></a>
                          <!-- This will be the content of the popover -->
                          <template #popper>
                              <p style="padding: 10px;margin-bottom: 0px;">
                                {{ 'Ovvy takes '+job.category.merchant_deduction_percent+'% transaction fee' }}
                              </p>
                          </template>
                      </VDropdown>
                      <br/>
                      -${{$root.commaDecimal(transaction_fee)}}
                    </strong> 
                    
                  </h6>
                  <h6 v-if="job.bid.merchant_penalty && job.bid.merchant_penalty > 0" style="font-size: 80%">
                    <strong>
                      Penalty 
                      
                      <VDropdown :showTriggers="['hover', 'click']" :hideTriggers="['click','hover']" class="custom-tooltip">
                          <!-- This will be the popover reference (for the events and position) -->
                          <a href="#" @click.prevent=""><img src="~@/assets/img/outline-alert.png" style="width: 20px;"></a>
                          <!-- This will be the content of the popover -->
                          <template #popper>
                              <p style="padding: 10px;margin-bottom: 0px;">
                                {{ job.bid.penalty_notes }}
                              </p>
                          </template>
                      </VDropdown>
                      <br/>
                      -${{$root.commaDecimal(job.bid.merchant_penalty)}} 
                    </strong> 
                    
                  </h6>
                  <small>
                    You will receive 
                  </small>
                  <h4 class="mt-2 text-danger">${{$root.commaDecimal(merchant_total)}}</h4>
                </div>
              </div>
            </div>
          </section>
          <section v-else-if="jobActivityType == 'bid' && bid" class="bg-white view-white">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12 mt-2">
                  <hr/>
                  <h5><strong>Payment</strong></h5>
                  <h6><strong>Service Fee ${{$root.commaDecimal(bid.callout_fee)}}</strong></h6>
                  <small>
                    You will receive 
                    <VDropdown :showTriggers="['hover', 'click']" :hideTriggers="['click','hover']" class="custom-tooltip">
                        <!-- This will be the popover reference (for the events and position) -->
                        <a href="#" @click.prevent=""><img src="~@/assets/img/outline-alert.png" style="width: 20px;"></a>
                        <!-- This will be the content of the popover -->
                        <template #popper>
                            <p style="padding: 10px;margin-bottom: 0px;">
                              {{ 'Ovvy takes '+job.category.merchant_deduction_percent+'% transaction fee' }}
                            </p>
                        </template>
                    </VDropdown>
                  </small>
                  <h4 class="mt-2 text-danger">${{$root.commaDecimal(bid.callout_fee - (bid.callout_fee * (Number(job.category.merchant_deduction_percent) / 100)))}}</h4>
                </div>
              </div>
            </div>
          </section>
          <section v-else-if="jobActivityType == 'browse' && $root.user_id != job.client_id && job.type == 'fixed'" class="bg-white view-white">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12 mt-2">
                  <hr/>
                  <h5><strong>Payment</strong></h5>
                  <h6><strong>Service Fee ${{$root.commaDecimal(job.fixed_price)}}</strong></h6>
                  <br/>
                  <h5><strong>Total ${{$root.commaDecimal(job.fixed_price)}}</strong></h5>
                  <small>
                    You will receive 
                    <VDropdown :showTriggers="['hover', 'click']" :hideTriggers="['click','hover']" class="custom-tooltip">
                        <!-- This will be the popover reference (for the events and position) -->
                        <a href="#" @click.prevent=""><img src="~@/assets/img/outline-alert.png" style="width: 20px;"></a>
                        <!-- This will be the content of the popover -->
                        <template #popper>
                            <p style="padding: 10px;margin-bottom: 0px;">
                              {{ 'Ovvy takes '+job.category.merchant_deduction_percent+'% transaction fee' }}
                            </p>
                        </template>
                    </VDropdown>
                  </small>
                  <h4 class="mt-2 text-danger">${{$root.commaDecimal(job.fixed_price - (job.fixed_price * (Number(job.category.merchant_deduction_percent) / 100)))}}</h4>
                </div>
              </div>
            </div>
          </section>

          <section v-if="jobActivityType == 'complete' && $root.user_id == job.client_id && job.status != 'cancel' && job.status != 'expired'" class="bg-white view-white">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12 mt-2">
                  <hr/>
                  <h6><strong>Total</strong><strong class="pull-right">${{$root.commaDecimal(final_total)}}</strong></h6>
                </div>
              </div>
            </div>
          </section>

          <!-- Posted By -->
          <section v-if="$root.user_id != job.client_id"  class="row-white mt-2 posted-by">
            <div class="container-fluid">
              <div class="row">
                  <div class="col-lg-12">
                    <hr/>
                    <a v-if="job.bid && job.bid.vendor_id == $root.user_id && job.merchant_activity_type == 'complete'" 
                      :href="'/#/job/' + $root.encrypt((job.id).toString()) + '/chat/'+$root.encrypt(($root.user_id).toString())+'?_b=job'"
                      class="flex-wrp-center color-black" >
                      <div class="wrp flex-row">
                        <img v-if="job.client.profile.avatar" :src="job.client.profile.avatar.medium_thumb_url">
                        <img v-else src="~@/assets/images/posted.png">
                        <div>
                            <p>Posted By</p>
                            <h6>{{job.client.full_name}}</h6>
                        </div>
                      </div>  
                      <div >
                        <p><em>Chat <i class="fa fa-angle-right black-color"></i></em></p>
                      </div>
                    </a>
                    <div v-else class="flex-wrp-center">
                      <div class="wrp flex-row">
                        <img v-if="job.client.profile.avatar" :src="job.client.profile.avatar.medium_thumb_url">
                        <img v-else src="~@/assets/images/posted.png">
                        <div>
                            <p>Posted By</p>
                            <h6>{{job.client.full_name}}</h6>
                        </div>
                      </div>  
                    </div>
                    <hr/>  
                  </div>
              </div>
            </div>
          </section>

          <div v-if="jobActivityType == 'won' || jobActivityType == 'bid'" style="height: 50px"></div>
          
          <!-- BUTTONS -->
          <div :class="jobActivityType == 'complete' ? '' : 'hide_tablet'">
            <section v-if="jobActivityType == 'post' || jobActivityType == 'browse'">
              <div class="container-fluid mt-2">
                    <div class="">
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <router-link v-if="$root.user_id == job.client_id && job.bid_count == 0" :to="'/job/' + $root.encrypt((job.id).toString()) + '/edit' " class="btn btn-green w100 paid-btn mb-1">
                            Edit Job
                          </router-link>
                          <button v-if="$root.user_id == job.client_id && job.bid_count == 0" @click="cancelPostJob()" type="button" class="btn btn-light w100 paid-btn">
                            Cancel Job
                          </button>
                          <p v-else-if="$root.user_id != job.client_id && job.status == 'cancel'">
                            <strong>This job has been Cancelled.</strong>
                          </p>
                          <p v-else-if="$root.user_id != job.client_id && job.status == 'expired'">
                            <strong>This job has Expired.</strong>
                          </p>
                          <button v-else-if="$root.user_id != job.client_id && job.bid_id == null" @click="vendorBidProcess()" type="button" class="btn btn-green w100 paid-btn">
                            {{job.type == 'bidding' ? 'Bid' : 'Accept'}}
                          </button>
                          <p v-else-if="$root.user_id != job.client_id && job.bid_id != null">
                            <strong>This job has already been taken. Act faster next time.</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
            </section>
            <section v-else-if="jobActivityType == 'complete'">
              <div :class="job.client_status == 'Cancelled' || job.client_status == 'Expired' ? 'hide_tablet' : ''" class="container-fluid mt-2">
                    <div class="">
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <button v-if="$root.user_id == job.client_id && job.category.status_for_client == 'active'" type="button" class="btn btn-green w100 paid-btn" data-bs-toggle="modal" data-bs-target="#job-rebook">
                            Rebook
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
            </section>
            <section v-else-if="jobActivityType == 'won'">
              <div class="container-fluid mt-2">
                    <div class="">
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <button v-if="job.bid && $root.user_id == job.bid.vendor_id" @click="showIfJobCompletePage = true" type="button" 
                            :class=" Number(job.bid.price) > 0 && job.bid.paid_status == 'paid_callout' ? 'disabled' : ''"
                            class="btn btn-green w100 paid-btn">
                            Mark Complete ${{$root.commaDecimal(final_total)}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
            </section>
            <section v-else-if="jobActivityType == 'bid' && !job.bid && bid && $root.user_id != job.client_id">
              <div class="container-fluid mt-2">
                    <div class="">
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <button @click="setBidForm(bid);showAcceptPage = true" type="button" class="btn btn-green w100 paid-btn">
                            Edit Bid
                          </button>

                          <button @click="cancelMyBid(bid)" type="button" class="btn btn-light mt-2 w100 paid-btn">
                            Cancel Bid
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
            </section>
          </div>

          <!-- CHAT -->
          <section v-if="jobActivityType == 'bid' || jobActivityType == 'won' || (jobActivityType == 'browse' && !job.bid && job.status == 'open')" class="chat-icon hide_tablet">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <a :href="'/#/job/' + $root.encrypt((job.id).toString()) + '/chat/'+$root.encrypt(($root.user_id).toString())+'?_b=job'" class="btn">Chat</a>
                </div>
              </div>
            </div>
          </section> 

          <!-- ASK FOR EXTRA FEE -->
          <section v-if="jobActivityType == 'won' && job.bid && 
            job.bid.paid_status == 'paid_callout' && $root.user_id == job.bid.vendor_id" class="extra-fee-icon hide_tablet">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <div v-if="Number(job.bid.price) > 0" class="pending">
                      <span class="price">${{$root.commaDecimal(job.bid.price)}}</span> <br>
                      Additional charge pending<br/>
                      <a href="" data-bs-toggle="modal" data-bs-target="#extra-fees-modal" type="button"><em>Edit</em></a>
                      <span style="color: #FE4D45;margin: 0px 5px;"><em>|</em></span>
                      <a @click.prevent="cancelExtraFeeRequest()" href="#" type="button" class="margin-left: 20px;"><em>Cancel</em></a>
                    </div>
                    <button v-else class="btn" data-bs-toggle="modal" data-bs-target="#extra-fees-modal">
                      <span>+</span>
                      <span style="font-size: 30px;">$</span>
                    </button>
                  </div>
                </div>
              </div>
          </section> 
        </div>
        <div v-else-if="showAcceptPage" class="main-content-wrp">

          <div v-if="$root.phone_verified == 0">
            <OtpVerify :hide_header="true"/>
          </div>
          <div v-else>
            <section class="grey-bg mt-3">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12" style="text-align:left">
                    <h6>{{job.type == 'fixed' ? 'Fixed Price' : 'Enter Bid Charge'}}</h6>
                  </div>
                </div>
              </div>
            </section>
            <section class="bid-price">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <input v-if="job.type == 'bidding'" v-model="form.callout_fee" type="text" 
                    @change="addDollar()"
                    class="amount-field hide_number_arrow mb-3" placeholder="$0.00"/>
                    <h1 v-else class="text-center mt-2">${{$root.commaDecimal(form.callout_fee)}}</h1>
                    <small>
                      You will receive ${{$root.commaDecimal(merchantReceivable)}} 
                      <VDropdown :showTriggers="['hover', 'click']" :hideTriggers="['click','hover']" class="custom-tooltip">
                          <!-- This will be the popover reference (for the events and position) -->
                          <a href="#" @click.prevent=""><img src="~@/assets/img/outline-alert.png" style="width: 20px;"></a>
                          <!-- This will be the content of the popover -->
                          <template #popper>
                              <p style="padding: 10px;margin-bottom: 0px;">
                                {{ 'Ovvy takes '+job.category.merchant_deduction_percent+'% transaction fee' }}
                              </p>
                          </template>
                      </VDropdown>
                    </small>
                  </div>
                </div>
              </div>
            </section>
            <section class="grey-bg mt-3">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-6" style="text-align:left">
                    <h6>{{ job_date_detail ? job_date_detail.question : 'What date would you like the service to be rendered?'}}</h6>
                  </div>
                  <div class="col-sm-6" style="text-align:left">
                    <h6 class="mt-2">
                      <span>{{$root.formatDate(job.date_from, 'ddd Do MMM')}}</span>
                      <span v-if="job.date_from != job.date_to"> - {{$root.formatDate(job.date_to, 'ddd Do MMM')}}</span>
                      <!-- <br v-if="job.allow_counter_proposal == 1"/>
                      <span v-if="job.allow_counter_proposal == 1"><small>or Counter Offer!</small></span> -->
                    </h6>
                  </div>
                </div>
              </div>
            </section>
            <section class="row-white mt-3">
              <div class="container-fluid">
                <div class="row date-picker m-0">
                  <div class="col-lg-12 col-12">
                    <!-- <div v-if="job.allow_counter_proposal == 1" class="inner m-0 p-0">
                      <Datepicker v-model="form.full_date" :enableTimePicker="false" 
                        @update:modelValue="updateDate('date')" :clearable="false" 
                        :minDate="new Date()"
                        autoApply hideInputIcon>
                      </Datepicker>
                    </div> -->
                    <div v-if="job.date_from != job.date_to" class="inner m-0 p-0">
                      <Datepicker v-model="form.full_date" :enableTimePicker="false" 
                        @update:modelValue="updateDate('date')" :clearable="false" 
                        :minDate="new Date()"
                        :maxDate="new Date(job.date_to)"
                        autoApply hideInputIcon>
                      </Datepicker>
                    </div>
                    <div v-else class="inner m-0 p-0">
                      {{$root.formatDate(form.date, 'ddd Do MMM')}}
                      <div id="dtBox"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="grey-bg mt-4">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-6" style="text-align:left">
                    <h6>{{ job_time_detail ? job_time_detail.question : 'What time would you like to start the move?' }}</h6>
                  </div>
                  <div class="col-sm-6" style="text-align:left">
                    <h6 class="mt-2">
                      <span>{{$root.convertTimeToLocal(job.time_from)}}</span> - 
                      <span>{{$root.convertTimeToLocal(job.time_to)}}</span>
                      <br v-if="job.allow_counter_proposal == 1"/>
                      <span v-if="job.allow_counter_proposal == 1"><small>or Counter Offer!</small></span>
                    </h6>
                  </div>
                </div>
              </div>
            </section>
            <section class="time-picker">
              <div class="container-fluid">
                <div class="row">
                  <div v-if="job.allow_counter_proposal == 1" class="col-lg-12 pt-3">
                    <Datepicker v-model="form.full_time" :is24="false" 
                    @update:modelValue="updateDate('time')"
                    :startTime="{ hours: $root.convertTimeToLocal(job.time_from, 'HH'), minutes: 0 }"
                    :minTime="{ hours: Number($root.convertTimeToLocal(job.time_from, 'HH')) - 3 }"
                    :maxTime="{ hours: Number($root.convertTimeToLocal(job.time_to, 'HH')) + 3}"
                    timePicker hideInputIcon :clearable="false" no-hours-overlay/>
                  </div>
                  <div v-else class="col-lg-12 pt-3">
                    <Datepicker v-model="form.full_time" :is24="false" 
                    @update:modelValue="updateDate('time')"
                    :startTime="{ hours: $root.convertTimeToLocal(job.time_from, 'HH'), minutes: 0 }"
                    :minTime="{ hours: $root.convertTimeToLocal(job.time_from, 'HH') }"
                    :maxTime="{ hours: $root.convertTimeToLocal(job.time_to, 'HH')}"
                    timePicker hideInputIcon :clearable="false" no-hours-overlay/>
                  </div>
                </div>
              </div>
            </section>
            <section class="grey-bg mt-3">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12" style="text-align:left">
                    <h6>Comments</h6>
                  </div>
                </div>
              </div>
            </section>
            <section class="mt-3">
              <div class="container-fluid">
                <div class="row ">
                  <div class="col-lg-12 p-0" style="text-align:left">
                    <small>Enter comments for the client</small>
                    <textarea v-model="form.comment" rows="4" class="form-control mt-1"></textarea>
                  </div>
                </div>
              </div>
            </section>
            <div class="mt-3 row hide_tablet">
              <div class="col-lg-12 text-center">
                <button @click="nextAcceptJob()" type="button" class="btn btn-green w100 paid-btn">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="showConfirmPage" class=" main-content-wrp">
          <section class="general-cleaning">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-lg-12">
                  <div class="wrp">
                    <div>
                      <h4 class="m-0">{{job.category.name}}</h4>
                    </div>
                    <img :src="job.category.link_icon" style="width:65px;">
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="row-white  payment">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <h5>Service Charges</h5>
                  <h4 class="mt-2 text-danger">{{display_callout_fee}}</h4>
                  <small>
                    You will receive ${{$root.commaDecimal(merchantReceivable)}} 
                    <VDropdown :showTriggers="['hover', 'click']" :hideTriggers="['click','hover']" class="custom-tooltip">
                        <!-- This will be the popover reference (for the events and position) -->
                        <a href="#" @click.prevent=""><img src="~@/assets/img/outline-alert.png" style="width: 20px;"></a>
                        <!-- This will be the content of the popover -->
                        <template #popper>
                            <p style="padding: 10px;margin-bottom: 0px;">
                              {{ 'Ovvy takes '+job.category.merchant_deduction_percent+'% transaction fee' }}
                            </p>
                        </template>
                    </VDropdown>
                  </small>
                </div>
              </div>
            </div>
          </section>
          <section class="row-white mt-2 payment">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <h5>Date & Time</h5>
                  <h6 class="mt-2">{{$root.formatDate(form.date, 'ddd Do MMM')}}</h6>
                  <h6>{{$root.convertTimeTo12(form.time)}}</h6>
                </div>
              </div>
            </div>
          </section>
          <section v-if="form.comment != ''" class="row-white mt-2 payment">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <h5>Comments</h5>
                  <h6>{{form.comment}}</h6>
                </div>
              </div>
            </div>
          </section>
          <section class="row-white mt-2 payment">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <p class="text-info">IMPT: Kindly ensure of your availability before confirming the job. We appreciate your commitment to the time and date.</p>
                  <p class="mt-4">By proceeding, you accept the latest version of <a href="#" data-bs-toggle="modal" data-bs-target="#merchants-cancellation-policy">Cancellation Policy.</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div class="mt-3 row hide_tablet">
            <div class="col-lg-12 text-center">
              <button @click="confirmAcceptJob()" type="button" class="btn btn-green w100 paid-btn">
                CONFIRM {{display_callout_fee}}
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="showJobDetailsPage" class="main-content-wrp">
          <JobDetails :job="job" />
        </div>
        <div v-else-if="showBidDetailsPage" class="main-content-wrp">
          <BidDetails :bid="bid" :job="job"/>
          <p v-if="job && jobActivityType == 'accepted' && job.client_id == $root.user_id" class="text-center mt-2">
            <small v-if="job.cancel_request">
              <a @click.prevent="" class="red"><em>Cancel Request Pending</em></a>
            </small> 
            <small v-else>
              <a @click.prevent="showCancelRequestPage = true" >Request to Cancel Job</a>
            </small> 
          </p>
        </div>
        <div v-else-if="showIfJobCompletePage" class="main-content-wrp">
          <IfJobComplete :job="job" :hide_header="true" :hide_job_details="true"
            @back="showMainPage = true"/>
        </div>
        <div v-else-if="showBidJobDetailsPage" class="main-content-wrp">
          <JobDetails :job="job" />
        </div>
        <div v-else-if="showCancelRequestPage" class="main-content-wrp">
          <section class="row-gray">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <h6>Reason for Cancellation</h6>
                </div>
              </div>
            </div>
          </section>
          <section class="reason">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="check-box">
                    <label class="form-check-label" for="creason1">Submitted in error</label>
                    <input v-model="cancel_form.reason" type="radio" class="form-check-input" id="creason1" name="reason" value="Submitted in error">
                  </div>
                  <div class="check-box">
                    <label class="form-check-label" for="creason2">Found another vendor/merchant</label>
                    <input v-model="cancel_form.reason" type="radio" class="form-check-input" id="creason2" name="reason" value="Found another vendor/merchant">
                  </div>
                  <div class="check-box">
                    <label class="form-check-label" for="creason3">No show by merchant/vendor</label>
                    <input v-model="cancel_form.reason" type="radio" class="form-check-input" id="creason3" name="reason" value="No show by merchant/vendor">
                  </div>
                  <div class="check-box">
                    <label class="form-check-label" for="creason4">Other</label>
                    <input v-model="cancel_form.reason" type="radio" class="form-check-input" id="creason4" name="reason" value="Other">
                  </div>
                </div>
                <div class="col-lg-12">
                  <small>Please use the text box below to describe in as much as detail possible. We will be in touch with any questions/clarifications.</small>
                  <textarea v-model="cancel_form.memo" class="form-control" rows="5" id="comment" :disabled="cancel_form.reason != 'Other'"></textarea>
                </div>
              </div>
            </div>
          </section>
          <section class="text-center">
            <a @click.prevent="submitCancelRequestForm()" href="#" class="btn next-btn">SUBMIT</a>
          </section>
        </div>
      </div>
    </div>

    <!-- FOOTER BUTTON -->
    <section v-if="showMainPage" class="footer-button show_tablet" >
      <div class="container-fluid">
        <div class="row">
          <div v-if="jobActivityType == 'complete' && (job.client_status == 'Cancelled' || job.client_status == 'Expired')"
            class="footer-job-status-container col-lg-12">
            <p>{{ job.client_status }}</p>
          </div>
          <div class="col-lg-12 text-center">
              <div class="container-fluid mt-2">
                <div class="row">
                  <div v-if="jobActivityType == 'bid' || jobActivityType == 'won'  || (jobActivityType == 'browse' && !job.bid && job.status == 'open')" class="col-lg-12 show_tablet footer-chat-icon">
                      <a :href="'/#/job/' + $root.encrypt((job.id).toString()) + '/chat/'+$root.encrypt(($root.user_id).toString())+'?_b=job'" class="btn" :class="jobActivityType">Chat</a>
                  </div>
                  <div v-if="jobActivityType == 'won' && job.bid && job.bid.paid_status == 'paid_callout' && $root.user_id == job.bid.vendor_id" 
                    class="col-lg-12 show_tablet footer-extra-fee-icon">
                      <div v-if="Number(job.bid.price) > 0" class="pending">
                        <span class="price">${{$root.commaDecimal(job.bid.price)}}</span> <br>
                        Additional charge pending<br/>
                        <a href="" data-bs-toggle="modal" data-bs-target="#extra-fees-modal" type="button"><em>Edit</em></a>
                        <span style="color: #FE4D45;margin: 0px 5px;"><em>|</em></span>
                        <a @click.prevent="cancelExtraFeeRequest()" href="#" type="button" class="margin-left: 20px;"><em>Cancel</em></a>
                      </div>
                      <button v-else class="btn" data-bs-toggle="modal" data-bs-target="#extra-fees-modal">
                        <span>+</span>
                        <span style="font-size: 30px;">$</span>
                      </button>
                  </div>
                </div>
              </div>
              <!-- BUTTONS -->
              <section v-if="jobActivityType == 'post' || jobActivityType == 'browse'">
                <div class="container-fluid mt-2">
                      <div class="">
                        <div class="row">
                          <div class="col-lg-12 text-center">
                            <router-link v-if="$root.user_id == job.client_id && job.bid_count == 0" :to="'/job/' + $root.encrypt((job.id).toString()) + '/edit'" class="btn btn-green w100 paid-btn mb-1">
                              Edit Job
                            </router-link>
                            <button v-if="$root.user_id == job.client_id && job.bid_count == 0" @click="cancelPostJob()" type="button" class="btn btn-light w100 paid-btn">
                              Cancel Job
                            </button>
                            <p v-else-if="$root.user_id != job.client_id && job.status == 'cancel'">
                              <strong>This job has been Cancelled.</strong>
                            </p>
                            <p v-else-if="$root.user_id != job.client_id && job.status == 'expired'">
                              <strong>This job has Expired.</strong>
                            </p>
                            <button v-else-if="$root.user_id != job.client_id && job.bid_id == null" @click="vendorBidProcess()" type="button" class="btn btn-green w100 paid-btn">
                              {{job.type == 'bidding' ? 'Bid' : 'Accept'}}
                            </button>
                            <p v-else-if="$root.user_id != job.client_id && job.bid_id != null">
                              <strong>This job has already been taken. Act faster next time.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
              </section>
              <section v-else-if="jobActivityType == 'complete' && (job.client_status == 'Cancelled' || job.client_status == 'Expired')">
                <div class="container-fluid mt-2">
                      <div class="">
                        <div class="row">
                          <div class="col-lg-12 text-center">
                            <button v-if="$root.user_id == job.client_id && job.category.status_for_client == 'active'" type="button" class="btn btn-green w100 paid-btn" data-bs-toggle="modal" data-bs-target="#job-rebook">
                              Rebook
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
              </section>
              <section v-else-if="jobActivityType == 'won'">
                <div class="container-fluid mt-2">
                      <div class="">
                        <div class="row">
                          <div class="col-lg-12 text-center">
                            <button v-if="job.bid && $root.user_id == job.bid.vendor_id" @click="showIfJobCompletePage = true" type="button" 
                              class="btn btn-green w100 paid-btn" 
                              :class=" Number(job.bid.price) > 0 && job.bid.paid_status == 'paid_callout' ? 'disabled' : ''">
                              Mark Complete ${{$root.commaDecimal(final_total)}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
              </section>
              <section v-else-if="jobActivityType == 'bid' && !job.bid && bid && $root.user_id != job.client_id">
                <div class="container-fluid mt-2">
                      <div class="">
                        <div class="row">
                          <div class="col-lg-12 text-center">
                            <button @click="setBidForm(bid);showAcceptPage = true" type="button" class="btn btn-green w100 paid-btn mb-1">
                              Edit Bid
                            </button>

                            <button @click="cancelMyBid(bid)" type="button" class="btn btn-light mt-2 w100 paid-btn">
                              Cancel Bid
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
              </section>
          </div>
        </div>
      </div>
    </section>
    <section v-else-if="showAcceptPage" class="footer-button show_tablet" >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <button @click="nextAcceptJob()" type="button" class="btn btn-green w100 paid-btn">
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
    <section v-else-if="showConfirmPage" class="footer-button show_tablet" >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <button @click="confirmAcceptJob()" type="button" class="btn btn-green w100 paid-btn">
              CONFIRM {{display_callout_fee}}
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- STATUS -->
    <section v-if="loaded && showMainPage && jobActivityType == 'complete' && $root.user_id == job.client_id && (job.client_status != 'Complete' && job.client_status != 'Paid')" 
      id="job-status-container">
      <div class="container-fluid mt-2">
        <div class="">
          <div class="row">
            <div class="col-lg-12">
                <p id="jobStat-title" class="job-stat-title">{{ job.client_status }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="loaded && showMainPage && jobActivityType == 'complete' && $root.user_id != job.client_id && job.bid" id="merchant-job-status-container">
      <div class="container-fluid mt-2">
        <div class="">
          <div class="row">
            <div class="col-lg-6">
              <small v-if="job.bid.end_datetime">{{ $root.convertUTCToLocal(job.bid.end_datetime, 'ddd Do MMMM, hh:mm A') }}</small>
              <p id="jobStat-title">{{ job.merchant_status }}</p>
            </div>
            <div class="col-lg-6 text-right">
              <small>
                Transaction Fee: {{job.category.merchant_deduction_percent}}%
                <span v-if="job.bid.merchant_penalty && job.bid.merchant_penalty > 0">& Penalty: ${{$root.commaDecimal(job.bid.merchant_penalty)}}</span>
              </small>
              <p id="jobStat-title">${{$root.commaDecimal(merchant_total)}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </section>
  <!-- <div class="space-maker"></div>    -->
  <TheFooter :hide_mobile="true"/>

<div class="modal" id="extra-fees-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <div class="title">
          <h6 class="modal-title">Extra Fee</h6>
         </div>
         <div class="close">
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
       </div>

      <!-- Modal body -->
      <div class="modal-body">
        <h6>Enter your extra fee</h6>
        <p class="amonut-title">Extra fee charges required to complete job</p>
        <input v-model="extra_fee_form.price" type="number" class="amount-field hide_number_arrow" />
        <br/>
        <button @click="requestExtraFee()" type="button" class="btn btn-green w100 paid-btn" data-bs-dismiss="modal" :disabled="extra_fee_form.price == ''">Submit</button>
      </div>
    </div>
  </div>
</div>

<div v-if="loaded" class="modal" id="job-rebook">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <div class="wrape bg-gray">
            <img :src="job.category.link_icon">
            <div class="text">
              <h6>{{job.title}}</h6>
              <p v-if="job.locations[0]">{{$root.getFullAddress(job.locations[0])}}</p>
            </div>
          </div>

          <ul id="recent-job-choice-list">
            <li>
              <input type="radio" name="rebook_job_to" v-model="$root.rebook_job_to"  :value="'all'"/>
              <label>
                <span style="margin-right: 10px;position: relative;top: 8px;margin-left:20px">
                  <img src="~@/assets/img/ic_everyone.png" style="width: 40px;">
                </span>
                <span style="position: relative;top: 5px;">
                  <strong>Send to Everyone</strong>
                </span>
              </label>
            </li>

            <li v-if="job.bid">
              <input type="radio" name="rebook_job_to" v-model="$root.rebook_job_to" :value="'vendor'"/>
              <label v-if="job.bid.vendor.profile.avatar">
                <span style="margin-right: 10px;position: relative;top: 5px;left: 20px;">
                  <div class="circle-avatar-40" :style="'background-image: url('+job.bid.vendor.profile.avatar.medium_thumb_url+');margin-right:0px;position: relative;'"></div>
                  <img v-if="job.bid.vendor.profile.ovvy_verified" src="~@/assets/img/verified_user.png" class="verify-img" style="bottom: -13px;"> 
                </span>
                <span style="position: relative;top: 18px;left: 20px;">
                  <strong>Send to {{ job.bid.vendor.full_name }}</strong>
                </span>
              </label>
              <label v-else>
                <span style="margin-right: 10px;position: relative;top: 10px;left: 20px;">
                  <img src="~@/assets/img/profile-pic.png"  style="width: 40px;">
                  <img v-if="job.bid.vendor.profile.ovvy_verified" src="~@/assets/img/verified_user.png" class="verify-img" style="bottom: -13px;"> 
                </span>
                <span style="position: relative;top: 10px;left: 20px;">
                  <strong>Send to {{ job.bid.vendor.full_name }}</strong>
                </span>
              </label>
            </li>
          </ul>

          <div class="buttons">
            <a href="#" class="close btn"  data-bs-dismiss="modal">CLOSE</a>
            <router-link v-if="$root.rebook_job_to == 'all'" :to="'/jobs/new/' + $root.encrypt((job.category_id).toString()) + '?_jid=' + $root.encrypt((job.id).toString())" class="repost btn">REPOST</router-link>
            <router-link v-else-if="$root.rebook_job_to == 'vendor'" :to="'/jobs/new/' + $root.encrypt((job.category_id).toString()) + '?_vid=' + $root.encrypt((job.bid.vendor_id).toString())" class="repost btn">REPOST</router-link>
            <a v-else href="#" @click.prevent="$root.repostError" class="repost btn">REPOST</a>
          </div>
        </div>
      </div>
    </div>
</div>


</template>

<script>
import TheHeader from '@/components/common/TheHeader'
import TheFooter from '@/components/common/TheFooter'
import JobDetails  from '@/components/jobs/details'
import BidDetails  from '@/components/bids/details'
import OtpVerify from '@/components/auth/OtpVerify'
import IfJobComplete  from '@/components/jobs/if_complete'
import { getJob, submitBid, getJobBids, getMediaFiles, getMyBid, updateBid, cancelJob, getVendorBid, editBid, cancelBid, requestCancelJob } from "@/api"
import moment from 'moment'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: 'JobView',
  components: {
    TheHeader,
    TheFooter,
    JobDetails,
    BidDetails,
    vSelect,
    IfJobComplete,
    OtpVerify,
  },
  data() {
    return {
      loaded: false,
      pageTitle: 'Job Detail',
      showMainPage: true,
      showAcceptPage: false,
      showConfirmPage: false,
      showJobDetailsPage: false,
      showBidDetailsPage: false,
      showExtraFeePage:false,
      showIfJobCompletePage: false,
      showBidJobDetailsPage: false,
      showCancelRequestPage:false,
      id: '',
      job: {},
      job_time_detail: {},
      job_date_detail: {},
      bids: [],
      bids_filter: {
        order: {
          value: 'id',
          dir: 'desc',
          label: 'MOST RECENT BID'
        }
      },
      bid_orders: [
        {
          value: 'id',
          dir: 'desc',
          label: 'MOST RECENT BID'
        },
        {
          value: 'vendor_highest_rated',
          dir: 'desc',
          label: 'HIGHEST RATED'
        },
        {
          value: 'price',
          dir: 'asc',
          label: 'LOWEST PRICE'
        },
        {
          value: 'vendor_most_completed_jobs',
          dir: 'desc',
          label: 'MOST JOBS COMPLETED'
        },
      ],
      bid: {},
      status: 'paid',
      total: 240,
      useCredits: false, 
      form: {
        callout_fee: '',
        date: '',
        full_date: '',
        time: '',
        full_time: '',
        comment: ''
      },
      extra_fee_form: {
        price: ''
      },
      cancel_form: {
        reason: '',
        memo: ''
      },
    }
  },
   watch: {
    showMainPage: function(newVal) {
      if(newVal) {
        console.log(newVal)
        console.log('show main page')
        this.pageTitle = 'Job Detail'
        this.showAcceptPage = !newVal
        this.showConfirmPage = !newVal
        this.showJobDetailsPage = !newVal
        this.showBidDetailsPage = !newVal
        this.showIfJobCompletePage = !newVal
        this.showBidJobDetailsPage = !newVal
        this.showCancelRequestPage = !newVal

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
      }
    },
    showAcceptPage: function (newVal) {
      if(newVal) {
        this.pageTitle = this.job.category.name
        this.$root.scrollToTop()

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
        this.showMainPage = !newVal
      }
    },
    showConfirmPage: function (newVal) {
      if(newVal) {
        this.pageTitle = 'Confirm'
        this.$root.scrollToTop()

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
        this.showMainPage = !newVal
      }
    },
    showJobDetailsPage: function(newVal) {
      if(newVal) {
        this.pageTitle = 'Job Detail'
        this.$root.scrollToTop()

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
        this.showMainPage = !newVal
      }
      
    },
    showBidDetailsPage: function(newVal) {
      if(newVal) {
        this.pageTitle = 'Job'
        this.$root.scrollToTop()

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
        this.showMainPage = !newVal
      }
      
    },
    showIfJobCompletePage: function(newVal) {
      if(newVal) {
        this.pageTitle = 'SERVICE COMPLETION'
        this.$root.scrollToTop()

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
        this.showMainPage = !newVal
        
      }
      
    },
    showBidJobDetailsPage: function(newVal) {
      if(newVal) {
        this.pageTitle = 'Job Detail'
        this.$root.scrollToTop()

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
        this.showMainPage = !newVal
      }
      
    },
    showCancelRequestPage: function(newVal) {
      if(newVal) {
        this.pageTitle = 'Request to Cancel Job'
        this.$root.scrollToTop()

        const $this = this
        setTimeout(function() {
          $this.$root.manualAdjustScreen(30);
        }, 100)
        this.showBidDetailsPage =  !newVal
        this.showMainPage = !newVal
      }
      
    },
    'bids_filter.order': function () {
      this.getBids()
    },
  },
  computed: {
    jobActivityType() {
      return this.job.client_id == this.$root.user_id ? this.job.client_activity_type : this.job.merchant_activity_type
      // if(this.job.status == 'open' && !this.job.bid) return 'posts'
      // return this.job.status;
    },
    merchantReceivable() {
      let callout_fee = (this.form.callout_fee).toString().replace(/[^\d.]/g,'');
      if(callout_fee != '' && callout_fee > 0) {
        let deduct = Number(this.job.category.merchant_deduction_percent) / 100;
        return callout_fee - (callout_fee * deduct)
      }else return 0;
    },
    final_total() {
      let total = Number(this.job.bid.callout_fee);
      if(this.job.bid.paid_status == 'paid_service_fee') {
        total += Number(this.job.bid.price);
      }
      return total
    },
    transaction_fee() {
      let deduct = Number(this.job.category.merchant_deduction_percent) / 100,
        total = Number(this.job.bid.callout_fee);
      if(this.job.bid.paid_status == 'paid_service_fee') total += Number(this.job.bid.price)
      return total * deduct
    },
    merchant_total() {
      let deduct = Number(this.job.category.merchant_deduction_percent) / 100,
        total = Number(this.job.bid.callout_fee);
      if(this.job.bid.paid_status == 'paid_service_fee') total += Number(this.job.bid.price)

      let merchtotal = total  - (total * deduct);

      if(this.job.bid.merchant_penalty && this.job.bid.merchant_penalty > 0) merchtotal -= Number(this.job.bid.merchant_penalty)

      return merchtotal;
    },
    display_callout_fee() {
      let val = (this.form.callout_fee).toString().replace(/[^\d.]/g,'');
      return '$' + this.$root.commaDecimal(val);
    },
    actual_callout_fee() {
      return Number((this.form.callout_fee).toString().replace(/[^\d.]/g,''));
    }
  },
  methods: {
    back() {
      if(this.job.client_id == this.$root.user_id) {
        if(this.$route.query._b && this.$route.query._b == 'chat') {
          if(this.$route.query._cvid) {
            window.location.href = window.location.origin + '/#/job/' + this.$route.params.code + '/chat/' + this.$route.query._cvid;
          }else {
            window.location.href = window.location.origin + '/#/job/' + this.$route.params.code + '/chat/' + this.$root.encrypt((this.bid.vendor_id).toString());
          }
        }else if(this.showJobDetailsPage) {
          this.$root.scrollToTop()
          this.showMainPage = true
        }else if(this.showBidJobDetailsPage) {
          this.$root.scrollToTop()
          this.showBidJobDetailsPage = false
          this.showBidDetailsPage = true
        }else if(this.showBidDetailsPage && this.jobActivityType == 'accepted') {
          window.location.href = window.location.origin + '/#/activity#' + this.jobActivityType;
        }else if(this.showBidDetailsPage && this.jobActivityType == 'complete') {
          window.location.href = window.location.origin + '/#/activity#' + this.jobActivityType;
        }else if(this.showBidDetailsPage) {
          this.$root.scrollToTop()
          this.showMainPage = true
        }else if(this.showCancelRequestPage) {
          this.$root.scrollToTop()
          this.showCancelRequestPage = false
          this.showBidDetailsPage = true
        }else {
          window.location.href = window.location.origin + '/#/activity#' + this.jobActivityType;
        }
      }else {
        if(this.$route.query._b && this.$route.query._b == 'chat') {
          if(!this.job.bid) {
            window.location.href = window.location.origin + '/#/job/' + this.$route.params.code + '/chat/' + this.$root.encrypt((this.$root.user_id).toString());
          }else {
            window.location.href = window.location.origin + '/#/job/' + this.$route.params.code + '/chat/' + this.$root.encrypt((this.job.bid.vendor_id).toString());
          }
        }else if(this.jobActivityType == 'browse') {
          if(this.showAcceptPage) {
            this.$root.scrollToTop()
            this.showMainPage = true
          }else if(this.showConfirmPage){
            this.$root.scrollToTop()
            this.showConfirmPage = false
            this.showAcceptPage = true
          }else {
            window.location.href = window.location.origin + '/#/';
          }
        }else if(this.jobActivityType == 'bid') {
          if(this.showAcceptPage) {
            this.$root.scrollToTop()
            this.showMainPage = true
          }else if(this.showConfirmPage){
            this.$root.scrollToTop()
            this.showAcceptPage = true
          }else {
            window.location.href = window.location.origin + '/#/activity#'  + this.jobActivityType;
          }
        }else if(this.jobActivityType == 'won') {
          if(this.showIfJobCompletePage) {
            this.$root.scrollToTop()
            this.showMainPage = true
          }else {
            window.location.href = window.location.origin + '/#/activity#' + this.jobActivityType;
          }
        }else {
          window.location.href = window.location.origin + '/#/activity#' + this.jobActivityType;
        }


        // if(this.showConfirmPage) {
        //     this.showAcceptPage = true
        //   }else if(this.showAcceptPage) {
        //     this.$root.scrollToTop()
        //     this.showMainPage = true
        //     // this.showConfirmPage = false
        //     // this.showAcceptPage = false
        //   }else {
        //     window.location.href = window.location.origin + '/#/browse';
        //   }
      }
    },
    async getModels() {
      const $this = this
      this.$root.isLoading = true;
      await getJob(this.id, {
        with: 'bid.vendor.profile.avatar, client.profile.avatar, bid.promo.promo, promo.promo, cancel_request',
        // with_count: 'bid.vendor.merchant_reviews',
        custom: 'vendor_review_stats,payment_method'
      }).then(response => {
        // console.log(response.data)
        this.$root.isLoading = false;
        this.loaded = true
        this.job = response.data

        if(this.job.client_id == this.$root.user_id && this.$root.profile_view == 'merchant') {
          this.$root.forceChangeProfileView('client');
        }else if(this.job.client_id != this.$root.user_id && this.$root.profile_view == 'client' && this.$root.merchant_verified == 1) {
          this.$root.forceChangeProfileView('merchant');
        }
        
        if($this.job.client_id == $this.$root.user_id && !$this.job.bid && $this.job.client_activity_type != "complete") {
          $this.getBids()
        }else if(!$this.job.bid){
          $this.getMyBid()
        }

        //$this.showBidDetailsPage = true
        if($this.job.client_id == $this.$root.user_id && 
          ($this.job.client_activity_type == 'accepted') || 
            $this.job.client_activity_type == 'complete' && ($this.job.client_status == 'Complete' || $this.job.client_status == 'Paid')) {
          $this.showBidDetails($this.job.bid)
        }
        

        if(this.job.date_from == this.job.date_to) {
          this.form.date = this.job.date_from
        }

        if(this.job.type == 'fixed') {
          this.form.callout_fee = this.job.fixed_price
        }

        this.job.details.forEach(function(detail) {
          if(detail.question_type == 'image_video') {
            $this.getMediaFiles(detail)
          }else if(detail.question_type == 'date') {
            $this.job_date_detail = detail
          }else if(detail.question_type == 'time') {
            $this.job_time_detail = detail
          }
        });

        setTimeout(function() {
          $this.$root.manualAdjustScreen(100);
        }, 500)

        // console.log('TIME:')
        // console.log($this.job.time_from)
        // console.log($this.$root.convertTimeToLocal($this.job.time_from, 'HH'))
        // console.log(Number($this.$root.convertTimeToLocal($this.job.time_from, 'HH')) - 3)
        // console.log($this.job.time_to)
        // console.log($this.$root.convertTimeToLocal($this.job.time_to, 'HH'))
        // console.log(Number($this.$root.convertTimeToLocal($this.job.time_to, 'HH')) + 3)
      }).catch(error => {
        this.$root.isLoading = false;
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'An error was encountered. Please try again.',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = '/#/activity';
        });
        console.log(error)
      })
    },
    vendorBidProcess() {
      if(this.job.category.type == 'fixed') {
        if(this.job.settings && this.job.settings.merchant_rating_filter && this.job.settings.merchant_rating_filter == true && this.job.vendor_id == null) {
         
          console.log(moment.utc(this.job.created_at).toDate())
          let base_date = moment.utc(this.job.created_at).add(this.job.settings.merchant_rating_filter_minutes, 'm');
          console.log(base_date)
          console.log(moment().isSameOrAfter(base_date))
          if(moment().isSameOrAfter(base_date)) { //base date already passed
            return this.showAcceptPage = true;
          }else {
            if(this.job.settings.merchant_rating_filter_rating && 
            this.job.settings.merchant_current_ratings < this.job.settings.merchant_rating_filter_rating) {
              this.$swal.fire({
                title: 'Unqualified',
                text: "Due to your current merchant rating, you are not qualified to accept the job yet. Please wait until " + this.$root.convertTimeToLocal(base_date) + ' to be able accept.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#f44336',
              })
            }else {
              return this.showAcceptPage = true;
            }
          }
        }else {
          return this.showAcceptPage = true;
        }
      }else {
        return this.showAcceptPage = true;
      }

      return 
    },
    cancelPostJob() {
      const $this = this
      $this.$swal.fire({
          title: 'Cancel Job',
          text: "Are you sure you want to cancel this job?",
          icon: 'warning',
          confirmButtonText: 'Yes, Cancel',
          confirmButtonColor: '#f44336',
          showCancelButton: true,
          cancelButtonText: 'No, Don\'t Cancel'
      }).then((result) => {
          if (result.isConfirmed) {
            $this.cancelJob()
          }
      })
    },
    async cancelJob() {
      const $this = this
      this.$root.isLoading = true

      await cancelJob(this.job.id).then(() => {
          // console.log(response)
          // console.log('done')
          $this.$root.isLoading = false

          $this.$notify({
              title: "Job is Cancelled",
              type: 'success'
          });
          $this.$root.goTo('activity')
        }).catch(error => {
          console.log(error.response.data)
          $this.$root.isLoading = false

          this.$notify({
              title: "An error occured.",
              text: error.response.data.message,
              type: 'error'
          });
        })
    },
    pay() {
        const $this = this
        this.$swal({
            // icon: 'warning',
            // title: 'You have unsaved changes!',
            text: "Your money will not be released until the job has been confirmed completed!",
            confirmButtonText: 'OK',
            confirmButtonColor: '#e73644',
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
                // console.log('paid')
                $this.status = 'accept-paid'
                $this.total = 270
                $this.useCredits = false
            }else {
              //  console.log('canceld')
            }
        })
    },
    updateDate(type) {
      if(type == 'date') {
        this.form.date = moment(this.form.full_date).format('YYYY-MM-DD')
      }else if(type == 'time') {
        let mins = this.form.full_time.minutes == 0 ? '00' : this.form.full_time.minutes,
          hrs = this.form.full_time.hours >= 10 ? this.form.full_time.hours : '0' + (this.form.full_time.hours).toString();
        this.form.time = hrs + ':' + mins
      }
    },
    nextAcceptJob() {
      let noError = true;

      if(this.actual_callout_fee == '' && this.actual_callout_fee <= 0) {
        noError = false;
        this.$notify({
            title: "An error occured.",
            text: "Bid is required",
            type: 'error'
        });
      }else if(Number(this.actual_callout_fee) < 30) {
        noError = false;
        this.$notify({
            title: "An error occured.",
            text: "Min. Bid is $30.00",
            type: 'error'
        });
      }

      if(this.form.date == '') {
        noError = false;
        this.$notify({
            title: "An error occured.",
            text: "Date is required",
            type: 'error'
        });
      }

      if(this.form.time == '') {
        noError = false;
        this.$notify({
            title: "An error occured.",
            text: "Time is required",
            type: 'error'
        });
      }

      if(this.form.date != '' && this.form.time != '') {
        let now = moment()

        if(!now.isSameOrBefore(moment(this.form.date + ' ' + this.form.time))) {
          noError = false;
          this.$notify({
            title: "An error occured.",
            text: "Make sure that the date and time has not passed yet",
            type: 'error'
          });
        }
      }

      if(noError) {
        console.log('no error')
        this.showMainPage = false;
        this.showAcceptPage = false;
        this.showConfirmPage = true;
        
      }
    },
    async confirmAcceptJob() {
      // console.log('CONFIRMED')
      const $this = this
      this.$root.isLoading = true

      let formData = new FormData();
      if(this.$root.enable_recaptcha) formData.append('recaptcha_token', await this.$root.recaptcha('joba'));
      formData.append('job_id', this.job.id)
      formData.append('callout_fee', this.actual_callout_fee)
      formData.append('start_datetime', this.form.date)
      formData.append('date_start', this.form.date)
      formData.append('time_start', this.form.time)
      formData.append('comment', this.form.comment == '' || this.form.comment == null ? '' : this.form.comment);
      formData.append('type', 'callout')
      formData.append('is_corporate', 0)

      if(this.jobActivityType == 'bid' && !this.job.bid && this.bid && this.$root.user_id != this.job.client_id) {
        await editBid(this.bid.id, formData).then(() => {
            // console.log(response)
            // console.log('done')
            $this.$root.isLoading = false

            $this.$notify({
                title: "Congratulations!",
                text: "Bid update is Successful",
                type: 'success'
            });
            $this.$root.goTo('activity')
          }).catch(error => {
            console.log(error.response.data)
            $this.$root.isLoading = false

            this.$notify({
                title: "An error occured.",
                text: error.response.data.message,
                type: 'error'
            });
          })
      }else {
        await submitBid(formData).then(() => {
            // console.log(response)
            // console.log('done')
            $this.$root.isLoading = false

            $this.$notify({
                title: "Congratulations!",
                text: "Bid is Successful",
                type: 'success'
            });
            if($this.job.type == 'bidding')
              $this.$root.goTo('activity')
            else $this.$root.goTo('activity#won')
          }).catch(error => {
            console.log(error.response.data)
            $this.$root.isLoading = false

            this.$notify({
                title: "An error occured.",
                text: error.response.data.message,
                type: 'error'
            });
          })
      }
      
    },
    async getBids() {
      this.$root.isLoading = true;
      await getJobBids(this.id, {
        order_by: this.bids_filter.order.value,
        order_dir: this.bids_filter.order.dir,
        with: 'vendor.profile.avatar',
        custom: 'vendor_review_stats',
        status: 0
      }).then(response => {
        // console.log(response.data)
        this.$root.isLoading = false;
        this.loaded = true
        this.bids = response.data
      }).catch(error => {
        this.$root.isLoading = false;
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'An error was encountered. Please try again.',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = '/#/activity';
        });
        console.log(error)
      })
    },
    showBidDetails(bid) {
      this.bid = bid
      this.showBidDetailsPage = true
      // console.log(bid)
    },
    async getMediaFiles(detail) {
      if(detail.answer_array.length == 0) return;
      await getMediaFiles({
        ids: detail.answer_array.join()
      }).then(response => {
        detail.answer_array = response.data
        
      });
    },
    async getMyBid() {
      this.$root.isLoading = true;
      await getMyBid(this.id).then(response => {
        // console.log(response.data)
        this.$root.isLoading = false;
        this.loaded = true
        this.bid = response.data
      }).catch(error => {
        this.$root.isLoading = false;
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'An error was encountered. Please try again.',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = '/#/activity';
        });
        console.log(error)
      })
    },
    requestExtraFee() {
      if(Number(this.extra_fee_form.price) < 10) {
        this.$notify({
            title: "Extra fee must be more than $10",
            type: 'error'
        });
        this.extra_fee_form.price = '';
        return;
      }
      const $this = this
      $this.$swal.fire({
        title: 'Extra Fee Request',
          html:
            '<p style="text-align: left;"><strong>Payment</strong><br/>' +
            'Service Fee Charge $' + this.$root.commaDecimal(this.job.bid.callout_fee) + '<br/>' +
            'Extra Fee $' + this.$root.commaDecimal(this.extra_fee_form.price)  + '<br/>' +
            '<strong>Total</strong><br/>' +
            '$' + this.$root.commaDecimal(Number(this.extra_fee_form.price) + Number(this.job.bid.callout_fee)) + '</p>',
          confirmButtonText: 'CONFIRM $' + this.$root.commaDecimal(this.extra_fee_form.price),
          confirmButtonColor: '#f44336',
          showCancelButton: true,
          cancelButtonText: 'Cancel'
      }).then((result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            formData.append('price', this.extra_fee_form.price)
            $this.updateBid(formData)
          }else {
            this.extra_fee_form.price = ''
          }
      })
    },
    async updateBid(formData) {
      const $this = this
      this.$root.isLoading = true

      await updateBid(this.job.bid_id, formData).then(() => {
          // console.log(response)
          // console.log('done')
          $this.$root.isLoading = false

          location.reload();
        }).catch(error => {
          console.log(error.response.data)
          $this.$root.isLoading = false

          this.$notify({
              title: "An error occured.",
              text: error.response.data.message,
              type: 'error'
          });
        })
    },
    async getVendorBid(vendor) {
      const $this = this
      this.$root.isLoading = true
      await getVendorBid(this.id, vendor).then(response => {
          $this.$root.isLoading = false
          // console.log(response.data)
          // console.log('done')
          $this.bid = response.data
          $this.showBidDetailsPage = true
        }).catch(error => {
          // console.log(error.response.data)
          $this.$root.isLoading = false

          this.$notify({
              title: "An error occured.",
              text: error.response.data.message,
              type: 'error'
          });
        })
    },
    cancelExtraFeeRequest() {
      const $this = this
      this.$swal.fire({
          text: "Are you sure you want to remove extra fee request?",
          icon: 'warning',
          confirmButtonText: 'Continue',
          confirmButtonColor: '#f44336',
          showCancelButton: true,
      }).then((result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            formData.append('price', 0)
            $this.updateBid(formData)
          }
      })
    },
    setBidForm(bid) {
      let time = bid.time_start.split(':');
      let fee = (bid.callout_fee).toString().replace(/[^\d.]/g,'');
      fee = '$' + this.$root.commaDecimal(fee)
      this.form.callout_fee = fee
      this.form.date = bid.date_start
      this.form.full_date = new Date(bid.date_start)
      this.form.time = time[0] + ':' + time[1]
      this.form.full_time = {
        hours: time[0],
        minutes: time[1],
        seconds: time[2]
      }
      this.form.comment = bid.comment
    },
    cancelMyBid(bid) {
      const $this = this
      $this.$swal.fire({
          title: 'Cancel Service',
          text: "Are you sure you want to cancel this service?",
          icon: 'warning',
          confirmButtonText: 'Cancel',
          confirmButtonColor: '#f44336',
          showCancelButton: true,
          cancelButtonText: 'Close'
      }).then((result) => {
          if (result.isConfirmed) {
            $this.cancelBid(bid)
          }
      })
    },
    async cancelBid(bid) {
      const $this = this
      this.$root.isLoading = true

      await cancelBid(bid.id).then(() => {
          // console.log(response)
          // console.log('done')
          $this.$root.isLoading = false

          $this.$notify({
              title: "Bid is Cancelled",
              type: 'success'
          });
          $this.$root.goTo('activity')
        }).catch(error => {
          // console.log(error.response.data)
          $this.$root.isLoading = false

          this.$notify({
              title: "An error occured.",
              text: error.response.data.message,
              type: 'error'
          });
        })
    },
    addDollar() {
      // console.log(this.form.callout_fee)
      let val = (this.form.callout_fee).toString().replace(/[^\d.]/g,'');
      this.form.callout_fee = '$' + this.$root.commaDecimal(val)
    },
    merchantBreadcrumbClass(type){
      let class_str = ''
      if(type == 'bid') {
        class_str = 'active'
        // class_str = this.job.merchant_activity_type == 'bid' || this.job.merchant_status == 'Complete' ? 'active' : ''
      }else if(type == 'win') {
        if(this.job.merchant_activity_type == 'bid') {
          class_str = 'next'
        }else {
          class_str = this.job.merchant_activity_type == 'won' || this.job.merchant_activity_type == 'complete' ? 'active' : '';
        }
      }else if(type == 'complete') {
        if(this.job.merchant_activity_type == 'won') {
          class_str = 'next'
        }else {
          class_str = this.job.merchant_activity_type == 'complete' && this.job.merchant_status != 'Cancelled' ? 'active' : ''
        }
      }else if(type == 'paid') {
        if(this.job.merchant_activity_type == 'complete' && (this.job.merchant_status == 'Incomplete' || this.job.merchant_status == 'Pending')) {
          class_str = 'next'
        }else {
          class_str = this.job.merchant_activity_type == 'complete' && this.job.merchant_status == 'Complete' ? 'active' : ''
        }
      }

      return class_str;
    },
    async submitCancelRequestForm() {
      const $this = this

      if(!this.cancel_form.reason) {
        this.$notify({
            title: "An error occured.",
            text: 'Reason is required',
            type: 'error'
        });
        return;
      }

      if(this.cancel_form.reason == 'Other' && !this.cancel_form.memo) {
        this.$notify({
            title: "An error occured.",
            text: 'Comment is required',
            type: 'error'
        });
        return;
      }

      let formData = new FormData();
      formData.append('reason', this.cancel_form.reason);
      if(this.cancel_form.reason == 'Other') {
        formData.append('memo', this.cancel_form.memo);
      }

      $this.$root.isLoading = true;
      await requestCancelJob($this.job.id, formData).then(() => {
        $this.$root.isLoading = false;

        $this.$swal.fire({
            text: "The Ovvy Support Team will review your request and inform you once a decision has been made.",
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#f44336',
        }).then(() => {
          location.reload();
        })
      }).catch(error => {
        console.log(error.response.data)
        $this.$root.isLoading = false

        this.$notify({
            title: "An error occured.",
            text: error.response.data.message,
            type: 'error'
        });
      })
    }
  },
  created() {
    this.id = this.$root.decrypt(this.$route.params.code);
    this.getModels()

    if(this.$route.query._vid) {
      // console.log(this.$root.decrypt(this.$route.query._vid).toString());
      this.getVendorBid(this.$root.decrypt(this.$route.query._vid).toString());
    }

    // console.log(this.$route.query.status)
    if(this.$route.query.status) this.status = this.$route.query.status

    if(this.status == 'accept') {
        this.total = 30
    }else if(this.status == 'posted') {
        this.total = 75
    }else if(this.status == 'accept-paid') {
        this.total = 270
    }
  },
  mounted() {
    const $this = this
    window.Echo.channel('public')
      .listen("Jobs\\Updated", (e) => {
        console.log(e);
        if($this.id == e.id) {
          if($this.$root.user_id != e.vendor_id) {
            location.reload();
          }
        }
      });
    // let now = moment()
    // console.log(now)

    // console.log(now.isSameOrBefore(moment("2023-01-19 07:00")))
    // console.log(now.isSameOrBefore(moment("2023-01-20 07:00")))
    // console.log(now.isSameOrBefore(moment("2023-01-20 15:00")))
    // console.log(now.isSameOrBefore(moment("2023-01-20 16:30")))
    // console.log(now.isSameOrBefore(moment("2023-01-20 20:00")))
    // console.log(now.isSameOrBefore(moment("2023-01-21 07:00")))
  }
}
</script>

<style scoped>
#job-status-container {
  height: 50px;
  background-color: #4e4e4e;
  color: #fff;
  width: 50%;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  margin-bottom: 30px;
}

#merchant-job-status-container {
  height: 85px;
  background-color: #4e4e4e;
  color: #fff;
  width: 50%;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  margin-bottom: 30px;
}

#merchant-job-status-container small {
  color: #fff;
}

#jobStat-title {
  font-size: 20px;
}

.job-stat-title {
  text-align: center;
}


#merchant-job-status-container .col-lg-6 {
  width: 50%;
}

.text-right {
  text-align: right;
}

.circle-avatar {
	width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
}


.circle-avatar-40 {
  width: 40px;
  height: 40px;
  min-width: 40px;
  -webkit-border-radius: 40px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 40px;
  -moz-background-clip: padding;
  border-radius: 40px;
  background-clip: padding-box;
  margin: 7px 20px 0 0px;
  float: left;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 1025px) {
  #job-status-container, #merchant-job-status-container {
      width: 90%;
  }
}

@media (max-width: 768px) {
  #job-status-container {
    display: none;
    height: 75px;
    width: 100%;
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 0;
    padding-top: 10px;
    margin-bottom: 0px;
  }

  .footer-job-status-container {
    width: 100%;
    margin-bottom: 0px;
    background-color: #4e4e4e;
    color: #fff;
  }

  .footer-job-status-container p {
    font-size: 20px;
    margin-top: 1rem;
    margin-left: 10px;
  }

  #merchant-job-status-container {
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 0;
    padding-top: 10px;
    margin-bottom: 0px;
  }

  .job-stat-title {
    text-align: left;
  }

  .client-comlete-wrp {
    padding-bottom: 140px !important;
  }

}

@media (max-width: 500px) {
  .main-content-wrp {
    padding-bottom: 50px;
  }

  .merchant-comlete-wrp {
    padding-bottom: 75px;
  }
}

@media (max-width: 468px) {
  #merchant-job-status-container small {
    font-size: 11px;
  }
  #jobStat-title {
    font-size: 18px;
  }
}
</style>