<template>
    <section class="general-cleaning">    
        <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
            <div class="wrp">
                <h4>{{job.title}}</h4>
                <img :src="job.category.link_icon" style="width:45px;">
            </div>
            </div>
        </div>
        </div>
    </section>
    <section class="row-gray">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                <h6>Job ID</h6>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-white view-white">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
            <h6>{{job.id}}</h6>
            </div>
        </div>
    </div>
    </section>
    <section v-for="(question, l) in job.details" :key="l">
        <div v-if="question.question_type == 'image_video'">
            <div v-if="question.answer_array.length > 0" class="row-gray">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12">
                        <h6>{{question.question}}</h6>
                    </div>
                    </div>
                </div>
            </div>
            <div v-if="question.answer_array.length > 0" class="row-white">
                <div class="container-fluid">
                    <div class="row">
                        <!-- <div class="col-lg-12">
                        <div class="d-flex" style="flex-wrap: wrap;row-gap: 10px;column-gap: 10px;">
                            <div v-for="(answer, m) in question.answer_array" :key="m" class="img-wrp img-upload-cont">
                                <video v-if="answer.id && (answer.mimetype == 'video/mp4' || answer.mimetype == 'video/quicktime')" width="125" height="108" controls>
                                    <source :src ="answer.full_url" id="video_here">
                                        Your browser does not support HTML5 video.
                                </video>
                                <a v-else-if="answer.id && answer.mimetype != 'video/mp4' && answer.mimetype != 'video/quicktime'" @click="setGallery(answer)" 
                                    data-bs-toggle="modal" data-bs-target="#show-image-modal">
                                    <img :src="answer.full_url"/>
                                </a>
                            </div>
                        </div>
                        </div> -->

                        <div class="col-lg-12">
                            <div class="gallery-container">
                                <figure v-for="(answer, m) in question.answer_array" :key="m" :class="(answer.mimetype == 'video/mp4' || answer.mimetype == 'video/quicktime') ? 'video' : ''">
                                    <video v-if="answer.id && (answer.mimetype == 'video/mp4' || answer.mimetype == 'video/quicktime')" 
                                        controls>
                                        <source :src ="answer.full_url" id="video_here">
                                            Your browser does not support HTML5 video.
                                    </video>
                                    <a v-else-if="answer.id && answer.mimetype != 'video/mp4' && answer.mimetype != 'video/quicktime'" @click="setGallery(answer)" 
                                        data-bs-toggle="modal" data-bs-target="#show-image-modal">
                                        <img :src="answer.full_url"/>
                                    </a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="question.question_type == 'instruction'">
            <div class="row-gray">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12">
                        <h6>{{question.category_question.label}}</h6>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row-white">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                        <div class="d-flex justify-content-between">
                            <span v-html="question.answer"></span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row-gray">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-12">
                        <h6>{{question.question}}</h6>
                    </div>
                    </div>
                </div>
            </div>
            <div v-if="question.question_type == 'time'" class="row-white">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                        <div class="d-flex">
                            <div v-for="(answer, m) in question.answer_array" :key="m" class="mb-2 time-cont">
                            <span class="">{{answer.title}}</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="!question.answer_array || question.question_type == 'instruction' || question.question_type == 'text-field'" class="row-white">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                        <div class="d-flex justify-content-between">
                            <span v-if="question.question_type == 'instruction'" v-html="question.answer"></span>
                            <h6 v-else>
                                {{$root.capitalizeFirstLetter(question.answer)}}
                            </h6>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else v-for="(answer, m) in question.answer_array" :key="m" class="row-white">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                        <div v-if="question.question_type == 'date'" class="d-flex justify-content-between">
                            <h6>
                                <span>{{$root.formatDate(answer.date_from, 'ddd Do MMM')}}</span>
                                <span v-if="answer.date_from != answer.date_to"> - {{$root.formatDate(answer.date_to, 'ddd Do MMM')}}</span> <br/>
                            </h6>
                        </div>
                        <div v-else-if="question.question_type == 'time'" class="justify-content-between">
                            
                        </div>
                        <div v-else class="d-flex justify-content-between">
                            <h6>
                                {{answer.name}}
                                <br/>
                                <small v-if="answer.description">{{answer.description}}</small>
                            </h6>
                            <h6 v-if="answer.showCounterOnFe == 1">x {{answer.quantity}}</h6>
                            <h6 v-if="answer.price && answer.price > 0">${{$root.insertDecimal(answer.price)}}</h6>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="question.question_type == 'time'">
                <div class="row-gray">
                    <div class="container-fluid">
                        <div class="row">
                        <div class="col-lg-12">
                            <!-- <h6>Allow Merchants to counter propose time</h6> -->
                            <h6>Allow Merchants to counter-propose within a window of plus or minus 3 hours from the time you've selected above</h6>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row-white">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                            <div class="d-flex justify-content-between">
                                <h6>
                                    {{job.allow_counter_proposal ? 'Yes' : 'No'}}
                                </h6>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>

    <section v-for="(location, l) in job.locations" :key="l">
        <div class="row-gray">
        <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12">
                <h6>{{location.question}}</h6>
            </div>
            </div>
        </div>
        </div>
        <div class="row-white">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 email">
                    <a :href="$root.getGMapsLink(location)" target="_blank" class="d-flex justify-content-between black-color">
                        <h6>{{$root.getFullAddress(location)}}</h6>
                        <i class="fa fa-angle-right" style="float: right;"></i>
                    </a>
                </div>
            </div>
        </div>
        </div>
    </section>

    <section v-if="job.type == 'fixed' && jobActivityType == 'post'">
        <div class="row-gray">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <h6>Payment Amount</h6>
                </div>
            </div>
          </div>
        </div>
        <div class="amonut">
          <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <h6>Service Fee Charge</h6>
                    <h5>${{this.$root.insertDecimal(job.fixed_price)}}</h5>
                  </div>

                  <div v-if="job.fixed_price_credit > 0" class="d-flex justify-content-between">
                      <h6>Credits Used</h6>
                      <h5>- ${{this.$root.insertDecimal(job.fixed_price_credit)}}</h5>
                  </div>

                  <div v-if="job.promo" class="d-flex justify-content-between txt-green">
                      <h6>Promo({{ job.promo.promo.promo_code }})</h6>
                      <h5>- ${{this.$root.insertDecimal(Number(job.promo.discount))}}</h5>
                  </div>

                  <hr/>
                </div>
                <div v-if="job.payment_method" class="col-lg-12">
                    <h6>Payment Used: </h6>
                    <div>
                        <img v-if="job.payment_method.card && job.payment_method.card.brand == 'visa'" src="~@/assets/images/visa.png" style="max-width: 40px;"/> 
                        <img v-else-if="job.payment_method.card && job.payment_method.card.brand == 'mastercard'" src="~@/assets/images/mastercard.png" style="max-width: 40px;"/>
                        <i v-else-if="job.payment_method.card" class="fa fa-credit-card-alt" aria-hidden="true" style="font-size: 20px;"></i>
                        <strong> {{job.payment_method.card.last4}}</strong>
                    </div>
                </div>

            </div>
          </div>
        </div>
    </section>
    
    <section v-if="job.type == 'fixed' && jobActivityType == 'post'" class="mt-3">
        <div class="container-fluid">
        <div class="">
            <div class="row">
            <div class="col-lg-12 text-center">
                <div class="total-amonut-2">
                <h6>Total</h6>
                <h5>
                    $ {{this.$root.insertDecimal(final_total)}}
                    <!-- <br v-if="job.fixed_price != final_total"/>
                    <span v-if="job.fixed_price != final_total" style="text-decoration: line-through;font-size: 90%;">$ {{this.$root.insertDecimal(job.fixed_price)}}</span> -->
                </h5>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>

    <!-- BUTTONS -->
    <section v-if="job.client_id == $root.user_id && jobActivityType == 'post' && job.bid_count > 0">
        <div class="container-fluid mt-2">
            <div class="">
            <div class="row">
                <div class="col-lg-12 text-center">
                <button @click="cancelPostJob()" type="button" class="btn btn-light w100 paid-btn">
                    Cancel
                </button>
                </div>
            </div>
            </div>
        </div>
    </section>
    
    <div class="modal" id="show-image-modal">
        <div class="modal-dialog  modal-xl modal-dialog-centered">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <div class="title">
                    </div>
                    <div class="close">
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                </div>
                <!-- Modal body -->
                <div v-if="hasGallery" class="modal-body">
                    <v-zoomer ref="galleryZoom">
                    <img :src="gallery.full_url" style="width: 100%"/>
                    </v-zoomer>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { cancelJob } from "@/api"

  export default {
    name: 'JobDetail',
    components: {
    },
    props: {
        job: Object,
    },
    data() {
      return {
        loaded: false,
        gallery: {},
        hasGallery: false
      }
    },
    computed: {
      jobActivityType() {
        return this.job.client_activity_type
        // if(this.job.status == 'open' && !this.job.bid) return 'posts'
        // return this.job.status;
      },
      final_total() {
          let total = this.job.fixed_price

          if(this.job.promo) {
              total -= Number(this.job.promo.discount)
          }

          total -= Number(this.job.fixed_price_credit)

          return total
        },
    },
    methods: {
        cancelPostJob() {
            const $this = this
            $this.$swal.fire({
                title: 'Cancel Job',
                text: "Are you sure you want to cancel this job?",
                icon: 'warning',
                confirmButtonText: 'Yes, Cancel',
                confirmButtonColor: '#f44336',
                showCancelButton: true,
                cancelButtonText: 'No, Don\'t Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    $this.cancelJob()
                }
            })
        },
        async cancelJob() {
            const $this = this
            this.$root.isLoading = true

            await cancelJob(this.job.id).then(() => {
                // console.log(response)
                // console.log('done')
                $this.$root.isLoading = false

                $this.$notify({
                    title: "Job is Cancelled",
                    type: 'success'
                });
                $this.$root.goTo('activity')
            }).catch(error => {
                console.log(error.response.data)
                $this.$root.isLoading = false

                this.$notify({
                    title: "An error occured.",
                    text: error.response.data.message,
                    type: 'error'
                });
            })
        },
        setGallery(image) {
            this.gallery = image
            this.hasGallery = true
            this.$refs.galleryZoom.reset();
        }
    },
    created() {
      this.id = this.job.id

        
    }
  }
  </script>