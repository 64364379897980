export default {
  BANKS: "/banks",
  BANNERS: "/banners",
  BIDS: "/bids",
  BLOCKED_MERCHANTS: "/blocked-merchants",
  CASH_OUTS: "/cash_outs",
  CATEGORIES: {
    CATEGORIES: "/categories",
    MERCHANTS: "/categories/merchants",
    CLIENTS: "/categories/clients",
  },
  CHATS: "/chats",
  DEALS: "/deals",
  OTP: {
    OTP: "/otp",
    SEND: "/otp/send",
    VERIFY: "/otp/verify"
  },
  MEDIA_FILES: "/media_files",
  REGISTER: "/auth/signup",
  FACEBOOK: "/auth/facebook",
  APPLE: "/auth/apple",
  LOGIN: "/auth/login",
  LOGIN_CHECK: "/auth/login/check",
  VERIFIED_CHECK: "/auth/verified/check",
  FORGOT_PASS: "/auth/forgot",
  RESET_PASS: "/auth/reset",
  UPDATE_AUTH: "/auth/update",
  USERS: "/users",
  SERVICEABLE_LOCATIONS: '/serviceable_locations',
  SERVICE_TYPES: '/service_types',
  JOBS: '/jobs',
  USER_LOCATIONS: '/user_locations',
  USER_GALLERIES: '/user_galleries',
  PAYMENT_METHODS: '/payment_methods',
  PROMOS: '/promos',
  CREDITS: '/credits',
  TOKEN_LOGIN: "/auth/token_login",
  ACCOUNT_DELETE_REQUEST: "/account/delete/request",
  ACCOUNT_DELETE_FEEDBACK: "/account/delete/feedback",
  GET_ACTIVE_ACCOUNT_DELETE_REQUEST: "/account/delete/request/active",
  INTERESTS: "/interests",
  LANGUAGES: "/languages",
  NEWSFEEDS: "/news_feeds",
  REDEEMS: "/redeems",
  REVIEWS: "/reviews",
  REWARDS: "/rewards",
  DISPUTES: "/disputes",
  FAVOURITES: "/favourites",
  NOTIFICATIONS: "/notifications"
};
